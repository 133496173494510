import React from 'react';
import PropTypes from 'prop-types';

const IconLogo = props => {
  const { className, ...rest } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="39" height="19" viewBox="0 0 49 24" fill="none">
    <path d="M28.96 0.44C31.7333 0.44 33.9333 1.28 35.56 2.96C37.2133 4.64 38.04 6.97333 38.04 9.96V23H31.24V10.88C31.24 9.44 30.8533 8.33333 30.08 7.56C29.3333 6.76 28.2933 6.36 26.96 6.36C25.6267 6.36 24.5733 6.76 23.8 7.56C23.0533 8.33333 22.68 9.44 22.68 10.88V23H15.88V10.88C15.88 9.44 15.4933 8.33333 14.72 7.56C13.9733 6.76 12.9333 6.36 11.6 6.36C10.2667 6.36 9.21333 6.76 8.44 7.56C7.69333 8.33333 7.32 9.44 7.32 10.88V23H0.48V0.679999H7.32V3.48C8.01333 2.54667 8.92 1.81333 10.04 1.28C11.16 0.719999 12.4267 0.44 13.84 0.44C15.52 0.44 17.0133 0.8 18.32 1.52C19.6533 2.24 20.6933 3.26667 21.44 4.6C22.2133 3.37333 23.2667 2.37333 24.6 1.6C25.9333 0.826666 27.3867 0.44 28.96 0.44Z" fill="#01243C"/>
    <path d="M44.0238 23.32C42.8238 23.32 41.8371 22.9733 41.0638 22.28C40.3171 21.56 39.9438 20.68 39.9438 19.64C39.9438 18.5733 40.3171 17.68 41.0638 16.96C41.8371 16.24 42.8238 15.88 44.0238 15.88C45.1971 15.88 46.1571 16.24 46.9038 16.96C47.6771 17.68 48.0638 18.5733 48.0638 19.64C48.0638 20.68 47.6771 21.56 46.9038 22.28C46.1571 22.9733 45.1971 23.32 44.0238 23.32Z" fill="#1982A4"/>
    </svg>
  );
};


const { string } = PropTypes;

IconLogo.defaultProps = {
  className: null,
};

IconLogo.propTypes = {
  className: string,
};

export default IconLogo;
