import lillmossenImg from './assets/placeholder.jpg';
import kolvallenImg from './assets/kolvallen.jpg';
import gronhultImg from './assets/VattenfallGronhult.png';


export const medlaProjects = [
  {
    id: 'lillmossen',
    Områdes_ID: 'lillmossen',
    Projektnamn: 'Lillmossen',
    Verksamhetsutövare: 'Medla',
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "May 02, 2021",
    Planerat_drifttagande: "Dec 12, 2024",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vadstena",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "May 02, 2019",

    // Medla config
    // Both Områdes_ID and id are needed and should follow the standard format "project-name"

    image: lillmossenImg,
    location: {
      lat: 63.166695,
      lng: 17.27748,
    },
    bounds: {
        ne: '63.96074168,17.70965133',
        sw: '60.28740006,13.58712057',
    },
    summary: 'Medlas exempel med 32 vindkraftverk med en totalhöjd om högst 290 m. Projektområdet är beläget ca 25 km söder om Umeå, ca 20 km norr om Normaling och ca 8 km nordväst om Hörnefors.',
    summaryEn: 'Medlas example with 32 wind turbines with a total height of no more than 290 m. The project area is located approx. 25 km south of Umeå, approx. 20 km north of Normaling and approx. 8 km northwest of Hörnefors.',
    about: 'Medlas exempel med 32 vindkraftverk med en totalhöjd om högst 290 m. Projektområdet är beläget ca 25 km söder om Umeå, ca 20 km norr om Normaling och ca 8 km nordväst om Hörnefors.',
    aboutEn: 'Medlas example with 32 wind turbines with a total height of no more than 290 m. The project area is located approx. 25 km south of Umeå, approx. 20 km north of Normaling and approx. 8 km northwest of Hörnefors.',
    externalLink: 'https://www.medla.app/anslut-projekt',
  },
  {
    id: "tomasliden",
    Områdes_ID: "tomasliden",
    Projektnamn: "Tomasliden",
    Verksamhetsutövare: "WPD Onshore Tomasliden AB",
    Organisationsnummer: "556898-0964",
    Aktuella_verk: 10,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 185,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Oct 01, 2022",
    Planerat_drifttagande: "Jul 01, 2024",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Norsjö",
    Län: "Västerbottens län",
    Elområde: "Luleå",
    Senast_sparad: "Oct 17, 2022",

    image: lillmossenImg, 
    location: {
      lat: 64.914148,
      lng: 19.483077,
    },
    bounds: {
      ne: '65.43224223,20.37332522',
      sw: '64.36842344,18.30862978',
    },
    summary: 'wpd planerar att uppföra 10 vindkraftverk med 200 m totalhöjd på Tomasliden. Projektområdet ligger i Norsjö kommun, cirka 10 km väster om Bastuträsk och 20 km sydost om samhället Norsjö.',
    summaryEn: 'wpd plans to erect 10 wind turbines with a total height of 200 m on Tomasliden. The project area is located in Norsjö municipality, approximately 10 km west of Bastuträsk and 20 km southeast of the community Norsjö.',
    about: 'wpd planerar att uppföra 10 vindkraftverk med 200 m totalhöjd på Tomasliden. Projektområdet ligger i Norsjö kommun, cirka 10 km väster om Bastuträsk och 20 km sydost om samhället Norsjö. Förstärkning och byggnation av tillfartsvägar och vägar inom vindkraftsparken beräknas starta i början av november 2022. Fortsatt byggnation av vägar, kranplaner samt anläggande av fundament och kablar planeras ske med start våren 2023. Installation av vindkraftverken planeras ske under sommaren 2024.',
    aboutEn: 'wpd plans to erect 10 wind turbines with a total height of 200 m on Tomasliden. The project area is located in Norsjö municipality, approximately 10 km west of Bastuträsk and 20 km southeast of the community Norsjö. Reinforcement and construction of access roads and roads within the wind farm is expected to start at the beginning of November 2022. Continued construction of roads, crane planes and construction of foundations and cables is planned to take place starting in the spring of 2023. Installation of the wind turbines is planned to take place during the summer of 2024.',
    externalLink: 'https://www.wpd.se/aktuella-projekt/vindkraft-pa-land/tomasliden/',

  },
  {
    id: 'velinga',
    Områdes_ID: 'velinga',
    Projektnamn: 'Velinga',
    Verksamhetsutövare: 'Vattenfall Vindkraft AB',
    Organisationsnummer: '556581-4273',
    Aktuella_verk: 12,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: '2023',
    Planerat_drifttagande: '2025',
    Området_ej_aktuellt_i_sin_helhet: 'Ja',
    Kommun: 'Tidaholm',
    Län: 'Västra Götalands län',
    Elområde: 'Stockholm',
    Senast_sparad: 'Apr 11 2023',

    image: gronhultImg,
    location: {
      lat: 58.09215,
      lng: 14.009379,
    },
    bounds: {
      ne: '58.28604025,14.28744251',
      sw: '57.90795458,13.6912549',
    },
    summary: 'I början av 2024 påbörjade Vattenfall byggnationen av Velinga vindkraftpark med 12 vindkraftverk med en totalhöjd av 180 meter. Projektområdet ligger i Tidaholms kommun i Västra Götalands län.',
    summaryEn: 'At the beginning of 2024, Vattenfall started the construction of the Velinga wind park, featuring 12 wind turbines with a total height of 180 meters. The project area is located in the municipality of Tidaholm, Västra Götaland County.',
    about: 'I början av 2024 påbörjade Vattenfall byggnationen av Velinga vindkraftpark med 12 vindkraftverk med en totalhöjd av 180 meter. Projektområdet ligger i Tidaholms kommun i Västra Götalands län. I januari 2024 etablerades ett platskontor och därefter fortsätter arbetet med infrastruktur såsom vägar, fundament, uppställningsplatser och kabeldragning. Under sommar 2025 påbörjas transport och montering av vindkraftverken. Parken beräknas vara i drift i början av 2026.',
    aboutEn: 'At the beginning of 2024, Vattenfall started the construction of the Velinga wind park, featuring 12 wind turbines with a total height of 180 meters. The project area is located in the municipality of Tidaholm, Västra Götaland County. In January 2024, a site office was established and then work continues with infrastructure such as roads, foundations, staging areas and cable laying. During the summer of 2025, transport and assembly of the wind turbines will begin. The park is expected to be operational in early 2026.',
    externalLink: 'http://www.vattenfall.se/velingavind',
},
];

export const externalProjects = [
  {
    Områdes_ID: "2161-V-008",
    Projektnamn: "Kölvallen",
    Verksamhetsutövare: "Arise Windpower AB",
    Organisationsnummer: "556274-6726",
    Aktuella_verk: 43,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 305.3,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "2022",
    Planerat_drifttagande: "2024",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ljusdal",
    Län: "Gävleborgs län",
    Elområde: "Sundsvall",
    Senast_sparad: "Oct 14, 2021",

    // Medla config

    image: kolvallenImg,
    lat: 61.830080,
    lng: 16.090436,
    ne: '63.03626025,18.0991935',
    sw: '60.59913922,14.2480015',
    about: 'Arise utvecklar projektet Kölvallen i närheten av Sveg. Kölvallen är ett systerprojekt till projektet Skaftåsen, 35 turbiner, som för närvarande är under byggnation. Kölvallen har 42-43 fullt tillståndsgivna turbiner med en totalhöjd på 220 m. Vi hoppas på att kunna starta bygget under andra halvan av 2022 och avsluta under 2024.',
    externalLink: 'https://www.arise.se/',
  },
  {
    Områdes_ID: "0584-V-018",
    Projektnamn: "Kattorp 1",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vadstena",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0584-V-019",
    Projektnamn: "Bårstad 2",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vadstena",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0584-V-020",
    Projektnamn: "Åbylund",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vadstena",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 10, 2021",
  },
  {
    Områdes_ID: "0584-V-021",
    Projektnamn: "Bondorlunda",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 4,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vadstena",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0584-V-022",
    Projektnamn: "Granby",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vadstena",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 06, 2019",
  },
  {
    Områdes_ID: "1493-V-008",
    Projektnamn: "Brodderud",
    Verksamhetsutövare: "Svenska Vindbolaget AB",
    Organisationsnummer: "556759-9013",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mariestad",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 20, 2020",
  },
  {
    Områdes_ID: "2284-V-017",
    Projektnamn: "Sidensjö",
    Verksamhetsutövare: "Sidensjö Vindkraft AB",
    Organisationsnummer: "556799-1806",
    Aktuella_verk: 48,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 433.16,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Jun 01, 2013",
    Planerat_drifttagande: "Jun 01, 2015",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Örnsköldsvik",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2260-V-001",
    Projektnamn: "Holmsjöåsen",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ånge",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "Feb 07, 2020",
  },
  {
    Områdes_ID: "2283-V-023",
    Projektnamn: "Isbillen-Kullmyran",
    Verksamhetsutövare: "NordanVind vindkraft AB",
    Organisationsnummer: "556574-0403",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 28,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "Dec 01, 2022",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sollefteå",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "Sep 10, 2021",
  },
  {
    Områdes_ID: "2281-V-006",
    Projektnamn: "Jenåsen",
    Verksamhetsutövare: "Wind Farm Jenasen AB",
    Organisationsnummer: "556935-0373",
    Aktuella_verk: 23,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 115,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sundsvall",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "Oct 07, 2021",
  },
  {
    Områdes_ID: "2281-V-007",
    Projektnamn: "Kråktorpet",
    Verksamhetsutövare: "Svenska Vindbolaget AB",
    Organisationsnummer: "556759-9013",
    Aktuella_verk: 52,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 260,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "Oct 01, 2019",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sundsvall",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "Jan 26, 2021",
  },
  {
    Områdes_ID: "0583-V-014",
    Projektnamn: "Kämpelandet",
    Verksamhetsutövare: "Universal Power AB",
    Organisationsnummer: "556726-3859",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Motala",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2283-V-024",
    Projektnamn: "Kärmsjön",
    Verksamhetsutövare: "NordanVind vindkraft AB",
    Organisationsnummer: "556574-0403",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Sollefteå",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "2280-V-014",
    Projektnamn: "Lindomsberget",
    Verksamhetsutövare: "Gamesa Energy Sweden AB",
    Organisationsnummer: "556814-4181",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Härnösand",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "2260-V-002",
    Projektnamn: "Vindkraftpark Långåsen",
    Verksamhetsutövare: "RWE Renewables Sweden AB",
    Organisationsnummer: "556938-6864",
    Aktuella_verk: 7,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 42,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ånge",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "Nov 01, 2021",
  },
  {
    Områdes_ID: "2280-V-008",
    Projektnamn: "Möckelsjöberget",
    Verksamhetsutövare: "Möckelsjö Energi AB",
    Organisationsnummer: "556756-0882",
    Aktuella_verk: 5,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 30,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Härnösand",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2281-V-008",
    Projektnamn: "Nylandsbergen, Getåsen, Rödsjöåsen.",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 18,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 180,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "Aug 01, 2019",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sundsvall",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "Aug 13, 2019",
  },
  {
    Områdes_ID: "2283-V-025",
    Projektnamn: "Ranasjön",
    Verksamhetsutövare: "Krange Vind AB",
    Organisationsnummer: "556957-8056",
    Aktuella_verk: 40,
    Antal_ej_koordinatsatta_verk: 9,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Oct 01, 2021",
    Planerat_drifttagande: "Jun 01, 2024",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sollefteå",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "Dec 02, 2021",
  },
  {
    Områdes_ID: "0980-V-044",
    Projektnamn: "Väskinde Skäggs 3",
    Verksamhetsutövare: "Stefan Widen AB",
    Organisationsnummer: "556651-9715",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.51,
    Uppmätt_årsproduktion_GWh: 1.21,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0583-V-026",
    Projektnamn: "Ödeby",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Motala",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "May 02, 2019",
  },
  {
    Områdes_ID: "0583-V-027",
    Projektnamn: "Medevi",
    Verksamhetsutövare: "Cloudberry Wind AB",
    Organisationsnummer: "556795-5074",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Motala",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0583-V-028",
    Projektnamn: "Rådåsa",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Motala",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 05, 2019",
  },
  {
    Områdes_ID: "0583-V-029",
    Projektnamn: "Åsa",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Motala",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 05, 2019",
  },
  {
    Områdes_ID: "2260-V-004",
    Projektnamn: "Vindkraftpark Östavall",
    Verksamhetsutövare: "RWE Renewables Sweden AB",
    Organisationsnummer: "556938-6864",
    Aktuella_verk: 36,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ånge",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "Nov 01, 2021",
  },
  {
    Områdes_ID: "2462-V-006",
    Projektnamn: "Klimpfjäll",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vilhelmina",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "Apr 29, 2019",
  },
  {
    Områdes_ID: "2283-V-030",
    Projektnamn: "Storsjöhöjden",
    Verksamhetsutövare: "FORSCA AB",
    Organisationsnummer: "556839-1915",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 11,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Sollefteå",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "0980-V-015",
    Projektnamn: "Lojstahed",
    Verksamhetsutövare: "Gaimald AB",
    Organisationsnummer: "556823-4628",
    Aktuella_verk: 66,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2260-V-006",
    Projektnamn: "Klevberget 2",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 97.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ånge",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1080-V-009",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Karlskrona",
    Län: "Blekinge län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2418-V-003",
    Projektnamn: "Hornberget etapp 2",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Malå",
    Län: "Västerbottens län",
    Elområde: "Luleå",
    Senast_sparad: "Mar 23, 2021",
  },
  {
    Områdes_ID: "1080-V-011",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Karlskrona",
    Län: "Blekinge län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2482-V-012",
    Projektnamn: "Blåbergsliden",
    Verksamhetsutövare: "Holmen energi AB",
    Organisationsnummer: "556524-8456",
    Aktuella_verk: 29,
    Antal_ej_koordinatsatta_verk: 3,
    Beräknad_årsproduktion_GWh: 397.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Apr 01, 2020",
    Planerat_drifttagande: "Dec 01, 2022",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Skellefteå",
    Län: "Västerbottens län",
    Elområde: "Luleå",
    Senast_sparad: "Jan 12, 2022",
  },
  {
    Områdes_ID: "2482-V-013",
    Projektnamn: "Ljusvattnet",
    Verksamhetsutövare: "Jämtkraft AB",
    Organisationsnummer: "556001-6064",
    Aktuella_verk: 37,
    Antal_ej_koordinatsatta_verk: 37,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Skellefteå",
    Län: "Västerbottens län",
    Elområde: "Luleå",
    Senast_sparad: "Aug 27, 2021",
  },
  {
    Områdes_ID: "1080-V-012",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Karlskrona",
    Län: "Blekinge län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1080-V-013",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Karlskrona",
    Län: "Blekinge län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2417-V-001",
    Projektnamn: "Brinken",
    Verksamhetsutövare: "Brinken Vind AB",
    Organisationsnummer: "556850-5043",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Norsjö",
    Län: "Västerbottens län",
    Elområde: "Luleå",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "1384-V-005",
    Projektnamn: "Dal",
    Verksamhetsutövare: "Gamesa Energy Sweden AB",
    Organisationsnummer: "556814-4181",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 21.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "Dec 27, 2011",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kungsbacka",
    Län: "Hallands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0685-V-016",
    Projektnamn: "Kullboarp",
    Verksamhetsutövare: "Greenextreme AB",
    Organisationsnummer: "556722-4596",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vetlanda",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 08, 2021",
  },
  {
    Områdes_ID: "0685-V-018",
    Projektnamn: "Erikshester",
    Verksamhetsutövare: "Smålands Miljöenergi AB",
    Organisationsnummer: "556784-5168",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 11.4,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vetlanda",
    Län: "Jönköpings län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1473-V-016",
    Projektnamn: "Underbacken",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 2.58,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Töreboda",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0563-V-008",
    Projektnamn: "Hovgården Stavlösa",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5.25,
    Uppmätt_årsproduktion_GWh: 5.28,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vadstena",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0584-V-024",
    Projektnamn: "Medhamra 1",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5,
    Uppmätt_årsproduktion_GWh: 3.13,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vadstena",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0584-V-025",
    Projektnamn: "Medhamra 2",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5,
    Uppmätt_årsproduktion_GWh: 3.27,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vadstena",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0980-V-053",
    Projektnamn: "Lau Liffride Nr 1 & 2",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0980-V-055",
    Projektnamn: "Hellvi Smöjen",
    Verksamhetsutövare: "Slitevind AB",
    Organisationsnummer: "556453-2819",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Jan 09, 2019",
  },
  {
    Områdes_ID: "0980-V-063",
    Projektnamn: "Hall Vindpark",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 9,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0980-V-006",
    Projektnamn: "Storugns vindpark",
    Verksamhetsutövare: "Siral System & Co AB",
    Organisationsnummer: "556171-8296",
    Aktuella_verk: 11,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 31.76,
    Uppmätt_årsproduktion_GWh: 26.81,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 09, 2019",
  },
  {
    Områdes_ID: "0980-V-064",
    Projektnamn: "Risugns Vindpark",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1080-V-001",
    Projektnamn: "Yttre stengrund",
    Verksamhetsutövare: "Vattenfall Vindkraft AB",
    Organisationsnummer: "556581-4273",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Karlskrona",
    Län: "Blekinge län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0840-V-040",
    Projektnamn: "Utgrunden I",
    Verksamhetsutövare: "Vattenfall Vindkraft AB",
    Organisationsnummer: "556581-4273",
    Aktuella_verk: 7,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 37.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mörbylånga",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Aug 10, 2021",
  },
  {
    Områdes_ID: "0586-V-044",
    Projektnamn: "Näsby/Överberg",
    Verksamhetsutövare: "Örbacken Energi Handelsbolag",
    Organisationsnummer: "969715-2594",
    Aktuella_verk: 7,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 41.3,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mjölby",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0580-V-022",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Linköping",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "May 10, 2019",
  },
  {
    Områdes_ID: "0583-V-003",
    Projektnamn: "Fingal af Boberg",
    Verksamhetsutövare: "Gunnarssons Vindkraft AB",
    Organisationsnummer: "556704-3483",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.75,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Motala",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1290-V-040",
    Projektnamn: "Östra Ljungby",
    Verksamhetsutövare: "Fred Olsen Renewables AB",
    Organisationsnummer: "556591-2077",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Aug 29, 2019",
  },
  {
    Områdes_ID: "1290-V-068",
    Projektnamn: "Vånga",
    Verksamhetsutövare: "Statkraft Södra Vindarrende AB",
    Organisationsnummer: "556091-8772",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 15, 2021",
  },
  {
    Områdes_ID: "1498-V-048",
    Projektnamn: "Hångsdala Falan",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tidaholm",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Aug 26, 2019",
  },
  {
    Områdes_ID: "0586-V-046",
    Projektnamn: "Ebborp Gårdsverk",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mjölby",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "May 02, 2019",
  },
  {
    Områdes_ID: "0583-V-031",
    Projektnamn: "Kämpelandet",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Motala",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 05, 2019",
  },
  {
    Områdes_ID: "0586-V-050",
    Projektnamn: "Hulterstad",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mjölby",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "May 02, 2019",
  },
  {
    Områdes_ID: "0586-V-051",
    Projektnamn: "Bötterstad",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mjölby",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Apr 30, 2019",
  },
  {
    Områdes_ID: "0586-V-052",
    Projektnamn: "Narvered",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mjölby",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "May 02, 2019",
  },
  {
    Områdes_ID: "0586-V-053",
    Projektnamn: "Vallerstads-Östanbäck",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mjölby",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "May 02, 2019",
  },
  {
    Områdes_ID: "0586-V-059",
    Projektnamn: "Vistena 15-2",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mjölby",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "May 02, 2019",
  },
  {
    Områdes_ID: "0586-V-062",
    Projektnamn: "Tungelunda Skeby",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mjölby",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "May 02, 2019",
  },
  {
    Områdes_ID: "0586-V-064",
    Projektnamn: "Bjälbo",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5.4,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mjölby",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 25, 2019",
  },
  {
    Områdes_ID: "0586-V-065",
    Projektnamn: "Vistena 15-1",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.6,
    Uppmätt_årsproduktion_GWh: 1.72,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mjölby",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0586-V-066",
    Projektnamn: "Vistena 18-1",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mjölby",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0586-V-070",
    Projektnamn: "Klackeborg",
    Verksamhetsutövare: "Mjölby-Svartådalen Energi AB",
    Organisationsnummer: "556093-1593",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 15.99,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mjölby",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Mar 11, 2020",
  },
  {
    Områdes_ID: "0583-V-032",
    Projektnamn: "Ekebyborna-Mörby",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Motala",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0583-V-033",
    Projektnamn: "Rocklunda",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Motala",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "May 02, 2019",
  },
  {
    Områdes_ID: "0583-V-035",
    Projektnamn: "Vinberga",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Motala",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 10, 2021",
  },
  {
    Områdes_ID: "0583-V-036",
    Projektnamn: "Stenby-Örvad",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Motala",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 10, 2021",
  },
  {
    Områdes_ID: "0583-V-037",
    Projektnamn: "Fossala",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Motala",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 10, 2021",
  },
  {
    Områdes_ID: "0583-V-038",
    Projektnamn: "Varvs-Skrikstad",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Motala",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 10, 2021",
  },
  {
    Områdes_ID: "0583-V-039",
    Projektnamn: "Storeberg",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Motala",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0583-V-040",
    Projektnamn: "Råtorp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Motala",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 10, 2021",
  },
  {
    Områdes_ID: "0583-V-041",
    Projektnamn: "Boberg 3",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Motala",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 10, 2021",
  },
  {
    Områdes_ID: "0586-V-072",
    Projektnamn: "Lunna",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mjölby",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 10, 2021",
  },
  {
    Områdes_ID: "0586-V-073",
    Projektnamn: "Haddestad",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mjölby",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Apr 02, 2019",
  },
  {
    Områdes_ID: "0586-V-075",
    Projektnamn: "Häradsmossen",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mjölby",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 10, 2021",
  },
  {
    Områdes_ID: "0583-V-043",
    Projektnamn: "grupp Hälleberga",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Motala",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1470-V-030",
    Projektnamn: "Skattegården",
    Verksamhetsutövare: "Agrivind AB",
    Organisationsnummer: "556242-1387",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Apr 17, 2019",
  },
  {
    Områdes_ID: "1470-V-048",
    Projektnamn: "Skattegården",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Apr 17, 2019",
  },
  {
    Områdes_ID: "1447-V-006",
    Projektnamn: "Kroppfjäll 311",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Gullspång",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 15, 2021",
  },
  {
    Områdes_ID: "1497-V-050",
    Projektnamn: "Hökensås",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hjo",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "May 02, 2019",
  },
  {
    Områdes_ID: "1499-V-011",
    Projektnamn: "Höverö",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Falköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 27, 2019",
  },
  {
    Områdes_ID: "1494-V-023",
    Projektnamn: "Brotorp",
    Verksamhetsutövare: "Brotorp Vind AB",
    Organisationsnummer: "556799-3745",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Lidköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Apr 17, 2019",
  },
  {
    Områdes_ID: "1447-V-007",
    Projektnamn: "Annehärad vindkraft",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Gullspång",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "May 02, 2019",
  },
  {
    Områdes_ID: "1447-V-008",
    Projektnamn: "Södra råda",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Gullspång",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1473-V-017",
    Projektnamn: "Östen Töreboda",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Töreboda",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1430-V-036",
    Projektnamn: "Hällvadsholm",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Munkedal",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1430-V-034",
    Projektnamn: "Synneröd",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Munkedal",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 11, 2020",
  },
  {
    Områdes_ID: "0781-V-034",
    Projektnamn: "Deranäs",
    Verksamhetsutövare: "Billyvind AB",
    Organisationsnummer: "556705-6931",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ljungby",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "Oct 18, 2021",
  },
  {
    Områdes_ID: "2401-V-003",
    Projektnamn: "Gabrielsberget",
    Verksamhetsutövare: "Gabrielsberget Vind Syd AB",
    Organisationsnummer: "556792-1621",
    Aktuella_verk: 40,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 240,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Jan 01, 2010",
    Planerat_drifttagande: "Jul 31, 2012",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Nordmaling",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "Oct 07, 2021",
  },
  {
    Områdes_ID: "0781-V-037",
    Projektnamn: "Katoden 4",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ljungby",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0781-V-039",
    Projektnamn: "Fathult",
    Verksamhetsutövare: "Statkraft Södra Vindkraft AB",
    Organisationsnummer: "556785-3873",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ljungby",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1430-V-038",
    Projektnamn: "Torkelsrud-Munkedal",
    Verksamhetsutövare: "Munkedal",
    Organisationsnummer: "212000-1330",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Munkedal",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 21, 2021",
  },
  {
    Områdes_ID: "1498-V-049",
    Projektnamn: "Gunnagården",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tidaholm",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Apr 08, 2019",
  },
  {
    Områdes_ID: "1499-V-049",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Falköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1446-V-003",
    Projektnamn: "Magderud - Hanhult",
    Verksamhetsutövare: "Triventus AB",
    Organisationsnummer: "556627-3016",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Karlsborg",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0685-V-020",
    Projektnamn: "Slageryd",
    Verksamhetsutövare: "POG International AB",
    Organisationsnummer: "556667-5467",
    Aktuella_verk: 6,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 70.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "Feb 01, 2020",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vetlanda",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "Jun 30, 2020",
  },
  {
    Områdes_ID: "2161-V-010",
    Projektnamn: "Vindpark Svartvallsberget",
    Verksamhetsutövare: "Svartvallsberget SPW AB",
    Organisationsnummer: "556878-1446",
    Aktuella_verk: 10,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 50,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ljusdal",
    Län: "Gävleborgs län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0683-V-006",
    Projektnamn: "Ed",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Värnamo",
    Län: "Jönköpings län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1083-V-013",
    Projektnamn: "Lörby 7 Lörby/Ysane",
    Verksamhetsutövare: "Ekovind AB",
    Organisationsnummer: "556343-8208",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.75,
    Uppmätt_årsproduktion_GWh: 1.7,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sölvesborg",
    Län: "Blekinge län",
    Elområde: "Malmö",
    Senast_sparad: "Mar 11, 2020",
  },
  {
    Områdes_ID: "0685-V-022",
    Projektnamn: "Krassaberg",
    Verksamhetsutövare: "Smålands Miljöenergi AB",
    Organisationsnummer: "556784-5168",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5.7,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vetlanda",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0685-V-023",
    Projektnamn: "Björkekull Samf.:1",
    Verksamhetsutövare: "Smålands Miljöenergi AB",
    Organisationsnummer: "556784-5168",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5.7,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vetlanda",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0662-V-013",
    Projektnamn: "Gunnilstorp Tranhult",
    Verksamhetsutövare: "Kraftö AB",
    Organisationsnummer: "556708-7456",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Gislaved",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1485-V-015",
    Projektnamn: "Projekt Stenhult",
    Verksamhetsutövare: "Rabbalshede Kraft AB",
    Organisationsnummer: "556681-4652",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Uddevalla",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "1491-V-015",
    Projektnamn: "Liared",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ulricehamn",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 25, 2019",
  },
  {
    Områdes_ID: "1491-V-016",
    Projektnamn: "Fänneslunda",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ulricehamn",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1452-V-006",
    Projektnamn: "Håcksvik del 2",
    Verksamhetsutövare: "Gamesa Energy Sweden AB",
    Organisationsnummer: "556814-4181",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tranemo",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 15, 2021",
  },
  {
    Områdes_ID: "1490-V-003",
    Projektnamn: "Borgstena",
    Verksamhetsutövare: "Borås Energi och Miljö AB",
    Organisationsnummer: "556527-5590",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 42,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Borås",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1491-V-014",
    Projektnamn: "Dållebo",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 66,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "Nov 01, 2023",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ulricehamn",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 30, 2021",
  },
  {
    Områdes_ID: "0684-V-008",
    Projektnamn: "Tritteboda",
    Verksamhetsutövare: "Vattenfall Vindkraft AB",
    Organisationsnummer: "556581-4273",
    Aktuella_verk: 15,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Sävsjö",
    Län: "Jönköpings län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1440-V-003",
    Projektnamn: "Aletrion Vindkraft",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ale",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1491-V-018",
    Projektnamn: "Hallabron (Borås)",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ulricehamn",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Apr 08, 2019",
  },
  {
    Områdes_ID: "1415-V-021",
    Projektnamn: "Sköllunga",
    Verksamhetsutövare: "Rabbalshede Kraft AB",
    Organisationsnummer: "556681-4652",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 37.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "Mar 01, 2021",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Stenungsund",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Apr 14, 2021",
  },
  {
    Områdes_ID: "1498-V-005",
    Projektnamn: "Hångsdala Vindkraftverk (Ann-El-i)",
    Verksamhetsutövare: "Tidaholms Vind ek. för.",
    Organisationsnummer: "769603-6511",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.37,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tidaholm",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1287-V-014",
    Projektnamn: "Näsbyholm",
    Verksamhetsutövare: "Universal Power AB",
    Organisationsnummer: "556726-3859",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 20.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Trelleborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Jan 07, 2019",
  },
  {
    Områdes_ID: "1290-V-075",
    Projektnamn: "Arkelstorp-Brännskulla",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Nov 30, 2021",
  },
  {
    Områdes_ID: "1277-V-031",
    Projektnamn: "Kvidinge Syllstorp",
    Verksamhetsutövare: "Billyvind AB",
    Organisationsnummer: "556705-6931",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Åstorp",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1290-V-113",
    Projektnamn: "Äsphult-Bjärnhult",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "May 03, 2019",
  },
  {
    Områdes_ID: "1291-V-068",
    Projektnamn: "Östra Herrestad",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Simrishamn",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "0760-V-038",
    Projektnamn: "Nottebäck Hult",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Uppvidinge",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "Mar 29, 2019",
  },
  {
    Områdes_ID: "1981-V-001",
    Projektnamn: "Norrberget",
    Verksamhetsutövare: "VKS Vindkraft Sverige AB",
    Organisationsnummer: "556807-1889",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Sala",
    Län: "Västmanlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2303-V-017",
    Projektnamn: "Borgvattnet 2",
    Verksamhetsutövare: "JP Vind AB",
    Organisationsnummer: "556754-9174",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ragunda",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Jan 28, 2020",
  },
  {
    Områdes_ID: "1380-V-001",
    Projektnamn: "Bohult",
    Verksamhetsutövare: "Arise Windpower AB",
    Organisationsnummer: "556274-6726",
    Aktuella_verk: 12,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 46.24,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Halmstad",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0381-V-013",
    Projektnamn: "Bred",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Enköping",
    Län: "Uppsala län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0604-V-002",
    Projektnamn: "Gårebo",
    Verksamhetsutövare: "Luveryd Vindkraft AB",
    Organisationsnummer: "556756-5766",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Aneby",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "Mar 23, 2021",
  },
  {
    Områdes_ID: "0604-V-009",
    Projektnamn: "Älgön",
    Verksamhetsutövare: "Väktaren Vind AB",
    Organisationsnummer: "556628-7578",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Aneby",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0604-V-011",
    Projektnamn: "Stora Lönhult",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Aneby",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0685-V-012",
    Projektnamn: "Milletorp",
    Verksamhetsutövare: "Vrångens Kraft AB",
    Organisationsnummer: "556778-9705",
    Aktuella_verk: 11,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 66,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vetlanda",
    Län: "Jönköpings län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0760-V-006",
    Projektnamn: "Bäckaskog",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Uppvidinge",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0760-V-013",
    Projektnamn: "Herråkra",
    Verksamhetsutövare: "Kraftö AB",
    Organisationsnummer: "556708-7456",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Uppvidinge",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0760-V-015",
    Projektnamn: "Tångabo",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Uppvidinge",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0760-V-020",
    Projektnamn: "Herråkra Hult",
    Verksamhetsutövare: "Kraftö AB",
    Organisationsnummer: "556708-7456",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Uppvidinge",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 10, 2021",
  },
  {
    Områdes_ID: "0767-V-007",
    Projektnamn: "Fagraböke",
    Verksamhetsutövare: "Arise Windpower AB",
    Organisationsnummer: "556274-6726",
    Aktuella_verk: 21,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Markaryd",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0840-V-046",
    Projektnamn: "Övertorp",
    Verksamhetsutövare: "Väktaren Vind AB",
    Organisationsnummer: "556628-7578",
    Aktuella_verk: 5,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mörbylånga",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1492-V-017",
    Projektnamn: "Nedre Strand",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Åmål",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "1081-V-001",
    Projektnamn: "Tokaryd",
    Verksamhetsutövare: "Vingkraft AB",
    Organisationsnummer: "556528-1945",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ronneby",
    Län: "Blekinge län",
    Elområde: "Malmö",
    Senast_sparad: "Mar 28, 2019",
  },
  {
    Områdes_ID: "1214-V-025",
    Projektnamn: "Hedagården",
    Verksamhetsutövare: "Hedagården Vindkraft 4 AB",
    Organisationsnummer: "556716-6912",
    Aktuella_verk: 6,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 21,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Svalöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1260-V-006",
    Projektnamn: "Vrams Gunnarstorp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Bjuv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1262-V-008",
    Projektnamn: "Fjelie-Laxmans Åkarp",
    Verksamhetsutövare: "Västanby AB",
    Organisationsnummer: "556604-8392",
    Aktuella_verk: 5,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 27,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Lomma",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 18, 2019",
  },
  {
    Områdes_ID: "1276-V-007",
    Projektnamn: "Färingtofta",
    Verksamhetsutövare: "RWE Renewables Sweden AB",
    Organisationsnummer: "556938-6864",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Klippan",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 22, 2021",
  },
  {
    Områdes_ID: "1276-V-017",
    Projektnamn: "Färingtofta",
    Verksamhetsutövare: "RWE Renewables Sweden AB",
    Organisationsnummer: "556938-6864",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Klippan",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 22, 2021",
  },
  {
    Områdes_ID: "1283-V-086",
    Projektnamn: "Lydinge-Benarp",
    Verksamhetsutövare: "PEAB Anläggning AB",
    Organisationsnummer: "556568-6721",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Helsingborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1285-V-019",
    Projektnamn: "Skarhults nygård",
    Verksamhetsutövare: "Universal Wind AB",
    Organisationsnummer: "556571-8151",
    Aktuella_verk: 6,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 25.2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Eslöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 09, 2020",
  },
  {
    Områdes_ID: "1285-V-051",
    Projektnamn: "Hjularöd",
    Verksamhetsutövare: "BayWa r.e. Scandinavia AB",
    Organisationsnummer: "556885-6503",
    Aktuella_verk: 7,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Eslöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1292-V-003",
    Projektnamn: "Vegeholm",
    Verksamhetsutövare: "Bjäre Kraft Energi AB",
    Organisationsnummer: "556527-5020",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ängelholm",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1461-V-021",
    Projektnamn: "Erikstads-Kärr",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mellerud",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 15, 2021",
  },
  {
    Områdes_ID: "0840-V-015",
    Projektnamn: "Gunnarstorp",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mörbylånga",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "1293-V-005",
    Projektnamn: "Åraslöv",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hässleholm",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 17, 2019",
  },
  {
    Områdes_ID: "1276-V-008",
    Projektnamn: "",
    Verksamhetsutövare: "BayWa r.e. Scandinavia AB",
    Organisationsnummer: "556885-6503",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Klippan",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1435-V-013",
    Projektnamn: "Hällevadsholm Väster",
    Verksamhetsutövare: "Rabbalshede Kraft AB",
    Organisationsnummer: "556681-4652",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tanum",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 08, 2021",
  },
  {
    Områdes_ID: "1430-V-017",
    Projektnamn: "Hällevadsholm V",
    Verksamhetsutövare: "Rabbalshede Kraft AB",
    Organisationsnummer: "556681-4652",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 13.2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Munkedal",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1430-V-025",
    Projektnamn: "Hällevadsholm Väster",
    Verksamhetsutövare: "Rabbalshede Kraft AB",
    Organisationsnummer: "556681-4652",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Munkedal",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1452-V-004",
    Projektnamn: "Vindkraftprojekt Skyås",
    Verksamhetsutövare: "Kraftö AB",
    Organisationsnummer: "556708-7456",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tranemo",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1266-V-040",
    Projektnamn: "Bönhult-Älmhult",
    Verksamhetsutövare: "Kraftö AB",
    Organisationsnummer: "556708-7456",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hörby",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1315-V-004",
    Projektnamn: "Fröslida",
    Verksamhetsutövare: "Arise Windfarm 3 AB",
    Organisationsnummer: "556758-9105",
    Aktuella_verk: 9,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 55.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Hylte",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1435-V-044",
    Projektnamn: "Tormoserödsfjället",
    Verksamhetsutövare: "Conceptor Vindkraft AB",
    Organisationsnummer: "556751-9706",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tanum",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Jan 15, 2019",
  },
  {
    Områdes_ID: "1497-V-047",
    Projektnamn: "Laggarebolet",
    Verksamhetsutövare: "Triventus AB",
    Organisationsnummer: "556627-3016",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hjo",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1462-V-012",
    Projektnamn: "Gunnarsvattnets vindpark",
    Verksamhetsutövare: "Vattenfall Vindkraft AB",
    Organisationsnummer: "556581-4273",
    Aktuella_verk: 14,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "LillaEdet",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1492-V-001",
    Projektnamn: "Edsleskogs Hult",
    Verksamhetsutövare: "Veddige Vindkraft AB",
    Organisationsnummer: "556753-6072",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 18,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Åmål",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1492-V-008",
    Projektnamn: "Vindpark Edsleskog",
    Verksamhetsutövare: "Veddige Vindkraft AB",
    Organisationsnummer: "556753-6072",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Åmål",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "2283-V-002",
    Projektnamn: "Hocksjön",
    Verksamhetsutövare: "Jämtkraft AB",
    Organisationsnummer: "556001-6064",
    Aktuella_verk: 45,
    Antal_ej_koordinatsatta_verk: 22,
    Beräknad_årsproduktion_GWh: 595.08,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "Dec 01, 2022",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sollefteå",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "Aug 31, 2021",
  },
  {
    Områdes_ID: "2121-V-001",
    Projektnamn: "Bergvind Annefors",
    Verksamhetsutövare: "Bergvik Skog AB",
    Organisationsnummer: "556610-2959",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 47.7,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ovanåker",
    Län: "Gävleborgs län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2132-V-010",
    Projektnamn: "Norrhälsinge vindpark",
    Verksamhetsutövare: "RES Renewable Norden AB",
    Organisationsnummer: "556616-0684",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 137.2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Nordanstig",
    Län: "Gävleborgs län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2184-V-006",
    Projektnamn: "Norrhälsinge vindpark",
    Verksamhetsutövare: "RES Renewable Norden AB",
    Organisationsnummer: "556616-0684",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 259.7,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hudiksvall",
    Län: "Gävleborgs län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1497-V-014",
    Projektnamn: "Sandruder",
    Verksamhetsutövare: "Kivenäbben Lantbruk AB",
    Organisationsnummer: "556398-5877",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hjo",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1495-V-028",
    Projektnamn: "MUNSTORP 7043",
    Verksamhetsutövare: "Lilläng Drift AB",
    Organisationsnummer: "556816-9931",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 7,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Skara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1497-V-043",
    Projektnamn: "Laggarebolet",
    Verksamhetsutövare: "Triventus AB",
    Organisationsnummer: "556627-3016",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hjo",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1260-V-004",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Bjuv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "1261-V-011",
    Projektnamn: "Virke",
    Verksamhetsutövare: "Universal Wind AB",
    Organisationsnummer: "556571-8151",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 2.7,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kävlinge",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 08, 2020",
  },
  {
    Områdes_ID: "1265-V-022",
    Projektnamn: "Bäretofta",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Sjöbo",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Mar 12, 2021",
  },
  {
    Områdes_ID: "1266-V-030",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hörby",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 11, 2019",
  },
  {
    Områdes_ID: "1266-V-039",
    Projektnamn: "Hjärås",
    Verksamhetsutövare: "GiroVind Energi AB",
    Organisationsnummer: "556680-8167",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hörby",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1277-V-005",
    Projektnamn: "Vallaröd Björnekulla",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Åstorp",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Mar 27, 2019",
  },
  {
    Områdes_ID: "1277-V-006",
    Projektnamn: "Broby",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Åstorp",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1277-V-009",
    Projektnamn: "Vallaröd Kvidinge",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Åstorp",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Mar 27, 2019",
  },
  {
    Områdes_ID: "1278-V-016",
    Projektnamn: "Frestensfälla",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Båstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1276-V-012",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Klippan",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Mar 05, 2019",
  },
  {
    Områdes_ID: "1277-V-016",
    Projektnamn: "Sånna",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Åstorp",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Mar 27, 2019",
  },
  {
    Områdes_ID: "1283-V-075",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Helsingborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1286-V-008",
    Projektnamn: "Fröslöv",
    Verksamhetsutövare: "Wede Växt AB",
    Organisationsnummer: "556279-4346",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1292-V-030",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ängelholm",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Oct 10, 2019",
  },
  {
    Områdes_ID: "1291-V-042",
    Projektnamn: "Munkarynga",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Simrishamn",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1291-V-023",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Simrishamn",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1264-V-011",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Skurup",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1264-V-009",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Skurup",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Oct 09, 2019",
  },
  {
    Områdes_ID: "1262-V-003",
    Projektnamn: "Månstorp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Lomma",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1214-V-044",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Svalöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 05, 2019",
  },
  {
    Områdes_ID: "1214-V-045",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Svalöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1233-V-006",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vellinge",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Mar 27, 2019",
  },
  {
    Områdes_ID: "2463-V-008",
    Projektnamn: "Bäckaskog",
    Verksamhetsutövare: "TCC AB",
    Organisationsnummer: "556627-8056",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Åsele",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "2422-V-004",
    Projektnamn: "Verboberget",
    Verksamhetsutövare: "RES Renewable Norden AB",
    Organisationsnummer: "556616-0684",
    Aktuella_verk: 5,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Sorsele",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1463-V-001",
    Projektnamn: "Horreds-Lindhult",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mark",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1863-V-001",
    Projektnamn: "Orrmosshöjden",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Hällefors",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2421-V-005",
    Projektnamn: "Långsjöby",
    Verksamhetsutövare: "Triventus Wind Power AB",
    Organisationsnummer: "556863-1765",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Storuman",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2404-V-002",
    Projektnamn: "Bränt-Kullsjöliden",
    Verksamhetsutövare: "Fred Olsen Renewables AB",
    Organisationsnummer: "556591-2077",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vindeln",
    Län: "Västerbottens län",
    Elområde: "Luleå",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1471-V-034",
    Projektnamn: "Västermark",
    Verksamhetsutövare: "Administration Göteneporten AB",
    Organisationsnummer: "556765-6516",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 15,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Götene",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2480-V-020",
    Projektnamn: "Holmön - södra",
    Verksamhetsutövare: "Slitevind AB",
    Organisationsnummer: "556453-2819",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Umeå",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2480-V-019",
    Projektnamn: "Holmön - norra",
    Verksamhetsutövare: "Slitevind AB",
    Organisationsnummer: "556453-2819",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Umeå",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1495-V-032",
    Projektnamn: "NEDERSÅNNA / 7042",
    Verksamhetsutövare: "Administration Göteneporten AB",
    Organisationsnummer: "556765-6516",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Skara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 25, 2019",
  },
  {
    Områdes_ID: "2417-V-002",
    Projektnamn: "Bodberget - norra",
    Verksamhetsutövare: "Gothia Vind AB",
    Organisationsnummer: "556714-2095",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Norsjö",
    Län: "Västerbottens län",
    Elområde: "Luleå",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2417-V-004",
    Projektnamn: "Bodberget - östra",
    Verksamhetsutövare: "Gothia Vind AB",
    Organisationsnummer: "556714-2095",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Norsjö",
    Län: "Västerbottens län",
    Elområde: "Luleå",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2417-V-005",
    Projektnamn: "Bjursele",
    Verksamhetsutövare: "Gothia Vind AB",
    Organisationsnummer: "556714-2095",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Norsjö",
    Län: "Västerbottens län",
    Elområde: "Luleå",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1283-V-088",
    Projektnamn: "Bårslöv",
    Verksamhetsutövare: "Billyvind AB",
    Organisationsnummer: "556705-6931",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Helsingborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1463-V-028",
    Projektnamn: "Skephults-Backen",
    Verksamhetsutövare: "Billyvind AB",
    Organisationsnummer: "556705-6931",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mark",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Dec 03, 2021",
  },
  {
    Områdes_ID: "0767-V-009",
    Projektnamn: "Sannamåd",
    Verksamhetsutövare: "Billyvind AB",
    Organisationsnummer: "556705-6931",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Markaryd",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "Dec 03, 2021",
  },
  {
    Områdes_ID: "0763-V-005",
    Projektnamn: "Skåramåla",
    Verksamhetsutövare: "Billyvind AB",
    Organisationsnummer: "556705-6931",
    Aktuella_verk: 8,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "May 01, 2022",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tingsryd",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "Aug 05, 2021",
  },
  {
    Områdes_ID: "0763-V-006",
    Projektnamn: "Krokshult",
    Verksamhetsutövare: "Billyvind AB",
    Organisationsnummer: "556705-6931",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tingsryd",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0685-V-024",
    Projektnamn: "Salvaryd",
    Verksamhetsutövare: "Billyvind AB",
    Organisationsnummer: "556705-6931",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vetlanda",
    Län: "Jönköpings län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 01, 2019",
  },
  {
    Områdes_ID: "0780-V-009",
    Projektnamn: "Ellanda",
    Verksamhetsutövare: "Billyvind AB",
    Organisationsnummer: "556705-6931",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Växjö",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "0780-V-010",
    Projektnamn: "Öja",
    Verksamhetsutövare: "Billyvind AB",
    Organisationsnummer: "556705-6931",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Växjö",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 30, 2019",
  },
  {
    Områdes_ID: "0765-V-002",
    Projektnamn: "Sånnamad",
    Verksamhetsutövare: "Billyvind AB",
    Organisationsnummer: "556705-6931",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Älmhult",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "Mar 25, 2021",
  },
  {
    Områdes_ID: "0765-V-003",
    Projektnamn: "Ljunggårdsköp",
    Verksamhetsutövare: "Billyvind AB",
    Organisationsnummer: "556705-6931",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Älmhult",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "Oct 02, 2019",
  },
  {
    Områdes_ID: "0683-V-008",
    Projektnamn: "Åreved Schedingsnäs",
    Verksamhetsutövare: "Gothia Vind AB",
    Organisationsnummer: "556714-2095",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Värnamo",
    Län: "Jönköpings län",
    Elområde: "Malmö",
    Senast_sparad: "Jan 18, 2019",
  },
  {
    Områdes_ID: "2418-V-007",
    Projektnamn: "Jokkmokksliden",
    Verksamhetsutövare: "Skellefteå Kraft AB",
    Organisationsnummer: "556016-2561",
    Aktuella_verk: 10,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 73,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Malå",
    Län: "Västerbottens län",
    Elområde: "Luleå",
    Senast_sparad: "May 18, 2020",
  },
  {
    Områdes_ID: "1491-V-017",
    Projektnamn: "Fänneslunda Del 1",
    Verksamhetsutövare: "Göteborg Energi AB",
    Organisationsnummer: "556362-6794",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ulricehamn",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1882-V-006",
    Projektnamn: "Stora Kettstaka",
    Verksamhetsutövare: "Askersund",
    Organisationsnummer: "212000-1983",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Askersund",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "Aug 27, 2019",
  },
  {
    Områdes_ID: "1495-V-041",
    Projektnamn: "Nord Billingen",
    Verksamhetsutövare: "Vallebygdens Vind AB",
    Organisationsnummer: "556800-3171",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Skara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "0428-V-008",
    Projektnamn: "Ättersta",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vingåker",
    Län: "Södermanlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0885-V-008",
    Projektnamn: "Stora Istad II",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Borgholm",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 05, 2019",
  },
  {
    Områdes_ID: "0584-V-023",
    Projektnamn: "Aska",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0.02,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vadstena",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2283-V-026",
    Projektnamn: "Salsjön",
    Verksamhetsutövare: "Krange Vind AB",
    Organisationsnummer: "556957-8056",
    Aktuella_verk: 25,
    Antal_ej_koordinatsatta_verk: 1,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Oct 01, 2021",
    Planerat_drifttagande: "Jun 01, 2024",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sollefteå",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "Jun 01, 2021",
  },
  {
    Områdes_ID: "0980-V-040",
    Projektnamn: "Klinte Klinte s:43, Eksebo kraft 2 [Klinte 1 Henni",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.65,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0583-V-022",
    Projektnamn: "Brotorp 2",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Motala",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 26, 2019",
  },
  {
    Områdes_ID: "0980-V-042",
    Projektnamn: "Othem Österby Tornsvalan",
    Verksamhetsutövare: "Siral System & Co AB",
    Organisationsnummer: "556171-8296",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.22,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0980-V-043",
    Projektnamn: "Väskinde Skäggs 1",
    Verksamhetsutövare: "Stefan Widen AB",
    Organisationsnummer: "556651-9715",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.51,
    Uppmätt_årsproduktion_GWh: 1.14,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0583-V-023",
    Projektnamn: "Boberg 2",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 19.2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Motala",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2280-V-016",
    Projektnamn: "Skuruberget",
    Verksamhetsutövare: "Gamesa Energy Sweden AB",
    Organisationsnummer: "556814-4181",
    Aktuella_verk: 5,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 25,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Härnösand",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "Dec 01, 2021",
  },
  {
    Områdes_ID: "2284-V-018",
    Projektnamn: "Trattberget, Skallberget.",
    Verksamhetsutövare: "Vindin AB",
    Organisationsnummer: "556713-5172",
    Aktuella_verk: 30,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 180,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Örnsköldsvik",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2280-V-018",
    Projektnamn: "Grönmyrberget",
    Verksamhetsutövare: "Odalholmen AB",
    Organisationsnummer: "556758-7505",
    Aktuella_verk: 5,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Härnösand",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "Dec 01, 2021",
  },
  {
    Områdes_ID: "2401-V-007",
    Projektnamn: "Storfall",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Nordmaling",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "2260-V-005",
    Projektnamn: "Portberget",
    Verksamhetsutövare: "Windico AB",
    Organisationsnummer: "556776-9202",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ånge",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "Dec 01, 2021",
  },
  {
    Områdes_ID: "1080-V-010",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Karlskrona",
    Län: "Blekinge län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2284-V-020",
    Projektnamn: "Blackfjället",
    Verksamhetsutövare: "Stavro Vind Aktiebolag",
    Organisationsnummer: "556953-6153",
    Aktuella_verk: 22,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 275,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Örnsköldsvik",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "Jan 18, 2022",
  },
  {
    Områdes_ID: "2284-V-021",
    Projektnamn: "Brattmyrliden - Lidenprojekten",
    Verksamhetsutövare: "Brattmyrliden Vind AB",
    Organisationsnummer: "556856-7597",
    Aktuella_verk: 19,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 262.2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Dec 01, 2017",
    Planerat_drifttagande: "Jun 01, 2021",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Örnsköldsvik",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "Sep 08, 2021",
  },
  {
    Områdes_ID: "2284-V-022",
    Projektnamn: "Blodrotberget",
    Verksamhetsutövare: "Stavro Vind Aktiebolag",
    Organisationsnummer: "556953-6153",
    Aktuella_verk: 40,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 500,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "Oct 01, 2021",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Örnsköldsvik",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "Jan 18, 2022",
  },
  {
    Områdes_ID: "2284-V-023",
    Projektnamn: "Bursjöliden",
    Verksamhetsutövare: "Bursjöliden Vind AB",
    Organisationsnummer: "556856-7571",
    Aktuella_verk: 22,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Örnsköldsvik",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "Dec 02, 2021",
  },
  {
    Områdes_ID: "1080-V-014",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Karlskrona",
    Län: "Blekinge län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1080-V-015",
    Projektnamn: "Porsgöl",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Karlskrona",
    Län: "Blekinge län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "2184-V-004",
    Projektnamn: "Norrhälsinge (Järnblästen)",
    Verksamhetsutövare: "Vindkraft i Norrhälsinge AB",
    Organisationsnummer: "556754-9562",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hudiksvall",
    Län: "Gävleborgs län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0563-V-003",
    Projektnamn: "Börstad 2",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.7,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vadstena",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 26, 2019",
  },
  {
    Områdes_ID: "0563-V-013",
    Projektnamn: "Frideborg",
    Verksamhetsutövare: "Rocksvind AB",
    Organisationsnummer: "556305-2819",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.4,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vadstena",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0580-V-002",
    Projektnamn: "vkv_lin-001",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 2.15,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Linköping",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0580-V-011",
    Projektnamn: "vkv_lin-012",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Linköping",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0583-V-002",
    Projektnamn: "Borringe 1",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.75,
    Uppmätt_årsproduktion_GWh: 1.69,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Motala",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0583-V-007",
    Projektnamn: "Långeryd I",
    Verksamhetsutövare: "Sverige Vindkraftkooperativ Ek för",
    Organisationsnummer: "769603-7089",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.8,
    Uppmätt_årsproduktion_GWh: 1.86,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Motala",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0583-V-008",
    Projektnamn: "Långeryd II",
    Verksamhetsutövare: "Ventum Energi AB",
    Organisationsnummer: "556394-0336",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Motala",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0583-V-018",
    Projektnamn: "Grupp Brotorp",
    Verksamhetsutövare: "Sverige Vindkraftkooperativ Ek för",
    Organisationsnummer: "769603-7089",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.7,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Motala",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0586-V-002",
    Projektnamn: "Askegården",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mjölby",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1381-V-027",
    Projektnamn: "Ängsholm",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.95,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Laholm",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1381-V-029",
    Projektnamn: "Ågård",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.9,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Laholm",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1430-V-008",
    Projektnamn: "Torkelsrud",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Munkedal",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1435-V-037",
    Projektnamn: "Vindpark Lursäng",
    Verksamhetsutövare: "Rabbalshede Kraft AB",
    Organisationsnummer: "556681-4652",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 16.9,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Feb 01, 2021",
    Planerat_drifttagande: "Dec 01, 2022",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tanum",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 30, 2021",
  },
  {
    Områdes_ID: "1462-V-010",
    Projektnamn: "Utby",
    Verksamhetsutövare: "Firma Ramström Vind AB",
    Organisationsnummer: "556688-2683",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "LillaEdet",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1462-V-023",
    Projektnamn: "Marieström",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.08,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "LillaEdet",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 14, 2019",
  },
  {
    Områdes_ID: "1470-V-002",
    Projektnamn: "3953 Blad",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.7,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1470-V-026",
    Projektnamn: "Rytterås",
    Verksamhetsutövare: "Afsåns Vind AB",
    Organisationsnummer: "556767-9500",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1471-V-009",
    Projektnamn: "Erikstorp III",
    Verksamhetsutövare: "Erikstorp Vind AB",
    Organisationsnummer: "556648-0215",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Götene",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 06, 2021",
  },
  {
    Områdes_ID: "1472-V-001",
    Projektnamn: "Höghult 1",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tibro",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1473-V-004",
    Projektnamn: "Bäck",
    Verksamhetsutövare: "Svenska Vindbolaget AB",
    Organisationsnummer: "556759-9013",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Töreboda",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 20, 2020",
  },
  {
    Områdes_ID: "1487-V-004",
    Projektnamn: "Brålanda-Torp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.9,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vänersborg",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 25, 2019",
  },
  {
    Områdes_ID: "1488-V-006",
    Projektnamn: "Kortered",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Trollhättan",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "1488-V-009",
    Projektnamn: "Ulvstorp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Trollhättan",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 06, 2019",
  },
  {
    Områdes_ID: "1488-V-011",
    Projektnamn: "Wästgöta Wind",
    Verksamhetsutövare: "Wästgöta Wind AB",
    Organisationsnummer: "556944-6478",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 6.9,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Trollhättan",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1494-V-006",
    Projektnamn: "St Levene",
    Verksamhetsutövare: "SiBräcka vind",
    Organisationsnummer: "934001-0421",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Lidköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1495-V-009",
    Projektnamn: "SKALLMEJA / 3815",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.7,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Skara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 25, 2019",
  },
  {
    Områdes_ID: "1495-V-017",
    Projektnamn: "NATTORP 7303",
    Verksamhetsutövare: "Administration Göteneporten AB",
    Organisationsnummer: "556765-6516",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Skara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1495-V-030",
    Projektnamn: "ÄNDEN 7843",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Skara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 05, 2019",
  },
  {
    Områdes_ID: "1496-V-005",
    Projektnamn: "Rydingstorp 1",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Skövde",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1497-V-012",
    Projektnamn: "Laggarebolet",
    Verksamhetsutövare: "Agrivind AB",
    Organisationsnummer: "556242-1387",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hjo",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1881-V-002",
    Projektnamn: "Segås",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kumla",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1472-V-002",
    Projektnamn: "Långsjön 1",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tibro",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1473-V-008",
    Projektnamn: "Magderud - Hanhult",
    Verksamhetsutövare: "Triventus AB",
    Organisationsnummer: "556627-3016",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Töreboda",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1495-V-029",
    Projektnamn: "TRANUM",
    Verksamhetsutövare: "Agrivind AB",
    Organisationsnummer: "556242-1387",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Skara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1785-V-009",
    Projektnamn: "Sjövik",
    Verksamhetsutövare: "Åmål Vindkraft AB",
    Organisationsnummer: "556753-6080",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 27,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Säffle",
    Län: "Värmlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1785-V-013",
    Projektnamn: "Vindpark Sjövik",
    Verksamhetsutövare: "Åmål Vindkraft AB",
    Organisationsnummer: "556753-6080",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 27,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Säffle",
    Län: "Värmlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1487-V-002",
    Projektnamn: "Balltorp",
    Verksamhetsutövare: "CRC Vindkraft AB",
    Organisationsnummer: "556700-3545",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vänersborg",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "1494-V-015",
    Projektnamn: "Jung",
    Verksamhetsutövare: "Jungavind AB",
    Organisationsnummer: "556658-1970",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Lidköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Mar 02, 2020",
  },
  {
    Områdes_ID: "0509-V-002",
    Projektnamn: "Tägneby",
    Verksamhetsutövare: "Hästholmens Vind Samf. för.",
    Organisationsnummer: "716426-0940",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.4,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ödeshög",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0509-V-003",
    Projektnamn: "",
    Verksamhetsutövare: "Hästholmens Vind Samf. för.",
    Organisationsnummer: "716426-0940",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ödeshög",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0509-V-004",
    Projektnamn: "Tegneby",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.25,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ödeshög",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0509-V-006",
    Projektnamn: "Glänås Vind",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.7,
    Uppmätt_årsproduktion_GWh: 0.76,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ödeshög",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Aug 31, 2020",
  },
  {
    Områdes_ID: "0509-V-008",
    Projektnamn: "Millingstorp",
    Verksamhetsutövare: "HillMill AB",
    Organisationsnummer: "556599-5635",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.85,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ödeshög",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Aug 31, 2020",
  },
  {
    Områdes_ID: "0509-V-009",
    Projektnamn: "Millingstorp I",
    Verksamhetsutövare: "Mill Wind AB",
    Organisationsnummer: "556543-0047",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ödeshög",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Aug 31, 2020",
  },
  {
    Områdes_ID: "0509-V-010",
    Projektnamn: "Runnestad 3",
    Verksamhetsutövare: "The Great Adventure Scandinavia Consulting AB",
    Organisationsnummer: "556742-5896",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.85,
    Uppmätt_årsproduktion_GWh: 1.42,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ödeshög",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0509-V-011",
    Projektnamn: "Runnestad 3",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ödeshög",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Aug 31, 2020",
  },
  {
    Områdes_ID: "0509-V-012",
    Projektnamn: "Runnestad2",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.85,
    Uppmätt_årsproduktion_GWh: 1.66,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ödeshög",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Aug 31, 2020",
  },
  {
    Områdes_ID: "0509-V-013",
    Projektnamn: "Runnestad I",
    Verksamhetsutövare: "Runnestad Vindkraft AB",
    Organisationsnummer: "556599-6013",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.9,
    Uppmätt_årsproduktion_GWh: 1.77,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ödeshög",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Aug 31, 2020",
  },
  {
    Områdes_ID: "0509-V-016",
    Projektnamn: "Hästholmen",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ödeshög",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Aug 31, 2020",
  },
  {
    Områdes_ID: "0562-V-001",
    Projektnamn: "Svarttorp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Finspång",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 15, 2019",
  },
  {
    Områdes_ID: "0563-V-004",
    Projektnamn: "Hagebyhöga-Sandby",
    Verksamhetsutövare: "LinVind Ekonomisk förening",
    Organisationsnummer: "769601-3973",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.55,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vadstena",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0563-V-005",
    Projektnamn: "Hagebyhöga-Sandby",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.55,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vadstena",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0563-V-006",
    Projektnamn: "Hagebyhöga-Sandby",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.55,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vadstena",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0563-V-007",
    Projektnamn: "Hovgården I",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.75,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vadstena",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0563-V-009",
    Projektnamn: "Häckenäs III",
    Verksamhetsutövare: "Häckenäs Lantbruks AB",
    Organisationsnummer: "556439-4467",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.95,
    Uppmätt_årsproduktion_GWh: 1.15,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vadstena",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0563-V-011",
    Projektnamn: "Stavlösa",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.75,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vadstena",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0563-V-014",
    Projektnamn: "Häckenäs Betty",
    Verksamhetsutövare: "Häckenäs Vind Samfällighetsförening",
    Organisationsnummer: "716426-1450",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.05,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vadstena",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0563-V-015",
    Projektnamn: "Häckenäs II",
    Verksamhetsutövare: "Häckenäs Vind Samfällighetsförening",
    Organisationsnummer: "716426-1450",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.25,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vadstena",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0563-V-016",
    Projektnamn: "Börstad 1",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.78,
    Uppmätt_årsproduktion_GWh: 1.68,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vadstena",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0580-V-003",
    Projektnamn: "Elvina",
    Verksamhetsutövare: "Tekniska Verken i Linköping AB",
    Organisationsnummer: "556004-9727",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.3,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Linköping",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 15, 2019",
  },
  {
    Områdes_ID: "0580-V-004",
    Projektnamn: "vkv_lin-003",
    Verksamhetsutövare: "LinVind Ekonomisk förening",
    Organisationsnummer: "769601-3973",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Linköping",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0580-V-005",
    Projektnamn: "vkv_lin-004",
    Verksamhetsutövare: "Roxenvind AB",
    Organisationsnummer: "556551-1614",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.1,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Linköping",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0580-V-007",
    Projektnamn: "vkv_lin-008",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Linköping",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0580-V-008",
    Projektnamn: "vkv_lin-009",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Linköping",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0580-V-009",
    Projektnamn: "vkv_lin-010",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Linköping",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0580-V-010",
    Projektnamn: "vkv_lin-011",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Linköping",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 09, 2021",
  },
  {
    Områdes_ID: "0580-V-012",
    Projektnamn: "vkv_lin-013",
    Verksamhetsutövare: "Hackeryd vind AB",
    Organisationsnummer: "556853-2831",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 4.8,
    Uppmätt_årsproduktion_GWh: 5.97,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Linköping",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0580-V-013",
    Projektnamn: "vkv_lin-014",
    Verksamhetsutövare: "Hackeryd vind AB",
    Organisationsnummer: "556853-2831",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 4.8,
    Uppmätt_årsproduktion_GWh: 5.72,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Linköping",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0580-V-014",
    Projektnamn: "vkv_lin-015",
    Verksamhetsutövare: "Hackeryd vind AB",
    Organisationsnummer: "556853-2831",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 4.8,
    Uppmätt_årsproduktion_GWh: 5.85,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Linköping",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0580-V-018",
    Projektnamn: "vkv_lin-029",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Linköping",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0580-V-019",
    Projektnamn: "vkv_lin-030",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Linköping",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0580-V-020",
    Projektnamn: "vkv_lin-031",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Linköping",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0580-V-021",
    Projektnamn: "vkv_lin-032",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Linköping",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0581-V-001",
    Projektnamn: "Björnsnäs",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Norrköping",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0581-V-002",
    Projektnamn: "Flämminge Gård",
    Verksamhetsutövare: "Firma Ramström Vind AB",
    Organisationsnummer: "556688-2683",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Norrköping",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0581-V-003",
    Projektnamn: "Färjestaden",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Norrköping",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0581-V-004",
    Projektnamn: "Gisselö Gård",
    Verksamhetsutövare: "Kuddby Power AB",
    Organisationsnummer: "556791-4667",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 3.4,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Norrköping",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0581-V-006",
    Projektnamn: "Kuddby-Åby",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Norrköping",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0581-V-007",
    Projektnamn: "Svenneby",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Norrköping",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0581-V-009",
    Projektnamn: "Svärtinge udde",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Norrköping",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 09, 2021",
  },
  {
    Områdes_ID: "0581-V-011",
    Projektnamn: "Ållonö slott",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Norrköping",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 05, 2019",
  },
  {
    Områdes_ID: "0582-V-003",
    Projektnamn: "Kårebo",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Söderköping",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0583-V-001",
    Projektnamn: "Boberg 1",
    Verksamhetsutövare: "GI Eriksson AB",
    Organisationsnummer: "556455-7527",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.75,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Motala",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0583-V-004",
    Projektnamn: "Ebborp Vind, Emma",
    Verksamhetsutövare: "Ebborp vind",
    Organisationsnummer: "556738-7674",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 4.97,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Motala",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0583-V-005",
    Projektnamn: "Lårstad 2",
    Verksamhetsutövare: "Boxholms Gård AB",
    Organisationsnummer: "556503-2629",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5.4,
    Uppmätt_årsproduktion_GWh: 6.18,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Motala",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0583-V-009",
    Projektnamn: "Elvinda, Rocklunda",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.6,
    Uppmätt_årsproduktion_GWh: 1.62,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Motala",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0583-V-010",
    Projektnamn: "Rävsjö",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Motala",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0583-V-011",
    Projektnamn: "Skedevi Bonnorp Vind AB",
    Verksamhetsutövare: "Skedevi Bonnorp Vind AB",
    Organisationsnummer: "556733-8479",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 4.97,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Motala",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0583-V-013",
    Projektnamn: "Varby",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 3.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Motala",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0583-V-019",
    Projektnamn: "Stenkil",
    Verksamhetsutövare: "S Vind El i Motala AB",
    Organisationsnummer: "556636-8733",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.7,
    Uppmätt_årsproduktion_GWh: 1.53,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Motala",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0583-V-020",
    Projektnamn: "Osvald",
    Verksamhetsutövare: "Klockrike Vind AB",
    Organisationsnummer: "556758-7927",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.7,
    Uppmätt_årsproduktion_GWh: 1.67,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Motala",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0583-V-021",
    Projektnamn: "Lårstad 1",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5.4,
    Uppmätt_årsproduktion_GWh: 6.27,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Motala",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0586-V-001",
    Projektnamn: "Appunamöllan",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.35,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mjölby",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0586-V-003",
    Projektnamn: "Bjälbo-Skenaån Vind",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.75,
    Uppmätt_årsproduktion_GWh: 1.9,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mjölby",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0586-V-006",
    Projektnamn: "Högby 1",
    Verksamhetsutövare: "Högby Gård Lantbruks AB",
    Organisationsnummer: "556403-9062",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.4,
    Uppmätt_årsproduktion_GWh: 1.37,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mjölby",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0586-V-007",
    Projektnamn: "Isberget I",
    Verksamhetsutövare: "Farmarenergi i Åtvidaberg AB-FÅAB",
    Organisationsnummer: "556466-5312",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.6,
    Uppmätt_årsproduktion_GWh: 1.69,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mjölby",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0586-V-008",
    Projektnamn: "Isberget II",
    Verksamhetsutövare: "Skonberga Vind AB",
    Organisationsnummer: "556667-8941",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.6,
    Uppmätt_årsproduktion_GWh: 1.62,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mjölby",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0586-V-009",
    Projektnamn: "Isberget III",
    Verksamhetsutövare: "Isberget Vindkraft AB",
    Organisationsnummer: "556664-3234",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.6,
    Uppmätt_årsproduktion_GWh: 1.59,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mjölby",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0586-V-010",
    Projektnamn: "Karleby vindkraftverk",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.95,
    Uppmätt_årsproduktion_GWh: 2.01,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mjölby",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0586-V-011",
    Projektnamn: "Karlebytorp vind 2",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.87,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mjölby",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0586-V-016",
    Projektnamn: "Normlösa Torpa Vind",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.81,
    Uppmätt_årsproduktion_GWh: 1.8,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mjölby",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0586-V-018",
    Projektnamn: "Skeby Vind",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mjölby",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0586-V-019",
    Projektnamn: "Skeby/Tungelunda vindkraft 4",
    Verksamhetsutövare: "TÖS Vind AB",
    Organisationsnummer: "556734-9708",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.8,
    Uppmätt_årsproduktion_GWh: 1.88,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mjölby",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0586-V-021",
    Projektnamn: "Skorteby, Lillebror",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.23,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mjölby",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0586-V-022",
    Projektnamn: "Skorteby, Storebror",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mjölby",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0586-V-023",
    Projektnamn: "Tungelunda - Skeby 3",
    Verksamhetsutövare: "Tungelunda Vind AB",
    Organisationsnummer: "556635-5177",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mjölby",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0586-V-024",
    Projektnamn: "Uljeberg",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.4,
    Uppmätt_årsproduktion_GWh: 1.44,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mjölby",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0586-V-025",
    Projektnamn: "Wahlby 1",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.85,
    Uppmätt_årsproduktion_GWh: 1.88,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mjölby",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0586-V-029",
    Projektnamn: "Väderstad Vind",
    Verksamhetsutövare: "Väderstad Vind AB",
    Organisationsnummer: "556634-4601",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.8,
    Uppmätt_årsproduktion_GWh: 1.79,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mjölby",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0586-V-032",
    Projektnamn: "Appuna",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mjölby",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0586-V-033",
    Projektnamn: "Birger Jarl",
    Verksamhetsutövare: "Bjälbo Lantbruks AB",
    Organisationsnummer: "556341-8028",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.3,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mjölby",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0586-V-034",
    Projektnamn: "Ingrid Ylva Bjälbo",
    Verksamhetsutövare: "Bjälbo Lantbruks AB",
    Organisationsnummer: "556341-8028",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.9,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mjölby",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0586-V-035",
    Projektnamn: "Magnus Ladulås",
    Verksamhetsutövare: "Bjälbo Lantbruks AB",
    Organisationsnummer: "556341-8028",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 2,
    Uppmätt_årsproduktion_GWh: 2.18,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mjölby",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0586-V-036",
    Projektnamn: "Bjälbo",
    Verksamhetsutövare: "Wettern Energi AB",
    Organisationsnummer: "556900-5365",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 2.1,
    Uppmätt_årsproduktion_GWh: 2.15,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mjölby",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0586-V-037",
    Projektnamn: "Bjälbo Lennart",
    Verksamhetsutövare: "Wettern Energi AB",
    Organisationsnummer: "556900-5365",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.15,
    Uppmätt_årsproduktion_GWh: 1.28,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mjölby",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0586-V-038",
    Projektnamn: "Bränna",
    Verksamhetsutövare: "R&B Vindkraft AB",
    Organisationsnummer: "556561-9490",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.55,
    Uppmätt_årsproduktion_GWh: 1.51,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mjölby",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0586-V-039",
    Projektnamn: "Herrgårdsvind",
    Verksamhetsutövare: "Herrgårdsvind AB",
    Organisationsnummer: "556599-9280",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.71,
    Uppmätt_årsproduktion_GWh: 1.63,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mjölby",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0586-V-040",
    Projektnamn: "Varnäs Vind",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.8,
    Uppmätt_årsproduktion_GWh: 1.73,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mjölby",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0586-V-041",
    Projektnamn: "Lagmansberga",
    Verksamhetsutövare: "Lagmansberga Vind ek för",
    Organisationsnummer: "769602-1695",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.1,
    Uppmätt_årsproduktion_GWh: 1.06,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mjölby",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0586-V-042",
    Projektnamn: "Appuna",
    Verksamhetsutövare: "Appuna Gård AB",
    Organisationsnummer: "556409-1923",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mjölby",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0586-V-043",
    Projektnamn: "Navered",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mjölby",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 15, 2019",
  },
  {
    Områdes_ID: "1380-V-002",
    Projektnamn: "Elvira Vind",
    Verksamhetsutövare: "Notos Vind AB",
    Organisationsnummer: "556824-1839",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.38,
    Uppmätt_årsproduktion_GWh: 0.37,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Halmstad",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1380-V-012",
    Projektnamn: "Stjernarps Gods",
    Verksamhetsutövare: "Stjernarps Gods AB",
    Organisationsnummer: "556408-8671",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.9,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Halmstad",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1380-V-013",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.35,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Halmstad",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1380-V-014",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.4,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Halmstad",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1380-V-015",
    Projektnamn: "Eldsberga",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Halmstad",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1380-V-016",
    Projektnamn: "Andersfält Norr",
    Verksamhetsutövare: "Vindwal AB",
    Organisationsnummer: "556747-8531",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.75,
    Uppmätt_årsproduktion_GWh: 1.59,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Halmstad",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1380-V-017",
    Projektnamn: "Andersfält Mitt",
    Verksamhetsutövare: "Vindwal AB",
    Organisationsnummer: "556747-8531",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.75,
    Uppmätt_årsproduktion_GWh: 1.57,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Halmstad",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1380-V-018",
    Projektnamn: "Andersfält Syd",
    Verksamhetsutövare: "Vindwal AB",
    Organisationsnummer: "556747-8531",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.7,
    Uppmätt_årsproduktion_GWh: 1.44,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Halmstad",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1380-V-019",
    Projektnamn: "Dragabol",
    Verksamhetsutövare: "Bohusvind AB",
    Organisationsnummer: "556387-8361",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Halmstad",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1381-V-001",
    Projektnamn: "Bonnarp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.35,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Laholm",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1381-V-004",
    Projektnamn: "Havsvind",
    Verksamhetsutövare: "Havsvind AB",
    Organisationsnummer: "556551-5672",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.4,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Laholm",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1381-V-006",
    Projektnamn: "Mestocka VKV",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.04,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Laholm",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1381-V-010",
    Projektnamn: "Putsered",
    Verksamhetsutövare: "Arise Windpower AB",
    Organisationsnummer: "556274-6726",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Laholm",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "Jan 22, 2019",
  },
  {
    Områdes_ID: "1381-V-011",
    Projektnamn: "Seglaberga 4",
    Verksamhetsutövare: "Södra Hallands Kraft Energi AB",
    Organisationsnummer: "556526-5252",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.9,
    Uppmätt_årsproduktion_GWh: 0.16,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Laholm",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1381-V-012",
    Projektnamn: "Seglaberga 5",
    Verksamhetsutövare: "Södra Hallands Kraft Energi AB",
    Organisationsnummer: "556526-5252",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Laholm",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1381-V-014",
    Projektnamn: "Skottorp",
    Verksamhetsutövare: "Skottorp Säteri AB",
    Organisationsnummer: "556209-5710",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.3,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Laholm",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1381-V-016",
    Projektnamn: "Sydvind",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.9,
    Uppmätt_årsproduktion_GWh: 0.88,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Laholm",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1381-V-021",
    Projektnamn: "Vindkraftverk Forslund",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.35,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Laholm",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1381-V-022",
    Projektnamn: "Vindkraftverk Östorp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.5,
    Uppmätt_årsproduktion_GWh: 1.24,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Laholm",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1381-V-023",
    Projektnamn: "Äng-EL",
    Verksamhetsutövare: "Ängabergs Lantbruks AB",
    Organisationsnummer: "556408-7384",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.36,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Laholm",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1381-V-024",
    Projektnamn: "Ösarp/Knöbbens vindkraftverk",
    Verksamhetsutövare: "Knöbbens Vind AB",
    Organisationsnummer: "556598-8325",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.4,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Laholm",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1381-V-025",
    Projektnamn: "Östergård",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Laholm",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1381-V-026",
    Projektnamn: "Ax-El",
    Verksamhetsutövare: "Ömvind AB",
    Organisationsnummer: "556500-8561",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.45,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Laholm",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1381-V-028",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.39,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Laholm",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1381-V-030",
    Projektnamn: "Laholm/Tjärby",
    Verksamhetsutövare: "Södra Hallands Kraft Energi AB",
    Organisationsnummer: "556526-5252",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Laholm",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 15, 2019",
  },
  {
    Områdes_ID: "1381-V-031",
    Projektnamn: "Kärragård",
    Verksamhetsutövare: "HB Björn Hanssen & Co",
    Organisationsnummer: "949200-5468",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.35,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Laholm",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1381-V-032",
    Projektnamn: "Laholm/Tjärby",
    Verksamhetsutövare: "Södra Hallands Kraft Energi AB",
    Organisationsnummer: "556526-5252",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Laholm",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 14, 2019",
  },
  {
    Områdes_ID: "1381-V-033",
    Projektnamn: "Tjärby",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.99,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Laholm",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 14, 2019",
  },
  {
    Områdes_ID: "1381-V-034",
    Projektnamn: "Laholm/Tjärby",
    Verksamhetsutövare: "Södra Hallands Kraft Energi AB",
    Organisationsnummer: "556526-5252",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Laholm",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 15, 2019",
  },
  {
    Områdes_ID: "1381-V-035",
    Projektnamn: "Mammarp",
    Verksamhetsutövare: "Mammarps Wind AB",
    Organisationsnummer: "556545-4856",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Laholm",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1381-V-036",
    Projektnamn: "Lilla Tjärby Gård",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Laholm",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1381-V-037",
    Projektnamn: "Klägstorp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.95,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Laholm",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1381-V-038",
    Projektnamn: "Hov",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.95,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Laholm",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1381-V-039",
    Projektnamn: "Klägstorp",
    Verksamhetsutövare: "Hofbyvind Kooperativ Ek för",
    Organisationsnummer: "769602-4699",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.93,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Laholm",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1381-V-040",
    Projektnamn: "Gunn-El",
    Verksamhetsutövare: "Ängabergs Lantbruks AB",
    Organisationsnummer: "556408-7384",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.2,
    Uppmätt_årsproduktion_GWh: 0.69,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Laholm",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1381-V-041",
    Projektnamn: "Tjärby",
    Verksamhetsutövare: "Södra Hallands Kraft Energi AB",
    Organisationsnummer: "556526-5252",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Laholm",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1381-V-042",
    Projektnamn: "Månstorps Gård",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.9,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Laholm",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1381-V-043",
    Projektnamn: "Triton Energi",
    Verksamhetsutövare: "Triton Lantbruk & Energi AB",
    Organisationsnummer: "556556-9422",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.95,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Laholm",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1381-V-044",
    Projektnamn: "Kövlinge",
    Verksamhetsutövare: "Kövlinge Vind AB",
    Organisationsnummer: "556558-3274",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Laholm",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1381-V-045",
    Projektnamn: "Värestorps Vind",
    Verksamhetsutövare: "HB Värestorps Gård Hans Olsson & Co",
    Organisationsnummer: "916439-5361",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.35,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Laholm",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1381-V-046",
    Projektnamn: "Mellby",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.95,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Laholm",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 14, 2019",
  },
  {
    Områdes_ID: "1381-V-047",
    Projektnamn: "Mellby",
    Verksamhetsutövare: "White Windmill AB",
    Organisationsnummer: "556743-7099",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.93,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Laholm",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1381-V-048",
    Projektnamn: "Mellby",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.95,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Laholm",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1381-V-049",
    Projektnamn: "Björnsgård",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Laholm",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1381-V-050",
    Projektnamn: "Mellby",
    Verksamhetsutövare: "Mellby Kraft AB",
    Organisationsnummer: "556529-5267",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Laholm",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1381-V-051",
    Projektnamn: "Mellby",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Laholm",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1381-V-052",
    Projektnamn: "Mellby Kraft I",
    Verksamhetsutövare: "Mellby Kraft AB",
    Organisationsnummer: "556529-5267",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Laholm",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1381-V-053",
    Projektnamn: "Dömestorp II",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.65,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Laholm",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1381-V-054",
    Projektnamn: "Dömestorp I",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.93,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Laholm",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1381-V-055",
    Projektnamn: "Genevad",
    Verksamhetsutövare: "Hurva Vind AB",
    Organisationsnummer: "556568-4734",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.4,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Laholm",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1382-V-003",
    Projektnamn: "Askomebjär",
    Verksamhetsutövare: "Askome Vind AB",
    Organisationsnummer: "556881-8859",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 7.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falkenberg",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "Oct 05, 2021",
  },
  {
    Områdes_ID: "1382-V-013",
    Projektnamn: "Rosendal 1",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.45,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falkenberg",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1382-V-018",
    Projektnamn: "735999224950737069 Tågarp 114",
    Verksamhetsutövare: "Roland Bengtsson Lantbruk AB",
    Organisationsnummer: "556316-2428",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.03,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falkenberg",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 14, 2019",
  },
  {
    Områdes_ID: "1382-V-019",
    Projektnamn: "Kärrets Gård",
    Verksamhetsutövare: "AB Kärrets Gård",
    Organisationsnummer: "556166-9465",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.25,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falkenberg",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1382-V-021",
    Projektnamn: "Kärrets Gård II",
    Verksamhetsutövare: "AB Kärrets Gård",
    Organisationsnummer: "556166-9465",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.7,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falkenberg",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1382-V-022",
    Projektnamn: "Kuling",
    Verksamhetsutövare: "Vindenergi Kuling AB",
    Organisationsnummer: "556599-7060",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.93,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falkenberg",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1382-V-023",
    Projektnamn: "Kuling",
    Verksamhetsutövare: "Vindenergi Kuling AB",
    Organisationsnummer: "556599-7060",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.93,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falkenberg",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1382-V-024",
    Projektnamn: "Kuling",
    Verksamhetsutövare: "Vindenergi Kuling AB",
    Organisationsnummer: "556599-7060",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.93,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falkenberg",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1382-V-025",
    Projektnamn: "Kuling",
    Verksamhetsutövare: "Vindenergi Kuling AB",
    Organisationsnummer: "556599-7060",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.93,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falkenberg",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1382-V-026",
    Projektnamn: "Ventosum",
    Verksamhetsutövare: "Fastighets AB Balder",
    Organisationsnummer: "556525-6905",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.02,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falkenberg",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1382-V-027",
    Projektnamn: "Kuling",
    Verksamhetsutövare: "Vindenergi Kuling AB",
    Organisationsnummer: "556599-7060",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.93,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falkenberg",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1382-V-028",
    Projektnamn: "Ventosum",
    Verksamhetsutövare: "Fastighets AB Balder",
    Organisationsnummer: "556525-6905",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.25,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falkenberg",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1382-V-029",
    Projektnamn: "Ventosum",
    Verksamhetsutövare: "Fastighets AB Balder",
    Organisationsnummer: "556525-6905",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falkenberg",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1382-V-030",
    Projektnamn: "Falkenbergsporten 3",
    Verksamhetsutövare: "Bixia Gryningsvind AB",
    Organisationsnummer: "556779-5348",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falkenberg",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1382-V-031",
    Projektnamn: "Ventosum",
    Verksamhetsutövare: "Fastighets AB Balder",
    Organisationsnummer: "556525-6905",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falkenberg",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1382-V-032",
    Projektnamn: "Falkenbergsporten 4",
    Verksamhetsutövare: "Bixia ProWin AB",
    Organisationsnummer: "556884-4848",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falkenberg",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1382-V-033",
    Projektnamn: "Ventosum",
    Verksamhetsutövare: "Liseberg AB",
    Organisationsnummer: "556023-6811",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.02,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falkenberg",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1382-V-034",
    Projektnamn: "Ventosum",
    Verksamhetsutövare: "Fastighets AB Balder",
    Organisationsnummer: "556525-6905",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.25,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falkenberg",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1382-V-035",
    Projektnamn: "Ventosum 10",
    Verksamhetsutövare: "PeJo Kraftproduktion AB",
    Organisationsnummer: "556749-6202",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.25,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falkenberg",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1382-V-036",
    Projektnamn: "Ventosum",
    Verksamhetsutövare: "Fastighets AB Balder",
    Organisationsnummer: "556525-6905",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.25,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falkenberg",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1382-V-037",
    Projektnamn: "",
    Verksamhetsutövare: "Falkenberg Energi AB",
    Organisationsnummer: "556526-9627",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.55,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falkenberg",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1382-V-038",
    Projektnamn: "",
    Verksamhetsutövare: "Falkenberg Energi AB",
    Organisationsnummer: "556526-9627",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.55,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falkenberg",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1382-V-039",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falkenberg",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1382-V-040",
    Projektnamn: "Galtås Syd",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.45,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falkenberg",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1382-V-041",
    Projektnamn: "Galtås Nord",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.45,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falkenberg",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1382-V-042",
    Projektnamn: "",
    Verksamhetsutövare: "Erikstorp Utveckling AB",
    Organisationsnummer: "556748-1667",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.55,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falkenberg",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1382-V-043",
    Projektnamn: "",
    Verksamhetsutövare: "Vindkraft i Falkenberg AB",
    Organisationsnummer: "556526-1004",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.98,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falkenberg",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1383-V-004",
    Projektnamn: "MIT 9003",
    Verksamhetsutövare: "Varberg Energi AB",
    Organisationsnummer: "556524-3010",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Varberg",
    Län: "Hallands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 15, 2019",
  },
  {
    Områdes_ID: "1383-V-015",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Varberg",
    Län: "Hallands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1383-V-017",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Varberg",
    Län: "Hallands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1384-V-001",
    Projektnamn: "Almedal",
    Verksamhetsutövare: "Sverige Vindkraftkooperativ Ek för",
    Organisationsnummer: "769603-7089",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5.9,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kungsbacka",
    Län: "Hallands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1384-V-004",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kungsbacka",
    Län: "Hallands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1407-V-001",
    Projektnamn: "Hönö Pinan",
    Verksamhetsutövare: "Ekovind AB",
    Organisationsnummer: "556343-8208",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.7,
    Uppmätt_årsproduktion_GWh: 1.61,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Öckerö",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1407-V-002",
    Projektnamn: "Pinan",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Öckerö",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1415-V-003",
    Projektnamn: "Hog",
    Verksamhetsutövare: "Enprest AB",
    Organisationsnummer: "556735-7818",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Stenungsund",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Mar 15, 2019",
  },
  {
    Områdes_ID: "1415-V-004",
    Projektnamn: "Holm",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Stenungsund",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1415-V-006",
    Projektnamn: "Järnklätt",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Stenungsund",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1415-V-007",
    Projektnamn: "Halleby",
    Verksamhetsutövare: "Svalvind AB",
    Organisationsnummer: "556728-8146",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Stenungsund",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1415-V-008",
    Projektnamn: "Ranebo",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Stenungsund",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1415-V-010",
    Projektnamn: "Hälle vindkraftverk",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.13,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Stenungsund",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1419-V-001",
    Projektnamn: "Hjälteby",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tjörn",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 04, 2019",
  },
  {
    Områdes_ID: "1419-V-003",
    Projektnamn: "Kuballe",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tjörn",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1419-V-004",
    Projektnamn: "Näs",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tjörn",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1419-V-006",
    Projektnamn: "Rävsal",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tjörn",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1419-V-007",
    Projektnamn: "Sibräcka",
    Verksamhetsutövare: "SiBräcka vind",
    Organisationsnummer: "934001-0421",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tjörn",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1419-V-008",
    Projektnamn: "Sibräcka",
    Verksamhetsutövare: "SiBräcka vind",
    Organisationsnummer: "934001-0421",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.9,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tjörn",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1419-V-010",
    Projektnamn: "Stenkyrka-Bö",
    Verksamhetsutövare: "Wallhamnbolagen AB",
    Organisationsnummer: "556215-6033",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tjörn",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1419-V-012",
    Projektnamn: "Tolleby",
    Verksamhetsutövare: "Wallhamnbolagen AB",
    Organisationsnummer: "556215-6033",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tjörn",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1419-V-014",
    Projektnamn: "VallaHällene",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tjörn",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1419-V-015",
    Projektnamn: "Vallhamns hamn",
    Verksamhetsutövare: "Wallhamnbolagen AB",
    Organisationsnummer: "556215-6033",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tjörn",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1419-V-016",
    Projektnamn: "Vallhamns hamn",
    Verksamhetsutövare: "Wallhamnbolagen AB",
    Organisationsnummer: "556215-6033",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tjörn",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1419-V-017",
    Projektnamn: "Vindkraftverk Habborsby",
    Verksamhetsutövare: "SiBräcka vind",
    Organisationsnummer: "934001-0421",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tjörn",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 04, 2019",
  },
  {
    Områdes_ID: "1419-V-018",
    Projektnamn: "Vindkraftverket Stella",
    Verksamhetsutövare: "Korda Plast AB",
    Organisationsnummer: "556095-7101",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tjörn",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1419-V-019",
    Projektnamn: "Hällemöllan I",
    Verksamhetsutövare: "AB Hällebäcks Gård",
    Organisationsnummer: "556221-9153",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.6,
    Uppmätt_årsproduktion_GWh: 0.54,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tjörn",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1419-V-020",
    Projektnamn: "Hällemöllan II",
    Verksamhetsutövare: "AB Hällebäcks Gård",
    Organisationsnummer: "556221-9153",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.6,
    Uppmätt_årsproduktion_GWh: 0.63,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tjörn",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1419-V-021",
    Projektnamn: "Rönnäng",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.45,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tjörn",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1421-V-004",
    Projektnamn: "Alma 1",
    Verksamhetsutövare: "Triventus Power Generation AB",
    Organisationsnummer: "556712-7567",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1,
    Uppmätt_årsproduktion_GWh: 0.85,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Orust",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1421-V-006",
    Projektnamn: "Sörgården",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Orust",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 14, 2019",
  },
  {
    Områdes_ID: "1421-V-009",
    Projektnamn: "Henån",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.13,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Orust",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1421-V-010",
    Projektnamn: "Henån/Häröd",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Orust",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 14, 2019",
  },
  {
    Områdes_ID: "1421-V-011",
    Projektnamn: "Mollösund",
    Verksamhetsutövare: "Bohusvind AB",
    Organisationsnummer: "556387-8361",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Orust",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1427-V-002",
    Projektnamn: "Victoria",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.3,
    Uppmätt_årsproduktion_GWh: 1.03,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sotenäs",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1427-V-003",
    Projektnamn: "Sophie Hogenäset",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.1,
    Uppmätt_årsproduktion_GWh: 1,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sotenäs",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1427-V-004",
    Projektnamn: "Hovenäset, Ingeborg",
    Verksamhetsutövare: "Bohusvind AB",
    Organisationsnummer: "556387-8361",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.1,
    Uppmätt_årsproduktion_GWh: 0.97,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sotenäs",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1430-V-002",
    Projektnamn: "Hermansröd",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.9,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Munkedal",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1430-V-003",
    Projektnamn: "Berg",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Munkedal",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Jan 15, 2019",
  },
  {
    Områdes_ID: "1430-V-033",
    Projektnamn: "Berg",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Munkedal",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Apr 20, 2021",
  },
  {
    Områdes_ID: "1430-V-005",
    Projektnamn: "Haga",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Munkedal",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 11, 2020",
  },
  {
    Områdes_ID: "1430-V-006",
    Projektnamn: "Ramberg",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Munkedal",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 11, 2020",
  },
  {
    Områdes_ID: "1430-V-020",
    Projektnamn: "Dusgård",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Munkedal",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Apr 30, 2019",
  },
  {
    Områdes_ID: "1430-V-023",
    Projektnamn: "Dingle Naturbruksgymnasium",
    Verksamhetsutövare: "Västfastigheter V:a Götalandsregionen",
    Organisationsnummer: "232100-0131",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Munkedal",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1435-V-001",
    Projektnamn: "Hulda",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.33,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tanum",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1435-V-002",
    Projektnamn: "Bramseröd",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tanum",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1435-V-004",
    Projektnamn: "Bro",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tanum",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1435-V-009",
    Projektnamn: "Projekt Ledum",
    Verksamhetsutövare: "Rabbalshede Kraft AB",
    Organisationsnummer: "556681-4652",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 6.4,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tanum",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1435-V-010",
    Projektnamn: "Dusgård",
    Verksamhetsutövare: "Bohus Energi Projektering AB",
    Organisationsnummer: "556673-8349",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.95,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tanum",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1435-V-018",
    Projektnamn: "Hornbore",
    Verksamhetsutövare: "Bottna Vind ek för",
    Organisationsnummer: "769600-5789",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tanum",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 19, 2019",
  },
  {
    Områdes_ID: "1435-V-019",
    Projektnamn: "Hede Värniksgården",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tanum",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1435-V-025",
    Projektnamn: "Kil",
    Verksamhetsutövare: "Rabbalshede Kraft AB",
    Organisationsnummer: "556681-4652",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tanum",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Jan 11, 2019",
  },
  {
    Områdes_ID: "1435-V-026",
    Projektnamn: "Kil",
    Verksamhetsutövare: "Rabbalshede Kraft AB",
    Organisationsnummer: "556681-4652",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tanum",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1435-V-029",
    Projektnamn: "Grebban 1",
    Verksamhetsutövare: "Noshjulet AB",
    Organisationsnummer: "556591-2333",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.1,
    Uppmätt_årsproduktion_GWh: 0.9,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tanum",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1435-V-030",
    Projektnamn: "Grebban 2",
    Verksamhetsutövare: "Windy ek för",
    Organisationsnummer: "769606-4802",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.1,
    Uppmätt_årsproduktion_GWh: 0.89,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tanum",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1435-V-031",
    Projektnamn: "Grebban 3, Tanumshede",
    Verksamhetsutövare: "Åsele Kraft AB",
    Organisationsnummer: "556041-0648",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.6,
    Uppmätt_årsproduktion_GWh: 1.51,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tanum",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1435-V-033",
    Projektnamn: "Projekt Ledum",
    Verksamhetsutövare: "Rabbalshede Kraft AB",
    Organisationsnummer: "556681-4652",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 6.4,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tanum",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1435-V-047",
    Projektnamn: "Bramseröd",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tanum",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1435-V-049",
    Projektnamn: "Rörkärr",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tanum",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 25, 2019",
  },
  {
    Områdes_ID: "1435-V-053",
    Projektnamn: "Projekt Ledum",
    Verksamhetsutövare: "Rabbalshede Kraft AB",
    Organisationsnummer: "556681-4652",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 6.4,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tanum",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1435-V-056",
    Projektnamn: "Tannam Smeby",
    Verksamhetsutövare: "Smebyvind AB",
    Organisationsnummer: "556618-6366",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.3,
    Uppmätt_årsproduktion_GWh: 1.2,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tanum",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1435-V-057",
    Projektnamn: "Tannam",
    Verksamhetsutövare: "Ceding Vind AB",
    Organisationsnummer: "556664-6963",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.25,
    Uppmätt_årsproduktion_GWh: 0.9,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tanum",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1435-V-058",
    Projektnamn: "Tannam",
    Verksamhetsutövare: "Spawer Kraft AB",
    Organisationsnummer: "556738-8920",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.95,
    Uppmätt_årsproduktion_GWh: 0.67,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tanum",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1435-V-065",
    Projektnamn: "Torseröds Vindkraftpark 6",
    Verksamhetsutövare: "Torseröds vindkraftpark",
    Organisationsnummer: "934002-8324",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.55,
    Uppmätt_årsproduktion_GWh: 1.23,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tanum",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "May 12, 2020",
  },
  {
    Områdes_ID: "1435-V-067",
    Projektnamn: "Kil",
    Verksamhetsutövare: "Rabbalshede Kraft AB",
    Organisationsnummer: "556681-4652",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tanum",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Jan 11, 2019",
  },
  {
    Områdes_ID: "1439-V-005",
    Projektnamn: "Kylsäters Vindkraftverk",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.1,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Färgelanda",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 14, 2019",
  },
  {
    Områdes_ID: "1441-V-001",
    Projektnamn: "Lundby-Gråbo",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Lerum",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 05, 2019",
  },
  {
    Områdes_ID: "1441-V-002",
    Projektnamn: "Hallsås",
    Verksamhetsutövare: "Förbo AB",
    Organisationsnummer: "556109-8350",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Lerum",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1441-V-003",
    Projektnamn: "Lergraven",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Lerum",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 05, 2019",
  },
  {
    Områdes_ID: "1441-V-004",
    Projektnamn: "Skallsjö",
    Verksamhetsutövare: "Stall Ryggebol AB",
    Organisationsnummer: "556742-1739",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Lerum",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1442-V-002",
    Projektnamn: "Frötorp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vårgårda",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1442-V-003",
    Projektnamn: "Hoberg",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vårgårda",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1442-V-004",
    Projektnamn: "Hol 1",
    Verksamhetsutövare: "Holvind AB",
    Organisationsnummer: "556676-9146",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.4,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vårgårda",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 15, 2019",
  },
  {
    Områdes_ID: "1444-V-001",
    Projektnamn: "3951 Sven Vind",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.7,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Grästorp",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 14, 2019",
  },
  {
    Områdes_ID: "1444-V-002",
    Projektnamn: "3952 Tomten",
    Verksamhetsutövare: "Astranna Vind AB",
    Organisationsnummer: "556659-6465",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.7,
    Uppmätt_årsproduktion_GWh: 1.58,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Grästorp",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1444-V-003",
    Projektnamn: "Astranna Vind",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Grästorp",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 06, 2021",
  },
  {
    Områdes_ID: "1444-V-004",
    Projektnamn: "Astranna Vind",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.9,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Grästorp",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 06, 2021",
  },
  {
    Områdes_ID: "1444-V-005",
    Projektnamn: "Astranna Vind",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Grästorp",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1444-V-007",
    Projektnamn: "FloSal Vind",
    Verksamhetsutövare: "FloSal Vind AB",
    Organisationsnummer: 5567610661,
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Grästorp",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1444-V-008",
    Projektnamn: "FloSal Vind",
    Verksamhetsutövare: "FloSal Vind AB",
    Organisationsnummer: 5567610661,
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Grästorp",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1444-V-010",
    Projektnamn: "Håberg 2",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.7,
    Uppmätt_årsproduktion_GWh: 1.62,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Grästorp",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1444-V-012",
    Projektnamn: "Vindkraftverk i Sal",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Grästorp",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 15, 2021",
  },
  {
    Områdes_ID: "1444-V-014",
    Projektnamn: "Rudberga",
    Verksamhetsutövare: "Sonnys Maskiner AB",
    Organisationsnummer: "556407-9902",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Grästorp",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1444-V-015",
    Projektnamn: "Rudberga",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.02,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Grästorp",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1444-V-016",
    Projektnamn: "Råglanna",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.01,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Grästorp",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1444-V-017",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Grästorp",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1445-V-001",
    Projektnamn: "Bragnum vindkraftverk",
    Verksamhetsutövare: "Lekåsa Vind AB",
    Organisationsnummer: "556709-8156",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Essunga",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1445-V-004",
    Projektnamn: "Åsens säteri",
    Verksamhetsutövare: "Sporttorget i Vara AB",
    Organisationsnummer: "556543-5152",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Essunga",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1446-V-004",
    Projektnamn: "Gräshult",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Karlsborg",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1447-V-004",
    Projektnamn: "Sanden",
    Verksamhetsutövare: "Otterbäckens Vind 1 ek för",
    Organisationsnummer: "769603-1116",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.7,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gullspång",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 15, 2019",
  },
  {
    Områdes_ID: "1452-V-005",
    Projektnamn: "Ömmestorp",
    Verksamhetsutövare: "WindEn sweden AB",
    Organisationsnummer: "556766-8040",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tranemo",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1461-V-003",
    Projektnamn: "Grinstads-Hagen 1",
    Verksamhetsutövare: "Slättens Vind ek för",
    Organisationsnummer: "769606-9082",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mellerud",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1461-V-007",
    Projektnamn: "Bäcken",
    Verksamhetsutövare: "Dalboslättens Vind AB",
    Organisationsnummer: "556802-3385",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mellerud",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1461-V-008",
    Projektnamn: "Ållerud",
    Verksamhetsutövare: "Slättens Vind ek för",
    Organisationsnummer: "769606-9082",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mellerud",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 22, 2019",
  },
  {
    Områdes_ID: "1461-V-010",
    Projektnamn: "Erikstads-Mossebol 1",
    Verksamhetsutövare: "Slättens Vind ek för",
    Organisationsnummer: "769606-9082",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mellerud",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 22, 2019",
  },
  {
    Områdes_ID: "1461-V-011",
    Projektnamn: "Erikstads-Mossebol 2",
    Verksamhetsutövare: "Dalboslättens Vind AB",
    Organisationsnummer: "556802-3385",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mellerud",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1461-V-016",
    Projektnamn: "Vena",
    Verksamhetsutövare: "Slättens Vind ek för",
    Organisationsnummer: "769606-9082",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mellerud",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1461-V-022",
    Projektnamn: "Östebyn",
    Verksamhetsutövare: "Vindbyggarna i Lysekil AB",
    Organisationsnummer: "556634-6424",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mellerud",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 15, 2021",
  },
  {
    Områdes_ID: "1461-V-026",
    Projektnamn: "Glysbyn",
    Verksamhetsutövare: "Bolstad Vind AB",
    Organisationsnummer: "556798-4637",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5.35,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mellerud",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1461-V-027",
    Projektnamn: "Bolstads-Torp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mellerud",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1461-V-028",
    Projektnamn: "Brändekulla/Sunnanå",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.25,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mellerud",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1461-V-029",
    Projektnamn: "Nygården",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mellerud",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 14, 2019",
  },
  {
    Områdes_ID: "1461-V-030",
    Projektnamn: "Nygården",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mellerud",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1461-V-031",
    Projektnamn: "Nordkärrshögar",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mellerud",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1461-V-032",
    Projektnamn: "Rödjans vindkraftverk",
    Verksamhetsutövare: "Rödjans Lantbruks AB",
    Organisationsnummer: "556394-0534",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mellerud",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 15, 2019",
  },
  {
    Områdes_ID: "1461-V-033",
    Projektnamn: "Rödjans Gård",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.26,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mellerud",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1461-V-034",
    Projektnamn: "Järns-Bön",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.9,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mellerud",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1462-V-002",
    Projektnamn: "Ström",
    Verksamhetsutövare: "Firma Ramström Vind AB",
    Organisationsnummer: "556688-2683",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "LillaEdet",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Aug 19, 2019",
  },
  {
    Områdes_ID: "1462-V-006",
    Projektnamn: "Prässebergens vindpark",
    Verksamhetsutövare: "LOA Vind AB",
    Organisationsnummer: "556756-2573",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "LillaEdet",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 22, 2019",
  },
  {
    Områdes_ID: "1462-V-008",
    Projektnamn: "Prässebergens vindpark",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "LillaEdet",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 07, 2021",
  },
  {
    Områdes_ID: "1463-V-002",
    Projektnamn: "Kappelabo",
    Verksamhetsutövare: "Firma Ramström Vind AB",
    Organisationsnummer: "556688-2683",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mark",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1463-V-003",
    Projektnamn: "Skepared",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mark",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1463-V-005",
    Projektnamn: "Hyssna-Hökås",
    Verksamhetsutövare: "Hyssna Vind AB",
    Organisationsnummer: "556719-9830",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mark",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1463-V-007",
    Projektnamn: "Staxered",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.07,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mark",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1463-V-008",
    Projektnamn: "Haby",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mark",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 05, 2019",
  },
  {
    Områdes_ID: "1463-V-009",
    Projektnamn: "Naturbruksgymn Strömmaskolan(bevis1998-11-04)",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.25,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mark",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1463-V-010",
    Projektnamn: "Åsen (gårdskraftverk) Slutbevis 2007-03-15",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.03,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mark",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1463-V-015",
    Projektnamn: "Flohult",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mark",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1463-V-016",
    Projektnamn: "Hulatorp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mark",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1463-V-017",
    Projektnamn: "Holm vindkraftspark ? Park i flera komm o län",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mark",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1463-V-018",
    Projektnamn: "Västfastigheter, Västra Götalansregionen/Natgym",
    Verksamhetsutövare: "Västfastigheter V:a Götalandsregionen",
    Organisationsnummer: "232100-0131",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mark",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 05, 2019",
  },
  {
    Områdes_ID: "1463-V-019",
    Projektnamn: "Kappelabo",
    Verksamhetsutövare: "Firma Ramström Vind AB",
    Organisationsnummer: "556688-2683",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mark",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "1463-V-025",
    Projektnamn: "Bläsebo",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mark",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1463-V-026",
    Projektnamn: "Björketorp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mark",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 14, 2019",
  },
  {
    Områdes_ID: "1463-V-027",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mark",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1465-V-005",
    Projektnamn: "Holmarp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Svenljunga",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 14, 2019",
  },
  {
    Områdes_ID: "1470-V-003",
    Projektnamn: "3954 Gallegården",
    Verksamhetsutövare: "Hallqvist Gräv- & Täckdiknings AB",
    Organisationsnummer: "556437-4394",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.65,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1470-V-006",
    Projektnamn: "Fårhaga",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1470-V-007",
    Projektnamn: "Gategården, Bengt C",
    Verksamhetsutövare: "Wallenstam Vindkraft Gategården AB",
    Organisationsnummer: "556739-8820",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5.5,
    Uppmätt_årsproduktion_GWh: 5.53,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1470-V-008",
    Projektnamn: "Halvås",
    Verksamhetsutövare: "Firma Ramström Vind AB",
    Organisationsnummer: "556688-2683",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.85,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1470-V-009",
    Projektnamn: "Helleberg 1",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.4,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1470-V-010",
    Projektnamn: "Håkantorp 1",
    Verksamhetsutövare: "Vara Blåst AB",
    Organisationsnummer: "556774-3074",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5.3,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1470-V-011",
    Projektnamn: "Håkantorp 2",
    Verksamhetsutövare: "Ekovind AB",
    Organisationsnummer: "556343-8208",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5.3,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1470-V-012",
    Projektnamn: "Kaggården",
    Verksamhetsutövare: "Lidbil Fastighet Vara AB",
    Organisationsnummer: "556448-9820",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1470-V-016",
    Projektnamn: "Mörkagården 2",
    Verksamhetsutövare: "MA vind AB",
    Organisationsnummer: "556708-5302",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1470-V-017",
    Projektnamn: "Mörkagården 4",
    Verksamhetsutövare: "Lumbers Vind AB",
    Organisationsnummer: "556778-6271",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1470-V-018",
    Projektnamn: "Mörkagården 1",
    Verksamhetsutövare: "Vindsone AB",
    Organisationsnummer: "556658-2101",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.7,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1470-V-019",
    Projektnamn: "Mörkagården 3",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1470-V-020",
    Projektnamn: "Rangeltorp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.43,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1470-V-022",
    Projektnamn: "Rosa",
    Verksamhetsutövare: "Östbäck Lantbruk I Vara AB",
    Organisationsnummer: "556371-7080",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1470-V-024",
    Projektnamn: "Ryda",
    Verksamhetsutövare: "Ryda Vind AB",
    Organisationsnummer: "556679-0860",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1470-V-025",
    Projektnamn: "Ryda 2",
    Verksamhetsutövare: "Ryda Vind AB",
    Organisationsnummer: "556679-0860",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1470-V-028",
    Projektnamn: "Skarstad",
    Verksamhetsutövare: "Ledsjö Vind AB",
    Organisationsnummer: "556436-7281",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5.2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1470-V-039",
    Projektnamn: "Edum",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 14, 2019",
  },
  {
    Områdes_ID: "1470-V-040",
    Projektnamn: "Edum",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 14, 2019",
  },
  {
    Områdes_ID: "1470-V-041",
    Projektnamn: "Vara",
    Verksamhetsutövare: "Slättens Vind ek för",
    Organisationsnummer: "769606-9082",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1470-V-042",
    Projektnamn: "Vedum Horshaga",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1470-V-043",
    Projektnamn: "Stora Bäckebo",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.7,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 14, 2019",
  },
  {
    Områdes_ID: "1470-V-044",
    Projektnamn: "Sandåker",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.17,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1470-V-045",
    Projektnamn: "TL Vind 1",
    Verksamhetsutövare: "TL Vind AB",
    Organisationsnummer: "556779-7369",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 6.1,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1470-V-046",
    Projektnamn: "TL Vind 2",
    Verksamhetsutövare: "TL Vind AB",
    Organisationsnummer: "556779-7369",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 6.1,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1470-V-047",
    Projektnamn: "TL Vind 3",
    Verksamhetsutövare: "TL Vind AB",
    Organisationsnummer: "556779-7369",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 6.1,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1471-V-001",
    Projektnamn: "Brattefors I",
    Verksamhetsutövare: "Kinnekulle Ring AB",
    Organisationsnummer: "556125-9267",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "Jul 01, 1999",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Götene",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1471-V-002",
    Projektnamn: "Broholm I",
    Verksamhetsutövare: "Ova Vind AB",
    Organisationsnummer: "556669-6745",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 2.3,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "Oct 19, 2007",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Götene",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1471-V-003",
    Projektnamn: "Brunnstorp I",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "Aug 25, 2004",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Götene",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 22, 2019",
  },
  {
    Områdes_ID: "1471-V-004",
    Projektnamn: "Bäckgården",
    Verksamhetsutövare: "Erikstorp Utveckling AB",
    Organisationsnummer: "556748-1667",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Götene",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1471-V-005",
    Projektnamn: "Dalaholm I",
    Verksamhetsutövare: "Stenökra AB",
    Organisationsnummer: "556676-9203",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "Oct 19, 2005",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Götene",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 22, 2019",
  },
  {
    Områdes_ID: "1471-V-006",
    Projektnamn: "Dalaholm II",
    Verksamhetsutövare: "Stenökra AB",
    Organisationsnummer: "556676-9203",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.7,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "Nov 01, 2009",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Götene",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1471-V-008",
    Projektnamn: "Erikstorp II",
    Verksamhetsutövare: "Erikstorp Vind AB",
    Organisationsnummer: "556648-0215",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.3,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "Oct 17, 2003",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Götene",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 07, 2021",
  },
  {
    Områdes_ID: "1471-V-010",
    Projektnamn: "Horsmarka",
    Verksamhetsutövare: "Greenextreme AB",
    Organisationsnummer: "556722-4596",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Götene",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1471-V-012",
    Projektnamn: "Jättadansen I",
    Verksamhetsutövare: "Roséns i Götene AB",
    Organisationsnummer: "556583-6573",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 4.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Götene",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1471-V-013",
    Projektnamn: "Kollbogården I",
    Verksamhetsutövare: "Kilabackens Vind AB",
    Organisationsnummer: "556657-8547",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.7,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Götene",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Mar 15, 2019",
  },
  {
    Områdes_ID: "1471-V-014",
    Projektnamn: "Kollbogården II",
    Verksamhetsutövare: "Agrivind AB",
    Organisationsnummer: "556242-1387",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Götene",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Mar 15, 2019",
  },
  {
    Områdes_ID: "1471-V-016",
    Projektnamn: "Kyrkebo I",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.35,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Götene",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 15, 2019",
  },
  {
    Områdes_ID: "1471-V-017",
    Projektnamn: "Källstorp I",
    Verksamhetsutövare: "Skeby Vind AB",
    Organisationsnummer: "556625-4784",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.7,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Götene",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 22, 2019",
  },
  {
    Områdes_ID: "1471-V-018",
    Projektnamn: "Mariedal I",
    Verksamhetsutövare: "Ova Vind AB",
    Organisationsnummer: "556669-6745",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5,
    Uppmätt_årsproduktion_GWh: 5.2,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Götene",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1471-V-019",
    Projektnamn: "Nolebo I",
    Verksamhetsutövare: "Ova Vind AB",
    Organisationsnummer: "556669-6745",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.5,
    Uppmätt_årsproduktion_GWh: 1.42,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Götene",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1471-V-022",
    Projektnamn: "Rasegården I",
    Verksamhetsutövare: "Broby Vind AB",
    Organisationsnummer: "556628-8352",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Götene",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1471-V-024",
    Projektnamn: "Hangelösa 1",
    Verksamhetsutövare: "Skattegården Hangelösa AB",
    Organisationsnummer: "556428-1003",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.53,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Götene",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1471-V-025",
    Projektnamn: "Skeby I",
    Verksamhetsutövare: "Skeby Vind AB",
    Organisationsnummer: "556625-4784",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.3,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Götene",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1471-V-026",
    Projektnamn: "Skeby II",
    Verksamhetsutövare: "Skeby Vind AB",
    Organisationsnummer: "556625-4784",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Götene",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1471-V-028",
    Projektnamn: "Stockebäck I",
    Verksamhetsutövare: "Agrivind AB",
    Organisationsnummer: "556242-1387",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5.2,
    Uppmätt_årsproduktion_GWh: 5.14,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Götene",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1471-V-030",
    Projektnamn: "Stora lund I",
    Verksamhetsutövare: "Kaspiska Vind AB",
    Organisationsnummer: "556645-7999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Götene",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1471-V-031",
    Projektnamn: "Stora lund II",
    Verksamhetsutövare: "Agrivind AB",
    Organisationsnummer: "556242-1387",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5,
    Uppmätt_årsproduktion_GWh: 5.2,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Götene",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1471-V-032",
    Projektnamn: "Svenska Foder",
    Verksamhetsutövare: "Agrivind AB",
    Organisationsnummer: "556242-1387",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Götene",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1471-V-033",
    Projektnamn: "Västermark I",
    Verksamhetsutövare: "Blåsut Vind AB",
    Organisationsnummer: "556656-8472",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Götene",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1471-V-035",
    Projektnamn: "Vättlösa I",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Götene",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1471-V-036",
    Projektnamn: "Vättlösa II",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Götene",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Aug 28, 2019",
  },
  {
    Områdes_ID: "1472-V-003",
    Projektnamn: "Långsjön 4",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tibro",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1472-V-004",
    Projektnamn: "Skinnfällen 1",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tibro",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1472-V-005",
    Projektnamn: "Stora Kråkhult 1",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tibro",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 06, 2019",
  },
  {
    Områdes_ID: "1472-V-007",
    Projektnamn: "Spännefalla",
    Verksamhetsutövare: "Triventus AB",
    Organisationsnummer: "556627-3016",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 3.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tibro",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1473-V-001",
    Projektnamn: "Borrud 1",
    Verksamhetsutövare: "Svenska Vindbolaget AB",
    Organisationsnummer: "556759-9013",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Töreboda",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 20, 2020",
  },
  {
    Områdes_ID: "1473-V-002",
    Projektnamn: "Borrud 2",
    Verksamhetsutövare: "Svenska Vindbolaget AB",
    Organisationsnummer: "556759-9013",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Töreboda",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 20, 2020",
  },
  {
    Områdes_ID: "1473-V-007",
    Projektnamn: "Ljungås",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Töreboda",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1473-V-009",
    Projektnamn: "Bert",
    Verksamhetsutövare: "Berts Elektriska HB",
    Organisationsnummer: "916569-7864",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Töreboda",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1473-V-011",
    Projektnamn: "Nolåsen",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.05,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Töreboda",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1473-V-013",
    Projektnamn: "Smeby",
    Verksamhetsutövare: "Sörgårdsvind AB",
    Organisationsnummer: "556677-6943",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Töreboda",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1473-V-015",
    Projektnamn: "Bällefors-Kyrketorp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.1,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Töreboda",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1480-V-002",
    Projektnamn: "Gårdsten",
    Verksamhetsutövare: "Göteborg Energi AB",
    Organisationsnummer: "556362-6794",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Göteborg",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1484-V-005",
    Projektnamn: "Lyse Bonus",
    Verksamhetsutövare: "Lärbro SPW AB",
    Organisationsnummer: "556794-5935",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.95,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Lysekil",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1484-V-006",
    Projektnamn: "Lys-Vind",
    Verksamhetsutövare: "Mellby Kraft AB",
    Organisationsnummer: "556529-5267",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.95,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Lysekil",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Jun 24, 2021",
  },
  {
    Områdes_ID: "1484-V-007",
    Projektnamn: "Sivik III",
    Verksamhetsutövare: "Mellby Kraft AB",
    Organisationsnummer: "556529-5267",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Lysekil",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Jun 24, 2021",
  },
  {
    Områdes_ID: "1484-V-008",
    Projektnamn: "Si-Vind",
    Verksamhetsutövare: "Mellby Kraft AB",
    Organisationsnummer: "556529-5267",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.95,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Lysekil",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Jun 24, 2021",
  },
  {
    Områdes_ID: "1484-V-009",
    Projektnamn: "Sture Nolby HA 046",
    Verksamhetsutövare: "Grönljung AB",
    Organisationsnummer: "556404-1712",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Lysekil",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1484-V-010",
    Projektnamn: "Vind Invest",
    Verksamhetsutövare: "Mellby Kraft AB",
    Organisationsnummer: "556529-5267",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.7,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Lysekil",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Jun 24, 2021",
  },
  {
    Områdes_ID: "1485-V-001",
    Projektnamn: "",
    Verksamhetsutövare: "Listaskogens Stuteri AB",
    Organisationsnummer: "556595-7551",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Uddevalla",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "1485-V-012",
    Projektnamn: "Ljungkile Svenshögen",
    Verksamhetsutövare: "Rabbalshede Kraft AB",
    Organisationsnummer: "556681-4652",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Uddevalla",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1485-V-016",
    Projektnamn: "RÅLANDA",
    Verksamhetsutövare: "Wallenstam Vindkraft Rålanda AB",
    Organisationsnummer: "556721-5206",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Uddevalla",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 25, 2019",
  },
  {
    Områdes_ID: "1485-V-018",
    Projektnamn: "RÅLANDA",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Uddevalla",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1485-V-022",
    Projektnamn: "Torkelsröd i Uddevalla",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.09,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Uddevalla",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Nov 08, 2021",
  },
  {
    Områdes_ID: "1485-V-027",
    Projektnamn: "Kavlanda",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.12,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Uddevalla",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1486-V-006",
    Projektnamn: "Hogdals-Hjälmberg",
    Verksamhetsutövare: "Älvsborgsvind AB (publ)",
    Organisationsnummer: "556508-4448",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 4,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Strömstad",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1486-V-009",
    Projektnamn: "Björnvinden",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.59,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Strömstad",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1487-V-001",
    Projektnamn: "Asmundtorp",
    Verksamhetsutövare: "Wind Energy i Brålanda AB",
    Organisationsnummer: "556734-1978",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.83,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vänersborg",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 25, 2019",
  },
  {
    Områdes_ID: "1487-V-005",
    Projektnamn: "Brålanda-Torp",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5.35,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vänersborg",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 25, 2019",
  },
  {
    Områdes_ID: "1487-V-006",
    Projektnamn: "Båberg",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vänersborg",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1487-V-007",
    Projektnamn: "Fristorp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vänersborg",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1487-V-009",
    Projektnamn: "Gestads-Björnerud",
    Verksamhetsutövare: "Triventus AB",
    Organisationsnummer: "556627-3016",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vänersborg",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1487-V-010",
    Projektnamn: "Gestads-Björnerud",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vänersborg",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1487-V-012",
    Projektnamn: "Holm",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5.35,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vänersborg",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 25, 2019",
  },
  {
    Områdes_ID: "1487-V-016",
    Projektnamn: "Nuntorpskolans vindkraftverk",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.25,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vänersborg",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1487-V-017",
    Projektnamn: "Ramnered",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vänersborg",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "1487-V-018",
    Projektnamn: "Rånnum",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vänersborg",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1487-V-020",
    Projektnamn: "Simonstorp",
    Verksamhetsutövare: "CRC Vindkraft AB",
    Organisationsnummer: "556700-3545",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vänersborg",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "1487-V-021",
    Projektnamn: "Siviken",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vänersborg",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1487-V-023",
    Projektnamn: "Tobyn",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vänersborg",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "1487-V-024",
    Projektnamn: "Troneberg",
    Verksamhetsutövare: "Elplanering Väst AB",
    Organisationsnummer: "556341-6923",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.02,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vänersborg",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1487-V-025",
    Projektnamn: "Uppegården",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vänersborg",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1487-V-026",
    Projektnamn: "Vänersnäs",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vänersborg",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 06, 2019",
  },
  {
    Områdes_ID: "1487-V-029",
    Projektnamn: "Biängen",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vänersborg",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1487-V-030",
    Projektnamn: "Bränneriet",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vänersborg",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1487-V-031",
    Projektnamn: "3965-Eka Lilla V-näs",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.17,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vänersborg",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1487-V-032",
    Projektnamn: "Wenersnes",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.7,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vänersborg",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1487-V-033",
    Projektnamn: "Åstebo",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vänersborg",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 06, 2019",
  },
  {
    Områdes_ID: "1488-V-001",
    Projektnamn: "Hullsjön 1",
    Verksamhetsutövare: "Lunden Ek för",
    Organisationsnummer: "769601-0706",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.25,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Trollhättan",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1488-V-002",
    Projektnamn: "Hullsjön 2",
    Verksamhetsutövare: "Vindkraftens Vänner Lunden Björkelund Ekonomisk Fö",
    Organisationsnummer: "769610-3923",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.3,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Trollhättan",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1488-V-004",
    Projektnamn: "Halltorp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Trollhättan",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1488-V-008",
    Projektnamn: "Velanda gård",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Trollhättan",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1488-V-010",
    Projektnamn: "Vittene",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.05,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Trollhättan",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1489-V-001",
    Projektnamn: "Genneved",
    Verksamhetsutövare: "Björka Vind AB",
    Organisationsnummer: "556815-6144",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Alingsås",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1489-V-002",
    Projektnamn: "Rödeneplatån",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Alingsås",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Mar 07, 2019",
  },
  {
    Områdes_ID: "1490-V-002",
    Projektnamn: "Vindkraft Rångedala",
    Verksamhetsutövare: "Nytello Invest AB",
    Organisationsnummer: "556493-6119",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Borås",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 08, 2019",
  },
  {
    Områdes_ID: "1491-V-007",
    Projektnamn: "Hössna",
    Verksamhetsutövare: "Greenextreme AB",
    Organisationsnummer: "556722-4596",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ulricehamn",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "1491-V-011",
    Projektnamn: "Zepyrus af Hov",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.33,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ulricehamn",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1491-V-012",
    Projektnamn: "Älmestad",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ulricehamn",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 14, 2019",
  },
  {
    Områdes_ID: "1492-V-005",
    Projektnamn: "Hässelbacka",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.17,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Åmål",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1492-V-007",
    Projektnamn: "Söderbodane",
    Verksamhetsutövare: "Älvsborgsvind AB (publ)",
    Organisationsnummer: "556508-4448",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Åmål",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1493-V-003",
    Projektnamn: "Hasslerör",
    Verksamhetsutövare: "Svenska Vindbolaget AB",
    Organisationsnummer: "556759-9013",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mariestad",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 20, 2020",
  },
  {
    Områdes_ID: "1493-V-004",
    Projektnamn: "Hasslerör",
    Verksamhetsutövare: "Svenska Vindbolaget AB",
    Organisationsnummer: "556759-9013",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mariestad",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 20, 2020",
  },
  {
    Områdes_ID: "1493-V-009",
    Projektnamn: "Bromöllan",
    Verksamhetsutövare: "Brommö Vind ek för",
    Organisationsnummer: "769601-0482",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.1,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mariestad",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1493-V-010",
    Projektnamn: "Sjöbergs Säteri",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.32,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mariestad",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1494-V-001",
    Projektnamn: "Backa Vind 3",
    Verksamhetsutövare: "Freja Agri AB",
    Organisationsnummer: "556729-4433",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Lidköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1494-V-002",
    Projektnamn: "Lovene",
    Verksamhetsutövare: "LoVind AB",
    Organisationsnummer: "556779-0067",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Lidköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1494-V-007",
    Projektnamn: "Eke Vind",
    Verksamhetsutövare: "Agrivind AB",
    Organisationsnummer: "556242-1387",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.9,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Lidköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1494-V-009",
    Projektnamn: "Härjevads Vind",
    Verksamhetsutövare: "Härjevads Vind AB",
    Organisationsnummer: "556721-9687",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.7,
    Uppmätt_årsproduktion_GWh: 1.88,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Lidköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1494-V-012",
    Projektnamn: "Almetorps Säteri",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.2,
    Uppmätt_årsproduktion_GWh: 0.22,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Lidköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1494-V-014",
    Projektnamn: "Kedumsvik",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.36,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Lidköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1494-V-016",
    Projektnamn: "Lanneholm",
    Verksamhetsutövare: "Johan Vind Hb",
    Organisationsnummer: "969676-7764",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Lidköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1494-V-017",
    Projektnamn: "Lanna 1, Lidköping",
    Verksamhetsutövare: "Sydvind Energi AB",
    Organisationsnummer: "556809-1812",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Lidköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1494-V-018",
    Projektnamn: "Månsagården",
    Verksamhetsutövare: "Uvereds vindenergi ek. för.",
    Organisationsnummer: "769602-5324",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.1,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Lidköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1494-V-021",
    Projektnamn: "Skog 1",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.87,
    Uppmätt_årsproduktion_GWh: 1.74,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Lidköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1494-V-022",
    Projektnamn: "Skog 2",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 2,
    Uppmätt_årsproduktion_GWh: 1.89,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Lidköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1495-V-001",
    Projektnamn: "RANÅKER / 747",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.9,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Skara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1495-V-002",
    Projektnamn: "MÄRENE 1992",
    Verksamhetsutövare: "AB Bröd Bergström",
    Organisationsnummer: "556362-8212",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.65,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Skara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1495-V-003",
    Projektnamn: "ÖLANDA / 4391",
    Verksamhetsutövare: "Sahlströms Jordbruk AB",
    Organisationsnummer: "556399-8557",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Skara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1495-V-005",
    Projektnamn: "VIGLUNDA / 4456",
    Verksamhetsutövare: "Javi Vind AB",
    Organisationsnummer: "556705-6410",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Skara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1495-V-006",
    Projektnamn: "SKALLMEJA / 5680",
    Verksamhetsutövare: "Skallmeja BAS-vind AB",
    Organisationsnummer: "556539-8913",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.7,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Skara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 07, 2021",
  },
  {
    Områdes_ID: "1495-V-008",
    Projektnamn: "MUNSTORP 5691",
    Verksamhetsutövare: "Vindwal AB",
    Organisationsnummer: "556747-8531",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Skara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1495-V-011",
    Projektnamn: "HÄSTHALLA / 5692",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Skara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 25, 2019",
  },
  {
    Områdes_ID: "1495-V-012",
    Projektnamn: "SKALLMEJA / 5694",
    Verksamhetsutövare: "Agrivind AB",
    Organisationsnummer: "556242-1387",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Skara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 25, 2019",
  },
  {
    Områdes_ID: "1495-V-013",
    Projektnamn: "ISTRUMS-LYCKE / 5695",
    Verksamhetsutövare: "Sahlströms Jordbruk AB",
    Organisationsnummer: "556399-8557",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.4,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Skara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1495-V-014",
    Projektnamn: "MUNSTORP 7012",
    Verksamhetsutövare: "Agrivind AB",
    Organisationsnummer: "556242-1387",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Skara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1495-V-015",
    Projektnamn: "BLOMBACKA / 5693",
    Verksamhetsutövare: "Blombacka Vind AB",
    Organisationsnummer: "556681-4009",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Skara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1495-V-018",
    Projektnamn: "TÅSTORP / A",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Skara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1495-V-021",
    Projektnamn: "STUBBE",
    Verksamhetsutövare: "Agrivind AB",
    Organisationsnummer: "556242-1387",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Skara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1495-V-023",
    Projektnamn: "SKELYCKE",
    Verksamhetsutövare: "Agrivind AB",
    Organisationsnummer: "556242-1387",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Skara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1495-V-024",
    Projektnamn: "SKALLMEJA / 7304",
    Verksamhetsutövare: "Elvingsgården Vind AB",
    Organisationsnummer: "556878-0992",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5.7,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Skara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 06, 2021",
  },
  {
    Områdes_ID: "1495-V-026",
    Projektnamn: "Tagelberg 1",
    Verksamhetsutövare: "Bixia Gryningsvind AB",
    Organisationsnummer: "556779-5348",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5.4,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Skara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1495-V-035",
    Projektnamn: "TÅSTORP / B",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Skara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1495-V-037",
    Projektnamn: "HORSHAGA",
    Verksamhetsutövare: "Schönborg Vind AB",
    Organisationsnummer: "556782-7398",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Skara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "1496-V-001",
    Projektnamn: "Brumstorp 1",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.4,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Skövde",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 25, 2019",
  },
  {
    Områdes_ID: "1496-V-002",
    Projektnamn: "Greby 1",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Skövde",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1496-V-003",
    Projektnamn: "Klockartorp 1",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Skövde",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 25, 2019",
  },
  {
    Områdes_ID: "1496-V-004",
    Projektnamn: "Kyrkbolet 1",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Skövde",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1496-V-006",
    Projektnamn: "Dagstorp 1",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Skövde",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Apr 02, 2019",
  },
  {
    Områdes_ID: "1496-V-007",
    Projektnamn: "Tåstorp 1",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Skövde",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1496-V-009",
    Projektnamn: "Askeberga 5",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Skövde",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1496-V-010",
    Projektnamn: "Djursätra 2",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Skövde",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 04, 2019",
  },
  {
    Områdes_ID: "1496-V-011",
    Projektnamn: "Djursätra 1",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Skövde",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 25, 2019",
  },
  {
    Områdes_ID: "1496-V-014",
    Projektnamn: "Vkv Vaholm 1-8",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Skövde",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1496-V-016",
    Projektnamn: "Skultorp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Skövde",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1497-V-004",
    Projektnamn: "Sörtorp",
    Verksamhetsutövare: "Anders Åkesson Konsult AB",
    Organisationsnummer: "556764-5600",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hjo",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1497-V-008",
    Projektnamn: "Hjällö",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.7,
    Uppmätt_årsproduktion_GWh: 1.43,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Hjo",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1497-V-010",
    Projektnamn: "Stakahemmet",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hjo",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Aug 28, 2019",
  },
  {
    Områdes_ID: "1497-V-011",
    Projektnamn: "Stakahemmet",
    Verksamhetsutövare: "Stakahemmets Vind AB",
    Organisationsnummer: "556710-7882",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Hjo",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 25, 2019",
  },
  {
    Områdes_ID: "1497-V-013",
    Projektnamn: "Stora Solberga",
    Verksamhetsutövare: "Agrivind AB",
    Organisationsnummer: "556242-1387",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5.3,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Hjo",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1497-V-020",
    Projektnamn: "Atteby",
    Verksamhetsutövare: "Gota Industriutveckling AB",
    Organisationsnummer: "556286-9486",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Hjo",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 25, 2019",
  },
  {
    Områdes_ID: "1497-V-021",
    Projektnamn: "Karstorp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.3,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Hjo",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 25, 2019",
  },
  {
    Områdes_ID: "1497-V-022",
    Projektnamn: "Styrshult",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Hjo",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1497-V-023",
    Projektnamn: "Bjärg",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hjo",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1497-V-024",
    Projektnamn: "Grevbäcks-Munkebo",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hjo",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1497-V-025",
    Projektnamn: "Stekarekärret",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hjo",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1497-V-026",
    Projektnamn: "Överryd",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hjo",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1497-V-027",
    Projektnamn: "Fridene",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.3,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Hjo",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1497-V-029",
    Projektnamn: "Nyskog",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Hjo",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1497-V-030",
    Projektnamn: "Dalshult",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hjo",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "1497-V-031",
    Projektnamn: "Dalshult-Eskelid",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hjo",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "1497-V-032",
    Projektnamn: "Dalshult 1;2 (Hålls vilande på sök. begäran)",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hjo",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Aug 28, 2019",
  },
  {
    Områdes_ID: "1497-V-033",
    Projektnamn: "Signesbo",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Hjo",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1497-V-034",
    Projektnamn: "Granbolet",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.1,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Hjo",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1497-V-035",
    Projektnamn: "Signesbo",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Hjo",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1497-V-038",
    Projektnamn: "Signesbo",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hjo",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1497-V-039",
    Projektnamn: "Äskelid",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hjo",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "1497-V-040",
    Projektnamn: "Nyskog",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hjo",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "1497-V-041",
    Projektnamn: "Grevbäcks Munkebo",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Hjo",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 25, 2019",
  },
  {
    Områdes_ID: "1497-V-042",
    Projektnamn: "Bjärg",
    Verksamhetsutövare: "Triventus AB",
    Organisationsnummer: "556627-3016",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hjo",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1497-V-044",
    Projektnamn: "Svebråta",
    Verksamhetsutövare: "Triventus AB",
    Organisationsnummer: "556627-3016",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hjo",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1497-V-045",
    Projektnamn: "Laggarebolet",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.34,
    Uppmätt_årsproduktion_GWh: 1.34,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Hjo",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1497-V-049",
    Projektnamn: "Mellomberga",
    Verksamhetsutövare: "Klöverenergi AB",
    Organisationsnummer: "556698-2632",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hjo",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "1498-V-008",
    Projektnamn: "Lammevad",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.7,
    Uppmätt_årsproduktion_GWh: 1.83,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tidaholm",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1498-V-010",
    Projektnamn: "Simona Vindkraft Leringen",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tidaholm",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1499-V-001",
    Projektnamn: "Ammagården 1",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Falköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1499-V-004",
    Projektnamn: "Badene 5",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.3,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1499-V-005",
    Projektnamn: "Badene 6 (lilla)",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.13,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 15, 2019",
  },
  {
    Områdes_ID: "1499-V-006",
    Projektnamn: "Floby 1",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.1,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 15, 2019",
  },
  {
    Områdes_ID: "1499-V-007",
    Projektnamn: "Grimskullen",
    Verksamhetsutövare: "Österåstorken AB",
    Organisationsnummer: "556545-2264",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.85,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1499-V-009",
    Projektnamn: "Göteve 1",
    Verksamhetsutövare: "Götlinds Svets AB",
    Organisationsnummer: "556142-7799",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.14,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1499-V-012",
    Projektnamn: "Kampagården 1",
    Verksamhetsutövare: "Smebyvind AB",
    Organisationsnummer: "556618-6366",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1499-V-013",
    Projektnamn: "Kleva 1",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1499-V-015",
    Projektnamn: "Källeberg 6",
    Verksamhetsutövare: "Ekovind AB",
    Organisationsnummer: "556343-8208",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.25,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1499-V-016",
    Projektnamn: "Kälvene 1",
    Verksamhetsutövare: "Sofab Fastigheter AB",
    Organisationsnummer: "556749-9651",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.33,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1499-V-017",
    Projektnamn: "Luttra 1",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.35,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1499-V-019",
    Projektnamn: "Mönarp 1",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.9,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 25, 2019",
  },
  {
    Områdes_ID: "1499-V-020",
    Projektnamn: "Mönarp 2",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.9,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 25, 2019",
  },
  {
    Områdes_ID: "1499-V-021",
    Projektnamn: "Naglarp 1",
    Verksamhetsutövare: "Rajo Fastighets AB",
    Organisationsnummer: "556462-6439",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.62,
    Uppmätt_årsproduktion_GWh: 1.37,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1499-V-022",
    Projektnamn: "Naglarp 2",
    Verksamhetsutövare: "Rajo Fastighets AB",
    Organisationsnummer: "556462-6439",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.7,
    Uppmätt_årsproduktion_GWh: 1.61,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1499-V-026",
    Projektnamn: "Rosenskog A1",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.7,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1499-V-027",
    Projektnamn: "Rosenskog A2",
    Verksamhetsutövare: "Agrivind AB",
    Organisationsnummer: "556242-1387",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.7,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1499-V-028",
    Projektnamn: "Rosenskog A6",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Falköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1499-V-029",
    Projektnamn: "Rosenskog V7",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Falköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Apr 01, 2019",
  },
  {
    Områdes_ID: "1499-V-030",
    Projektnamn: "Rosenskog W8, Anders Götlind",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Falköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Apr 01, 2019",
  },
  {
    Områdes_ID: "1499-V-031",
    Projektnamn: "Rosenskog W9, Anders Götlind",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Falköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Apr 01, 2019",
  },
  {
    Områdes_ID: "1499-V-036",
    Projektnamn: "Snösbäck 1",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.15,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 25, 2019",
  },
  {
    Områdes_ID: "1499-V-037",
    Projektnamn: "Sundsholm",
    Verksamhetsutövare: "Sundsholm el HB",
    Organisationsnummer: "969724-9317",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 4.4,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1499-V-038",
    Projektnamn: "Tunhem 1",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.65,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 25, 2019",
  },
  {
    Områdes_ID: "1499-V-039",
    Projektnamn: "Tunhem 2",
    Verksamhetsutövare: "Östra Tunhem Vind AB",
    Organisationsnummer: "556704-3020",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.75,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 15, 2019",
  },
  {
    Områdes_ID: "1499-V-040",
    Projektnamn: "Tunhem 3",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 2.1,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1499-V-041",
    Projektnamn: "Tyska gården 1",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.82,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 25, 2019",
  },
  {
    Områdes_ID: "1499-V-042",
    Projektnamn: "Tyska gården 5",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 2.2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 25, 2019",
  },
  {
    Områdes_ID: "1499-V-044",
    Projektnamn: "Ugglum 1",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Falköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "May 02, 2019",
  },
  {
    Områdes_ID: "1499-V-045",
    Projektnamn: "Yllestad 1",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Falköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1499-V-046",
    Projektnamn: "Yllestad",
    Verksamhetsutövare: "Vindenergi i Badene AB",
    Organisationsnummer: "556611-1562",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Falköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 08, 2019",
  },
  {
    Områdes_ID: "1499-V-047",
    Projektnamn: "Gökhem",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1760-V-001",
    Projektnamn: "Släbråten",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Storfors",
    Län: "Värmlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Apr 02, 2019",
  },
  {
    Områdes_ID: "1761-V-002",
    Projektnamn: "Lucia, Boholmen",
    Verksamhetsutövare: "Kooperativet Hammarövind 1",
    Organisationsnummer: "769600-9278",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Hammarö",
    Län: "Värmlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1766-V-002",
    Projektnamn: "Skinnerud",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Sunne",
    Län: "Värmlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1766-V-003",
    Projektnamn: "Årås",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Sunne",
    Län: "Värmlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1780-V-003",
    Projektnamn: "Nolby, Arnön",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.7,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Karlstad",
    Län: "Värmlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 25, 2019",
  },
  {
    Områdes_ID: "1781-V-009",
    Projektnamn: "Posseberg",
    Verksamhetsutövare: "Cloudberry Offshore Wind AB",
    Organisationsnummer: "556710-7403",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 6.2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kristinehamn",
    Län: "Värmlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1781-V-010",
    Projektnamn: "Varnums-Högeberg",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kristinehamn",
    Län: "Värmlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1781-V-011",
    Projektnamn: "Kvarnbråten",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.35,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kristinehamn",
    Län: "Värmlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1785-V-002",
    Projektnamn: "Eskilsäters-Boda",
    Verksamhetsutövare: "Vidslättens Vind AB",
    Organisationsnummer: "556741-3751",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Säffle",
    Län: "Värmlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 25, 2019",
  },
  {
    Områdes_ID: "1785-V-003",
    Projektnamn: "Forsvik",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.11,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Säffle",
    Län: "Värmlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 14, 2019",
  },
  {
    Områdes_ID: "1785-V-006",
    Projektnamn: "Magnebyn",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Säffle",
    Län: "Värmlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1785-V-010",
    Projektnamn: "Stora Herrestad",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Säffle",
    Län: "Värmlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1785-V-015",
    Projektnamn: "Ölseruds prästgård",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Säffle",
    Län: "Värmlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 25, 2019",
  },
  {
    Områdes_ID: "1785-V-016",
    Projektnamn: "Önaholm",
    Verksamhetsutövare: "Perrolf Energi AB",
    Organisationsnummer: "556086-8688",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.45,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Säffle",
    Län: "Värmlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1814-V-002",
    Projektnamn: "Ekebergs gård Jeremias",
    Verksamhetsutövare: "Lanna Hiddinge Vindkraft.",
    Organisationsnummer: "769605-0025",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.25,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Lekeberg",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1861-V-003",
    Projektnamn: "Källtorp Tarsta",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.1,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Hallsberg",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1862-V-003",
    Projektnamn: "Vårbo",
    Verksamhetsutövare: "Greenextreme AB",
    Organisationsnummer: "556722-4596",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Degerfors",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1880-V-001",
    Projektnamn: "Göksholm",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Örebro",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1880-V-002",
    Projektnamn: "Elmer 1 - vindkraftverk",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.21,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Örebro",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1880-V-003",
    Projektnamn: "Elmer 2",
    Verksamhetsutövare: "Örebrovind Nr 1 Kooperativ Ek För",
    Organisationsnummer: "769601-1571",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.23,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Örebro",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1880-V-004",
    Projektnamn: "Kvismaren 2",
    Verksamhetsutövare: "Kvismardalens Vind Ek för",
    Organisationsnummer: "769610-7247",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.4,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Örebro",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1880-V-005",
    Projektnamn: "Kvismaren 3",
    Verksamhetsutövare: "Kvismardalens Vind Ek för",
    Organisationsnummer: "769610-7247",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.55,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Örebro",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1880-V-006",
    Projektnamn: "Lundstorp Vind",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.04,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Örebro",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 14, 2019",
  },
  {
    Områdes_ID: "1880-V-007",
    Projektnamn: "Nybble Vindkraftverk",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.3,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Örebro",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1880-V-008",
    Projektnamn: "Odensbacken 1",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.4,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Örebro",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1880-V-009",
    Projektnamn: "Usta",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.02,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Örebro",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1880-V-010",
    Projektnamn: "Hovsta",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Örebro",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "Aug 05, 2019",
  },
  {
    Områdes_ID: "1880-V-011",
    Projektnamn: "Vindkraftverk Irvingsholms Säteri",
    Verksamhetsutövare: "Irvingsholms Säteri AB",
    Organisationsnummer: "556624-1799",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Örebro",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1880-V-012",
    Projektnamn: "Kvismaren 1",
    Verksamhetsutövare: "Kvismardalens Vind Ek för",
    Organisationsnummer: "769610-7247",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.43,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Örebro",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1880-V-013",
    Projektnamn: "Askers Via",
    Verksamhetsutövare: "Ak i Asker AB",
    Organisationsnummer: "556681-8307",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.4,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Örebro",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1880-V-014",
    Projektnamn: "MellsaVind 1",
    Verksamhetsutövare: "Mellsa Vind ekonomisk förening",
    Organisationsnummer: "769620-2063",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.58,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Örebro",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1880-V-015",
    Projektnamn: "Norra Runnaby",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Örebro",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1880-V-016",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Örebro",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1880-V-017",
    Projektnamn: "Vintrosa",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Örebro",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1880-V-018",
    Projektnamn: "Götarsvik",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Örebro",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1880-V-019",
    Projektnamn: "Malgräva",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Örebro",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1882-V-003",
    Projektnamn: "Alva af Hulta",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 2.2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Askersund",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1882-V-004",
    Projektnamn: "Almudden",
    Verksamhetsutövare: "AskersundsBostäder AB",
    Organisationsnummer: "556432-8119",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.4,
    Uppmätt_årsproduktion_GWh: 1.19,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Askersund",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1885-V-001",
    Projektnamn: "Sverkesta Vindkraft G1",
    Verksamhetsutövare: "HB Sverkesta Gård",
    Organisationsnummer: "969703-2135",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.45,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Lindesberg",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1885-V-002",
    Projektnamn: "Rockhammar",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.45,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Lindesberg",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 25, 2019",
  },
  {
    Områdes_ID: "1980-V-001",
    Projektnamn: "Hannevind 11",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.01,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Västerås",
    Län: "Västmanlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1980-V-002",
    Projektnamn: "Tillberga",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Västerås",
    Län: "Västmanlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 14, 2019",
  },
  {
    Områdes_ID: "1981-V-002",
    Projektnamn: "Folderfors Vindkraft",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.05,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sala",
    Län: "Västmanlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1983-V-001",
    Projektnamn: "Hjulsta",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Köping",
    Län: "Västmanlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1984-V-001",
    Projektnamn: "Lunger",
    Verksamhetsutövare: "Teroc AB",
    Organisationsnummer: "556205-1275",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Arboga",
    Län: "Västmanlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Aug 27, 2019",
  },
  {
    Områdes_ID: "0512-V-001",
    Projektnamn: "Sunds-Lövåsa",
    Verksamhetsutövare: "Smålands Miljöenergi AB",
    Organisationsnummer: "556784-5168",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ydre",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0560-V-001",
    Projektnamn: "Örbacken",
    Verksamhetsutövare: "Örbacken Energi Handelsbolag",
    Organisationsnummer: "969715-2594",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 23.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Boxholm",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0580-V-015",
    Projektnamn: "",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Linköping",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "May 08, 2019",
  },
  {
    Områdes_ID: "0580-V-017",
    Projektnamn: "",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Linköping",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0583-V-006",
    Projektnamn: "Götala 1",
    Verksamhetsutövare: "Styra Vind AB",
    Organisationsnummer: "556802-8210",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 21.2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Motala",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Jun 11, 2020",
  },
  {
    Områdes_ID: "1315-V-002",
    Projektnamn: "Bästhult",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hylte",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1315-V-003",
    Projektnamn: "Ekås",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hylte",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 26, 2020",
  },
  {
    Områdes_ID: "1380-V-004",
    Projektnamn: "Sennan",
    Verksamhetsutövare: "Triventus AB",
    Organisationsnummer: "556627-3016",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Halmstad",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1381-V-017",
    Projektnamn: "Sävbyholm",
    Verksamhetsutövare: "Öresundskraft AB",
    Organisationsnummer: "556089-7851",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Laholm",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1382-V-001",
    Projektnamn: "Alered",
    Verksamhetsutövare: "ewz Atlantic Sverige AB",
    Organisationsnummer: "556925-8113",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 36.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falkenberg",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "Oct 08, 2021",
  },
  {
    Områdes_ID: "1382-V-012",
    Projektnamn: "Ramsjö",
    Verksamhetsutövare: "Kanal Kraft AB",
    Organisationsnummer: "556734-9187",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Falkenberg",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "1383-V-002",
    Projektnamn: "Gummaråsen",
    Verksamhetsutövare: "Varberg Energi AB",
    Organisationsnummer: "556524-3010",
    Aktuella_verk: 5,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 30,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Varberg",
    Län: "Hallands län",
    Elområde: "Stockholm",
    Senast_sparad: "Jan 09, 2019",
  },
  {
    Områdes_ID: "1383-V-003",
    Projektnamn: "Lahall",
    Verksamhetsutövare: "Södra Cell AB",
    Organisationsnummer: "556072-7348",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 22.1,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Varberg",
    Län: "Hallands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1383-V-008",
    Projektnamn: "Södra Cell Värö",
    Verksamhetsutövare: "Södra Cell AB",
    Organisationsnummer: "556072-7348",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Varberg",
    Län: "Hallands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "1383-V-009",
    Projektnamn: "Södra Cell Värö",
    Verksamhetsutövare: "Södra Skogsägarna ek för",
    Organisationsnummer: "729500-3789",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Varberg",
    Län: "Hallands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "1383-V-012",
    Projektnamn: "Ulvatorp",
    Verksamhetsutövare: "Veddige Vindkraft AB",
    Organisationsnummer: "556753-6072",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Varberg",
    Län: "Hallands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1383-V-014",
    Projektnamn: "Västra Derome",
    Verksamhetsutövare: "Varberg Energi AB",
    Organisationsnummer: "556524-3010",
    Aktuella_verk: 6,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 36,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Varberg",
    Län: "Hallands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1419-V-009",
    Projektnamn: "Stenkyrka-Bö",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tjörn",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 18, 2020",
  },
  {
    Områdes_ID: "1421-V-001",
    Projektnamn: "Kyrkeröd",
    Verksamhetsutövare: "Rabbalshede Kraft AB",
    Organisationsnummer: "556681-4652",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 20.4,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Orust",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1421-V-002",
    Projektnamn: "Väst-Tången",
    Verksamhetsutövare: "SiBräcka vind",
    Organisationsnummer: "934001-0421",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Orust",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1435-V-012",
    Projektnamn: "Taranderöd, Lurs-Amdal",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tanum",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 25, 2019",
  },
  {
    Områdes_ID: "1435-V-028",
    Projektnamn: "Vindpark Sögårdsfjället",
    Verksamhetsutövare: "Rabbalshede Kraft AB",
    Organisationsnummer: "556681-4652",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 23.2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tanum",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1435-V-042",
    Projektnamn: "Mungseröd",
    Verksamhetsutövare: "Mungseröds Vindpark AB",
    Organisationsnummer: "556328-3026",
    Aktuella_verk: 6,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 15.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tanum",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Jan 13, 2021",
  },
  {
    Områdes_ID: "1435-V-043",
    Projektnamn: "Mungseröd/Gurseröd/Skaveröd",
    Verksamhetsutövare: "ewz Atlantic Sverige AB",
    Organisationsnummer: "556925-8113",
    Aktuella_verk: 8,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 40,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tanum",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 08, 2021",
  },
  {
    Områdes_ID: "1435-V-050",
    Projektnamn: "Skalleröd",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tanum",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 25, 2019",
  },
  {
    Områdes_ID: "1435-V-055",
    Projektnamn: "Hud",
    Verksamhetsutövare: "Rabbalshede Kraft AB",
    Organisationsnummer: "556681-4652",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 18,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tanum",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1439-V-001",
    Projektnamn: "Vindpark Korpekullen",
    Verksamhetsutövare: "Rabbalshede Kraft AB",
    Organisationsnummer: "556681-4652",
    Aktuella_verk: 7,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 35,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Färgelanda",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Mar 18, 2021",
  },
  {
    Områdes_ID: "1440-V-002",
    Projektnamn: "Gräfsnäs Vindpark",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ale",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1461-V-001",
    Projektnamn: "Vänervind",
    Verksamhetsutövare: "Vänervind AB",
    Organisationsnummer: "556669-6026",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 20.4,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mellerud",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 22, 2019",
  },
  {
    Områdes_ID: "1461-V-005",
    Projektnamn: "Västergården 1",
    Verksamhetsutövare: "Wallenstam Vindkraft Västergården AB",
    Organisationsnummer: "556739-4829",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 20.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mellerud",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 22, 2019",
  },
  {
    Områdes_ID: "1462-V-001",
    Projektnamn: "Kärrsbackens vindpark",
    Verksamhetsutövare: "RWE Renewables Sweden AB",
    Organisationsnummer: "556938-6864",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "LillaEdet",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 22, 2021",
  },
  {
    Områdes_ID: "1462-V-005",
    Projektnamn: "Prässebergens vindpark",
    Verksamhetsutövare: "RWE Renewables Sweden AB",
    Organisationsnummer: "556938-6864",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 15.18,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "LillaEdet",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 22, 2021",
  },
  {
    Områdes_ID: "1462-V-007",
    Projektnamn: "Prässebergens vindpark",
    Verksamhetsutövare: "Prässeberg Vind AB",
    Organisationsnummer: "556809-2323",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 16.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "LillaEdet",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1462-V-011",
    Projektnamn: "Sannersby",
    Verksamhetsutövare: "Firma Ramström Vind AB",
    Organisationsnummer: "556688-2683",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "LillaEdet",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "1463-V-013",
    Projektnamn: "Vindkraftspark Björketorp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mark",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1466-V-002",
    Projektnamn: "Trollebergsmossen",
    Verksamhetsutövare: "GiroVind Energi AB",
    Organisationsnummer: "556680-8167",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 28,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Herrljunga",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1482-V-002",
    Projektnamn: "Vindkraft Örevattenåsen",
    Verksamhetsutövare: "Triventus Wind Power AB",
    Organisationsnummer: "556863-1765",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kungälv",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1485-V-020",
    Projektnamn: "Simmersröd",
    Verksamhetsutövare: "Triventus Wind Power AB",
    Organisationsnummer: "556863-1765",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Uddevalla",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1485-V-026",
    Projektnamn: "Vindpark Kråkeröd",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Uddevalla",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 26, 2019",
  },
  {
    Områdes_ID: "1487-V-008",
    Projektnamn: "Frugården",
    Verksamhetsutövare: "Universal Vind",
    Organisationsnummer: "556571-8185",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vänersborg",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "1487-V-019",
    Projektnamn: "Skogaryd",
    Verksamhetsutövare: "Skogaryd Vindkraft AB",
    Organisationsnummer: "556773-9791",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vänersborg",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1491-V-001",
    Projektnamn: "Bondegärde",
    Verksamhetsutövare: "Bondegärde Vindpark AB",
    Organisationsnummer: "556879-4647",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 13.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ulricehamn",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1492-V-002",
    Projektnamn: "Fröskog vindkraftpark, alt A",
    Verksamhetsutövare: "Veddige Vindkraft AB",
    Organisationsnummer: "556753-6072",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Åmål",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Mar 01, 2019",
  },
  {
    Områdes_ID: "1497-V-018",
    Projektnamn: "Grevbäcks Munkebo",
    Verksamhetsutövare: "Klöverenergi AB",
    Organisationsnummer: "556698-2632",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hjo",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "1497-V-036",
    Projektnamn: "Karlsberg",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hjo",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1498-V-001",
    Projektnamn: "Anneberg",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 37.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tidaholm",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Mar 25, 2020",
  },
  {
    Områdes_ID: "1498-V-003",
    Projektnamn: "Dimbo, Dimboholm",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tidaholm",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Mar 19, 2021",
  },
  {
    Områdes_ID: "1498-V-009",
    Projektnamn: "Råmmarehemmet",
    Verksamhetsutövare: "EnBW Sverige Vind AB",
    Organisationsnummer: "559132-8884",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 45,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Sep 01, 2018",
    Planerat_drifttagande: "May 01, 2021",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tidaholm",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Aug 02, 2021",
  },
  {
    Områdes_ID: "1499-V-014",
    Projektnamn: "Källeberg",
    Verksamhetsutövare: "Källeberg Vind AB",
    Organisationsnummer: "556793-6512",
    Aktuella_verk: 5,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 27,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1499-V-032",
    Projektnamn: "Rosenskog Vindpark",
    Verksamhetsutövare: "Agrivind AB",
    Organisationsnummer: "556242-1387",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Falköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Nov 14, 2019",
  },
  {
    Områdes_ID: "1730-V-001",
    Projektnamn: "Ryttersfjäll",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Eda",
    Län: "Värmlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1781-V-005",
    Projektnamn: "Bäckhammar",
    Verksamhetsutövare: "Bäckhammar Vind AB",
    Organisationsnummer: "556956-6176",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 50.4,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "Aug 01, 2020",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kristinehamn",
    Län: "Värmlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 17, 2020",
  },
  {
    Områdes_ID: "1860-V-003",
    Projektnamn: "Ramsnäs",
    Verksamhetsutövare: "ewz Atlantic Sverige AB",
    Organisationsnummer: "556925-8113",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 20.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Laxå",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 08, 2021",
  },
  {
    Områdes_ID: "1495-V-020",
    Projektnamn: "HÄNDENE",
    Verksamhetsutövare: "Agrivind AB",
    Organisationsnummer: "556242-1387",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Skara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1497-V-019",
    Projektnamn: "Lilla Solberga",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hjo",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0319-V-001",
    Projektnamn: "Skutskär",
    Verksamhetsutövare: "Vindin AB",
    Organisationsnummer: "556713-5172",
    Aktuella_verk: 5,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 30,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Älvkarleby",
    Län: "Uppsala län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0381-V-004",
    Projektnamn: "Koby 4 verk",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Enköping",
    Län: "Uppsala län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0684-V-004",
    Projektnamn: "Stockaryd",
    Verksamhetsutövare: "ENO- Energy Sweden AB",
    Organisationsnummer: "556877-9598",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sävsjö",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0684-V-007",
    Projektnamn: "Hylletofta",
    Verksamhetsutövare: "Greenextreme AB",
    Organisationsnummer: "556722-4596",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Sävsjö",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 24, 2021",
  },
  {
    Områdes_ID: "0760-V-022",
    Projektnamn: "Vraneke",
    Verksamhetsutövare: "Kraftö AB",
    Organisationsnummer: "556708-7456",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Uppvidinge",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0767-V-001",
    Projektnamn: "Nåthult",
    Verksamhetsutövare: "Slitevind AB",
    Organisationsnummer: "556453-2819",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Markaryd",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 29, 2019",
  },
  {
    Områdes_ID: "0834-V-003",
    Projektnamn: "Holma",
    Verksamhetsutövare: "GiroVind Energi AB",
    Organisationsnummer: "556680-8167",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Torsås",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0834-V-005",
    Projektnamn: "Trolleboda",
    Verksamhetsutövare: "Vattenfall Vindkraft AB",
    Organisationsnummer: "556581-4273",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Torsås",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0840-V-003",
    Projektnamn: "Björnhovda",
    Verksamhetsutövare: "Vingkraft AB",
    Organisationsnummer: "556528-1945",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mörbylånga",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0840-V-008",
    Projektnamn: "Bårby",
    Verksamhetsutövare: "Vingkraft AB",
    Organisationsnummer: "556528-1945",
    Aktuella_verk: 5,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mörbylånga",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0840-V-019",
    Projektnamn: "Kalkstad",
    Verksamhetsutövare: "Vingkraft AB",
    Organisationsnummer: "556528-1945",
    Aktuella_verk: 5,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mörbylånga",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0840-V-022",
    Projektnamn: "Kristinelund",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 5,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mörbylånga",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0840-V-029",
    Projektnamn: "Sandby",
    Verksamhetsutövare: "Vingkraft AB",
    Organisationsnummer: "556528-1945",
    Aktuella_verk: 6,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mörbylånga",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0840-V-031",
    Projektnamn: "Stora Brunneby",
    Verksamhetsutövare: "Vingkraft AB",
    Organisationsnummer: "556528-1945",
    Aktuella_verk: 5,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mörbylånga",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0840-V-035",
    Projektnamn: "Torp Ullevi",
    Verksamhetsutövare: "JEA Lantbruks AB",
    Organisationsnummer: "556453-9020",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mörbylånga",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0840-V-036",
    Projektnamn: "Törnbotten I",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mörbylånga",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "0840-V-039",
    Projektnamn: "Ullevi",
    Verksamhetsutövare: "Vingkraft AB",
    Organisationsnummer: "556528-1945",
    Aktuella_verk: 7,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mörbylånga",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0840-V-044",
    Projektnamn: "Övertorp 12",
    Verksamhetsutövare: "Väktaren Vind AB",
    Organisationsnummer: "556628-7578",
    Aktuella_verk: 6,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mörbylånga",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0862-V-001",
    Projektnamn: "Brinkabo",
    Verksamhetsutövare: "Svenska Vindbolaget AB",
    Organisationsnummer: "556759-9013",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Emmaboda",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 09, 2019",
  },
  {
    Områdes_ID: "0882-V-002",
    Projektnamn: "Sörvik",
    Verksamhetsutövare: "Triventus Wind Power AB",
    Organisationsnummer: "556863-1765",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Oskarshamn",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Jan 23, 2019",
  },
  {
    Områdes_ID: "0883-V-008",
    Projektnamn: "Tindered",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Västervik",
    Län: "Kalmar län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "1885-V-004",
    Projektnamn: "Ramsberg Syd",
    Verksamhetsutövare: "Lindesbergsbostäder AB",
    Organisationsnummer: "556406-2718",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Lindesberg",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 06, 2019",
  },
  {
    Områdes_ID: "2021-V-002",
    Projektnamn: "Röbergsfjället",
    Verksamhetsutövare: "EnBW Sverige Vind AB",
    Organisationsnummer: "559132-8884",
    Aktuella_verk: 8,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 46.4,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vansbro",
    Län: "Dalarnas län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 07, 2021",
  },
  {
    Områdes_ID: "2080-V-002",
    Projektnamn: "Tavelberget",
    Verksamhetsutövare: "Dala Vind AB",
    Organisationsnummer: "556696-6940",
    Aktuella_verk: 5,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 32.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falun",
    Län: "Dalarnas län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2104-V-001",
    Projektnamn: "Kaptensberget",
    Verksamhetsutövare: "Vingkraft Hakarp AB",
    Organisationsnummer: "556796-9844",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 36,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "Oct 01, 2012",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Hofors",
    Län: "Gävleborgs län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2132-V-007",
    Projektnamn: "Vindpark Brännåsen",
    Verksamhetsutövare: "Samkraft Grännsjön AB",
    Organisationsnummer: "556884-4780",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 22,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Nordanstig",
    Län: "Gävleborgs län",
    Elområde: "Sundsvall",
    Senast_sparad: "Oct 07, 2021",
  },
  {
    Områdes_ID: "2182-V-006",
    Projektnamn: "Ljusne/Vallvik",
    Verksamhetsutövare: "Samkraft AB",
    Organisationsnummer: "556754-5644",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 27.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Söderhamn",
    Län: "Gävleborgs län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2184-V-002",
    Projektnamn: "Storåsen",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hudiksvall",
    Län: "Gävleborgs län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2184-V-005",
    Projektnamn: "Vindpark Håcksta",
    Verksamhetsutövare: "Wind Håcksta AB",
    Organisationsnummer: "556924-7108",
    Aktuella_verk: 5,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 25,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Hudiksvall",
    Län: "Gävleborgs län",
    Elområde: "Sundsvall",
    Senast_sparad: "Oct 07, 2021",
  },
  {
    Områdes_ID: "2280-V-002",
    Projektnamn: "Stormon",
    Verksamhetsutövare: "Stormon Energi AB",
    Organisationsnummer: "556881-9576",
    Aktuella_verk: 5,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 64.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Jun 01, 2015",
    Planerat_drifttagande: "Jan 01, 2016",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Härnösand",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2281-V-003",
    Projektnamn: "Holms-Västbyn",
    Verksamhetsutövare: "Svenska Vindbolaget AB",
    Organisationsnummer: "556759-9013",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 25.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sundsvall",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2281-V-004",
    Projektnamn: "SUNDSSKOGEN",
    Verksamhetsutövare: "NordanVind vindkraft AB",
    Organisationsnummer: "556574-0403",
    Aktuella_verk: 5,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Sundsvall",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2282-V-002",
    Projektnamn: "Rammeldalsberget",
    Verksamhetsutövare: "OWN Power Projects AB",
    Organisationsnummer: "556603-1869",
    Aktuella_verk: 6,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 39,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kramfors",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2284-V-001",
    Projektnamn: "Stigshöjden",
    Verksamhetsutövare: "OX2 AB",
    Organisationsnummer: "556675-7497",
    Aktuella_verk: 6,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 61.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "Sep 01, 2019",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Örnsköldsvik",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "Sep 18, 2019",
  },
  {
    Områdes_ID: "2305-V-002",
    Projektnamn: "Högklippen",
    Verksamhetsutövare: "RWE Renewables Sweden AB",
    Organisationsnummer: "556938-6864",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Bräcke",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Feb 22, 2021",
  },
  {
    Områdes_ID: "2305-V-003",
    Projektnamn: "Ingridsveberget",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Bräcke",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Aug 23, 2019",
  },
  {
    Områdes_ID: "2305-V-004",
    Projektnamn: "Bodbergsplatån",
    Verksamhetsutövare: "Svenska Vindbolaget AB",
    Organisationsnummer: "556759-9013",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Bräcke",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "2326-V-004",
    Projektnamn: "Digerberget 1.",
    Verksamhetsutövare: "Wallenstam Energi AB",
    Organisationsnummer: "556618-8552",
    Aktuella_verk: 5,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 27,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Berg",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2409-V-001",
    Projektnamn: "Granberget 1",
    Verksamhetsutövare: "Umeå Energi Sol Vind och Vatten AB",
    Organisationsnummer: "556649-3127",
    Aktuella_verk: 6,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 33,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Robertsfors",
    Län: "Västerbottens län",
    Elområde: "Luleå",
    Senast_sparad: "Jan 14, 2019",
  },
  {
    Områdes_ID: "2409-V-002",
    Projektnamn: "Granberget 2",
    Verksamhetsutövare: "Umeå Energi Sol Vind och Vatten AB",
    Organisationsnummer: "556649-3127",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 16.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Robertsfors",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "Feb 06, 2019",
  },
  {
    Områdes_ID: "2463-V-001",
    Projektnamn: "Storberget",
    Verksamhetsutövare: "Åsele Vindkraft Storberget AB",
    Organisationsnummer: "556808-7984",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 22,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Åsele",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "Dec 02, 2020",
  },
  {
    Områdes_ID: "2480-V-005",
    Projektnamn: "Hörnefors",
    Verksamhetsutövare: "Umeå Energi Sol Vind och Vatten AB",
    Organisationsnummer: "556649-3127",
    Aktuella_verk: 5,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 25,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Umeå",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0604-V-001",
    Projektnamn: "Assjö 1, Ella",
    Verksamhetsutövare: "Sverige Vindkraftkooperativ Ek för",
    Organisationsnummer: "769603-7089",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 9.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Aneby",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0680-V-002",
    Projektnamn: "Vivian",
    Verksamhetsutövare: "Jönköping Energi AB",
    Organisationsnummer: "556015-3354",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.65,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Jönköping",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0117-V-001",
    Projektnamn: "Mats Eriksson vindkraftverk 1",
    Verksamhetsutövare: "Nedergårdens förvaltning HB",
    Organisationsnummer: "969635-6121",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Österåker",
    Län: "Stockholms län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 15, 2019",
  },
  {
    Områdes_ID: "0117-V-002",
    Projektnamn: "Ljusterö Vind vindkraftverk 2",
    Verksamhetsutövare: "Kraftö AB",
    Organisationsnummer: "556708-7456",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 4.4,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Österåker",
    Län: "Stockholms län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0125-V-001",
    Projektnamn: "Ekerö Asknäs",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ekerö",
    Län: "Stockholms län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0125-V-002",
    Projektnamn: "Stockby",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ekerö",
    Län: "Stockholms län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0125-V-003",
    Projektnamn: "Eknäs",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ekerö",
    Län: "Stockholms län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0136-V-001",
    Projektnamn: "Utö",
    Verksamhetsutövare: "Lärbro SPW AB",
    Organisationsnummer: "556794-5935",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.49,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Haninge",
    Län: "Stockholms län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0140-V-001",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Nykvarn",
    Län: "Stockholms län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0181-V-001",
    Projektnamn: "",
    Verksamhetsutövare: "Telge Nät AB",
    Organisationsnummer: "556558-1757",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Södertälje",
    Län: "Stockholms län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0188-V-001",
    Projektnamn: "Björkö",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Norrtälje",
    Län: "Stockholms län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 25, 2019",
  },
  {
    Områdes_ID: "0188-V-003",
    Projektnamn: "Liesta",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Norrtälje",
    Län: "Stockholms län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 26, 2021",
  },
  {
    Områdes_ID: "0188-V-004",
    Projektnamn: "Svenska Högarna",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Norrtälje",
    Län: "Stockholms län",
    Elområde: "Stockholm",
    Senast_sparad: "Aug 27, 2020",
  },
  {
    Områdes_ID: "0305-V-001",
    Projektnamn: "Bista",
    Verksamhetsutövare: "Lindströms husvagnar AB",
    Organisationsnummer: "556239-5011",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Håbo",
    Län: "Uppsala län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0380-V-001",
    Projektnamn: "Högsta",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Uppsala",
    Län: "Uppsala län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0380-V-002",
    Projektnamn: "Högsta",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Uppsala",
    Län: "Uppsala län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0381-V-003",
    Projektnamn: "Hamptäppan",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Enköping",
    Län: "Uppsala län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0381-V-005",
    Projektnamn: "Litslena djurby",
    Verksamhetsutövare: "Tawind AB",
    Organisationsnummer: "556774-7497",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Enköping",
    Län: "Uppsala län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0381-V-007",
    Projektnamn: "Tawind AB",
    Verksamhetsutövare: "Tawind AB",
    Organisationsnummer: "556774-7497",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.7,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Enköping",
    Län: "Uppsala län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0381-V-008",
    Projektnamn: "Silvia af",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.34,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Enköping",
    Län: "Uppsala län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0381-V-009",
    Projektnamn: "Skarpängen",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Enköping",
    Län: "Uppsala län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0381-V-010",
    Projektnamn: "Sneby",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Enköping",
    Län: "Uppsala län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0381-V-011",
    Projektnamn: "Sundet 3 verk",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Enköping",
    Län: "Uppsala län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0381-V-012",
    Projektnamn: "Vårfrukyrka",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Enköping",
    Län: "Uppsala län",
    Elområde: "Stockholm",
    Senast_sparad: "Jun 10, 2021",
  },
  {
    Områdes_ID: "0381-V-014",
    Projektnamn: "Rickeby",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Enköping",
    Län: "Uppsala län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0382-V-002",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Östhammar",
    Län: "Uppsala län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0428-V-001",
    Projektnamn: "Århammar",
    Verksamhetsutövare: "Söderspånga Gård Magnus o Thomas Århammar",
    Organisationsnummer: "918500-6690",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vingåker",
    Län: "Södermanlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0483-V-001",
    Projektnamn: "Äsköping",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Katrineholm",
    Län: "Södermanlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0483-V-002",
    Projektnamn: "Dagsjön",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Katrineholm",
    Län: "Södermanlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Jan 07, 2019",
  },
  {
    Områdes_ID: "0483-V-003",
    Projektnamn: "",
    Verksamhetsutövare: "Gimmersta AB",
    Organisationsnummer: "556351-1079",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 4.7,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Katrineholm",
    Län: "Södermanlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0483-V-004",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Katrineholm",
    Län: "Södermanlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0484-V-005",
    Projektnamn: "Vidökna",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Eskilstuna",
    Län: "Södermanlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0682-V-001",
    Projektnamn: "Fredriksdal",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.3,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Nässjö",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0682-V-002",
    Projektnamn: "Älskebo",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.05,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Nässjö",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0684-V-002",
    Projektnamn: "Ärnanäs",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Sävsjö",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 06, 2019",
  },
  {
    Områdes_ID: "0684-V-003",
    Projektnamn: "Skog (Tritteboda)",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 7.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Sävsjö",
    Län: "Jönköpings län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0684-V-005",
    Projektnamn: "Bjärkaryd",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Sävsjö",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0684-V-006",
    Projektnamn: "Lövshult",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sävsjö",
    Län: "Jönköpings län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0760-V-009",
    Projektnamn: "Nottebäcks-Heda",
    Verksamhetsutövare: "Kraftö AB",
    Organisationsnummer: "556708-7456",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Uppvidinge",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0760-V-021",
    Projektnamn: "Vraneke",
    Verksamhetsutövare: "Kraftö AB",
    Organisationsnummer: "556708-7456",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Uppvidinge",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0760-V-025",
    Projektnamn: "Nottebäcks-Nöbbele",
    Verksamhetsutövare: "Kraftö AB",
    Organisationsnummer: "556708-7456",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Uppvidinge",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0760-V-026",
    Projektnamn: "Mörtelek",
    Verksamhetsutövare: "Billyvind AB",
    Organisationsnummer: "556705-6931",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Uppvidinge",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "0760-V-027",
    Projektnamn: "Nottebäcks-Heda",
    Verksamhetsutövare: "GiroVind Energi AB",
    Organisationsnummer: "556680-8167",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Uppvidinge",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "0760-V-028",
    Projektnamn: "Mörtelek",
    Verksamhetsutövare: "Billyvind AB",
    Organisationsnummer: "556705-6931",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Uppvidinge",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 12, 2019",
  },
  {
    Områdes_ID: "0760-V-031",
    Projektnamn: "Hageskruv",
    Verksamhetsutövare: "Vej Vind AB",
    Organisationsnummer: "556713-8531",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Uppvidinge",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0760-V-033",
    Projektnamn: "Mörtelek",
    Verksamhetsutövare: "Billyvind AB",
    Organisationsnummer: "556705-6931",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Uppvidinge",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "0763-V-002",
    Projektnamn: "Billy Vind AB",
    Verksamhetsutövare: "Billyvind AB",
    Organisationsnummer: "556705-6931",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tingsryd",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 30, 2019",
  },
  {
    Områdes_ID: "0763-V-003",
    Projektnamn: "Billy Vind AB",
    Verksamhetsutövare: "Billyvind AB",
    Organisationsnummer: "556705-6931",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tingsryd",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 30, 2019",
  },
  {
    Områdes_ID: "0763-V-004",
    Projektnamn: "Linder Färmell",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tingsryd",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "May 02, 2019",
  },
  {
    Områdes_ID: "0764-V-002",
    Projektnamn: "Elensås",
    Verksamhetsutövare: "Billyvind AB",
    Organisationsnummer: "556705-6931",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Alvesta",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "0780-V-001",
    Projektnamn: "Karryd",
    Verksamhetsutövare: "Karrydvind e.k. förening",
    Organisationsnummer: "769618-6597",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Växjö",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0780-V-006",
    Projektnamn: "Målajord",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.03,
    Uppmätt_årsproduktion_GWh: 0.87,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Växjö",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 08, 2021",
  },
  {
    Områdes_ID: "0780-V-007",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Växjö",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0840-V-004",
    Projektnamn: "Björnhovda II",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mörbylånga",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0840-V-012",
    Projektnamn: "Gettlinge",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mörbylånga",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0840-V-013",
    Projektnamn: "Grönhögen",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mörbylånga",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0840-V-016",
    Projektnamn: "Gårdby",
    Verksamhetsutövare: "JEA Lantbruks AB",
    Organisationsnummer: "556453-9020",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mörbylånga",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0840-V-017",
    Projektnamn: "Holmetorp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mörbylånga",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "0840-V-023",
    Projektnamn: "Lindby",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mörbylånga",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0840-V-024",
    Projektnamn: "Långrälla",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mörbylånga",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "0840-V-027",
    Projektnamn: "Parboäng Gård",
    Verksamhetsutövare: "AB Parboäng Gård",
    Organisationsnummer: "556296-5045",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mörbylånga",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0840-V-033",
    Projektnamn: "Svibo",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mörbylånga",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0840-V-043",
    Projektnamn: "Västergården",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mörbylånga",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "0840-V-045",
    Projektnamn: "Degerhamn piren II",
    Verksamhetsutövare: "Kalmarsund Vind Ek. För.",
    Organisationsnummer: "769613-9661",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 2,
    Uppmätt_årsproduktion_GWh: 2.07,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mörbylånga",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0840-V-048",
    Projektnamn: "Degerhamn_4_1",
    Verksamhetsutövare: "OX2 AB",
    Organisationsnummer: "556675-7497",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mörbylånga",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "0840-V-056",
    Projektnamn: "Rogers",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mörbylånga",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0840-V-098",
    Projektnamn: "Gettlinge",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mörbylånga",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "0840-V-099",
    Projektnamn: "Gettlinge",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mörbylånga",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "0861-V-050",
    Projektnamn: "Södra Bäckebo",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.08,
    Uppmätt_årsproduktion_GWh: 0.06,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mönsterås",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Jan 07, 2019",
  },
  {
    Områdes_ID: "0880-V-007",
    Projektnamn: "Mortorp",
    Verksamhetsutövare: "Firma Ramström Vind AB",
    Organisationsnummer: "556688-2683",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kalmar",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0880-V-008",
    Projektnamn: "Mortorp Törsboda",
    Verksamhetsutövare: "Triventus AB",
    Organisationsnummer: "556627-3016",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kalmar",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0880-V-009",
    Projektnamn: "Hagby 1:2",
    Verksamhetsutövare: "GiroVind Energi AB",
    Organisationsnummer: "556680-8167",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 4,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kalmar",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0880-V-011",
    Projektnamn: "Ryssbylund, Nöbble och Stojby",
    Verksamhetsutövare: "Svenska Vindbolaget AB",
    Organisationsnummer: "556759-9013",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kalmar",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0880-V-012",
    Projektnamn: "Kulltorp",
    Verksamhetsutövare: "Firma Ramström Vind AB",
    Organisationsnummer: "556688-2683",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kalmar",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "0880-V-013",
    Projektnamn: "Vassmolösa",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kalmar",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "0880-V-014",
    Projektnamn: "Bottorp",
    Verksamhetsutövare: "RM Wind AB",
    Organisationsnummer: "556806-4462",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kalmar",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Dec 01, 2021",
  },
  {
    Områdes_ID: "0880-V-015",
    Projektnamn: "Ålem Gunnarsbo",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kalmar",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0880-V-017",
    Projektnamn: "Elverslösa",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kalmar",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0880-V-018",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kalmar",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0880-V-019",
    Projektnamn: "Vassmolösa Kulltorp",
    Verksamhetsutövare: "Svenska Vindbolaget AB",
    Organisationsnummer: "556759-9013",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kalmar",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "0880-V-020",
    Projektnamn: "Kulltorp Mortorp",
    Verksamhetsutövare: "Firma Ramström Vind AB",
    Organisationsnummer: "556688-2683",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kalmar",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "0885 -V-00",
    Projektnamn: "Stenninge_3_48",
    Verksamhetsutövare: "Helianthemum Vind AB",
    Organisationsnummer: "556650-9799",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.9,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Borgholm",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1080-V-004",
    Projektnamn: "Freja Olsäng",
    Verksamhetsutövare: "Blekinge Vindkraft Ekonomisk förening",
    Organisationsnummer: "769606-5171",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Karlskrona",
    Län: "Blekinge län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1080-V-005",
    Projektnamn: "Gudingen (Sturkö 2)",
    Verksamhetsutövare: "Ekovind AB",
    Organisationsnummer: "556343-8208",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.8,
    Uppmätt_årsproduktion_GWh: 1.94,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Karlskrona",
    Län: "Blekinge län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1080-V-006",
    Projektnamn: "Ådan (Sturkö 1)",
    Verksamhetsutövare: "Ekovind AB",
    Organisationsnummer: "556343-8208",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.8,
    Uppmätt_årsproduktion_GWh: 1.78,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Karlskrona",
    Län: "Blekinge län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1080-V-007",
    Projektnamn: "Harstorp 1",
    Verksamhetsutövare: "Firma Ramström Vind AB",
    Organisationsnummer: "556688-2683",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.98,
    Uppmätt_årsproduktion_GWh: 1.34,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Karlskrona",
    Län: "Blekinge län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1080-V-008",
    Projektnamn: "Hermansmåla",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Karlskrona",
    Län: "Blekinge län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1081-V-003",
    Projektnamn: "Svalemåla",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ronneby",
    Län: "Blekinge län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 05, 2019",
  },
  {
    Områdes_ID: "1083-V-001",
    Projektnamn: "Lörby 3",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.27,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sölvesborg",
    Län: "Blekinge län",
    Elområde: "Malmö",
    Senast_sparad: "Mar 11, 2020",
  },
  {
    Områdes_ID: "1083-V-002",
    Projektnamn: "Lörby 1 Toke",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.27,
    Uppmätt_årsproduktion_GWh: 0.97,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sölvesborg",
    Län: "Blekinge län",
    Elområde: "Malmö",
    Senast_sparad: "Mar 11, 2020",
  },
  {
    Områdes_ID: "1083-V-003",
    Projektnamn: "Lörby 6 Lörby/Ysane",
    Verksamhetsutövare: "Ekovind AB",
    Organisationsnummer: "556343-8208",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.75,
    Uppmätt_årsproduktion_GWh: 1.71,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sölvesborg",
    Län: "Blekinge län",
    Elområde: "Malmö",
    Senast_sparad: "Mar 11, 2020",
  },
  {
    Områdes_ID: "1083-V-004",
    Projektnamn: "Lörby 5 Lörby/Ysane",
    Verksamhetsutövare: "Ekovind AB",
    Organisationsnummer: "556343-8208",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.75,
    Uppmätt_årsproduktion_GWh: 1.7,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sölvesborg",
    Län: "Blekinge län",
    Elområde: "Malmö",
    Senast_sparad: "Mar 11, 2020",
  },
  {
    Områdes_ID: "1083-V-005",
    Projektnamn: "Lörby 4 Lörby/Ysane",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.75,
    Uppmätt_årsproduktion_GWh: 1.69,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sölvesborg",
    Län: "Blekinge län",
    Elområde: "Malmö",
    Senast_sparad: "Mar 11, 2020",
  },
  {
    Områdes_ID: "1083-V-006",
    Projektnamn: "Lörby 2 Krok",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.27,
    Uppmätt_årsproduktion_GWh: 1,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sölvesborg",
    Län: "Blekinge län",
    Elområde: "Malmö",
    Senast_sparad: "Mar 11, 2020",
  },
  {
    Områdes_ID: "1083-V-007",
    Projektnamn: "Hörby",
    Verksamhetsutövare: "Hafra Lyckorna Vind AB",
    Organisationsnummer: "556703-2593",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.5,
    Uppmätt_årsproduktion_GWh: 1.36,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sölvesborg",
    Län: "Blekinge län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1083-V-008",
    Projektnamn: "Hörby",
    Verksamhetsutövare: "Hafra Lyckorna Vind AB",
    Organisationsnummer: "556703-2593",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.5,
    Uppmätt_årsproduktion_GWh: 1.28,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sölvesborg",
    Län: "Blekinge län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1083-V-009",
    Projektnamn: "Hörby",
    Verksamhetsutövare: "Hafra Lyckorna Vind AB",
    Organisationsnummer: "556703-2593",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.5,
    Uppmätt_årsproduktion_GWh: 1.12,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sölvesborg",
    Län: "Blekinge län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1083-V-010",
    Projektnamn: "Hörby",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sölvesborg",
    Län: "Blekinge län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1083-V-011",
    Projektnamn: "Hörby",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sölvesborg",
    Län: "Blekinge län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1083-V-012",
    Projektnamn: "Hörby",
    Verksamhetsutövare: "Hanöbukten Offshore AB",
    Organisationsnummer: "556701-3643",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sölvesborg",
    Län: "Blekinge län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1315-V-012",
    Projektnamn: "Enslöv",
    Verksamhetsutövare: "Firma Ramström Vind AB",
    Organisationsnummer: "556688-2683",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Halmstad",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1315-V-014",
    Projektnamn: "Vindkraftverk Skavböke",
    Verksamhetsutövare: "Firma Ramström Vind AB",
    Organisationsnummer: "556688-2683",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Halmstad",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1864-V-001",
    Projektnamn: "Lerviken",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ljusnarsberg",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1885-V-006",
    Projektnamn: "Oppboga",
    Verksamhetsutövare: "Oppboga Säteri",
    Organisationsnummer: "556389-2669",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Lindesberg",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 05, 2019",
  },
  {
    Områdes_ID: "1885-V-003",
    Projektnamn: "Öby gård",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Lindesberg",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 05, 2019",
  },
  {
    Områdes_ID: "2021-V-009",
    Projektnamn: "Äppelbo",
    Verksamhetsutövare: "Äppelbovind Ekonomiska förening",
    Organisationsnummer: "769606-1485",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.56,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vansbro",
    Län: "Dalarnas län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2021-V-019",
    Projektnamn: "Äppelbo",
    Verksamhetsutövare: "Vindela ek för",
    Organisationsnummer: "769611-2411",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vansbro",
    Län: "Dalarnas län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2021-V-020",
    Projektnamn: "Äppelbo",
    Verksamhetsutövare: "Vindela ek för",
    Organisationsnummer: "769611-2411",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vansbro",
    Län: "Dalarnas län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2061-V-001",
    Projektnamn: "Uvberget Hanna",
    Verksamhetsutövare: "Dala Vindkraft Ekonomisk Förening",
    Organisationsnummer: "769613-8911",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Smedjebacken",
    Län: "Dalarnas län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2061-V-002",
    Projektnamn: "Uvberget Boel",
    Verksamhetsutövare: "Bärkehus AB",
    Organisationsnummer: "556527-4023",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.4,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Smedjebacken",
    Län: "Dalarnas län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2084-V-001",
    Projektnamn: "Näs",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Avesta",
    Län: "Dalarnas län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2085-V-002",
    Projektnamn: "Paljakoberget",
    Verksamhetsutövare: "Dala Vind AB",
    Organisationsnummer: "556696-6940",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 2.7,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ludvika",
    Län: "Dalarnas län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2180-V-005",
    Projektnamn: "",
    Verksamhetsutövare: "Gävle Kraftvärme AB",
    Organisationsnummer: "556527-3512",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.24,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gävle",
    Län: "Gävleborgs län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2182-V-004",
    Projektnamn: "Stråtjära",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Söderhamn",
    Län: "Gävleborgs län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2280-V-003",
    Projektnamn: "Kläfsön",
    Verksamhetsutövare: "Hedagården Vindkraft 4 AB",
    Organisationsnummer: "556716-6912",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.3,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Härnösand",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "Feb 14, 2019",
  },
  {
    Områdes_ID: "2280-V-004",
    Projektnamn: "Rödmyrberget",
    Verksamhetsutövare: "SR Energy AB",
    Organisationsnummer: "556711-9549",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 7,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Härnösand",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2280-V-006",
    Projektnamn: "Dalom",
    Verksamhetsutövare: "Härnövind ek för",
    Organisationsnummer: "769600-2570",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.85,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Härnösand",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2280-V-007",
    Projektnamn: "Vårdkasberget",
    Verksamhetsutövare: "Härnösand Energi och Miljö AB",
    Organisationsnummer: "556526-3745",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.1,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Härnösand",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2281-V-002",
    Projektnamn: "Verkstaden 4",
    Verksamhetsutövare: "Sundsvall Elnät AB",
    Organisationsnummer: "556502-7223",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Sundsvall",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "Sep 06, 2019",
  },
  {
    Områdes_ID: "2281-V-005",
    Projektnamn: "Björkön",
    Verksamhetsutövare: "Trärike Vindkraft ek för",
    Organisationsnummer: "769601-6331",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.19,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sundsvall",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2282-V-003",
    Projektnamn: "Hornöberget",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kramfors",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2284-V-010",
    Projektnamn: "Nyland",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Örnsköldsvik",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "Apr 15, 2019",
  },
  {
    Områdes_ID: "2284-V-011",
    Projektnamn: "Skagsudde Linda",
    Verksamhetsutövare: "Nolavind Kooperativa ek för",
    Organisationsnummer: "769600-9633",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.4,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Örnsköldsvik",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2284-V-012",
    Projektnamn: "Norrvåge",
    Verksamhetsutövare: "Gullvind AB",
    Organisationsnummer: "556600-7539",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 2.16,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Örnsköldsvik",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2284-V-013",
    Projektnamn: "Norrvåge",
    Verksamhetsutövare: "Gullvind AB",
    Organisationsnummer: "556600-7539",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 2.16,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Örnsköldsvik",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2284-V-014",
    Projektnamn: "Västerkäl",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Örnsköldsvik",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2309-V-002",
    Projektnamn: "Almåsa",
    Verksamhetsutövare: "Jämtkraft AB",
    Organisationsnummer: "556001-6064",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 2.16,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Krokom",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2309-V-003",
    Projektnamn: "669828001 Vallrun",
    Verksamhetsutövare: "Stefan Widen AB",
    Organisationsnummer: "556651-9715",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 2,
    Uppmätt_årsproduktion_GWh: 1.49,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Krokom",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2313-V-001",
    Projektnamn: "Hällingarna Vind ek för",
    Verksamhetsutövare: "Hällingarna Vind Ek. Förening",
    Organisationsnummer: "769612-8318",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Strömsund",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2313-V-002",
    Projektnamn: "Harrsjön",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 2.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Strömsund",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Jan 09, 2019",
  },
  {
    Områdes_ID: "2321-V-001",
    Projektnamn: "Bratteggen 1",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Åre",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2321-V-002",
    Projektnamn: "Bratteggen 2",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Åre",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2321-V-003",
    Projektnamn: "Gråsjön Anja",
    Verksamhetsutövare: "Kallbygdens ekonomiska förening",
    Organisationsnummer: "769602-6439",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Åre",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2321-V-004",
    Projektnamn: "Överhallen",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Åre",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2321-V-005",
    Projektnamn: "Hallen",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Åre",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2321-V-006",
    Projektnamn: "Bydalen",
    Verksamhetsutövare: "Beinlausn i Bydalen AB",
    Organisationsnummer: "556649-0222",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 2.1,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Åre",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2326-V-001",
    Projektnamn: "Kommerberget",
    Verksamhetsutövare: "Sverige Vindkraftkooperativ Ek för",
    Organisationsnummer: "769603-7089",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5.46,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Berg",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Feb 25, 2019",
  },
  {
    Områdes_ID: "2326-V-002",
    Projektnamn: "Björnskallen, Kajsa",
    Verksamhetsutövare: "OX2 AB",
    Organisationsnummer: "556675-7497",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.9,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Berg",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2326-V-008",
    Projektnamn: "Karlsbäcks Vindkraftverk",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.02,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Bjurholm",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2361-V-002",
    Projektnamn: "Östest-Kröket",
    Verksamhetsutövare: "Tännäs vind AB",
    Organisationsnummer: "556674-6722",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.9,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Härjedalen",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2361-V-009",
    Projektnamn: "Digerberget",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 3.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Härjedalen",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2361-V-017",
    Projektnamn: "Rändingsvallen",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Härjedalen",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Feb 14, 2019",
  },
  {
    Områdes_ID: "2401-V-001",
    Projektnamn: "Järnäsklubb vindkraftstation",
    Verksamhetsutövare: "FRIREK International AB",
    Organisationsnummer: "556404-5325",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.41,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Nordmaling",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2421-V-001",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Storuman",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2421-V-002",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Storuman",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2421-V-003",
    Projektnamn: "Skarvsjöby",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Storuman",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2462-V-001",
    Projektnamn: "Risträsk",
    Verksamhetsutövare: "Hemberget Energi AB",
    Organisationsnummer: "556757-9346",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vilhelmina",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "Feb 25, 2019",
  },
  {
    Områdes_ID: "2462-V-002",
    Projektnamn: "Latikberg",
    Verksamhetsutövare: "Svevind  AB",
    Organisationsnummer: "556743-1175",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 2.2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "Nov 26, 2010",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vilhelmina",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2462-V-003",
    Projektnamn: "Klimpfjäll",
    Verksamhetsutövare: "Vindskontoret Klimpfjäll AB",
    Organisationsnummer: "556842-4914",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.8,
    Uppmätt_årsproduktion_GWh: 0.71,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vilhelmina",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "Sep 16, 2020",
  },
  {
    Områdes_ID: "2462-V-004",
    Projektnamn: "Klimpfjäll",
    Verksamhetsutövare: "Vindskontoret Klimpfjäll AB",
    Organisationsnummer: "556842-4914",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.8,
    Uppmätt_årsproduktion_GWh: 0.89,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vilhelmina",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "Sep 16, 2020",
  },
  {
    Områdes_ID: "2462-V-005",
    Projektnamn: "Klimpfjäll",
    Verksamhetsutövare: "Vindskontoret Klimpfjäll AB",
    Organisationsnummer: "556842-4914",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.8,
    Uppmätt_årsproduktion_GWh: 0.89,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vilhelmina",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "Sep 16, 2020",
  },
  {
    Områdes_ID: "2480-V-001",
    Projektnamn: "Hörnefors",
    Verksamhetsutövare: "Umeå Energi AB",
    Organisationsnummer: "556097-8602",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Umeå",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "Apr 08, 2019",
  },
  {
    Områdes_ID: "2480-V-007",
    Projektnamn: "Hörnefors",
    Verksamhetsutövare: "Kvarkenvinden 1 Kooperativ ek för",
    Organisationsnummer: "769602-8096",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Umeå",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2480-V-009",
    Projektnamn: "Holmsund",
    Verksamhetsutövare: "Kvarkenvinden 1 Kooperativ ek för",
    Organisationsnummer: "769602-8096",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 6,
    Uppmätt_årsproduktion_GWh: 6.77,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Umeå",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2482-V-001",
    Projektnamn: "Klutmark",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Skellefteå",
    Län: "Västerbottens län",
    Elområde: "Luleå",
    Senast_sparad: "Feb 19, 2020",
  },
  {
    Områdes_ID: "2482-V-004",
    Projektnamn: "Bureå",
    Verksamhetsutövare: "SMIAB i Norr AB",
    Organisationsnummer: "556343-0007",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Skellefteå",
    Län: "Västerbottens län",
    Elområde: "Luleå",
    Senast_sparad: "Feb 18, 2020",
  },
  {
    Områdes_ID: "2482-V-006",
    Projektnamn: "Bure 1",
    Verksamhetsutövare: "Greenwind AB",
    Organisationsnummer: "556635-1069",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Skellefteå",
    Län: "Västerbottens län",
    Elområde: "Luleå",
    Senast_sparad: "Aug 28, 2020",
  },
  {
    Områdes_ID: "2482-V-007",
    Projektnamn: "Bure 2",
    Verksamhetsutövare: "Greenwind AB",
    Organisationsnummer: "556635-1069",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Skellefteå",
    Län: "Västerbottens län",
    Elområde: "Luleå",
    Senast_sparad: "Aug 28, 2020",
  },
  {
    Områdes_ID: "2482-V-008",
    Projektnamn: "Bure 3",
    Verksamhetsutövare: "Greenwind AB",
    Organisationsnummer: "556635-1069",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Skellefteå",
    Län: "Västerbottens län",
    Elområde: "Luleå",
    Senast_sparad: "Aug 28, 2020",
  },
  {
    Områdes_ID: "2482-V-009",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Skellefteå",
    Län: "Västerbottens län",
    Elområde: "Luleå",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2482-V-010",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Skellefteå",
    Län: "Västerbottens län",
    Elområde: "Luleå",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2482-V-011",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Skellefteå",
    Län: "Västerbottens län",
    Elområde: "Luleå",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2510-V-001",
    Projektnamn: "Vindmannen",
    Verksamhetsutövare: "Suorvavind AB",
    Organisationsnummer: "556696-4564",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1,
    Uppmätt_årsproduktion_GWh: 0.56,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Jokkmokk",
    Län: "Norrbottens län",
    Elområde: "Luleå",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2514-V-003",
    Projektnamn: "Siksundsön",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kalix",
    Län: "Norrbottens län",
    Elområde: "Luleå",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2514-V-004",
    Projektnamn: "Rian",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kalix",
    Län: "Norrbottens län",
    Elområde: "Luleå",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0582-V-002",
    Projektnamn: "Häljelöt",
    Verksamhetsutövare: "Söderköping Vind AB",
    Organisationsnummer: "556859-7875",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Söderköping",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1381-V-009",
    Projektnamn: "Grytsjön",
    Verksamhetsutövare: "Grytsjö Energi AB",
    Organisationsnummer: "556878-3020",
    Aktuella_verk: 12,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 69.96,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "May 31, 2013",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Laholm",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1430-V-019",
    Projektnamn: "Suttene",
    Verksamhetsutövare: "Fortum Generation AB",
    Organisationsnummer: "556006-8230",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Munkedal",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 13, 2019",
  },
  {
    Områdes_ID: "1435-V-062",
    Projektnamn: "Tormoseröd Vindpark",
    Verksamhetsutövare: "Tormoseröd Vindpark AB",
    Organisationsnummer: "556790-5574",
    Aktuella_verk: 13,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 70,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tanum",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 21, 2021",
  },
  {
    Områdes_ID: "1435-V-063",
    Projektnamn: "Tormoserödsfjället",
    Verksamhetsutövare: "Conceptor Vindkraft AB",
    Organisationsnummer: "556751-9706",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tanum",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Jan 15, 2019",
  },
  {
    Områdes_ID: "1446-V-002",
    Projektnamn: "Lunnekullen",
    Verksamhetsutövare: "Lunnekullen Vindkraft AB",
    Organisationsnummer: "556705-3045",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 207.9,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Karlsborg",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1461-V-024",
    Projektnamn: "Vindkraft Granan",
    Verksamhetsutövare: "SR Energy AB",
    Organisationsnummer: "556711-9549",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mellerud",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Jan 18, 2022",
  },
  {
    Områdes_ID: "1462-V-017",
    Projektnamn: "Projekt Stenhult",
    Verksamhetsutövare: "Rabbalshede Kraft AB",
    Organisationsnummer: "556681-4652",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 45.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "LillaEdet",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1486-V-007",
    Projektnamn: "Tormoserödsfjället",
    Verksamhetsutövare: "Conceptor Vindkraft AB",
    Organisationsnummer: "556751-9706",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Strömstad",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Jan 15, 2019",
  },
  {
    Områdes_ID: "1487-V-011",
    Projektnamn: "Vindkraft Granan",
    Verksamhetsutövare: "SR Energy AB",
    Organisationsnummer: "556711-9549",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vänersborg",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Jan 18, 2022",
  },
  {
    Områdes_ID: "1487-V-022",
    Projektnamn: "Skogaryd",
    Verksamhetsutövare: "Skogaryd Vindkraft AB",
    Organisationsnummer: "556773-9791",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vänersborg",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1489-V-005",
    Projektnamn: "Vindkraftpark Gräfsnäs och Livered m.fl.",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Alingsås",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1489-V-006",
    Projektnamn: "Gräfsnäs Vindpark",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Alingsås",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1492-V-011",
    Projektnamn: "Vindpark Sjövik/Önaholm",
    Verksamhetsutövare: "Gothia Vind AB",
    Organisationsnummer: "556714-2095",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Åmål",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1498-V-007",
    Projektnamn: "Kymbo",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tidaholm",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Mar 19, 2021",
  },
  {
    Områdes_ID: "1780-V-001",
    Projektnamn: "Sten-Kalles grund",
    Verksamhetsutövare: "ReWind Vänern AB",
    Organisationsnummer: "556836-5265",
    Aktuella_verk: 20,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 200,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Karlstad",
    Län: "Värmlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Mar 25, 2021",
  },
  {
    Områdes_ID: "1785-V-014",
    Projektnamn: "Vindpark Sjövik/Önaholm",
    Verksamhetsutövare: "Gothia Vind AB",
    Organisationsnummer: "556714-2095",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Säffle",
    Län: "Värmlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0512-V-005",
    Projektnamn: "Grevekulla",
    Verksamhetsutövare: "European Energy",
    Organisationsnummer: "556762-0041",
    Aktuella_verk: 6,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Aug 01, 2021",
    Planerat_drifttagande: "Jan 15, 2023",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ydre",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Dec 01, 2021",
  },
  {
    Områdes_ID: "0581-V-010",
    Projektnamn: "Östkinds Häradsallmänning",
    Verksamhetsutövare: "Firma Ramström Vind AB",
    Organisationsnummer: "556688-2683",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Norrköping",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 10, 2021",
  },
  {
    Områdes_ID: "0582-V-001",
    Projektnamn: "Torönsborg",
    Verksamhetsutövare: "Arise Windpower AB",
    Organisationsnummer: "556274-6726",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Söderköping",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0583-V-015",
    Projektnamn: "Björka",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 10.2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Motala",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Jan 11, 2019",
  },
  {
    Områdes_ID: "1315-V-005",
    Projektnamn: "Jonsbo",
    Verksamhetsutövare: "RWE Renewables Sweden AB",
    Organisationsnummer: "556938-6864",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hylte",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 22, 2021",
  },
  {
    Områdes_ID: "1315-V-007",
    Projektnamn: "Ryssbol",
    Verksamhetsutövare: "Arise Windpower AB",
    Organisationsnummer: "556274-6726",
    Aktuella_verk: 6,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 39.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Hylte",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1380-V-003",
    Projektnamn: "Fröllinge",
    Verksamhetsutövare: "OX2 AB",
    Organisationsnummer: "556675-7497",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Halmstad",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 17, 2019",
  },
  {
    Områdes_ID: "1380-V-008",
    Projektnamn: "Uppnora",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Halmstad",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 09, 2019",
  },
  {
    Områdes_ID: "1381-V-005",
    Projektnamn: "Knäred",
    Verksamhetsutövare: "RWE Renewables Sweden AB",
    Organisationsnummer: "556938-6864",
    Aktuella_verk: 10,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 47,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Laholm",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 22, 2021",
  },
  {
    Områdes_ID: "1381-V-007",
    Projektnamn: "Oxhult",
    Verksamhetsutövare: "Arise Windfarm 1 AB",
    Organisationsnummer: "556732-8942",
    Aktuella_verk: 12,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 56.76,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Laholm",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1381-V-008",
    Projektnamn: "Kåphult Vindkraftpark",
    Verksamhetsutövare: "Arise Windfarm 1 AB",
    Organisationsnummer: "556732-8942",
    Aktuella_verk: 7,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 40.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Laholm",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1381-V-013",
    Projektnamn: "Skogaby",
    Verksamhetsutövare: "Arise Windpower AB",
    Organisationsnummer: "556274-6726",
    Aktuella_verk: 7,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 24.2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Laholm",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1381-V-018",
    Projektnamn: "Tommared",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 6,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 36,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Laholm",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1381-V-019",
    Projektnamn: "Uddared",
    Verksamhetsutövare: "Uddared Energi Aktiebolag",
    Organisationsnummer: "556517-5709",
    Aktuella_verk: 9,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 54.99,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Laholm",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1382-V-002",
    Projektnamn: "Askomebjär",
    Verksamhetsutövare: "Askome Vind AB",
    Organisationsnummer: "556881-8859",
    Aktuella_verk: 10,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 67.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falkenberg",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "Oct 05, 2021",
  },
  {
    Områdes_ID: "1382-V-004",
    Projektnamn: "Björnåsen",
    Verksamhetsutövare: "European Energy",
    Organisationsnummer: "556762-0041",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Falkenberg",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "Jan 28, 2020",
  },
  {
    Områdes_ID: "1382-V-005",
    Projektnamn: "Hjuleberg Vindkraftpark",
    Verksamhetsutövare: "Vattenfall Vindkraft AB",
    Organisationsnummer: "556581-4273",
    Aktuella_verk: 12,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 98.04,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falkenberg",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1382-V-011",
    Projektnamn: "Okome",
    Verksamhetsutövare: "Vindbruk Halland AB",
    Organisationsnummer: "556761-6981",
    Aktuella_verk: 16,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Falkenberg",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1382-V-017",
    Projektnamn: "Äskåsen",
    Verksamhetsutövare: "RWE Renewables Sweden AB",
    Organisationsnummer: "556938-6864",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Falkenberg",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 22, 2021",
  },
  {
    Områdes_ID: "1383-V-007",
    Projektnamn: "Sällstorp",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Varberg",
    Län: "Hallands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "1384-V-002",
    Projektnamn: "Iglasjön",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 9,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 74.08,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kungsbacka",
    Län: "Hallands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1415-V-002",
    Projektnamn: "Garete-Rannekärr",
    Verksamhetsutövare: "Rabbalshede Kraft AB",
    Organisationsnummer: "556681-4652",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 21,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Stenungsund",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1421-V-003",
    Projektnamn: "Varekil",
    Verksamhetsutövare: "Rabbalshede Kraft AB",
    Organisationsnummer: "556681-4652",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Orust",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1430-V-009",
    Projektnamn: "Brattön",
    Verksamhetsutövare: "Brattön Vind AB",
    Organisationsnummer: "556753-8870",
    Aktuella_verk: 6,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 34.02,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Munkedal",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1430-V-015",
    Projektnamn: "Svarteborgs-Skogen",
    Verksamhetsutövare: "Dingleskogen Vind AB",
    Organisationsnummer: "556840-0864",
    Aktuella_verk: 6,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 30,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Munkedal",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "May 27, 2020",
  },
  {
    Områdes_ID: "1430-V-024",
    Projektnamn: "Vindpark Brattön-Sälelund",
    Verksamhetsutövare: "Rabbalshede Kraft AB",
    Organisationsnummer: "556681-4652",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Munkedal",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Nov 16, 2020",
  },
  {
    Områdes_ID: "1430-V-029",
    Projektnamn: "Vindpark Järmunderöd",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Munkedal",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1435-V-014",
    Projektnamn: "Skaveröd/Gurseröd",
    Verksamhetsutövare: "Skaveröd Gurseröd Vind AB",
    Organisationsnummer: "556809-3453",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 48,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tanum",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1435-V-021",
    Projektnamn: "Torgersröd, Håbäckemarken",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tanum",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 25, 2019",
  },
  {
    Områdes_ID: "1435-V-046",
    Projektnamn: "Vindpark Sögårdsfjället",
    Verksamhetsutövare: "Rabbalshede Kraft AB",
    Organisationsnummer: "556681-4652",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 58,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tanum",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1435-V-051",
    Projektnamn: "Skaveröd/Gurseröd",
    Verksamhetsutövare: "Skaveröd Gurseröd Vind AB",
    Organisationsnummer: "556809-3453",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 48,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tanum",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1435-V-059",
    Projektnamn: "Bramseröd",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 30,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tanum",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1435-V-060",
    Projektnamn: "Vindpark Tågeröd",
    Verksamhetsutövare: "Tanum Vindkraft AB",
    Organisationsnummer: "556753-6064",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 20.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tanum",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Nov 08, 2021",
  },
  {
    Områdes_ID: "1435-V-061",
    Projektnamn: "Taranderöd, Lurs-Amdal",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tanum",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 25, 2019",
  },
  {
    Områdes_ID: "1438-V-001",
    Projektnamn: "Töftedalsfjället",
    Verksamhetsutövare: "Töftedals vind AB",
    Organisationsnummer: "556753-8599",
    Aktuella_verk: 21,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 128.95,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Dals-Ed",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 17, 2019",
  },
  {
    Områdes_ID: "1438-V-002",
    Projektnamn: "Töftedalsfjället",
    Verksamhetsutövare: "Töftedals vind AB",
    Organisationsnummer: "556753-8599",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 24,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Dals-Ed",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1438-V-004",
    Projektnamn: "Vindpark Håbol",
    Verksamhetsutövare: "Gothia Vind AB",
    Organisationsnummer: "556714-2095",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Dals-Ed",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "1439-V-003",
    Projektnamn: "Projekt Vinnsäter",
    Verksamhetsutövare: "Rabbalshede Kraft AB",
    Organisationsnummer: "556681-4652",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Färgelanda",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1439-V-004",
    Projektnamn: "Vindpark Ödeborgsfjället",
    Verksamhetsutövare: "Rabbalshede Kraft AB",
    Organisationsnummer: "556681-4652",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 54,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Färgelanda",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1441-V-005",
    Projektnamn: "Rävbacka Vindkraftpark",
    Verksamhetsutövare: "Göteborg Energi AB",
    Organisationsnummer: "556362-6794",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 52,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Lerum",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1441-V-007",
    Projektnamn: "Öijared",
    Verksamhetsutövare: "Wallenstam Vindkraft Öijared AB",
    Organisationsnummer: "556699-8943",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Lerum",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 15, 2021",
  },
  {
    Områdes_ID: "1442-V-001",
    Projektnamn: "Vindpark Brevikshult",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vårgårda",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Jan 15, 2019",
  },
  {
    Områdes_ID: "1452-V-003",
    Projektnamn: "Vindkraftpark Älvshult",
    Verksamhetsutövare: "Billyvind AB",
    Organisationsnummer: "556705-6931",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tranemo",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "May 06, 2019",
  },
  {
    Områdes_ID: "1460-V-002",
    Projektnamn: "Dingelvik",
    Verksamhetsutövare: "Svenska Vindbolaget AB",
    Organisationsnummer: "556759-9013",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Bengtsfors",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 09, 2019",
  },
  {
    Områdes_ID: "1460-V-004",
    Projektnamn: "Skuggetorp  vindpark",
    Verksamhetsutövare: "Skuggetorp Vindkraft AB",
    Organisationsnummer: "556773-7993",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Bengtsfors",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 09, 2019",
  },
  {
    Områdes_ID: "1461-V-025",
    Projektnamn: "Vindpark Ränsliden",
    Verksamhetsutövare: "Scanergy Dalsland AB",
    Organisationsnummer: "556896-4414",
    Aktuella_verk: 7,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 82.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mellerud",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Nov 13, 2019",
  },
  {
    Områdes_ID: "1462-V-014",
    Projektnamn: "Gategårdens vindpark",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "LillaEdet",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Dec 11, 2018",
  },
  {
    Områdes_ID: "1462-V-019",
    Projektnamn: "Vindkraftprojekt Kärrsbacken",
    Verksamhetsutövare: "RWE Renewables Sweden AB",
    Organisationsnummer: "556938-6864",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "LillaEdet",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 22, 2021",
  },
  {
    Områdes_ID: "1462-V-021",
    Projektnamn: "Nedra Hagen",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 9,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "LillaEdet",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1463-V-020",
    Projektnamn: "Sätila Vindkraftpark",
    Verksamhetsutövare: "Göteborg Energi AB",
    Organisationsnummer: "556362-6794",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mark",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Apr 08, 2019",
  },
  {
    Områdes_ID: "1463-V-021",
    Projektnamn: "Björketorp",
    Verksamhetsutövare: "Varberg Energi AB",
    Organisationsnummer: "556524-3010",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 38.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mark",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1463-V-022",
    Projektnamn: "Öxabäck",
    Verksamhetsutövare: "Varberg Energi AB",
    Organisationsnummer: "556524-3010",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 66,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mark",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1465-V-002",
    Projektnamn: "Vindkraftspark Stenhult",
    Verksamhetsutövare: "Triventus AB",
    Organisationsnummer: "556627-3016",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Svenljunga",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1470-V-038",
    Projektnamn: "Vånga och Stenberga",
    Verksamhetsutövare: "Universal Power AB",
    Organisationsnummer: "556726-3859",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1473-V-005",
    Projektnamn: "Vindkraftpark Fimmerstad",
    Verksamhetsutövare: "Triventus Wind Power AB",
    Organisationsnummer: "556863-1765",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Töreboda",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Dec 22, 2020",
  },
  {
    Områdes_ID: "1473-V-006",
    Projektnamn: "Fägremo",
    Verksamhetsutövare: "Töreboda Vind AB",
    Organisationsnummer: "556795-4077",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Töreboda",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1480-v-001",
    Projektnamn: "Arendal/Risholmen",
    Verksamhetsutövare: "Göteborg Energi AB",
    Organisationsnummer: "556362-6794",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 23.27,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Göteborg",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1482-V-003",
    Projektnamn: "Vävra Berg",
    Verksamhetsutövare: "Rabbalshede Kraft AB",
    Organisationsnummer: "556681-4652",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kungälv",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "1484-V-004",
    Projektnamn: "Vindkraftpark Preem/LyseHogen",
    Verksamhetsutövare: "Vindin AB",
    Organisationsnummer: "556713-5172",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Lysekil",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Mar 18, 2021",
  },
  {
    Områdes_ID: "1485-V-003",
    Projektnamn: "Gunnarby",
    Verksamhetsutövare: "Gunnarby Vindkraft AB",
    Organisationsnummer: "556721-0967",
    Aktuella_verk: 8,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 48,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Uddevalla",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 27, 2020",
  },
  {
    Områdes_ID: "1485-V-010",
    Projektnamn: "KRÅKERÖD",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Uddevalla",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Jan 15, 2019",
  },
  {
    Områdes_ID: "1485-V-011",
    Projektnamn: "Ljungkile Norra",
    Verksamhetsutövare: "Rabbalshede Kraft AB",
    Organisationsnummer: "556681-4652",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Uddevalla",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "1485-V-013",
    Projektnamn: "Ljungkile-Hoven",
    Verksamhetsutövare: "Rabbalshede Kraft AB",
    Organisationsnummer: "556681-4652",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Uddevalla",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "1485-V-025",
    Projektnamn: "Vindpark Forshälla",
    Verksamhetsutövare: "Rabbalshede Kraft AB",
    Organisationsnummer: "556681-4652",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 102,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Uddevalla",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1486-V-001",
    Projektnamn: "Vindpark Femstenaberg",
    Verksamhetsutövare: "Rabbalshede Kraft AB",
    Organisationsnummer: "556681-4652",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Strömstad",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 06, 2020",
  },
  {
    Områdes_ID: "1486-V-004",
    Projektnamn: "Äng",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 25,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Strömstad",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1486-V-005",
    Projektnamn: "Tolvmanstegen",
    Verksamhetsutövare: "Tolvmanstegen Drift AB",
    Organisationsnummer: "556855-7200",
    Aktuella_verk: 22,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 122.98,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Strömstad",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1487-V-015",
    Projektnamn: "Nunntorp",
    Verksamhetsutövare: "Västfastigheter V:a Götalandsregionen",
    Organisationsnummer: "232100-0131",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vänersborg",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1489-V-004",
    Projektnamn: "Lärkeskogen vindkraft",
    Verksamhetsutövare: "Wallenstam Vindkraft Lärkeskogen AB",
    Organisationsnummer: "556731-4710",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Alingsås",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "1490-V-001",
    Projektnamn: "Vindkraftverk Rångedala, Falskog",
    Verksamhetsutövare: "Borås Energi och Miljö AB",
    Organisationsnummer: "556527-5590",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Borås",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 08, 2019",
  },
  {
    Områdes_ID: "1491-V-003",
    Projektnamn: "Galtåsen",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ulricehamn",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1491-V-004",
    Projektnamn: "Gullered",
    Verksamhetsutövare: "Greenextreme AB",
    Organisationsnummer: "556722-4596",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ulricehamn",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "1491-V-005",
    Projektnamn: "Gullered-Högshult Vindkraftpark",
    Verksamhetsutövare: "Greenextreme AB",
    Organisationsnummer: "556722-4596",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ulricehamn",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 28, 2019",
  },
  {
    Områdes_ID: "1491-V-006",
    Projektnamn: "Hällunda-Timmele",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 23,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "May 01, 2022",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ulricehamn",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 30, 2021",
  },
  {
    Områdes_ID: "1491-V-009",
    Projektnamn: "Marbäck",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ulricehamn",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "May 14, 2019",
  },
  {
    Områdes_ID: "1492-V-003",
    Projektnamn: "Fröskog vindkraftpark, alt A",
    Verksamhetsutövare: "Veddige Vindkraft AB",
    Organisationsnummer: "556753-6072",
    Aktuella_verk: 6,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Åmål",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 21, 2021",
  },
  {
    Områdes_ID: "1492-V-009",
    Projektnamn: "Vindpark Kingebol",
    Verksamhetsutövare: "European Wind Farms Kåre 1 AB",
    Organisationsnummer: "559011-1224",
    Aktuella_verk: 6,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Jan 01, 2021",
    Planerat_drifttagande: "Oct 01, 2022",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Åmål",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Jun 09, 2021",
  },
  {
    Områdes_ID: "1492-V-010",
    Projektnamn: "Vindpark Sjövik",
    Verksamhetsutövare: "Åmål Vindkraft AB",
    Organisationsnummer: "556753-6080",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 63,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Åmål",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1492-V-012",
    Projektnamn: "Vindpark Ånimskog",
    Verksamhetsutövare: "Gothia Vind AB",
    Organisationsnummer: "556714-2095",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Åmål",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 13, 2019",
  },
  {
    Områdes_ID: "1492-V-013",
    Projektnamn: "Östra Korsbyn",
    Verksamhetsutövare: "Triventus AB",
    Organisationsnummer: "556627-3016",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Åmål",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1496-V-015",
    Projektnamn: "Sparresäter Vindkraftpark",
    Verksamhetsutövare: "Västfastigheter V:a Götalandsregionen",
    Organisationsnummer: "232100-0131",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Skövde",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Mar 19, 2021",
  },
  {
    Områdes_ID: "1497-V-007",
    Projektnamn: "Norra Hulan",
    Verksamhetsutövare: "Triventus AB",
    Organisationsnummer: "556627-3016",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hjo",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1497-V-017",
    Projektnamn: "Prästbolet Hulan",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hjo",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "1498-V-011",
    Projektnamn: "Velinga",
    Verksamhetsutövare: "Cloudberry Offshore Wind AB",
    Organisationsnummer: "556710-7403",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tidaholm",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1499-V-023",
    Projektnamn: "Näset",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Falköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 09, 2019",
  },
  {
    Områdes_ID: "1765-V-001",
    Projektnamn: "Årjäng NV",
    Verksamhetsutövare: "Årjäng Nordväst Vind AB",
    Organisationsnummer: "556812-2666",
    Aktuella_verk: 9,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 77.4,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "Dec 01, 2014",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Årjäng",
    Län: "Värmlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1765-V-002",
    Projektnamn: "Årjäng NO",
    Verksamhetsutövare: "Rabbalshede Kraft AB",
    Organisationsnummer: "556681-4652",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Årjäng",
    Län: "Värmlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "1765-V-003",
    Projektnamn: "Årjäng NO",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Årjäng",
    Län: "Värmlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "1765-V-004",
    Projektnamn: "Årjäng SV",
    Verksamhetsutövare: "Årjäng Sydväst Vind AB",
    Organisationsnummer: "556872-2804",
    Aktuella_verk: 13,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 124.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Årjäng",
    Län: "Värmlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1781-V-001",
    Projektnamn: "Ölme",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 16,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 84.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kristinehamn",
    Län: "Värmlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 21, 2021",
  },
  {
    Områdes_ID: "1781-V-003",
    Projektnamn: "Bäckhammar",
    Verksamhetsutövare: "Bäckhammar Vind AB",
    Organisationsnummer: "556956-6176",
    Aktuella_verk: 11,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 138.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "Aug 01, 2020",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kristinehamn",
    Län: "Värmlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 17, 2020",
  },
  {
    Områdes_ID: "1781-V-004",
    Projektnamn: "Bäckhammar",
    Verksamhetsutövare: "Bäckhammar Vind AB",
    Organisationsnummer: "556956-6176",
    Aktuella_verk: 7,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 88.2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "Aug 01, 2020",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kristinehamn",
    Län: "Värmlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 17, 2020",
  },
  {
    Områdes_ID: "1781-V-006",
    Projektnamn: "Sättravallen",
    Verksamhetsutövare: "Cloudberry Offshore Wind AB",
    Organisationsnummer: "556710-7403",
    Aktuella_verk: 16,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 120,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kristinehamn",
    Län: "Värmlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1781-V-007",
    Projektnamn: "Långmarken",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 8,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 80,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kristinehamn",
    Län: "Värmlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Nov 08, 2019",
  },
  {
    Områdes_ID: "1783-V-001",
    Projektnamn: "Sunnemo-Håkanbol",
    Verksamhetsutövare: "Greenextreme AB",
    Organisationsnummer: "556722-4596",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 34.98,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hagfors",
    Län: "Värmlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1785-V-001",
    Projektnamn: "Botilsäter",
    Verksamhetsutövare: "Eurowind Energy",
    Organisationsnummer: "556753-6049",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 26,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Säffle",
    Län: "Värmlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1785-V-004",
    Projektnamn: "Hökhult",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Säffle",
    Län: "Värmlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Jan 28, 2020",
  },
  {
    Områdes_ID: "1785-V-005",
    Projektnamn: "Knappa",
    Verksamhetsutövare: "Eurowind Energy",
    Organisationsnummer: "556753-6049",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 71.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Säffle",
    Län: "Värmlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1785-V-007",
    Projektnamn: "Mohultet",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Säffle",
    Län: "Värmlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "1785-V-008",
    Projektnamn: "Sjönnebol",
    Verksamhetsutövare: "BayWa r.e. Scandinavia AB",
    Organisationsnummer: "556885-6503",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Säffle",
    Län: "Värmlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Nov 08, 2019",
  },
  {
    Områdes_ID: "1785-V-012",
    Projektnamn: "Torserud",
    Verksamhetsutövare: "Universal Wind AB",
    Organisationsnummer: "556571-8151",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Säffle",
    Län: "Värmlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "1860-V-002",
    Projektnamn: "Slottsbol",
    Verksamhetsutövare: "Slottsbol Drift AB",
    Organisationsnummer: "556875-9657",
    Aktuella_verk: 8,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 35.2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Laxå",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 06, 2021",
  },
  {
    Områdes_ID: "1882-V-002",
    Projektnamn: "Markebäck",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Askersund",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 08, 2019",
  },
  {
    Områdes_ID: "0120-V-001",
    Projektnamn: "Löknäsparken Nyvarpsträsk (1)",
    Verksamhetsutövare: "Kraftö AB",
    Organisationsnummer: "556708-7456",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 15,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Värmdö",
    Län: "Stockholms län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0382-V-001",
    Projektnamn: "Karö",
    Verksamhetsutövare: "Firma Ramström Vind AB",
    Organisationsnummer: "556688-2683",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Östhammar",
    Län: "Uppsala län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 08, 2021",
  },
  {
    Områdes_ID: "0604-V-006",
    Projektnamn: "Björka",
    Verksamhetsutövare: "Cloudberry Offshore Wind AB",
    Organisationsnummer: "556710-7403",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Aneby",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0604-V-007",
    Projektnamn: "Bolerum",
    Verksamhetsutövare: "Cloudberry Offshore Wind AB",
    Organisationsnummer: "556710-7403",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Aneby",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "Mar 19, 2021",
  },
  {
    Områdes_ID: "0604-V-008",
    Projektnamn: "Marbäcks-Kopparp",
    Verksamhetsutövare: "Cloudberry Offshore Wind AB",
    Organisationsnummer: "556710-7403",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Aneby",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0760-V-002",
    Projektnamn: "Älmedal",
    Verksamhetsutövare: "SR Energy AB",
    Organisationsnummer: "556711-9549",
    Aktuella_verk: 9,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Jan 01, 2023",
    Planerat_drifttagande: "Dec 31, 2024",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Uppvidinge",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 24, 2021",
  },
  {
    Områdes_ID: "0760-V-003",
    Projektnamn: "Tvinnesheda-Badeboda",
    Verksamhetsutövare: "SR Energy AB",
    Organisationsnummer: "556711-9549",
    Aktuella_verk: 47,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 599.72,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "Sep 01, 2022",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Uppvidinge",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "Jan 18, 2022",
  },
  {
    Områdes_ID: "0760-V-004",
    Projektnamn: "Lenhovda",
    Verksamhetsutövare: "Billyvind AB",
    Organisationsnummer: "556705-6931",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Uppvidinge",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 10, 2021",
  },
  {
    Områdes_ID: "0760-V-005",
    Projektnamn: "Bostorp",
    Verksamhetsutövare: "Väktaren Vind AB",
    Organisationsnummer: "556628-7578",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Uppvidinge",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 10, 2021",
  },
  {
    Områdes_ID: "0760-V-007",
    Projektnamn: "Lillahult",
    Verksamhetsutövare: "Billyvind AB",
    Organisationsnummer: "556705-6931",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Uppvidinge",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0760-V-010",
    Projektnamn: "Vraneke & Bihult",
    Verksamhetsutövare: "Väktaren Vind AB",
    Organisationsnummer: "556628-7578",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Uppvidinge",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 10, 2021",
  },
  {
    Områdes_ID: "0764-V-003",
    Projektnamn: "Blädingeås",
    Verksamhetsutövare: "Triventus Wind Power AB",
    Organisationsnummer: "556863-1765",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 30,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Alvesta",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "Mar 12, 2021",
  },
  {
    Områdes_ID: "0767-V-002",
    Projektnamn: "Alandsköp",
    Verksamhetsutövare: "RWE Renewables Sweden AB",
    Organisationsnummer: "556938-6864",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Markaryd",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 22, 2021",
  },
  {
    Områdes_ID: "0767-V-004",
    Projektnamn: "Sjöaryd",
    Verksamhetsutövare: "Arise Windpower AB",
    Organisationsnummer: "556274-6726",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Markaryd",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0767-V-005",
    Projektnamn: "Hyltan",
    Verksamhetsutövare: "Arise Windpower AB",
    Organisationsnummer: "556274-6726",
    Aktuella_verk: 6,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Markaryd",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0781-V-004",
    Projektnamn: "Byholma",
    Verksamhetsutövare: "Byholma Vind AB",
    Organisationsnummer: "556802-0308",
    Aktuella_verk: 15,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ljungby",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0781-V-006",
    Projektnamn: "Ljunga",
    Verksamhetsutövare: "Scanergy South AB",
    Organisationsnummer: "556758-6614",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ljungby",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "0834-V-002",
    Projektnamn: "Gettnabo II",
    Verksamhetsutövare: "Svenska Vindbolaget AB",
    Organisationsnummer: "556759-9013",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Torsås",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Oct 01, 2019",
  },
  {
    Områdes_ID: "0834-V-006",
    Projektnamn: "Videbäcksmåla_Torhult_Sandbacksmåla",
    Verksamhetsutövare: "WPD Onshore Hittsjön AB",
    Organisationsnummer: "556748-1923",
    Aktuella_verk: 12,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Torsås",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0840-V-005",
    Projektnamn: "Bläsinge",
    Verksamhetsutövare: "Vingkraft AB",
    Organisationsnummer: "556528-1945",
    Aktuella_verk: 6,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mörbylånga",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0840-V-011",
    Projektnamn: "Frösslunda",
    Verksamhetsutövare: "Vingkraft AB",
    Organisationsnummer: "556528-1945",
    Aktuella_verk: 32,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mörbylånga",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Jan 10, 2022",
  },
  {
    Områdes_ID: "0840-V-020",
    Projektnamn: "Kastlösa",
    Verksamhetsutövare: "Vingkraft AB",
    Organisationsnummer: "556528-1945",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mörbylånga",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0840-V-021",
    Projektnamn: "Kastlösa södra",
    Verksamhetsutövare: "OX2 AB",
    Organisationsnummer: "556675-7497",
    Aktuella_verk: 16,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 19.86,
    Uppmätt_årsproduktion_GWh: 13.46,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mörbylånga",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 01, 2021",
  },
  {
    Områdes_ID: "0840-V-026",
    Projektnamn: "Olstorp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 5,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mörbylånga",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Jan 10, 2022",
  },
  {
    Områdes_ID: "0840-V-034",
    Projektnamn: "Södra Kvinneby",
    Verksamhetsutövare: "Vingkraft AB",
    Organisationsnummer: "556528-1945",
    Aktuella_verk: 12,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mörbylånga",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0840-V-042",
    Projektnamn: "Ventlinge Alvar",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mörbylånga",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "0840-V-053",
    Projektnamn: "Triberga",
    Verksamhetsutövare: "Vingkraft AB",
    Organisationsnummer: "556528-1945",
    Aktuella_verk: 5,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mörbylånga",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0840-V-055",
    Projektnamn: "Utgrunden 2",
    Verksamhetsutövare: "RWE Renewables Sweden AB",
    Organisationsnummer: "556938-6864",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mörbylånga",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Oct 12, 2021",
  },
  {
    Områdes_ID: "0860-V-002",
    Projektnamn: "Fröreda",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 7,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 73.57,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Hultsfred",
    Län: "Kalmar län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0860-V-004",
    Projektnamn: "Tönshult",
    Verksamhetsutövare: "Statkraft Södra Vindarrende AB",
    Organisationsnummer: "556091-8772",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hultsfred",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0861-V-001",
    Projektnamn: "Skruvshult",
    Verksamhetsutövare: "Arise Windpower AB",
    Organisationsnummer: "556274-6726",
    Aktuella_verk: 5,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mönsterås",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0880-V-006",
    Projektnamn: "Värnanäs",
    Verksamhetsutövare: "Triventus Wind Power AB",
    Organisationsnummer: "556863-1765",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kalmar",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Nov 07, 2019",
  },
  {
    Områdes_ID: "0882-V-001",
    Projektnamn: "Bjälebo",
    Verksamhetsutövare: "Bjälebo Vindkraft AB",
    Organisationsnummer: "556735-9319",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Oskarshamn",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 12, 2019",
  },
  {
    Områdes_ID: "0882-V-004",
    Projektnamn: "Övrahammar",
    Verksamhetsutövare: "Kraftö AB",
    Organisationsnummer: "556708-7456",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Oskarshamn",
    Län: "Kalmar län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0883-V-002",
    Projektnamn: "Blekhem",
    Verksamhetsutövare: "Arise Windfarm 4 AB",
    Organisationsnummer: "556758-8933",
    Aktuella_verk: 6,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 35,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Västervik",
    Län: "Kalmar län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0883-V-003",
    Projektnamn: "Kilmare_Ytterhult",
    Verksamhetsutövare: "Arise Windpower AB",
    Organisationsnummer: "556274-6726",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Västervik",
    Län: "Kalmar län",
    Elområde: "Stockholm",
    Senast_sparad: "Jan 18, 2019",
  },
  {
    Områdes_ID: "1060-V-003",
    Projektnamn: "Månasken",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 5,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 25,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Olofström",
    Län: "Blekinge län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1060-V-004",
    Projektnamn: "Månasken",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 25,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Olofström",
    Län: "Blekinge län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1081-V-002",
    Projektnamn: "Hakarp",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 8,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 40,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ronneby",
    Län: "Blekinge län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2031-V-001",
    Projektnamn: "Hedbodberget Etapp 1",
    Verksamhetsutövare: "Hedbodberget Vind AB",
    Organisationsnummer: "556717-1979",
    Aktuella_verk: 15,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 88.63,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Rättvik",
    Län: "Dalarnas län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 02, 2020",
  },
  {
    Områdes_ID: "2034-V-001",
    Projektnamn: "Skäftberget",
    Verksamhetsutövare: "Fortum Generation AB",
    Organisationsnummer: "556006-8230",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Orsa",
    Län: "Dalarnas län",
    Elområde: "Sundsvall",
    Senast_sparad: "Feb 13, 2019",
  },
  {
    Områdes_ID: "2034-V-003",
    Projektnamn: "Mässingberget",
    Verksamhetsutövare: "OX2 AB",
    Organisationsnummer: "556675-7497",
    Aktuella_verk: 11,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 76.01,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Orsa",
    Län: "Dalarnas län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2062-V-001",
    Projektnamn: "Bosberget",
    Verksamhetsutövare: "Bosberget Vindkraft AB",
    Organisationsnummer: "556755-4810",
    Aktuella_verk: 16,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 136,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mora",
    Län: "Dalarnas län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2080-V-006",
    Projektnamn: "Sundborn Windpark",
    Verksamhetsutövare: "Sundborn Vind AB",
    Organisationsnummer: "556765-4602",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Falun",
    Län: "Dalarnas län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "2085-V-008",
    Projektnamn: "Orrberget/Stensvedberget",
    Verksamhetsutövare: "FORTE Nordic Wind AB",
    Organisationsnummer: "559128-7023",
    Aktuella_verk: 9,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 95.94,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "Dec 01, 2019",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ludvika",
    Län: "Dalarnas län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 07, 2021",
  },
  {
    Områdes_ID: "2101-V-006",
    Projektnamn: "Vindpark Fallåsberget",
    Verksamhetsutövare: "OX2 AB",
    Organisationsnummer: "556675-7497",
    Aktuella_verk: 10,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 75,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ockelbo",
    Län: "Gävleborgs län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2132-V-003",
    Projektnamn: "Vindkraftpark Jättendal",
    Verksamhetsutövare: "RWE Renewables Sweden AB",
    Organisationsnummer: "556938-6864",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Nordanstig",
    Län: "Gävleborgs län",
    Elområde: "Sundsvall",
    Senast_sparad: "Dec 10, 2021",
  },
  {
    Områdes_ID: "2161-V-002",
    Projektnamn: "Vindkraftpark Riberget",
    Verksamhetsutövare: "OX2 AB",
    Organisationsnummer: "556675-7497",
    Aktuella_verk: 12,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 96,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Oct 01, 2021",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ljusdal",
    Län: "Gävleborgs län",
    Elområde: "Sundsvall",
    Senast_sparad: "Nov 25, 2021",
  },
  {
    Områdes_ID: "2161-V-003",
    Projektnamn: "Vindkraftpark Dalskölen",
    Verksamhetsutövare: "Nordex Sverige AB",
    Organisationsnummer: "556756-9792",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 119,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ljusdal",
    Län: "Gävleborgs län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2161-V-004",
    Projektnamn: "Vindpark Högkölen",
    Verksamhetsutövare: "Windfarm Högkölen AB",
    Organisationsnummer: "556995-1279",
    Aktuella_verk: 18,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 243,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "Dec 31, 2018",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ljusdal",
    Län: "Gävleborgs län",
    Elområde: "Sundsvall",
    Senast_sparad: "May 18, 2021",
  },
  {
    Områdes_ID: "2161-V-005",
    Projektnamn: "Vindkraftpark Blacksåsberget",
    Verksamhetsutövare: "Nordex Sverige AB",
    Organisationsnummer: "556756-9792",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 54,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ljusdal",
    Län: "Gävleborgs län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2161-V-006",
    Projektnamn: "Vindpark Våsberget",
    Verksamhetsutövare: "Våsberget Vindkraft AB",
    Organisationsnummer: "556805-7573",
    Aktuella_verk: 8,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 80,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ljusdal",
    Län: "Gävleborgs län",
    Elområde: "Sundsvall",
    Senast_sparad: "Oct 07, 2021",
  },
  {
    Områdes_ID: "2180-V-002",
    Projektnamn: "Vindpark Kvissjaberget",
    Verksamhetsutövare: "Samkraft AB",
    Organisationsnummer: "556754-5644",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Gävle",
    Län: "Gävleborgs län",
    Elområde: "Sundsvall",
    Senast_sparad: "Jan 28, 2020",
  },
  {
    Områdes_ID: "2180-V-003",
    Projektnamn: "Vindpark Hittsjön",
    Verksamhetsutövare: "WPD Onshore Hittsjön AB",
    Organisationsnummer: "556748-1923",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Gävle",
    Län: "Gävleborgs län",
    Elområde: "Sundsvall",
    Senast_sparad: "Jan 21, 2021",
  },
  {
    Områdes_ID: "2180-V-004",
    Projektnamn: "Vindpark Hedesunda",
    Verksamhetsutövare: "Kraftö AB",
    Organisationsnummer: "556708-7456",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 150,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Gävle",
    Län: "Gävleborgs län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2182-V-007",
    Projektnamn: "Vindkraftpark Gullberg",
    Verksamhetsutövare: "Nordex Sverige AB",
    Organisationsnummer: "556756-9792",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Söderhamn",
    Län: "Gävleborgs län",
    Elområde: "Sundsvall",
    Senast_sparad: "Mar 01, 2019",
  },
  {
    Områdes_ID: "2183-V-002",
    Projektnamn: "Vindpark Arbrå",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Bollnäs",
    Län: "Gävleborgs län",
    Elområde: "Sundsvall",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "2183-V-004",
    Projektnamn: "Vindpark Hallbrån",
    Verksamhetsutövare: "OWN Power Projects AB",
    Organisationsnummer: "556603-1869",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Bollnäs",
    Län: "Gävleborgs län",
    Elområde: "Sundsvall",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "2184-V-007",
    Projektnamn: "Vindpark Glombo",
    Verksamhetsutövare: "RES Renewable Norden AB",
    Organisationsnummer: "556616-0684",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 60.2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hudiksvall",
    Län: "Gävleborgs län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2313-V-004",
    Projektnamn: "Ollebacken etapp 1",
    Verksamhetsutövare: "Ollebacken Energi AB",
    Organisationsnummer: "556700-0400",
    Aktuella_verk: 6,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 32.2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Strömsund",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2313-V-007",
    Projektnamn: "Ollebacken etapp 2",
    Verksamhetsutövare: "Ollebacken Energi AB",
    Organisationsnummer: "556700-0400",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 54,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Sep 02, 2020",
    Planerat_drifttagande: "Dec 22, 2021",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Strömsund",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Dec 01, 2021",
  },
  {
    Områdes_ID: "2313-V-009",
    Projektnamn: "Murufjället (9 st vindkraftverk)",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Strömsund",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Apr 08, 2019",
  },
  {
    Områdes_ID: "2425-V-001",
    Projektnamn: "Bliekevare 1",
    Verksamhetsutövare: "Bliekevare Vind AB",
    Organisationsnummer: "556704-9100",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 93.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Dorotea",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2463-V-002",
    Projektnamn: "Järvsjökullen",
    Verksamhetsutövare: "Åsele Vindkraft AB",
    Organisationsnummer: "556721-0082",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Åsele",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "Oct 26, 2021",
  },
  {
    Områdes_ID: "2482-V-002",
    Projektnamn: "Kinnbäck",
    Verksamhetsutövare: "Väktaren Vind AB",
    Organisationsnummer: "556628-7578",
    Aktuella_verk: 6,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 48,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Skellefteå",
    Län: "Västerbottens län",
    Elområde: "Luleå",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2482-V-005",
    Projektnamn: "Fjällboheden vindpark",
    Verksamhetsutövare: "Fjällboheden Vind AB",
    Organisationsnummer: "556822-9974",
    Aktuella_verk: 10,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 166,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "May 01, 2020",
    Planerat_drifttagande: "Dec 01, 2021",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Skellefteå",
    Län: "Västerbottens län",
    Elområde: "Luleå",
    Senast_sparad: "Dec 01, 2021",
  },
  {
    Områdes_ID: "2580-V-001",
    Projektnamn: "Selet",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Luleå",
    Län: "Norrbottens län",
    Elområde: "Luleå",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2580-V-002",
    Projektnamn: "Persön",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Luleå",
    Län: "Norrbottens län",
    Elområde: "Luleå",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0760-V-023",
    Projektnamn: "Holmahult",
    Verksamhetsutövare: "Kraftö AB",
    Organisationsnummer: "556708-7456",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Uppvidinge",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 10, 2021",
  },
  {
    Områdes_ID: "0764-V-001",
    Projektnamn: "Lyngsåsa",
    Verksamhetsutövare: "BayWa r.e. Scandinavia AB",
    Organisationsnummer: "556885-6503",
    Aktuella_verk: 22,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 229.9,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Oct 01, 2019",
    Planerat_drifttagande: "Oct 01, 2021",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Alvesta",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "May 06, 2021",
  },
  {
    Områdes_ID: "0834-V-004",
    Projektnamn: "Strömby",
    Verksamhetsutövare: "Firma Ramström Vind AB",
    Organisationsnummer: "556688-2683",
    Aktuella_verk: 20,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Torsås",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0880-V-005",
    Projektnamn: "Harstensbo_Mjödehult_Ugglebo",
    Verksamhetsutövare: "Harstensbo Vindkraft AB",
    Organisationsnummer: "556784-1274",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kalmar",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "0881-V-001",
    Projektnamn: "Gårdsryd",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Nybro",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Dec 09, 2020",
  },
  {
    Områdes_ID: "2021-V-003",
    Projektnamn: "Silkomhöjden",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 20.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vansbro",
    Län: "Dalarnas län",
    Elområde: "Stockholm",
    Senast_sparad: "Jan 09, 2019",
  },
  {
    Områdes_ID: "2029-V-001",
    Projektnamn: "Högberget",
    Verksamhetsutövare: "Dala Vind AB",
    Organisationsnummer: "556696-6940",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 15,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Leksand",
    Län: "Dalarnas län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2062-V-003",
    Projektnamn: "Kyrkberget",
    Verksamhetsutövare: "Jämtkraft AB",
    Organisationsnummer: "556001-6064",
    Aktuella_verk: 10,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 57,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mora",
    Län: "Dalarnas län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2084-V-002",
    Projektnamn: "Skallberget/Utterberget",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 15,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 90,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Avesta",
    Län: "Dalarnas län",
    Elområde: "Stockholm",
    Senast_sparad: "Dec 01, 2021",
  },
  {
    Områdes_ID: "2101-V-005",
    Projektnamn: "Vindpark StorVrången",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 23,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 128.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ockelbo",
    Län: "Gävleborgs län",
    Elområde: "Sundsvall",
    Senast_sparad: "Dec 01, 2021",
  },
  {
    Områdes_ID: "2101-V-009",
    Projektnamn: "Vindpark Klubbäcken",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ockelbo",
    Län: "Gävleborgs län",
    Elområde: "Sundsvall",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "2161-V-001",
    Projektnamn: "Vindpark Tandsjö",
    Verksamhetsutövare: "WPD Onshore Tandsjön AB",
    Organisationsnummer: "556743-2777",
    Aktuella_verk: 9,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 154.98,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ljusdal",
    Län: "Gävleborgs län",
    Elområde: "Sundsvall",
    Senast_sparad: "Dec 02, 2021",
  },
  {
    Områdes_ID: "2181-V-004",
    Projektnamn: "Vindpark Mombyåsen",
    Verksamhetsutövare: "Mombyåsen Wind Farm AB",
    Organisationsnummer: "556833-1507",
    Aktuella_verk: 10,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 64,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sandviken",
    Län: "Gävleborgs län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 07, 2021",
  },
  {
    Områdes_ID: "2183-V-003",
    Projektnamn: "Bergvind Annefors",
    Verksamhetsutövare: "Bergvik Skog AB",
    Organisationsnummer: "556610-2959",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 742,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Bollnäs",
    Län: "Gävleborgs län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2184-V-003",
    Projektnamn: "Dyråsvallen",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hudiksvall",
    Län: "Gävleborgs län",
    Elområde: "Sundsvall",
    Senast_sparad: "Sep 09, 2019",
  },
  {
    Områdes_ID: "2361-V-001",
    Projektnamn: "Vindpark Kölvallen",
    Verksamhetsutövare: "OWN Power Projects AB",
    Organisationsnummer: "556603-1869",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 163.3,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Härjedalen",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2281-V-001",
    Projektnamn: "ÖSTERRO",
    Verksamhetsutövare: "Svenska Vindbolaget AB",
    Organisationsnummer: "556759-9013",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Sundsvall",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "Sep 05, 2019",
  },
  {
    Områdes_ID: "1214-V-020",
    Projektnamn: "Heleneborg",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Svalöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1214-V-021",
    Projektnamn: "Tirup",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.45,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Svalöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Mar 27, 2019",
  },
  {
    Områdes_ID: "1214-V-022",
    Projektnamn: "Spargott",
    Verksamhetsutövare: "Spargodt AB",
    Organisationsnummer: "556102-9918",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.85,
    Uppmätt_årsproduktion_GWh: 1.49,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Svalöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1214-V-023",
    Projektnamn: "Almbacka, Felestad",
    Verksamhetsutövare: "Mimer vind AB",
    Organisationsnummer: "556608-8760",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.5,
    Uppmätt_årsproduktion_GWh: 1.54,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Svalöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Oct 28, 2020",
  },
  {
    Områdes_ID: "1214-V-024",
    Projektnamn: "Torrlösa",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Svalöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1214-V-029",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Svalöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 05, 2019",
  },
  {
    Områdes_ID: "1214-V-030",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Svalöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 05, 2019",
  },
  {
    Områdes_ID: "1214-V-032",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Svalöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 05, 2019",
  },
  {
    Områdes_ID: "1214-V-033",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Svalöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 05, 2019",
  },
  {
    Områdes_ID: "1214-V-034",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Svalöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 05, 2019",
  },
  {
    Områdes_ID: "1214-V-035",
    Projektnamn: "",
    Verksamhetsutövare: "Mimer vind AB",
    Organisationsnummer: "556608-8760",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Svalöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 05, 2019",
  },
  {
    Områdes_ID: "1214-V-036",
    Projektnamn: "",
    Verksamhetsutövare: "Mimer vind AB",
    Organisationsnummer: "556608-8760",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Svalöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 05, 2019",
  },
  {
    Områdes_ID: "1214-V-037",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Svalöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 05, 2019",
  },
  {
    Områdes_ID: "1214-V-039",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Svalöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 05, 2019",
  },
  {
    Områdes_ID: "1214-V-040",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Svalöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 05, 2019",
  },
  {
    Områdes_ID: "1214-V-042",
    Projektnamn: "Elico 1",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 8.31,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Svalöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Nov 28, 2019",
  },
  {
    Områdes_ID: "1214-V-051",
    Projektnamn: "Gissleberga",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Svalöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "1214-V-053",
    Projektnamn: "Duveke, Loarp, Halmstad 1",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Svalöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "1214-V-054",
    Projektnamn: "Duveke, Loarp, Halmstad 2",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Svalöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "1214-V-055",
    Projektnamn: "Duveke, Loarp, Halmstad 3",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Svalöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "1230-V-004",
    Projektnamn: "Västangård",
    Verksamhetsutövare: "Lars Magnusson Jordbruks AB",
    Organisationsnummer: "556321-0391",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Staffanstorp",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1230-V-005",
    Projektnamn: "Kornheddinge",
    Verksamhetsutövare: "Kornheddinge Mölla AB",
    Organisationsnummer: "556592-3165",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Staffanstorp",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 26, 2019",
  },
  {
    Områdes_ID: "1230-V-006",
    Projektnamn: "Trolleberg",
    Verksamhetsutövare: "Vindproduktion i Flackarp AB",
    Organisationsnummer: "556559-7282",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 3.4,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Staffanstorp",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1230-V-008",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Staffanstorp",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1233-V-003",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vellinge",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Mar 27, 2019",
  },
  {
    Områdes_ID: "1233-V-004",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vellinge",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Mar 27, 2019",
  },
  {
    Områdes_ID: "1233-V-005",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vellinge",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Mar 27, 2019",
  },
  {
    Områdes_ID: "1256-V-001",
    Projektnamn: "Beateberg 1",
    Verksamhetsutövare: "Timco I Lund AB",
    Organisationsnummer: "556272-4434",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.15,
    Uppmätt_årsproduktion_GWh: 0.93,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Östra Göinge",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1260-V-003",
    Projektnamn: "Södra Vram",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Bjuv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1261-V-006",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kävlinge",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "1261-V-009",
    Projektnamn: "Barsebäck",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.15,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kävlinge",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1261-V-010",
    Projektnamn: "Viking Vind",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.04,
    Uppmätt_årsproduktion_GWh: 0.89,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kävlinge",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1261-V-014",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kävlinge",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Mar 27, 2019",
  },
  {
    Områdes_ID: "1261-V-015",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kävlinge",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "1261-V-017",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kävlinge",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "1262-V-005",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Lomma",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Mar 27, 2019",
  },
  {
    Områdes_ID: "1263-V-001",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Svedala",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 01, 2019",
  },
  {
    Områdes_ID: "1263-V-002",
    Projektnamn: "Lilla Marieholms Kraft AB",
    Verksamhetsutövare: "Lilla Marieholms Kraft AB",
    Organisationsnummer: "556687-8400",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5.5,
    Uppmätt_årsproduktion_GWh: 6.65,
    Planerad_byggstart: "",
    Planerat_drifttagande: "Oct 15, 2010",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Svedala",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 08, 2020",
  },
  {
    Områdes_ID: "1263-V-004",
    Projektnamn: "Tranberga",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Svedala",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1263-V-006",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Svedala",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 01, 2019",
  },
  {
    Områdes_ID: "1263-V-007",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Svedala",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 01, 2019",
  },
  {
    Områdes_ID: "1263-V-009",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Svedala",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 01, 2019",
  },
  {
    Områdes_ID: "1263-V-010",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Svedala",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 01, 2019",
  },
  {
    Områdes_ID: "1263-V-011",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Svedala",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 01, 2019",
  },
  {
    Områdes_ID: "1263-V-012",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Svedala",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 01, 2019",
  },
  {
    Områdes_ID: "1264-V-003",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Skurup",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Oct 09, 2019",
  },
  {
    Områdes_ID: "1264-V-005",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Skurup",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 04, 2019",
  },
  {
    Områdes_ID: "1264-V-006",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Skurup",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "1264-V-007",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Skurup",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "1264-V-008",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Skurup",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "1264-V-010",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Skurup",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "1264-V-012",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Skurup",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 04, 2019",
  },
  {
    Områdes_ID: "1264-V-013",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Skurup",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Oct 09, 2019",
  },
  {
    Områdes_ID: "1264-V-016",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Skurup",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Oct 09, 2019",
  },
  {
    Områdes_ID: "1264-V-017",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Skurup",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Oct 09, 2019",
  },
  {
    Områdes_ID: "1264-V-018",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Skurup",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Oct 09, 2019",
  },
  {
    Områdes_ID: "1264-V-019",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Skurup",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Oct 09, 2019",
  },
  {
    Områdes_ID: "1264-V-020",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Skurup",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Oct 09, 2019",
  },
  {
    Områdes_ID: "1264-V-021",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Skurup",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Oct 09, 2019",
  },
  {
    Områdes_ID: "1265-V-002",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Sjöbo",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "1265-V-007",
    Projektnamn: "Klamby",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Sjöbo",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "1265-V-008",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Sjöbo",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "1265-V-011",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Sjöbo",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "1265-V-013",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Sjöbo",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "1265-V-015",
    Projektnamn: "Vanstad Kommungård",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.37,
    Uppmätt_årsproduktion_GWh: 0.98,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sjöbo",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1265-V-023",
    Projektnamn: "Ry",
    Verksamhetsutövare: "Firma Ramström Vind AB",
    Organisationsnummer: "556688-2683",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Sjöbo",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "1266-V-017",
    Projektnamn: "Långaröd",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.01,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Hörby",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1266-V-018",
    Projektnamn: "Årröd",
    Verksamhetsutövare: "Kraftringen Produktion AB",
    Organisationsnummer: "556475-7119",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.71,
    Uppmätt_årsproduktion_GWh: 0.63,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Hörby",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1266-V-020",
    Projektnamn: "He",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5.2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Hörby",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1266-V-022",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hörby",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Aug 29, 2019",
  },
  {
    Områdes_ID: "1266-V-023",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hörby",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Aug 29, 2019",
  },
  {
    Områdes_ID: "1266-V-024",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hörby",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 11, 2019",
  },
  {
    Områdes_ID: "1266-V-025",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hörby",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Aug 29, 2019",
  },
  {
    Områdes_ID: "1266-V-031",
    Projektnamn: "",
    Verksamhetsutövare: "Siral System & Co AB",
    Organisationsnummer: "556171-8296",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hörby",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1266-V-032",
    Projektnamn: "Norrto",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Hörby",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Dec 03, 2019",
  },
  {
    Områdes_ID: "1266-V-035",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Hörby",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 11, 2019",
  },
  {
    Områdes_ID: "1266-V-036",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Hörby",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1266-V-038",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hörby",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 29, 2019",
  },
  {
    Områdes_ID: "1266-V-041",
    Projektnamn: "Årröd",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Hörby",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Aug 28, 2019",
  },
  {
    Områdes_ID: "1267-V-007",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Höör",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 02, 2019",
  },
  {
    Områdes_ID: "1267-V-008",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Höör",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 02, 2019",
  },
  {
    Områdes_ID: "1267-V-010",
    Projektnamn: "Jordboen",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 3.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Höör",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 08, 2020",
  },
  {
    Områdes_ID: "1270-V-002",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tomelilla",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1270-V-004",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tomelilla",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1270-V-005",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tomelilla",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1270-V-006",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tomelilla",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1270-V-007",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tomelilla",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1270-V-011",
    Projektnamn: "Vitåkra",
    Verksamhetsutövare: "Vitåkravind AB",
    Organisationsnummer: "556615-2384",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.95,
    Uppmätt_årsproduktion_GWh: 1.48,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tomelilla",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1270-V-012",
    Projektnamn: "Lunnarp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 2.3,
    Uppmätt_årsproduktion_GWh: 2.03,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tomelilla",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1270-V-013",
    Projektnamn: "Månslunda I",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.35,
    Uppmätt_årsproduktion_GWh: 1.11,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tomelilla",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1270-V-014",
    Projektnamn: "Tomelilla",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tomelilla",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1270-V-015",
    Projektnamn: "Lunnarp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tomelilla",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1270-V-016",
    Projektnamn: "Ingel 1",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.46,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tomelilla",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1270-V-017",
    Projektnamn: "Ingel 2",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.46,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tomelilla",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1270-V-018",
    Projektnamn: "Ingel 3",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.46,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tomelilla",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1270-V-024",
    Projektnamn: "Lunnarp",
    Verksamhetsutövare: "Ekovind AB",
    Organisationsnummer: "556343-8208",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tomelilla",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 26, 2019",
  },
  {
    Områdes_ID: "1270-V-026",
    Projektnamn: "Appeltorp",
    Verksamhetsutövare: "Vindkällan Energi AB",
    Organisationsnummer: "556797-6880",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5.1,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tomelilla",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1270-V-027",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tomelilla",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 02, 2019",
  },
  {
    Områdes_ID: "1270-V-030",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tomelilla",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 02, 2019",
  },
  {
    Områdes_ID: "1270-V-031",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tomelilla",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "1270-V-032",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tomelilla",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "1270-V-037",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tomelilla",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 02, 2019",
  },
  {
    Områdes_ID: "1270-V-039",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tomelilla",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 02, 2019",
  },
  {
    Områdes_ID: "1270-V-040",
    Projektnamn: "Sälshög",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 6,
    Uppmätt_årsproduktion_GWh: 6.2,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tomelilla",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1270-V-042",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tomelilla",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1270-V-044",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tomelilla",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 04, 2019",
  },
  {
    Områdes_ID: "1270-V-046",
    Projektnamn: "Bondrum-Bontofta",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tomelilla",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Mar 27, 2019",
  },
  {
    Områdes_ID: "1270-V-047",
    Projektnamn: "Bondrum-Bontofta",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tomelilla",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Mar 27, 2019",
  },
  {
    Områdes_ID: "1270-V-048",
    Projektnamn: "Bondrum-Bontofta",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tomelilla",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Mar 27, 2019",
  },
  {
    Områdes_ID: "1272-V-001",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Bromölla",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 01, 2019",
  },
  {
    Områdes_ID: "1272-V-005",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Bromölla",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1276-V-004",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Klippan",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1276-V-006",
    Projektnamn: "Lundsgården",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Klippan",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1276-V-010",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Klippan",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Mar 05, 2019",
  },
  {
    Områdes_ID: "1276-V-014",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Klippan",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Mar 05, 2019",
  },
  {
    Områdes_ID: "1277-V-013",
    Projektnamn: "Maglaby",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Åstorp",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Mar 27, 2019",
  },
  {
    Områdes_ID: "1277-V-014",
    Projektnamn: "Syllstorp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Åstorp",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1277-V-023",
    Projektnamn: "Tranarp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Åstorp",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Mar 27, 2019",
  },
  {
    Områdes_ID: "1278-V-001",
    Projektnamn: "Mäsinge",
    Verksamhetsutövare: "Håkans Mink AB",
    Organisationsnummer: "556251-1849",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.6,
    Uppmätt_årsproduktion_GWh: 0.98,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Båstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1278-V-003",
    Projektnamn: "",
    Verksamhetsutövare: "Öllevind ek för",
    Organisationsnummer: "716436-7711",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Båstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1278-V-004",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Båstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 01, 2019",
  },
  {
    Områdes_ID: "1278-V-005",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Båstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 01, 2019",
  },
  {
    Områdes_ID: "1278-V-010",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Båstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1278-V-012",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Båstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1278-V-013",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Båstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 01, 2019",
  },
  {
    Områdes_ID: "1278-V-014",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Båstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 01, 2019",
  },
  {
    Områdes_ID: "1280-V-001",
    Projektnamn: "Boel",
    Verksamhetsutövare: "RWE Renewables Sweden AB",
    Organisationsnummer: "556938-6864",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Malmö",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 22, 2021",
  },
  {
    Områdes_ID: "1280-V-003",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Malmö",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 01, 2019",
  },
  {
    Områdes_ID: "1280-V-004",
    Projektnamn: "Norra hamnen",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Malmö",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1281-V-006",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Lund",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1281-V-007",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Lund",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Mar 29, 2019",
  },
  {
    Områdes_ID: "1281-V-010",
    Projektnamn: "Stångby",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Lund",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1281-V-011",
    Projektnamn: "Hansamöllan",
    Verksamhetsutövare: "Lundavind nr 1 Kooperativ ek för",
    Organisationsnummer: "769600-4188",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.15,
    Uppmätt_årsproduktion_GWh: 0.83,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Lund",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1281-V-012",
    Projektnamn: "Värpinge Clara",
    Verksamhetsutövare: "Kraftringen Produktion AB",
    Organisationsnummer: "556475-7119",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.05,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Lund",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1281-V-013",
    Projektnamn: "Hardeberga",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Lund",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1281-V-015",
    Projektnamn: "Hardeberga",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Lund",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1281-V-018",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Lund",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1281-V-021",
    Projektnamn: "Maria",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.4,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Lund",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1282-V-008",
    Projektnamn: "Annelöv",
    Verksamhetsutövare: "Ekovind AB",
    Organisationsnummer: "556343-8208",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 4,
    Uppmätt_årsproduktion_GWh: 4.18,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Landskrona",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1282-V-012",
    Projektnamn: "Norra Möinge",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Landskrona",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1283-V-052",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Helsingborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Mar 29, 2019",
  },
  {
    Områdes_ID: "1283-V-054",
    Projektnamn: "",
    Verksamhetsutövare: "Universal Wind AB",
    Organisationsnummer: "556571-8151",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Helsingborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1283-V-057",
    Projektnamn: "Stureholms Gård",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.38,
    Uppmätt_årsproduktion_GWh: 0.05,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Helsingborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 15, 2019",
  },
  {
    Områdes_ID: "1283-V-058",
    Projektnamn: "Ormastorp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Helsingborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1283-V-059",
    Projektnamn: "Örby",
    Verksamhetsutövare: "Öresundskraft Kraft & Värme AB",
    Organisationsnummer: "556501-1003",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.32,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Helsingborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1283-V-060",
    Projektnamn: "Örby",
    Verksamhetsutövare: "Helsingborgsvind Nr 1 Kooperativ Ek för",
    Organisationsnummer: "769600-9682",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.95,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Helsingborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1283-V-063",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Helsingborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Aug 28, 2019",
  },
  {
    Områdes_ID: "1283-V-064",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Helsingborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1283-V-066",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Helsingborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1283-V-067",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Helsingborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Aug 28, 2019",
  },
  {
    Områdes_ID: "1283-V-068",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Helsingborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1283-V-069",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Helsingborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Mar 29, 2019",
  },
  {
    Områdes_ID: "1283-V-070",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Helsingborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Mar 29, 2019",
  },
  {
    Områdes_ID: "1283-V-071",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Helsingborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Mar 29, 2019",
  },
  {
    Områdes_ID: "1283-V-072",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Helsingborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1283-V-074",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Helsingborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Mar 29, 2019",
  },
  {
    Områdes_ID: "1283-V-081",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Helsingborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1283-V-082",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Helsingborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1283-V-083",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Helsingborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Mar 29, 2019",
  },
  {
    Områdes_ID: "1284-V-001",
    Projektnamn: "Gunnestorp",
    Verksamhetsutövare: "Gunnestorps Miljö Energi AB",
    Organisationsnummer: "556663-8614",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Höganäs",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 22, 2019",
  },
  {
    Områdes_ID: "1284-V-005",
    Projektnamn: "Gunnestorp",
    Verksamhetsutövare: "Spargodt AB",
    Organisationsnummer: "556102-9918",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.87,
    Uppmätt_årsproduktion_GWh: 1.86,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Höganäs",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1284-V-007",
    Projektnamn: "Täppeshusen",
    Verksamhetsutövare: "Ekovind AB",
    Organisationsnummer: "556343-8208",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Höganäs",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1284-V-008",
    Projektnamn: "Täppeshusen",
    Verksamhetsutövare: "Ekovind AB",
    Organisationsnummer: "556343-8208",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Höganäs",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1284-V-009",
    Projektnamn: "Täppeshusen",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Höganäs",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1284-V-012",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Höganäs",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 22, 2019",
  },
  {
    Områdes_ID: "1284-V-013",
    Projektnamn: "Gunnestorp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Höganäs",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 22, 2019",
  },
  {
    Områdes_ID: "1284-V-014",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Höganäs",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1284-V-015",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Höganäs",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1284-V-016",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Höganäs",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1284-V-017",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Höganäs",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 01, 2019",
  },
  {
    Områdes_ID: "1284-V-020",
    Projektnamn: "Ingelsträde",
    Verksamhetsutövare: "Höganäs",
    Organisationsnummer: "212000-1165",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 4.67,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Höganäs",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1285-V-002",
    Projektnamn: "",
    Verksamhetsutövare: "Söderslätts Vind Investment AB",
    Organisationsnummer: "556485-8032",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Eslöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 09, 2019",
  },
  {
    Områdes_ID: "1285-V-004",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Eslöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1285-V-006",
    Projektnamn: "",
    Verksamhetsutövare: "Farina AB",
    Organisationsnummer: "556060-0743",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Eslöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1285-V-008",
    Projektnamn: "",
    Verksamhetsutövare: "Östra Karaby Vind AB",
    Organisationsnummer: "556590-1971",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Eslöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1285-V-012",
    Projektnamn: "Trollenäs",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.6,
    Uppmätt_årsproduktion_GWh: 0.65,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Eslöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1285-V-014",
    Projektnamn: "Backmöllan",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.15,
    Uppmätt_årsproduktion_GWh: 1.01,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Eslöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1285-V-015",
    Projektnamn: "Slättäng I",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.1,
    Uppmätt_årsproduktion_GWh: 1.06,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Eslöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1285-V-016",
    Projektnamn: "Aeolus",
    Verksamhetsutövare: "Hviderups Gods AB",
    Organisationsnummer: "556104-3372",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.85,
    Uppmätt_årsproduktion_GWh: 0.75,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Eslöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1285-V-017",
    Projektnamn: "Ö. Karaby",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.3,
    Uppmätt_årsproduktion_GWh: 1.47,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Eslöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1285-V-024",
    Projektnamn: "Västrabygård",
    Verksamhetsutövare: "Västraby Vindpark AB",
    Organisationsnummer: "556661-8491",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 4.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Eslöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 09, 2020",
  },
  {
    Områdes_ID: "1285-V-030",
    Projektnamn: "Östra Karaby II",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 2.2,
    Uppmätt_årsproduktion_GWh: 2.13,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Eslöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1285-V-031",
    Projektnamn: "Högersröd",
    Verksamhetsutövare: "Västraby Vindpark AB",
    Organisationsnummer: "556661-8491",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5.4,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Eslöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1285-V-032",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Eslöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Nov 30, 2021",
  },
  {
    Områdes_ID: "1285-V-033",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Eslöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1285-V-034",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Eslöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1285-V-035",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Eslöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 01, 2019",
  },
  {
    Områdes_ID: "1285-V-036",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Eslöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 01, 2019",
  },
  {
    Områdes_ID: "1285-V-037",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Eslöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 05, 2019",
  },
  {
    Områdes_ID: "1285-V-038",
    Projektnamn: "Gårdstånga",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Eslöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Oct 09, 2019",
  },
  {
    Områdes_ID: "1285-V-039",
    Projektnamn: "Värlinge gård",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Eslöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1285-V-040",
    Projektnamn: "Värlinge gård",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Eslöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1285-V-041",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Eslöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1285-V-042",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Eslöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1285-V-043",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Eslöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1285-V-044",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Eslöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1285-V-045",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Eslöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 08, 2019",
  },
  {
    Områdes_ID: "1285-V-046",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Eslöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1285-V-047",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Eslöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1285-V-052",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Eslöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 05, 2019",
  },
  {
    Områdes_ID: "1285-V-053",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Eslöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 05, 2019",
  },
  {
    Områdes_ID: "1285-V-054",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Eslöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 01, 2019",
  },
  {
    Områdes_ID: "1285-V-055",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Eslöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 01, 2019",
  },
  {
    Områdes_ID: "1285-V-056",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Eslöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 05, 2019",
  },
  {
    Områdes_ID: "1285-V-057",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Eslöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1285-V-063",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Eslöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 15, 2019",
  },
  {
    Områdes_ID: "1286-V-001",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1286-V-007",
    Projektnamn: "Kadesjö-Gussnava",
    Verksamhetsutövare: "Slitevind AB",
    Organisationsnummer: "556453-2819",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1286-V-015",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1286-V-016",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Aug 29, 2019",
  },
  {
    Områdes_ID: "1286-V-017",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Aug 27, 2019",
  },
  {
    Områdes_ID: "1286-V-018",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Aug 29, 2019",
  },
  {
    Områdes_ID: "1286-V-020",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Aug 29, 2019",
  },
  {
    Områdes_ID: "1286-V-021",
    Projektnamn: "Balkåkra Vind",
    Verksamhetsutövare: "HB Balkåkra Vind",
    Organisationsnummer: "969680-4369",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.5,
    Uppmätt_årsproduktion_GWh: 1.44,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1286-V-022",
    Projektnamn: "Köpingebro",
    Verksamhetsutövare: "Alevind Invest AB",
    Organisationsnummer: "556764-8638",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 26, 2019",
  },
  {
    Områdes_ID: "1286-V-023",
    Projektnamn: "Rynge III",
    Verksamhetsutövare: "Hurva Vind AB",
    Organisationsnummer: "556568-4734",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.35,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1286-V-025",
    Projektnamn: "St Herrestad I",
    Verksamhetsutövare: "BEST Vind i Ystad AB",
    Organisationsnummer: "556547-8897",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1286-V-026",
    Projektnamn: "St Herrestad II",
    Verksamhetsutövare: "BEST Vind i Ystad AB",
    Organisationsnummer: "556547-8897",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1286-V-030",
    Projektnamn: "Ruuthsbo I",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.5,
    Uppmätt_årsproduktion_GWh: 0.86,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1286-V-031",
    Projektnamn: "St. Herrestad",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 26, 2019",
  },
  {
    Områdes_ID: "1286-V-034",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 05, 2019",
  },
  {
    Områdes_ID: "1286-V-036",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1286-V-038",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Aug 29, 2019",
  },
  {
    Områdes_ID: "1286-V-039",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1286-V-041",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Aug 29, 2019",
  },
  {
    Områdes_ID: "1286-V-043",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1286-V-049",
    Projektnamn: "Fröslöv",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1287-V-002",
    Projektnamn: "Hammarlöv",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Trelleborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1287-V-007",
    Projektnamn: "Bösarp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.47,
    Uppmätt_årsproduktion_GWh: 0.36,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Trelleborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1287-V-008",
    Projektnamn: "Isiegården",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.72,
    Uppmätt_årsproduktion_GWh: 1.92,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Trelleborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1287-V-009",
    Projektnamn: "Björkliden",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.49,
    Uppmätt_årsproduktion_GWh: 1.2,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Trelleborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1287-V-010",
    Projektnamn: "Lilla Isie",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.5,
    Uppmätt_årsproduktion_GWh: 0.8,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Trelleborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1287-V-011",
    Projektnamn: "Gislöv II",
    Verksamhetsutövare: "Vindsamfällighetsföreningen Gislöv Ii",
    Organisationsnummer: "716439-1927",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Trelleborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1287-V-012",
    Projektnamn: "Brunshill",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Trelleborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1287-V-013",
    Projektnamn: "Råborg",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.42,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Trelleborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1287-V-015",
    Projektnamn: "Brönnestad-Hönsinge",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.59,
    Uppmätt_årsproduktion_GWh: 1.3,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Trelleborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1287-V-024",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Trelleborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 10, 2019",
  },
  {
    Områdes_ID: "1287-V-026",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Trelleborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 10, 2019",
  },
  {
    Områdes_ID: "1287-V-027",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Trelleborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 10, 2019",
  },
  {
    Områdes_ID: "1287-V-029",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Trelleborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 10, 2019",
  },
  {
    Områdes_ID: "1287-V-030",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Trelleborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1287-V-031",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Trelleborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 10, 2019",
  },
  {
    Områdes_ID: "1287-V-032",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Trelleborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 10, 2019",
  },
  {
    Områdes_ID: "1287-V-033",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Trelleborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 10, 2019",
  },
  {
    Områdes_ID: "1287-V-034",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Trelleborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1287-V-036",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Trelleborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 10, 2019",
  },
  {
    Områdes_ID: "1287-V-037",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Trelleborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 10, 2019",
  },
  {
    Områdes_ID: "1287-V-038",
    Projektnamn: "Margot",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Trelleborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1287-V-039",
    Projektnamn: "St Beddinge",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Trelleborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Oct 07, 2021",
  },
  {
    Områdes_ID: "1287-V-042",
    Projektnamn: "Hemmesdynge",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Trelleborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Nov 30, 2021",
  },
  {
    Områdes_ID: "1287-V-043",
    Projektnamn: "Grönby",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Trelleborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1290-V-038",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1290-V-042",
    Projektnamn: "Nymö",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1290-V-046",
    Projektnamn: "Lyngbygård",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 08, 2019",
  },
  {
    Områdes_ID: "1290-V-048",
    Projektnamn: "Helan Hovby",
    Verksamhetsutövare: "Mr Trend Unlimited Incorporation AB",
    Organisationsnummer: "556305-7263",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.15,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1290-V-049",
    Projektnamn: "Legered",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1290-V-052",
    Projektnamn: "Olseröd",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.16,
    Uppmätt_årsproduktion_GWh: 0.61,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1290-V-063",
    Projektnamn: "Ullstorp-Åkeboda",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 26, 2019",
  },
  {
    Områdes_ID: "1290-V-064",
    Projektnamn: "Öddestad",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1290-V-066",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 12, 2019",
  },
  {
    Områdes_ID: "1290-V-067",
    Projektnamn: "Kiaby",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1290-V-078",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1290-V-079",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1290-V-083",
    Projektnamn: "Tollarpabjär",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 7.3,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1290-V-085",
    Projektnamn: "",
    Verksamhetsutövare: "Billyvind AB",
    Organisationsnummer: "556705-6931",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1290-V-086",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1290-V-089",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1290-V-090",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1290-V-095",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 12, 2019",
  },
  {
    Områdes_ID: "1290-V-097",
    Projektnamn: "Lyngbygård",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1290-V-098",
    Projektnamn: "Karsholm",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1290-V-100",
    Projektnamn: "Råby Gård",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1290-V-107",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1290-V-110",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1291-V-001",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Simrishamn",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 05, 2019",
  },
  {
    Områdes_ID: "1291-V-003",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Simrishamn",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 03, 2019",
  },
  {
    Områdes_ID: "1291-V-004",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Simrishamn",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 03, 2019",
  },
  {
    Områdes_ID: "1291-V-009",
    Projektnamn: "Zavanna",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.38,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Simrishamn",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1291-V-010",
    Projektnamn: "Borrby1",
    Verksamhetsutövare: "Sverige Vindkraftkooperativ Ek för",
    Organisationsnummer: "769603-7089",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 2.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Simrishamn",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1291-V-012",
    Projektnamn: "Månsunen, Gislöv",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.49,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Simrishamn",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 15, 2019",
  },
  {
    Områdes_ID: "1291-V-014",
    Projektnamn: "Byåra",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 2.6,
    Uppmätt_årsproduktion_GWh: 2.74,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Simrishamn",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1291-V-015",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Simrishamn",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1291-V-016",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Simrishamn",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Mar 29, 2019",
  },
  {
    Områdes_ID: "1291-V-020",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Simrishamn",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 15, 2021",
  },
  {
    Områdes_ID: "1291-V-022",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Simrishamn",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 03, 2019",
  },
  {
    Områdes_ID: "1291-V-024",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Simrishamn",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 05, 2019",
  },
  {
    Områdes_ID: "1291-V-025",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Simrishamn",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 03, 2019",
  },
  {
    Områdes_ID: "1291-V-026",
    Projektnamn: "Vranarp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Simrishamn",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1291-V-033",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Simrishamn",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 05, 2019",
  },
  {
    Områdes_ID: "1291-V-035",
    Projektnamn: "Borrby3",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Simrishamn",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 11, 2020",
  },
  {
    Områdes_ID: "1291-V-038",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Simrishamn",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 05, 2019",
  },
  {
    Områdes_ID: "1291-V-039",
    Projektnamn: "Äsperöd",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Simrishamn",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 26, 2019",
  },
  {
    Områdes_ID: "1291-V-041",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Simrishamn",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 03, 2019",
  },
  {
    Områdes_ID: "1291-V-044",
    Projektnamn: "Sandby",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Simrishamn",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1291-V-047",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Simrishamn",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1292-V-007",
    Projektnamn: "Spannarp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ängelholm",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1292-V-010",
    Projektnamn: "Heden",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ängelholm",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 26, 2019",
  },
  {
    Områdes_ID: "1292-V-011",
    Projektnamn: "Ingelstorp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ängelholm",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1292-V-012",
    Projektnamn: "Skörpinge",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.45,
    Uppmätt_årsproduktion_GWh: 1.16,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ängelholm",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1292-V-013",
    Projektnamn: "Össjö",
    Verksamhetsutövare: "Össjö Vind AB",
    Organisationsnummer: "556616-9172",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.6,
    Uppmätt_årsproduktion_GWh: 0.09,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ängelholm",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1292-V-017",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ängelholm",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Oct 10, 2019",
  },
  {
    Områdes_ID: "1292-V-018",
    Projektnamn: "Humlarp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ängelholm",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1292-V-019",
    Projektnamn: "Härninge",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ängelholm",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1292-V-022",
    Projektnamn: "Boarp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ängelholm",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1292-V-025",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ängelholm",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Oct 10, 2019",
  },
  {
    Områdes_ID: "1292-V-027",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ängelholm",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1292-V-031",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ängelholm",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 06, 2019",
  },
  {
    Områdes_ID: "1293-V-003",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hässleholm",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1293-V-007",
    Projektnamn: "Östergård",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Hässleholm",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1293-V-019",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hässleholm",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1214-V-017",
    Projektnamn: "Norra Skrävlinge",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Svalöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "1214-V-019",
    Projektnamn: "Ängalid II, Karlsnäs",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.9,
    Uppmätt_årsproduktion_GWh: 2,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Svalöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1214-V-027",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Svalöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "1214-V-056",
    Projektnamn: "Ängalid IV, Norra Skrävlinge",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 2.2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Svalöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1260-V-005",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Bjuv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "1264-V-022",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Skurup",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1266-V-028",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hörby",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 11, 2019",
  },
  {
    Områdes_ID: "1266-V-033",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Hörby",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 11, 2019",
  },
  {
    Områdes_ID: "1266-V-034",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Hörby",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 11, 2019",
  },
  {
    Områdes_ID: "1270-V-001",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tomelilla",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1276-V-009",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Klippan",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Mar 05, 2019",
  },
  {
    Områdes_ID: "1276-V-011",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Klippan",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Mar 05, 2019",
  },
  {
    Områdes_ID: "1276-V-013",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Klippan",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Mar 05, 2019",
  },
  {
    Områdes_ID: "1276-V-019",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Klippan",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Mar 29, 2019",
  },
  {
    Områdes_ID: "1277-V-010",
    Projektnamn: "Kvidinge",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Åstorp",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Oct 09, 2019",
  },
  {
    Områdes_ID: "1277-V-019",
    Projektnamn: "Kärreberga",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Åstorp",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1277-V-020",
    Projektnamn: "Kärreberga",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Åstorp",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1277-V-022",
    Projektnamn: "Tranarp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Åstorp",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1282-V-002",
    Projektnamn: "Annelöv",
    Verksamhetsutövare: "Annelöv-Norrvidinge Vindsamfällighet",
    Organisationsnummer: "717902-3283",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.8,
    Uppmätt_årsproduktion_GWh: 0.8,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Landskrona",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1282-V-007",
    Projektnamn: "Annelöv 1",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.75,
    Uppmätt_årsproduktion_GWh: 0.75,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Landskrona",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1283-V-061",
    Projektnamn: "Ottarp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Helsingborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 26, 2019",
  },
  {
    Områdes_ID: "1283-V-073",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Helsingborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1285-V-005",
    Projektnamn: "",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Eslöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 09, 2019",
  },
  {
    Områdes_ID: "1285-V-013",
    Projektnamn: "Harriemöllan",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.43,
    Uppmätt_årsproduktion_GWh: 0.97,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Eslöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1285-V-062",
    Projektnamn: "Söderto-Mossarp",
    Verksamhetsutövare: "Billyvind AB",
    Organisationsnummer: "556705-6931",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Eslöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Dec 08, 2021",
  },
  {
    Områdes_ID: "1286-V-033",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 05, 2019",
  },
  {
    Områdes_ID: "1286-V-045",
    Projektnamn: "Örum vindkraftpark",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1286-V-047",
    Projektnamn: "Örum",
    Verksamhetsutövare: "Firma Ramström Vind AB",
    Organisationsnummer: "556688-2683",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1286-V-050",
    Projektnamn: "Fröslöv",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1290-V-093",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 08, 2019",
  },
  {
    Områdes_ID: "1291-V-048",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Simrishamn",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1292-V-016",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ängelholm",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Mar 29, 2019",
  },
  {
    Områdes_ID: "1292-V-033",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ängelholm",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1293-V-013",
    Projektnamn: "Skånes Värsjö",
    Verksamhetsutövare: "BayWa r.e. Scandinavia AB",
    Organisationsnummer: "556885-6503",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hässleholm",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1266-V-027",
    Projektnamn: "Norra Varalöv",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hörby",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "1282-V-001",
    Projektnamn: "",
    Verksamhetsutövare: "Billyvind AB",
    Organisationsnummer: "556705-6931",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Landskrona",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1291-V-046",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Simrishamn",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 03, 2019",
  },
  {
    Områdes_ID: "1256-V-002",
    Projektnamn: "Arkelstorp-Brännskulla",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Östra Göinge",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Nov 30, 2021",
  },
  {
    Områdes_ID: "1272-V-002",
    Projektnamn: "",
    Verksamhetsutövare: "Farina AB",
    Organisationsnummer: "556060-0743",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Bromölla",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1293-V-001",
    Projektnamn: "",
    Verksamhetsutövare: "GiroVind Energi AB",
    Organisationsnummer: "556680-8167",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hässleholm",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 09, 2019",
  },
  {
    Områdes_ID: "1256-V-003",
    Projektnamn: "",
    Verksamhetsutövare: "Billyvind AB",
    Organisationsnummer: "556705-6931",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Östra Göinge",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 16, 2019",
  },
  {
    Områdes_ID: "1257-V-001",
    Projektnamn: "Skånes Värsjö",
    Verksamhetsutövare: "BayWa r.e. Scandinavia AB",
    Organisationsnummer: "556885-6503",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Örkelljunga",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1265-V-021",
    Projektnamn: "Bellinga",
    Verksamhetsutövare: "Bjäre Kraft Energi AB",
    Organisationsnummer: "556527-5020",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Sjöbo",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1267-V-004",
    Projektnamn: "",
    Verksamhetsutövare: "BayWa r.e. Scandinavia AB",
    Organisationsnummer: "556885-6503",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Höör",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1267-V-009",
    Projektnamn: "Trulstorp",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 7,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Höör",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1272-V-006",
    Projektnamn: "",
    Verksamhetsutövare: "Kraftö AB",
    Organisationsnummer: "556708-7456",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Bromölla",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1275-V-004",
    Projektnamn: "",
    Verksamhetsutövare: "Kraftö AB",
    Organisationsnummer: "556708-7456",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Perstorp",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1283-V-051",
    Projektnamn: "Rosendal",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Helsingborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1283-V-079",
    Projektnamn: "Lydinge-Benarp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 6,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Helsingborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1286-V-010",
    Projektnamn: "Karlfältsgården",
    Verksamhetsutövare: "Slitevind AB",
    Organisationsnummer: "556453-2819",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Aug 29, 2019",
  },
  {
    Områdes_ID: "1287-V-020",
    Projektnamn: "Näsbyholm II",
    Verksamhetsutövare: "Universal Power AB",
    Organisationsnummer: "556726-3859",
    Aktuella_verk: 6,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Trelleborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1287-V-022",
    Projektnamn: "Maglarp vindkraftpark",
    Verksamhetsutövare: "Söderslätts Vind Investment AB",
    Organisationsnummer: "556485-8032",
    Aktuella_verk: 5,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 9.42,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Trelleborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Jun 10, 2020",
  },
  {
    Områdes_ID: "1290-V-073",
    Projektnamn: "Ullstorp-Åkeboda",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 15, 2021",
  },
  {
    Områdes_ID: "1291-V-028",
    Projektnamn: "Sjunkalotten",
    Verksamhetsutövare: "Bjäre Kraft Energi AB",
    Organisationsnummer: "556527-5020",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Simrishamn",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1292-V-001",
    Projektnamn: "Vegeholm",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ängelholm",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 15, 2021",
  },
  {
    Områdes_ID: "1293-V-011",
    Projektnamn: "",
    Verksamhetsutövare: "OX2 AB",
    Organisationsnummer: "556675-7497",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hässleholm",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 17, 2019",
  },
  {
    Områdes_ID: "1293-V-012",
    Projektnamn: "",
    Verksamhetsutövare: "BayWa r.e. Scandinavia AB",
    Organisationsnummer: "556885-6503",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hässleholm",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1293-V-018",
    Projektnamn: "",
    Verksamhetsutövare: "Billyvind AB",
    Organisationsnummer: "556705-6931",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hässleholm",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 30, 2019",
  },
  {
    Områdes_ID: "1293-V-023",
    Projektnamn: "",
    Verksamhetsutövare: "Billyvind AB",
    Organisationsnummer: "556705-6931",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hässleholm",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1293-V-026",
    Projektnamn: "Häringstorp",
    Verksamhetsutövare: "OX2 AB",
    Organisationsnummer: "556675-7497",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hässleholm",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1287-V-041",
    Projektnamn: "Åkebo",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Trelleborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1290-V-101",
    Projektnamn: "Ovesholm",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1293-V-004",
    Projektnamn: "Åraslöv",
    Verksamhetsutövare: "Billyvind AB",
    Organisationsnummer: "556705-6931",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Hässleholm",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Aug 28, 2019",
  },
  {
    Områdes_ID: "1214-V-013",
    Projektnamn: "Tirup",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Svalöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 05, 2019",
  },
  {
    Områdes_ID: "1214-V-014",
    Projektnamn: "Halmstadgården",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Svalöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 05, 2019",
  },
  {
    Områdes_ID: "1214-V-041",
    Projektnamn: "Svalöv",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Svalöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1214-V-047",
    Projektnamn: "Knutstorp",
    Verksamhetsutövare: "Knutstorps Skog AB",
    Organisationsnummer: "556409-8662",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Svalöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 05, 2019",
  },
  {
    Områdes_ID: "1214-V-048",
    Projektnamn: "Duveke vindkraftpark",
    Verksamhetsutövare: "Arise Windpower AB",
    Organisationsnummer: "556274-6726",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Svalöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 05, 2019",
  },
  {
    Områdes_ID: "1214-V-049",
    Projektnamn: "Knutstorp",
    Verksamhetsutövare: "Knutstorps Skog AB",
    Organisationsnummer: "556409-8662",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Svalöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 05, 2019",
  },
  {
    Områdes_ID: "1256-V-004",
    Projektnamn: "",
    Verksamhetsutövare: "Billyvind AB",
    Organisationsnummer: "556705-6931",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Östra Göinge",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 16, 2019",
  },
  {
    Områdes_ID: "1256-V-005",
    Projektnamn: "Uddarp",
    Verksamhetsutövare: "Billyvind AB",
    Organisationsnummer: "556705-6931",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Östra Göinge",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1261-V-018",
    Projektnamn: "Södervidinge",
    Verksamhetsutövare: "Universal Wind AB",
    Organisationsnummer: "556571-8151",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kävlinge",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "1263-V-008",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Svedala",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 01, 2019",
  },
  {
    Områdes_ID: "1263-V-014",
    Projektnamn: "Skabersjö vindkraftpark",
    Verksamhetsutövare: "RWE Renewables Sweden AB",
    Organisationsnummer: "556938-6864",
    Aktuella_verk: 5,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 28,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Svedala",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 22, 2021",
  },
  {
    Områdes_ID: "1264-V-015",
    Projektnamn: "Rydsgårds vindkraftpark",
    Verksamhetsutövare: "Arise Windpower AB",
    Organisationsnummer: "556274-6726",
    Aktuella_verk: 9,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Skurup",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1265-V-020",
    Projektnamn: "Ågerup",
    Verksamhetsutövare: "BayWa r.e. Scandinavia AB",
    Organisationsnummer: "556885-6503",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Sjöbo",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1270-V-008",
    Projektnamn: "Bondrum-Bontofta",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tomelilla",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1270-V-010",
    Projektnamn: "Vallsås",
    Verksamhetsutövare: "Fred Olsen Renewables AB",
    Organisationsnummer: "556591-2077",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 2.7,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tomelilla",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 08, 2020",
  },
  {
    Områdes_ID: "1270-V-033",
    Projektnamn: "Bondrum-Bontofta",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tomelilla",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "1270-V-036",
    Projektnamn: "Munka-Tågarp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tomelilla",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 29, 2019",
  },
  {
    Områdes_ID: "1275-V-003",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Perstorp",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Mar 29, 2019",
  },
  {
    Områdes_ID: "1276-V-020",
    Projektnamn: "",
    Verksamhetsutövare: "PEAB Anläggning AB",
    Organisationsnummer: "556568-6721",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Klippan",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1278-V-009",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Båstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1278-V-015",
    Projektnamn: "Frestensfälla",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Båstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1280-V-002",
    Projektnamn: "Lillgrund Vindkraftpark",
    Verksamhetsutövare: "Vattenfall Vindkraft AB",
    Organisationsnummer: "556581-4273",
    Aktuella_verk: 48,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 329.76,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Malmö",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Oct 17, 2019",
  },
  {
    Områdes_ID: "1282-V-004",
    Projektnamn: "Vindön",
    Verksamhetsutövare: "Econergi AB",
    Organisationsnummer: "559221-2947",
    Aktuella_verk: 12,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 16.44,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Landskrona",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Aug 20, 2020",
  },
  {
    Områdes_ID: "1282-V-006",
    Projektnamn: "Lundåkra",
    Verksamhetsutövare: "RWE Renewables Sweden AB",
    Organisationsnummer: "556938-6864",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 25,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Landskrona",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 22, 2021",
  },
  {
    Områdes_ID: "1283-V-056",
    Projektnamn: "Rögle",
    Verksamhetsutövare: "Akka Vind AB",
    Organisationsnummer: "556711-2015",
    Aktuella_verk: 9,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 54.16,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Helsingborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 08, 2020",
  },
  {
    Områdes_ID: "1283-V-076",
    Projektnamn: "Västraby",
    Verksamhetsutövare: "Söderslätts Vind Investment AB",
    Organisationsnummer: "556485-8032",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Helsingborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1283-V-080",
    Projektnamn: "Västraby",
    Verksamhetsutövare: "Västraby Gård Energi AB",
    Organisationsnummer: "556713-8408",
    Aktuella_verk: 8,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 38.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Helsingborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 08, 2020",
  },
  {
    Områdes_ID: "1283-V-085",
    Projektnamn: "Pilshult-Allerum",
    Verksamhetsutövare: "Solid Vind AB",
    Organisationsnummer: "556780-7572",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Helsingborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1283-V-087",
    Projektnamn: "Väla",
    Verksamhetsutövare: "Öresundskraft Kraft & Värme AB",
    Organisationsnummer: "556501-1003",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Helsingborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Dec 08, 2021",
  },
  {
    Områdes_ID: "1284-V-021",
    Projektnamn: "Rågåkra",
    Verksamhetsutövare: "Bjäre Kraft Energi AB",
    Organisationsnummer: "556527-5020",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 17,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Höganäs",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1285-V-018",
    Projektnamn: "Tängelsås",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5.4,
    Uppmätt_årsproduktion_GWh: 4.81,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Eslöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1285-V-020",
    Projektnamn: "Skarhult",
    Verksamhetsutövare: "Blåshult AB",
    Organisationsnummer: "556043-5587",
    Aktuella_verk: 8,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 25.4,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Eslöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 09, 2020",
  },
  {
    Områdes_ID: "1285-V-050",
    Projektnamn: "Viderup-Toftaholm",
    Verksamhetsutövare: "Borlunda Kraft AB",
    Organisationsnummer: "556655-8853",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Eslöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1285-V-059",
    Projektnamn: "",
    Verksamhetsutövare: "BayWa r.e. Scandinavia AB",
    Organisationsnummer: "556885-6503",
    Aktuella_verk: 9,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Eslöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1286-V-011",
    Projektnamn: "Marsvinsholm",
    Verksamhetsutövare: "Slitevind AB",
    Organisationsnummer: "556453-2819",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Aug 29, 2019",
  },
  {
    Områdes_ID: "1286-V-013",
    Projektnamn: "Marsvinsholm",
    Verksamhetsutövare: "Sydsvenska Vind i Skurup AB",
    Organisationsnummer: "556556-8226",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 15, 2021",
  },
  {
    Områdes_ID: "1287-V-021",
    Projektnamn: "",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 5,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Trelleborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1290-V-037",
    Projektnamn: "Karsholm",
    Verksamhetsutövare: "Karsholms Gods AB",
    Organisationsnummer: "556051-9554",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1290-V-039",
    Projektnamn: "Vittskövle",
    Verksamhetsutövare: "BayWa r.e. Scandinavia AB",
    Organisationsnummer: "556885-6503",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1290-V-043",
    Projektnamn: "Karsholm",
    Verksamhetsutövare: "Karsholms Gods AB",
    Organisationsnummer: "556051-9554",
    Aktuella_verk: 5,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 28,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 09, 2020",
  },
  {
    Områdes_ID: "1290-V-044",
    Projektnamn: "Isgrannatorp",
    Verksamhetsutövare: "Isgrannatorp Drift AB",
    Organisationsnummer: "556787-6833",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 13.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 09, 2020",
  },
  {
    Områdes_ID: "1290-V-047",
    Projektnamn: "Ovesholm",
    Verksamhetsutövare: "BayWa r.e. Scandinavia AB",
    Organisationsnummer: "556885-6503",
    Aktuella_verk: 7,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1290-V-057",
    Projektnamn: "Råbelöv",
    Verksamhetsutövare: "Råbelöfs fideikommiss AB",
    Organisationsnummer: "556578-4567",
    Aktuella_verk: 5,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 26.4,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 09, 2020",
  },
  {
    Områdes_ID: "1290-V-069",
    Projektnamn: "Åhus-Ripa",
    Verksamhetsutövare: "Kraftö AB",
    Organisationsnummer: "556708-7456",
    Aktuella_verk: 6,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1290-V-070",
    Projektnamn: "Höge Väg Vindkraftpark",
    Verksamhetsutövare: "Vattenfall Vindkraft AB",
    Organisationsnummer: "556581-4273",
    Aktuella_verk: 18,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 106.92,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 09, 2020",
  },
  {
    Områdes_ID: "1290-V-071",
    Projektnamn: "Fegelstorp",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 5,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1290-V-077",
    Projektnamn: "Borrestad-Tolseröd",
    Verksamhetsutövare: "BayWa r.e. Scandinavia AB",
    Organisationsnummer: "556885-6503",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 15, 2021",
  },
  {
    Områdes_ID: "1290-V-091",
    Projektnamn: "Träne Örmatofta",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1290-V-094",
    Projektnamn: "Ovesholm",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1291-V-002",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Simrishamn",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Jan 18, 2019",
  },
  {
    Områdes_ID: "1291-V-013",
    Projektnamn: "Östra Herrestad Vindkraftpark",
    Verksamhetsutövare: "Rabbalshede Vind AB",
    Organisationsnummer: "556872-2879",
    Aktuella_verk: 9,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 57.96,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Simrishamn",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Dec 10, 2020",
  },
  {
    Områdes_ID: "1291-V-031",
    Projektnamn: "",
    Verksamhetsutövare: "Wede Växt AB",
    Organisationsnummer: "556279-4346",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Simrishamn",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1292-V-004",
    Projektnamn: "Össjö Skog",
    Verksamhetsutövare: "Firma Ramström Vind AB",
    Organisationsnummer: "556688-2683",
    Aktuella_verk: 6,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 37.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ängelholm",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Oct 06, 2021",
  },
  {
    Områdes_ID: "1293-V-008",
    Projektnamn: "Häringstorp",
    Verksamhetsutövare: "OX2 AB",
    Organisationsnummer: "556675-7497",
    Aktuella_verk: 10,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hässleholm",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1293-V-014",
    Projektnamn: "",
    Verksamhetsutövare: "Statkraft Södra Vindarrende AB",
    Organisationsnummer: "556091-8772",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hässleholm",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 17, 2019",
  },
  {
    Områdes_ID: "1293-V-015",
    Projektnamn: "",
    Verksamhetsutövare: "Statkraft Södra Vindarrende AB",
    Organisationsnummer: "556091-8772",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hässleholm",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 17, 2019",
  },
  {
    Områdes_ID: "1293-V-017",
    Projektnamn: "Röke-Algustorp",
    Verksamhetsutövare: "Algustorp Vindkraft AB",
    Organisationsnummer: "556755-4802",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hässleholm",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1293-V-020",
    Projektnamn: "Ballingslöv",
    Verksamhetsutövare: "Svenska Vindbolaget AB",
    Organisationsnummer: "556759-9013",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hässleholm",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 17, 2019",
  },
  {
    Områdes_ID: "1293-V-021",
    Projektnamn: "Nävlingeåsen",
    Verksamhetsutövare: "BayWa r.e. Scandinavia AB",
    Organisationsnummer: "556885-6503",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hässleholm",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Mar 18, 2019",
  },
  {
    Områdes_ID: "1293-V-022",
    Projektnamn: "",
    Verksamhetsutövare: "Statkraft Södra Vindkraft AB",
    Organisationsnummer: "556785-3873",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hässleholm",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 17, 2019",
  },
  {
    Områdes_ID: "1373-V-002",
    Projektnamn: "Loshult",
    Verksamhetsutövare: "Arise Windpower AB",
    Organisationsnummer: "556274-6726",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Osby",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0509-V-019",
    Projektnamn: "Hästholmen",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ödeshög",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0509-V-020",
    Projektnamn: "Hästholmen",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ödeshög",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0509-V-021",
    Projektnamn: "Millingstorp",
    Verksamhetsutövare: "Mill Wind AB",
    Organisationsnummer: "556543-0047",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 1.8,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ödeshög",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 26, 2019",
  },
  {
    Områdes_ID: "0509-V-022",
    Projektnamn: "Krokek",
    Verksamhetsutövare: "Krokek Vind AB",
    Organisationsnummer: "556751-5290",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ödeshög",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 25, 2019",
  },
  {
    Områdes_ID: "0509-V-023",
    Projektnamn: "Valla Vind",
    Verksamhetsutövare: "Valla Säteri",
    Organisationsnummer: "923000-5150",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.7,
    Uppmätt_årsproduktion_GWh: 1.58,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ödeshög",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0509-V-024",
    Projektnamn: "",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ödeshög",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0509-V-026",
    Projektnamn: "",
    Verksamhetsutövare: "Triventus AB",
    Organisationsnummer: "556627-3016",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ödeshög",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0509-V-028",
    Projektnamn: "",
    Verksamhetsutövare: "Krokek Vind AB",
    Organisationsnummer: "556751-5290",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ödeshög",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0834-V-011",
    Projektnamn: "Ekelunda",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.7,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Torsås",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 22, 2019",
  },
  {
    Områdes_ID: "0834-V-013",
    Projektnamn: "Susekulla",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.1,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Torsås",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0834-V-014",
    Projektnamn: "Skorrö",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Torsås",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 06, 2019",
  },
  {
    Områdes_ID: "0834-V-016",
    Projektnamn: "Kvilla",
    Verksamhetsutövare: "Firma Ramström Vind AB",
    Organisationsnummer: "556688-2683",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Torsås",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "0834-V-025",
    Projektnamn: "Ekaryd 1",
    Verksamhetsutövare: "Blade Power AB",
    Organisationsnummer: "556587-1299",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Torsås",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 22, 2019",
  },
  {
    Områdes_ID: "0834-V-026",
    Projektnamn: "Kroka 1",
    Verksamhetsutövare: "GiroVind Energi AB",
    Organisationsnummer: "556680-8167",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.7,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Torsås",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0834-V-027",
    Projektnamn: "Kroka 2",
    Verksamhetsutövare: "Kalmar Energi Försäljning AB",
    Organisationsnummer: "556548-4317",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.9,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Torsås",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0834-V-028",
    Projektnamn: "Gunnarstorp",
    Verksamhetsutövare: "Kalmar Energi Försäljning AB",
    Organisationsnummer: "556548-4317",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.9,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Torsås",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0834-V-029",
    Projektnamn: "Ekaryd 2",
    Verksamhetsutövare: "Blade Power AB",
    Organisationsnummer: "556587-1299",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Torsås",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 22, 2019",
  },
  {
    Områdes_ID: "0885-V-013",
    Projektnamn: "Greby",
    Verksamhetsutövare: "Karsten Hansens AB Petitum",
    Organisationsnummer: "302281-1032",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.65,
    Uppmätt_årsproduktion_GWh: 1.29,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Borgholm",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0885-V-014",
    Projektnamn: "Jämjö",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.55,
    Uppmätt_årsproduktion_GWh: 0.64,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Borgholm",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0885-V-015",
    Projektnamn: "Laxeby",
    Verksamhetsutövare: "Pontus Olofsson Energi AB",
    Organisationsnummer: "556449-6387",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Borgholm",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 22, 2019",
  },
  {
    Områdes_ID: "0885-V-016",
    Projektnamn: "Långlöt",
    Verksamhetsutövare: "EMFI Energi AB",
    Organisationsnummer: "556177-9843",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.5,
    Uppmätt_årsproduktion_GWh: 1.67,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Borgholm",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0885-V-021",
    Projektnamn: "Stenninge",
    Verksamhetsutövare: "Helianthemum Vind AB",
    Organisationsnummer: "556650-9799",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.9,
    Uppmätt_årsproduktion_GWh: 1.71,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Borgholm",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0885-V-025",
    Projektnamn: "Byrum",
    Verksamhetsutövare: "Cloudberry Offshore Wind AB",
    Organisationsnummer: "556710-7403",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Borgholm",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0885-V-026",
    Projektnamn: "Böda Torp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Borgholm",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Aug 27, 2019",
  },
  {
    Områdes_ID: "0885-V-028",
    Projektnamn: "Gärdslösa",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5.1,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Borgholm",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0885-V-033",
    Projektnamn: "Mellböda",
    Verksamhetsutövare: "Cloudberry Offshore Wind AB",
    Organisationsnummer: "556710-7403",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Borgholm",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0885-V-045",
    Projektnamn: "Sammelstorp",
    Verksamhetsutövare: "Firma Ramström Vind AB",
    Organisationsnummer: "556688-2683",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Borgholm",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "0885-V-038",
    Projektnamn: "Stenninge",
    Verksamhetsutövare: "Helianthemum Vind AB",
    Organisationsnummer: "556650-9799",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Borgholm",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1286-V-053",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Aug 29, 2019",
  },
  {
    Områdes_ID: "1286-V-054",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1286-V-055",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Aug 27, 2019",
  },
  {
    Områdes_ID: "1286-V-058",
    Projektnamn: "",
    Verksamhetsutövare: "Slitevind AB",
    Organisationsnummer: "556453-2819",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1286-V-063",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Aug 29, 2019",
  },
  {
    Områdes_ID: "1286-V-066",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1286-V-067",
    Projektnamn: "",
    Verksamhetsutövare: "Gårdsvind AB",
    Organisationsnummer: "556820-5081",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1286-V-070",
    Projektnamn: "",
    Verksamhetsutövare: "Slitevind AB",
    Organisationsnummer: "556453-2819",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Aug 29, 2019",
  },
  {
    Områdes_ID: "1286-V-071",
    Projektnamn: "",
    Verksamhetsutövare: "Slitevind AB",
    Organisationsnummer: "556453-2819",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Aug 29, 2019",
  },
  {
    Områdes_ID: "1286-V-072",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Aug 29, 2019",
  },
  {
    Områdes_ID: "1286-V-073",
    Projektnamn: "",
    Verksamhetsutövare: "Firma Ramström Vind AB",
    Organisationsnummer: "556688-2683",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1286-V-074",
    Projektnamn: "",
    Verksamhetsutövare: "Slitevind AB",
    Organisationsnummer: "556453-2819",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1286-V-075",
    Projektnamn: "Hagestad",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 26, 2019",
  },
  {
    Områdes_ID: "1286-V-078",
    Projektnamn: "",
    Verksamhetsutövare: "Tackel & Tåg, Lars Nyman HB",
    Organisationsnummer: "916614-9832",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1286-V-083",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1286-V-085",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1286-V-086",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1286-V-087",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1286-V-092",
    Projektnamn: "",
    Verksamhetsutövare: "Slitevind AB",
    Organisationsnummer: "556453-2819",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1286-V-093",
    Projektnamn: "",
    Verksamhetsutövare: "Slitevind AB",
    Organisationsnummer: "556453-2819",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1286-V-105",
    Projektnamn: "",
    Verksamhetsutövare: "Slitevind AB",
    Organisationsnummer: "556453-2819",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1286-V-109",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Aug 29, 2019",
  },
  {
    Områdes_ID: "1286-V-113",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Aug 29, 2019",
  },
  {
    Områdes_ID: "1286-V-114",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1286-V-115",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1291-V-055",
    Projektnamn: "Borrby2",
    Verksamhetsutövare: "Raftastens jordbruk",
    Organisationsnummer: "556394-3181",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 2.6,
    Uppmätt_årsproduktion_GWh: 2.74,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Simrishamn",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1291-V-059",
    Projektnamn: "Tåghusa",
    Verksamhetsutövare: "Tåghusa 1:42 AB",
    Organisationsnummer: "556540-2285",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Simrishamn",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1291-V-063",
    Projektnamn: "Borrby2",
    Verksamhetsutövare: "WindEn sweden AB",
    Organisationsnummer: "556766-8040",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Simrishamn",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 05, 2019",
  },
  {
    Områdes_ID: "2480-V-016",
    Projektnamn: "Holmsund",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5.5,
    Uppmätt_årsproduktion_GWh: 5.85,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Umeå",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2480-V-017",
    Projektnamn: "Holmsund",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.1,
    Uppmätt_årsproduktion_GWh: 0.41,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Umeå",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0781-V-017",
    Projektnamn: "Skäckarp",
    Verksamhetsutövare: "Statkraft Södra Vindkraft AB",
    Organisationsnummer: "556785-3873",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ljungby",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0781-V-029",
    Projektnamn: "Staverhult",
    Verksamhetsutövare: "SR Energy AB",
    Organisationsnummer: "556711-9549",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "Jan 01, 2025",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ljungby",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "Jan 18, 2022",
  },
  {
    Områdes_ID: "0834-V-018",
    Projektnamn: "Kvilla 1",
    Verksamhetsutövare: "Greenextreme AB",
    Organisationsnummer: "556722-4596",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 22,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Torsås",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Jan 11, 2019",
  },
  {
    Områdes_ID: "0834-V-020",
    Projektnamn: "Gettnabo 1",
    Verksamhetsutövare: "Arise Windfarm 5 AB",
    Organisationsnummer: "556758-8982",
    Aktuella_verk: 6,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 30,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Torsås",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0834-V-022",
    Projektnamn: "Strömby västra",
    Verksamhetsutövare: "Åkesson & Bjerknaes AB",
    Organisationsnummer: "556798-1310",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Torsås",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "0834-V-023",
    Projektnamn: "Vetlycke",
    Verksamhetsutövare: "Svenska Vindbolaget AB",
    Organisationsnummer: "556759-9013",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Torsås",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Jan 10, 2022",
  },
  {
    Områdes_ID: "0885-V-012",
    Projektnamn: "Gärdslösa",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 5,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 25.5,
    Uppmätt_årsproduktion_GWh: 21.45,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Borgholm",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0885-V-019",
    Projektnamn: "Räpplinge",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 20.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Borgholm",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0885-V-022",
    Projektnamn: "Stora Istad",
    Verksamhetsutövare: "Istad Wind Power Management AB",
    Organisationsnummer: "556680-5676",
    Aktuella_verk: 7,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 44,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Borgholm",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0885-V-024",
    Projektnamn: "Arbelunda",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Borgholm",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Jan 15, 2019",
  },
  {
    Områdes_ID: "0885-V-031",
    Projektnamn: "Lerkaka",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 5,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 24,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Borgholm",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0885-V-035",
    Projektnamn: "Nedre Vannborga",
    Verksamhetsutövare: "Kalmar Energi Försäljning AB",
    Organisationsnummer: "556548-4317",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Borgholm",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "0885-V-036",
    Projektnamn: "Persnäs- Hallnäs",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Borgholm",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "0885-V-041",
    Projektnamn: "Norrby",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Borgholm",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "0885-V-043",
    Projektnamn: "Böda Kronopark",
    Verksamhetsutövare: "RWE Renewables Sweden AB",
    Organisationsnummer: "556938-6864",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Borgholm",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 22, 2021",
  },
  {
    Områdes_ID: "1291-V-067",
    Projektnamn: "Gyllebo",
    Verksamhetsutövare: "Triventus AB",
    Organisationsnummer: "556627-3016",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Simrishamn",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2284-V-015",
    Projektnamn: "Ava",
    Verksamhetsutövare: "Kraftö AB",
    Organisationsnummer: "556708-7456",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Örnsköldsvik",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2303-V-001",
    Projektnamn: "Granåsen",
    Verksamhetsutövare: "Jämtvind AB",
    Organisationsnummer: "556784-8808",
    Aktuella_verk: 6,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 36,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ragunda",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Aug 02, 2021",
  },
  {
    Områdes_ID: "2303-V-002",
    Projektnamn: "Storrisberget",
    Verksamhetsutövare: "Jämtvind AB",
    Organisationsnummer: "556784-8808",
    Aktuella_verk: 6,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 42,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ragunda",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Dec 02, 2021",
  },
  {
    Områdes_ID: "2303-V-003",
    Projektnamn: "Knulen",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ragunda",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Dec 07, 2021",
  },
  {
    Områdes_ID: "2401-V-004",
    Projektnamn: "Ava",
    Verksamhetsutövare: "Kraftö AB",
    Organisationsnummer: "556708-7456",
    Aktuella_verk: 17,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 187,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Nordmaling",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2401-V-005",
    Projektnamn: "Gabrielsberget Väst",
    Verksamhetsutövare: "Gabrielsberget Vind AB",
    Organisationsnummer: "556710-9300",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Nordmaling",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "2401-V-006",
    Projektnamn: "Stenberg",
    Verksamhetsutövare: "Kraftö AB",
    Organisationsnummer: "556708-7456",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Nordmaling",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "2404-V-001",
    Projektnamn: "Fäbodliden",
    Verksamhetsutövare: "Fäbodliden Vindkraft AB",
    Organisationsnummer: "556890-2646",
    Aktuella_verk: 24,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 164.88,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vindeln",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "Oct 05, 2021",
  },
  {
    Områdes_ID: "2425-V-002",
    Projektnamn: "Bliekevare",
    Verksamhetsutövare: "EnBW Sverige Vind AB",
    Organisationsnummer: "559132-8884",
    Aktuella_verk: 18,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 93.96,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Dorotea",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "Oct 05, 2021",
  },
  {
    Områdes_ID: "2463-V-005",
    Projektnamn: "Vindkraftpark Kvällåliden",
    Verksamhetsutövare: "RWE Renewables Sweden AB",
    Organisationsnummer: "556938-6864",
    Aktuella_verk: 34,
    Antal_ej_koordinatsatta_verk: 5,
    Beräknad_årsproduktion_GWh: 243.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Åsele",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "Dec 02, 2021",
  },
  {
    Områdes_ID: "2463-V-006",
    Projektnamn: "Vindkraftpark Bäckaskog",
    Verksamhetsutövare: "Nordex Sverige AB",
    Organisationsnummer: "556756-9792",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Åsele",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2480-V-013",
    Projektnamn: "Högaliden",
    Verksamhetsutövare: "Högaliden Vindkraft AB",
    Organisationsnummer: "559118-3768",
    Aktuella_verk: 25,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 350,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "Dec 01, 2021",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Umeå",
    Län: "Västerbottens län",
    Elområde: "Luleå",
    Senast_sparad: "Dec 10, 2021",
  },
  {
    Områdes_ID: "2480-V-015",
    Projektnamn: "Täfteå",
    Verksamhetsutövare: "Kraftö AB",
    Organisationsnummer: "556708-7456",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Umeå",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "Mar 23, 2021",
  },
  {
    Områdes_ID: "2481-V-001",
    Projektnamn: "Hornmyran",
    Verksamhetsutövare: "RES Renewable Norden AB",
    Organisationsnummer: "556616-0684",
    Aktuella_verk: 9,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 90,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Lycksele",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "Sep 10, 2021",
  },
  {
    Områdes_ID: "2481-V-003",
    Projektnamn: "Vinliden",
    Verksamhetsutövare: "Vinliden Vindkraft AB",
    Organisationsnummer: "556793-0382",
    Aktuella_verk: 11,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Lycksele",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "Mar 23, 2021",
  },
  {
    Områdes_ID: "2480-V-014",
    Projektnamn: "Petlandskär",
    Verksamhetsutövare: "Petlandskär Vind AB",
    Organisationsnummer: "556708-9841",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Umeå",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2283-V-007",
    Projektnamn: "Västvattnet",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Sollefteå",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "Oct 08, 2019",
  },
  {
    Områdes_ID: "2305-V-016",
    Projektnamn: "Mörttjärnberget",
    Verksamhetsutövare: "Statkraft SCA Vind AB",
    Organisationsnummer: "556706-3507",
    Aktuella_verk: 37,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 279.72,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Bräcke",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2361-V-025",
    Projektnamn: "Garpkölen",
    Verksamhetsutövare: "OX2 AB",
    Organisationsnummer: "556675-7497",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 26,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Härjedalen",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "2321-V-010",
    Projektnamn: "Moskogen",
    Verksamhetsutövare: "JP Vind AB",
    Organisationsnummer: "556754-9174",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 50,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Åre",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Feb 08, 2021",
  },
  {
    Områdes_ID: "2309-V-004",
    Projektnamn: "Råshön",
    Verksamhetsutövare: "OX2 AB",
    Organisationsnummer: "556675-7497",
    Aktuella_verk: 8,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 32.25,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Krokom",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Jun 01, 2020",
  },
  {
    Områdes_ID: "2309-V-005",
    Projektnamn: "Storrun",
    Verksamhetsutövare: "Storrun Vindkraft AB",
    Organisationsnummer: "556697-5313",
    Aktuella_verk: 12,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 80.04,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Krokom",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Oct 07, 2021",
  },
  {
    Områdes_ID: "2305-V-009",
    Projektnamn: "Bröcklingsberget 1",
    Verksamhetsutövare: "FP Lux Wind Bröcklingberget AB",
    Organisationsnummer: "556907-6887",
    Aktuella_verk: 5,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 70,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "May 01, 2020",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Bräcke",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Sep 08, 2021",
  },
  {
    Områdes_ID: "2305-V-010",
    Projektnamn: "Bröcklingsberget 2",
    Verksamhetsutövare: "FP Lux Wind Bröcklingberget AB",
    Organisationsnummer: "556907-6887",
    Aktuella_verk: 6,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 84,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "May 01, 2020",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Bräcke",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Sep 08, 2021",
  },
  {
    Områdes_ID: "2305-V-015",
    Projektnamn: "Midsommarberget",
    Verksamhetsutövare: "RWE Renewables Sweden AB",
    Organisationsnummer: "556938-6864",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Bräcke",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Feb 22, 2021",
  },
  {
    Områdes_ID: "2313-V-014",
    Projektnamn: "Havsnäs",
    Verksamhetsutövare: "Havsnäs Vindkraft AB",
    Organisationsnummer: "556707-2839",
    Aktuella_verk: 48,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 250.51,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Strömsund",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Oct 07, 2021",
  },
  {
    Områdes_ID: "2361-V-030",
    Projektnamn: "Rodovålen",
    Verksamhetsutövare: "Agrivind AB",
    Organisationsnummer: "556242-1387",
    Aktuella_verk: 6,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 26.7,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Härjedalen",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Oct 01, 2020",
  },
  {
    Områdes_ID: "2361-V-027",
    Projektnamn: "Långåvålen",
    Verksamhetsutövare: "Agrivind AB",
    Organisationsnummer: "556242-1387",
    Aktuella_verk: 7,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 37.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Härjedalen",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Oct 01, 2020",
  },
  {
    Områdes_ID: "2321-V-007",
    Projektnamn: "Gråsjön",
    Verksamhetsutövare: "Kallbygdens ekonomiska förening",
    Organisationsnummer: "769602-6439",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 25,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Åre",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2326-V-014",
    Projektnamn: "Middagsberget",
    Verksamhetsutövare: "Middagsberget Vindkraft AB",
    Organisationsnummer: "556731-8950",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 24.99,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Berg",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2305-V-011",
    Projektnamn: "Gårdsjöberget",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Bräcke",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Apr 02, 2019",
  },
  {
    Områdes_ID: "2326-V-013",
    Projektnamn: "Högberget",
    Verksamhetsutövare: "RWE Renewables Sweden AB",
    Organisationsnummer: "556938-6864",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Berg",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Feb 22, 2021",
  },
  {
    Områdes_ID: "2361-V-035",
    Projektnamn: "Ängersjökölen",
    Verksamhetsutövare: "Ängersjökölen Vindkraft AB",
    Organisationsnummer: "556805-7631",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Härjedalen",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Sep 10, 2021",
  },
  {
    Områdes_ID: "2361-V-028",
    Projektnamn: "Norderåsen",
    Verksamhetsutövare: "Vindvision Norr AB",
    Organisationsnummer: "556719-9699",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Härjedalen",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Mar 24, 2021",
  },
  {
    Områdes_ID: "2313-V-021",
    Projektnamn: "Tåsjöberget",
    Verksamhetsutövare: "RES Renewable Norden AB",
    Organisationsnummer: "556616-0684",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 45,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Strömsund",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2321-V-009",
    Projektnamn: "Klocka",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 25,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Åre",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2321-V-011",
    Projektnamn: "Tångböle",
    Verksamhetsutövare: "Torsburgen Vind AB",
    Organisationsnummer: "556728-1489",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 50,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Åre",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2361-V-032",
    Projektnamn: "Skaftåsen",
    Verksamhetsutövare: "Arise Windpower AB",
    Organisationsnummer: "556274-6726",
    Aktuella_verk: 35,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 145.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Nov 01, 2019",
    Planerat_drifttagande: "Dec 01, 2021",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Härjedalen",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Sep 09, 2021",
  },
  {
    Områdes_ID: "2305-V-014",
    Projektnamn: "Lövhögen",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Bräcke",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Sep 09, 2019",
  },
  {
    Områdes_ID: "2361-V-024",
    Projektnamn: "Digeråsen",
    Verksamhetsutövare: "Vindvision Norr AB",
    Organisationsnummer: "556719-9699",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Härjedalen",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2305-V-017",
    Projektnamn: "Nyhemsmanen",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 10,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Bräcke",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "2305-V-018",
    Projektnamn: "Nyhemsmanen-Storberget",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Bräcke",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Apr 02, 2019",
  },
  {
    Områdes_ID: "2380-V-004",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 75,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Östersund",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2303-V-009",
    Projektnamn: "Lill-Villflon",
    Verksamhetsutövare: "Väktaren Vind AB",
    Organisationsnummer: "556628-7578",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ragunda",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Feb 07, 2019",
  },
  {
    Områdes_ID: "2303-V-007",
    Projektnamn: "Kalkstenshöjden",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ragunda",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "2283-V-003",
    Projektnamn: "Kalkstenshöjden",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Sollefteå",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "2380-V-003",
    Projektnamn: "Storhögen",
    Verksamhetsutövare: "Statkraft SCA Vind AB",
    Organisationsnummer: "556706-3507",
    Aktuella_verk: 30,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 165,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Östersund",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2305-V-008",
    Projektnamn: "Bodberget",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Bräcke",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Apr 02, 2019",
  },
  {
    Områdes_ID: "2361-V-031",
    Projektnamn: "Sandtjärnberget",
    Verksamhetsutövare: "Sandtjärnsberget Vindkraft AB",
    Organisationsnummer: "556849-7035",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Härjedalen",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Oct 19, 2021",
  },
  {
    Områdes_ID: "2303-V-015",
    Projektnamn: "Björkvattnet",
    Verksamhetsutövare: "Statkraft SCA Vind AB",
    Organisationsnummer: "556706-3507",
    Aktuella_verk: 40,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 200,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ragunda",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2326-V-010",
    Projektnamn: "Dalåsen",
    Verksamhetsutövare: "Vindkraft i Dalåsen AB",
    Organisationsnummer: "556849-8769",
    Aktuella_verk: 66,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 649.44,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Berg",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2303-V-010",
    Projektnamn: "Skyttmon",
    Verksamhetsutövare: "JP Vind AB",
    Organisationsnummer: "556754-9174",
    Aktuella_verk: 11,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 55,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Strömsund",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2313-V-018",
    Projektnamn: "Skyttmon",
    Verksamhetsutövare: "JP Vind AB",
    Organisationsnummer: "556754-9174",
    Aktuella_verk: 38,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 165,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ragunda",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2361-V-029",
    Projektnamn: "Nysäteråsen",
    Verksamhetsutövare: "Schönborg Vind AB",
    Organisationsnummer: "556782-7398",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Härjedalen",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Apr 29, 2019",
  },
  {
    Områdes_ID: "2309-V-006",
    Projektnamn: "Tornäs",
    Verksamhetsutövare: "TG1 Kraft AB",
    Organisationsnummer: "556361-2620",
    Aktuella_verk: 10,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 15.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Krokom",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Dec 09, 2021",
  },
  {
    Områdes_ID: "2303-V-006",
    Projektnamn: "Kalkstenhöjden",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 10,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ragunda",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2326-V-012",
    Projektnamn: "Handsjöknusen",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 11,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Berg",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2313-V-024",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 75,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Strömsund",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2361-V-037",
    Projektnamn: "Brickan/Olingsdal",
    Verksamhetsutövare: "Bergvik Skog AB",
    Organisationsnummer: "556610-2959",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Härjedalen",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2361-V-039",
    Projektnamn: "Risbrunn",
    Verksamhetsutövare: "WPD Scandinavia AB",
    Organisationsnummer: "556666-3422",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Härjedalen",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2361-V-038",
    Projektnamn: "Glissjöberget/Norderåsen",
    Verksamhetsutövare: "Vindvision Norr AB",
    Organisationsnummer: "556719-9699",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Härjedalen",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2380-V-005",
    Projektnamn: "Ope",
    Verksamhetsutövare: "Rabbalshede Vindenergi AB (inaktuellt bolag)",
    Organisationsnummer: "556093-1874",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Östersund",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2309-V-009",
    Projektnamn: "Nordbyn",
    Verksamhetsutövare: "Svenska Vindbolaget AB",
    Organisationsnummer: "556759-9013",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Krokom",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Feb 27, 2020",
  },
  {
    Områdes_ID: "0642-V-005",
    Projektnamn: "Dintestorp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mullsjö",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 05, 2019",
  },
  {
    Områdes_ID: "0642-V-006",
    Projektnamn: "Rumperyd",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mullsjö",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0642-V-007",
    Projektnamn: "Ljunghem 1.13",
    Verksamhetsutövare: "Firma Ramström Vind AB",
    Organisationsnummer: "556688-2683",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mullsjö",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0642-V-008",
    Projektnamn: "Tunarp",
    Verksamhetsutövare: "Falanwind AB",
    Organisationsnummer: "556768-2389",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mullsjö",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0642-V-013",
    Projektnamn: "Ljunghem",
    Verksamhetsutövare: "Firma Ramström Vind AB",
    Organisationsnummer: "556688-2683",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mullsjö",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0643-V-004",
    Projektnamn: "Gölhult",
    Verksamhetsutövare: "Gölhults Utegrisar HB",
    Organisationsnummer: "969724-5364",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Habo",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0685-V-001",
    Projektnamn: "Salvaryd",
    Verksamhetsutövare: "Billyvind AB",
    Organisationsnummer: "556705-6931",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vetlanda",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 10, 2021",
  },
  {
    Områdes_ID: "0685-V-005",
    Projektnamn: "Broddstorp",
    Verksamhetsutövare: "Billyvind AB",
    Organisationsnummer: "556705-6931",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vetlanda",
    Län: "Jönköpings län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1381-V-002",
    Projektnamn: "Brånalt",
    Verksamhetsutövare: "Arise Windpower AB",
    Organisationsnummer: "556274-6726",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Laholm",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "1381-V-056",
    Projektnamn: "Öringe",
    Verksamhetsutövare: "RWE Renewables Sweden AB",
    Organisationsnummer: "556938-6864",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 15,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Laholm",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 22, 2021",
  },
  {
    Områdes_ID: "1444-V-009",
    Projektnamn: "Gunnarstorp",
    Verksamhetsutövare: "Gunnarstorp Vind AB",
    Organisationsnummer: "556778-6099",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 16.2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Grästorp",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1494-V-019",
    Projektnamn: "Uvereds Vindkraftförening",
    Verksamhetsutövare: "Uvereds Vindkraftspark",
    Organisationsnummer: "934005-1102",
    Aktuella_verk: 5,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 10.1,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Lidköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 24, 2020",
  },
  {
    Områdes_ID: "1494-V-027",
    Projektnamn: "Hasslösa",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Lidköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1499-V-035",
    Projektnamn: "Skörstorp",
    Verksamhetsutövare: "GSG Drift AB",
    Organisationsnummer: "556745-0548",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 16.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1430-V-012",
    Projektnamn: "Orreberg",
    Verksamhetsutövare: "Universal Power AB",
    Organisationsnummer: "556726-3859",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Munkedal",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1446-V-001",
    Projektnamn: "Hanhult",
    Verksamhetsutövare: "Triventus AB",
    Organisationsnummer: "556627-3016",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Karlsborg",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1446-V-006",
    Projektnamn: "Brasmaviken",
    Verksamhetsutövare: "Brasvind AB",
    Organisationsnummer: "556826-7719",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 10.4,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Karlsborg",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1446-V-007",
    Projektnamn: "Forsvik",
    Verksamhetsutövare: "Triventus AB",
    Organisationsnummer: "556627-3016",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Karlsborg",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1881-V-001",
    Projektnamn: "Vindpark Ekeby",
    Verksamhetsutövare: "Kumbro Vind AB",
    Organisationsnummer: "556914-8249",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 15.9,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kumla",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0509-V-001",
    Projektnamn: "Eveboda",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 3.9,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ödeshög",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Aug 31, 2020",
  },
  {
    Områdes_ID: "0580-V-006",
    Projektnamn: "vkv_lin-005",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Linköping",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0580-V-016",
    Projektnamn: "vkv_lin-021",
    Verksamhetsutövare: "Himna Energi AB",
    Organisationsnummer: "556635-8924",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Linköping",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0586-V-005",
    Projektnamn: "Herrberga",
    Verksamhetsutövare: "Herrberga Vind AB",
    Organisationsnummer: "556815-6060",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 15.99,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mjölby",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Mar 11, 2020",
  },
  {
    Områdes_ID: "1381-V-003",
    Projektnamn: "Dansbygget/Svenshult",
    Verksamhetsutövare: "Billyvind AB",
    Organisationsnummer: "556705-6931",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Laholm",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "May 15, 2019",
  },
  {
    Områdes_ID: "1382-V-010",
    Projektnamn: "Lövstaviken",
    Verksamhetsutövare: "Falkenbergs Kommun",
    Organisationsnummer: "212000x1231",
    Aktuella_verk: 6,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 26.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falkenberg",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1382-V-015",
    Projektnamn: "Vindil Kraft AB",
    Verksamhetsutövare: "Vindil Kraft AB",
    Organisationsnummer: "556690-6896",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 22,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falkenberg",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "Jan 09, 2019",
  },
  {
    Områdes_ID: "1382-V-016",
    Projektnamn: "Väby",
    Verksamhetsutövare: "Väby Driftintressenter AB",
    Organisationsnummer: "556857-0591",
    Aktuella_verk: 6,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 33,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falkenberg",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1383-V-005",
    Projektnamn: "Munkagård",
    Verksamhetsutövare: "Varberg Energi AB",
    Organisationsnummer: "556524-3010",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 15,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Varberg",
    Län: "Hallands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1383-V-013",
    Projektnamn: "Utteros",
    Verksamhetsutövare: "Varberg Energi AB",
    Organisationsnummer: "556524-3010",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 2.88,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Varberg",
    Län: "Hallands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1383-V-018",
    Projektnamn: "",
    Verksamhetsutövare: "Varberg Energi AB",
    Organisationsnummer: "556524-3010",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 3.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Varberg",
    Län: "Hallands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1419-V-005",
    Projektnamn: "Rävlanda, Tyft",
    Verksamhetsutövare: "SiBräcka vind",
    Organisationsnummer: "934001-0421",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tjörn",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1419-V-011",
    Projektnamn: "sundsby",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tjörn",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 15, 2021",
  },
  {
    Områdes_ID: "1419-V-013",
    Projektnamn: "Tyfta Vindkraftpark",
    Verksamhetsutövare: "Wallhamnbolagen AB",
    Organisationsnummer: "556215-6033",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tjörn",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 15, 2021",
  },
  {
    Områdes_ID: "1421-V-005",
    Projektnamn: "Ileberg 1, Tärnmåsen",
    Verksamhetsutövare: "Orust Drift AB",
    Organisationsnummer: "556817-6167",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 15.9,
    Uppmätt_årsproduktion_GWh: 17.34,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Orust",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "May 12, 2020",
  },
  {
    Områdes_ID: "1430-V-010",
    Projektnamn: "Järmunderöd(Lilla Parken) 3 verk",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 16.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Munkedal",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Jan 11, 2019",
  },
  {
    Områdes_ID: "1430-V-018",
    Projektnamn: "Toröd",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Munkedal",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Mar 07, 2019",
  },
  {
    Områdes_ID: "1430-V-022",
    Projektnamn: "Håby-Torp",
    Verksamhetsutövare: "Munkedals Skog & Event AB",
    Organisationsnummer: "556001-0950",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 18,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Munkedal",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1435-V-015",
    Projektnamn: "Skaveröd",
    Verksamhetsutövare: "Skaveröd Gurseröd Vind AB",
    Organisationsnummer: "556809-3453",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 18,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tanum",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1435-V-016",
    Projektnamn: "Skaveröd/Gurseröd",
    Verksamhetsutövare: "Skaveröd Gurseröd Vind AB",
    Organisationsnummer: "556809-3453",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 19.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tanum",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1435-V-032",
    Projektnamn: "Hud",
    Verksamhetsutövare: "Rabbalshede Kraft AB",
    Organisationsnummer: "556681-4652",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 18,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tanum",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1435-V-048",
    Projektnamn: "Skalleröd Håbäckemarken",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tanum",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 25, 2019",
  },
  {
    Områdes_ID: "1435-V-054",
    Projektnamn: "Skärbo",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 6,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 7.02,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tanum",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1445-V-003",
    Projektnamn: "Wästgöta Wind",
    Verksamhetsutövare: "Wästgöta Wind AB",
    Organisationsnummer: "556944-6478",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 27.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Essunga",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1446-V-005",
    Projektnamn: "Gräshult",
    Verksamhetsutövare: "Vindia AB",
    Organisationsnummer: "556547-0401",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.45,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Karlsborg",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 14, 2019",
  },
  {
    Områdes_ID: "1447-V-002",
    Projektnamn: "Gunnarstorp",
    Verksamhetsutövare: "Lilla Årås Vindpark AB",
    Organisationsnummer: "556824-3983",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 18,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gullspång",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1461-V-012",
    Projektnamn: "Qvantenburg 2",
    Verksamhetsutövare: "Slättens Vind ek för",
    Organisationsnummer: "769606-9082",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 16.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mellerud",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Jan 14, 2019",
  },
  {
    Områdes_ID: "1461-V-019",
    Projektnamn: "Kuserud 1",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mellerud",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 15, 2021",
  },
  {
    Områdes_ID: "1461-V-023",
    Projektnamn: "Karlsfält",
    Verksamhetsutövare: "Slättens Vind ek för",
    Organisationsnummer: "769606-9082",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 4.9,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mellerud",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1462-V-003",
    Projektnamn: "Berg Västra",
    Verksamhetsutövare: "Firma Ramström Vind AB",
    Organisationsnummer: "556688-2683",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "LillaEdet",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "May 16, 2019",
  },
  {
    Områdes_ID: "1462-V-015",
    Projektnamn: "Vindkraftpark Lekvall",
    Verksamhetsutövare: "Triventus AB",
    Organisationsnummer: "556627-3016",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "LillaEdet",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1466-V-001",
    Projektnamn: "Girovind",
    Verksamhetsutövare: "GiroVind Energi AB",
    Organisationsnummer: "556680-8167",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Herrljunga",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1470-V-029",
    Projektnamn: "Skatofta",
    Verksamhetsutövare: "Long Vindpark AB",
    Organisationsnummer: "556774-3124",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 16.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1482-V-001",
    Projektnamn: "Lycke",
    Verksamhetsutövare: "GiroVind Energi AB",
    Organisationsnummer: "556680-8167",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kungälv",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1485-V-021",
    Projektnamn: "STALE",
    Verksamhetsutövare: "Bohusvind AB",
    Organisationsnummer: "556387-8361",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Uddevalla",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1485-V-023",
    Projektnamn: "Vindkraftprojekt Stale",
    Verksamhetsutövare: "Bohusvind AB",
    Organisationsnummer: "556387-8361",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Uddevalla",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1485-V-028",
    Projektnamn: "LEJDEBERGEN",
    Verksamhetsutövare: "Bohusvind AB",
    Organisationsnummer: "556387-8361",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 21,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Uddevalla",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1486-V-003",
    Projektnamn: "Neanberg/VIK",
    Verksamhetsutövare: "Neanberg Vind AB",
    Organisationsnummer: "556817-9567",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 15.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Strömstad",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1491-V-002",
    Projektnamn: "Duvered",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ulricehamn",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 22, 2021",
  },
  {
    Områdes_ID: "1491-V-010",
    Projektnamn: "Vindpark Ekesbo",
    Verksamhetsutövare: "Svenska Vindbolaget AB",
    Organisationsnummer: "556759-9013",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ulricehamn",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "1493-V-002",
    Projektnamn: "Fåleberg",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 15,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mariestad",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1493-V-005",
    Projektnamn: "Vindkraft Bångahagen",
    Verksamhetsutövare: "Erikstorp Utveckling AB",
    Organisationsnummer: "556748-1667",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mariestad",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Apr 08, 2019",
  },
  {
    Områdes_ID: "1493-V-006",
    Projektnamn: "Sjöberg",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mariestad",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "May 14, 2019",
  },
  {
    Områdes_ID: "1495-V-010",
    Projektnamn: "SIMMATORP / 7835",
    Verksamhetsutövare: "OX2 AB",
    Organisationsnummer: "556675-7497",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 15.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Skara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Dec 09, 2020",
  },
  {
    Områdes_ID: "1495-V-016",
    Projektnamn: "VIGLUNDA / 7307",
    Verksamhetsutövare: "Driftbolaget Skånings Åsaka Vind AB",
    Organisationsnummer: "556705-6923",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 15,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Skara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1495-V-025",
    Projektnamn: "KILAGÅRDEN /7306-1",
    Verksamhetsutövare: "Wallenstam Vindkraft Skuggetorp AB",
    Organisationsnummer: "556773-8025",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 15,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Skara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1495-V-031",
    Projektnamn: "KARSTORP / 7044-3",
    Verksamhetsutövare: "Wallenstam Vindkraft Skogaryd AB",
    Organisationsnummer: "556775-1432",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 13.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Skara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1495-V-033",
    Projektnamn: "NYCKELTORP / 7308-2",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 15,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Skara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1495-V-038",
    Projektnamn: "BLOMBACKA / 7837-1",
    Verksamhetsutövare: "Blombacka Vind AB",
    Organisationsnummer: "556681-4009",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 11,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Skara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 06, 2021",
  },
  {
    Områdes_ID: "1495-V-046",
    Projektnamn: "Hasslösa",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 27,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Skara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1496-V-008",
    Projektnamn: "Askeberga",
    Verksamhetsutövare: "Vindia AB",
    Organisationsnummer: "556547-0401",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Skövde",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Jan 14, 2019",
  },
  {
    Områdes_ID: "1497-V-002",
    Projektnamn: "Bossgården (Galneryd)",
    Verksamhetsutövare: "ewz Atlantic Sverige AB",
    Organisationsnummer: "556925-8113",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 15,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Hjo",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 08, 2021",
  },
  {
    Områdes_ID: "1497-V-005",
    Projektnamn: "Getaryggen, Söderryd, Stora Björstorp",
    Verksamhetsutövare: "ewz Atlantic Sverige AB",
    Organisationsnummer: "556925-8113",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 15,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Hjo",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 08, 2021",
  },
  {
    Områdes_ID: "1497-V-006",
    Projektnamn: "Fågelås-Torp",
    Verksamhetsutövare: "Svenska Vindbolaget AB",
    Organisationsnummer: "556759-9013",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 15,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Hjo",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 08, 2021",
  },
  {
    Områdes_ID: "1497-V-015",
    Projektnamn: "Fågelås-Spakås, Borrbäckstorp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hjo",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "1499-V-003",
    Projektnamn: "Badene",
    Verksamhetsutövare: "Vindenergi i Badene AB",
    Organisationsnummer: "556611-1562",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 3.4,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 22, 2019",
  },
  {
    Områdes_ID: "1499-V-010",
    Projektnamn: "Göteve Vindpark",
    Verksamhetsutövare: "Agrivind AB",
    Organisationsnummer: "556242-1387",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Falköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Nov 14, 2019",
  },
  {
    Områdes_ID: "1499-V-018",
    Projektnamn: "Mönarp",
    Verksamhetsutövare: "SEBB Wind AB",
    Organisationsnummer: "556856-3901",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 6.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1499-V-033",
    Projektnamn: "Skånum A3",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Falköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "1499-V-043",
    Projektnamn: "Tyskagården",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 16.2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1781-V-008",
    Projektnamn: "Vissle",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kristinehamn",
    Län: "Värmlands län",
    Elområde: "Stockholm",
    Senast_sparad: "May 02, 2019",
  },
  {
    Områdes_ID: "1860-V-001",
    Projektnamn: "Ramsnäs",
    Verksamhetsutövare: "ewz Atlantic Sverige AB",
    Organisationsnummer: "556925-8113",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 15.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Laxå",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 08, 2021",
  },
  {
    Områdes_ID: "1861-V-002",
    Projektnamn: "Ullavi",
    Verksamhetsutövare: "Kumbro Vind AB",
    Organisationsnummer: "556914-8249",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 15.78,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Hallsberg",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0509-V-005",
    Projektnamn: "Kyleberg",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 3.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ödeshög",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Aug 31, 2020",
  },
  {
    Områdes_ID: "0509-V-015",
    Projektnamn: "Råby",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 3.43,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ödeshög",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Aug 31, 2020",
  },
  {
    Områdes_ID: "0512-V-003",
    Projektnamn: "Boda",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ydre",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0581-V-005",
    Projektnamn: "Högstad Gård",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Norrköping",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 05, 2019",
  },
  {
    Områdes_ID: "0581-V-008",
    Projektnamn: "Svenneby Gård",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Norrköping",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 05, 2019",
  },
  {
    Områdes_ID: "0586-V-004",
    Projektnamn: "Hackegården",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 3.6,
    Uppmätt_årsproduktion_GWh: 2.71,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mjölby",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1381-V-015",
    Projektnamn: "Skottorp",
    Verksamhetsutövare: "Edenberga Gård AB",
    Organisationsnummer: "556352-4197",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.42,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Laholm",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 15, 2019",
  },
  {
    Områdes_ID: "1381-V-020",
    Projektnamn: "Vallberga Gård Mölla",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.25,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Laholm",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1382-V-006",
    Projektnamn: "Hässlås Morups-Lunnagård",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 10.2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falkenberg",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1382-V-007",
    Projektnamn: "Hässlås Morups-Lunnagård",
    Verksamhetsutövare: "Växthuset Linds AB",
    Organisationsnummer: "556217-2097",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 10.2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falkenberg",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1383-V-006",
    Projektnamn: "Språngskulla",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Varberg",
    Län: "Hallands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1383-V-010",
    Projektnamn: "Torekull Mr trend 9001",
    Verksamhetsutövare: "Mr Trend Unlimited Incorporation AB",
    Organisationsnummer: "556305-7263",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Varberg",
    Län: "Hallands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1383-V-011",
    Projektnamn: "Tvååker",
    Verksamhetsutövare: "Varberg Energi AB",
    Organisationsnummer: "556524-3010",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 11,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Varberg",
    Län: "Hallands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 22, 2019",
  },
  {
    Områdes_ID: "1383-V-016",
    Projektnamn: "",
    Verksamhetsutövare: "Varberg Energi AB",
    Organisationsnummer: "556524-3010",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 2.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Varberg",
    Län: "Hallands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1415-V-005",
    Projektnamn: "Spekeröds-Torp",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Stenungsund",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1415-V-011",
    Projektnamn: "Hog",
    Verksamhetsutövare: "Lärbro SPW AB",
    Organisationsnummer: "556794-5935",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 3.98,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Stenungsund",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1427-V-001",
    Projektnamn: "Berg",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Sotenäs",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 15, 2021",
  },
  {
    Områdes_ID: "1430-V-004",
    Projektnamn: "Svarteborg",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Munkedal",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 18, 2020",
  },
  {
    Områdes_ID: "1430-V-011",
    Projektnamn: "Hede-Ryr",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Munkedal",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 05, 2019",
  },
  {
    Områdes_ID: "1430-V-028",
    Projektnamn: "Vindpark Anneröd",
    Verksamhetsutövare: "ANNERÖDS VINDKRAFT AB",
    Organisationsnummer: "556753-6056",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5.2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Munkedal",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "May 13, 2019",
  },
  {
    Områdes_ID: "1435-V-020",
    Projektnamn: "Hessland",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tanum",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 15, 2021",
  },
  {
    Områdes_ID: "1435-V-023",
    Projektnamn: "Kil",
    Verksamhetsutövare: "Rabbalshede Kraft AB",
    Organisationsnummer: "556681-4652",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 10,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tanum",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Jan 11, 2019",
  },
  {
    Områdes_ID: "1435-V-024",
    Projektnamn: "Kil",
    Verksamhetsutövare: "Rabbalshede Kraft AB",
    Organisationsnummer: "556681-4652",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 4,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tanum",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1435-V-027",
    Projektnamn: "Klöveröd",
    Verksamhetsutövare: "Veddige Vindkraft AB",
    Organisationsnummer: "556753-6072",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 12,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tanum",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1435-V-034",
    Projektnamn: "Taranderöd, Lurs-Amdal",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tanum",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 25, 2019",
  },
  {
    Områdes_ID: "1435-V-036",
    Projektnamn: "Lur-Backa",
    Verksamhetsutövare: "Veddige Vindkraft AB",
    Organisationsnummer: "556753-6072",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 11,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tanum",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1435-V-038",
    Projektnamn: "Kil-Lursäng",
    Verksamhetsutövare: "Rabbalshede Kraft AB",
    Organisationsnummer: "556681-4652",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 10,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tanum",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1435-V-040",
    Projektnamn: "Kil-Lursäng",
    Verksamhetsutövare: "Rabbalshede Kraft AB",
    Organisationsnummer: "556681-4652",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 10,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tanum",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1435-V-066",
    Projektnamn: "Kil",
    Verksamhetsutövare: "Rabbalshede Kraft AB",
    Organisationsnummer: "556681-4652",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 4,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tanum",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1438-V-003",
    Projektnamn: "Hajom Holane",
    Verksamhetsutövare: "Rabbalshede Vindenergi AB (inaktuellt bolag)",
    Organisationsnummer: "556093-1874",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 12,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Dals-Ed",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1444-V-006",
    Projektnamn: "Eolus Vind",
    Verksamhetsutövare: "Hyringa Vind AB",
    Organisationsnummer: "556854-0495",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 9.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Grästorp",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1444-V-011",
    Projektnamn: "Ulvstorp, Djerf",
    Verksamhetsutövare: "Carlssons Täckdikning AB",
    Organisationsnummer: "556619-0830",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 10.4,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1445-V-002",
    Projektnamn: "Tumleberg Norr",
    Verksamhetsutövare: "Tumleberg Vindenergi AB",
    Organisationsnummer: "556784-7578",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 10.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Essunga",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1452-V-002",
    Projektnamn: "Lid",
    Verksamhetsutövare: "H2 Vind AB",
    Organisationsnummer: "556776-2603",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 12,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tranemo",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1461-V-002",
    Projektnamn: "Tängelsbol",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 10.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mellerud",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 06, 2021",
  },
  {
    Områdes_ID: "1461-V-004",
    Projektnamn: "Grinstads-Hagen 2 öst",
    Verksamhetsutövare: "Slättens Vind ek för",
    Organisationsnummer: "769606-9082",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 3.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mellerud",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 22, 2019",
  },
  {
    Områdes_ID: "1461-V-006",
    Projektnamn: "Västergården 5",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mellerud",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 05, 2019",
  },
  {
    Områdes_ID: "1461-V-013",
    Projektnamn: "Fågelbacka",
    Verksamhetsutövare: "Dalboslättens Vind AB",
    Organisationsnummer: "556802-3385",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 10.4,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mellerud",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1461-V-015",
    Projektnamn: "Söbyn",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 11.1,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mellerud",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Jan 14, 2019",
  },
  {
    Områdes_ID: "1461-V-017",
    Projektnamn: "Bolstads-Prästgård 1",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 10.7,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mellerud",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Jan 14, 2019",
  },
  {
    Områdes_ID: "1461-V-018",
    Projektnamn: "Bråbol",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mellerud",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 15, 2021",
  },
  {
    Områdes_ID: "1461-V-020",
    Projektnamn: "Holken",
    Verksamhetsutövare: "CRC Vindkraft AB",
    Organisationsnummer: "556700-3545",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mellerud",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 15, 2021",
  },
  {
    Områdes_ID: "1462-V-004",
    Projektnamn: "Sannersby",
    Verksamhetsutövare: "Hulta Vind AB",
    Organisationsnummer: "556808-6028",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 11.3,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "LillaEdet",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1463-V-004",
    Projektnamn: "Stora Farsnäs",
    Verksamhetsutövare: "Firma Ramström Vind AB",
    Organisationsnummer: "556688-2683",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 13.2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mark",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1463-V-006",
    Projektnamn: "Berghem",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mark",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1470-V-004",
    Projektnamn: "Bränneberg",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 2.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1470-V-005",
    Projektnamn: "Bustorp",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "1470-V-033",
    Projektnamn: "Tornum",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5.3,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1470-V-034",
    Projektnamn: "Ulfstorp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 10,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1470-V-037",
    Projektnamn: "Vånga Hed 1",
    Verksamhetsutövare: "Lidbil Fastighet Vara AB",
    Organisationsnummer: "556448-9820",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 3.32,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 13, 2019",
  },
  {
    Områdes_ID: "1471-V-015",
    Projektnamn: "Kollbogården III",
    Verksamhetsutövare: "Agrivind AB",
    Organisationsnummer: "556242-1387",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Götene",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Mar 15, 2019",
  },
  {
    Områdes_ID: "1471-V-038",
    Projektnamn: "Äle",
    Verksamhetsutövare: "Broby Vind AB",
    Organisationsnummer: "556628-8352",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 3.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Götene",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 07, 2021",
  },
  {
    Områdes_ID: "1473-V-003",
    Projektnamn: "Äskekärr 1&2",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 3.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Töreboda",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1484-V-001",
    Projektnamn: "Nöteberg",
    Verksamhetsutövare: "Vindbyggarna i Lysekil AB",
    Organisationsnummer: "556634-6424",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Lysekil",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1484-V-002",
    Projektnamn: "Sivik",
    Verksamhetsutövare: "Vindbyggarna i Lysekil AB",
    Organisationsnummer: "556634-6424",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Lysekil",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "1484-V-003",
    Projektnamn: "Humlekärr",
    Verksamhetsutövare: "Lysekils Energi Vind AB",
    Organisationsnummer: "556711-0423",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 16,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Lysekil",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1484-V-011",
    Projektnamn: "Humlekärr",
    Verksamhetsutövare: "Lärbro SPW AB",
    Organisationsnummer: "556794-5935",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 2.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Lysekil",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "May 07, 2021",
  },
  {
    Områdes_ID: "1485-V-008",
    Projektnamn: "Gunnarby & Skoghem",
    Verksamhetsutövare: "Wallenstam Vindkraft Gunnarby AB",
    Organisationsnummer: "556707-1278",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Uddevalla",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Apr 08, 2019",
  },
  {
    Områdes_ID: "1485-V-009",
    Projektnamn: "HERRESTADS-SVENSERÖD",
    Verksamhetsutövare: "ANNERÖDS VINDKRAFT AB",
    Organisationsnummer: "556753-6056",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Uddevalla",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Aug 29, 2019",
  },
  {
    Områdes_ID: "1485-V-014",
    Projektnamn: "NORDMANNERÖD",
    Verksamhetsutövare: "Bohusvind AB",
    Organisationsnummer: "556387-8361",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Uddevalla",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1485-V-017",
    Projektnamn: "RÅLANDA",
    Verksamhetsutövare: "Wallenstam Vindkraft Rålanda AB",
    Organisationsnummer: "556721-5206",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 11,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Uddevalla",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 25, 2019",
  },
  {
    Områdes_ID: "1485-V-019",
    Projektnamn: "RÅSSERÖD",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 16,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Uddevalla",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1487-V-003",
    Projektnamn: "Bockegården",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vänersborg",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1487-V-013",
    Projektnamn: "Häljerud",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vänersborg",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1487-V-014",
    Projektnamn: "Hönseberg",
    Verksamhetsutövare: "Västfastigheter V:a Götalandsregionen",
    Organisationsnummer: "232100-0131",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vänersborg",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1489-V-003",
    Projektnamn: "Upplo",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Alingsås",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Aug 27, 2019",
  },
  {
    Områdes_ID: "1492-V-004",
    Projektnamn: "Hässelbacka",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.4,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Åmål",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1492-V-006",
    Projektnamn: "Söderbodane",
    Verksamhetsutövare: "Älvsborgsvind AB (publ)",
    Organisationsnummer: "556508-4448",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 9,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Åmål",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1493-V-001",
    Projektnamn: "Värslen",
    Verksamhetsutövare: "Firma Ramström Vind AB",
    Organisationsnummer: "556688-2683",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 13.7,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mariestad",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Aug 14, 2019",
  },
  {
    Områdes_ID: "1494-V-003",
    Projektnamn: "Arvidstorp vind",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 3.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Lidköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1494-V-004",
    Projektnamn: "Stenbrona-Saleby",
    Verksamhetsutövare: "Stenbrona Vindpark AB",
    Organisationsnummer: "556719-4682",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 10.4,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Lidköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1494-V-008",
    Projektnamn: "Närefors 1 Norr",
    Verksamhetsutövare: "Närvind AB",
    Organisationsnummer: "556802-3328",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 10.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Lidköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1494-V-013",
    Projektnamn: "Bast",
    Verksamhetsutövare: "Älvsborgsvind AB (publ)",
    Organisationsnummer: "556508-4448",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 2.3,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Lidköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1494-V-025",
    Projektnamn: "Lindärva",
    Verksamhetsutövare: "Mossängens Vind AB",
    Organisationsnummer: "556869-6560",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 4,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Lidköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1494-V-026",
    Projektnamn: "Härjevad",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 11,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Lidköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1494-V-028",
    Projektnamn: "Storeberg",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Lidköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "1495-V-004",
    Projektnamn: "ENTORP / 5690-1",
    Verksamhetsutövare: "Agrivind AB",
    Organisationsnummer: "556242-1387",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 8.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Skara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1495-V-022",
    Projektnamn: "HORSHAGA / 7309",
    Verksamhetsutövare: "Jula Logistics AB m.fl.",
    Organisationsnummer: "556583-1384",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 11,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Skara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1497-V-001",
    Projektnamn: "Skalleberg och Dunabolet",
    Verksamhetsutövare: "ewz Atlantic Sverige AB",
    Organisationsnummer: "556925-8113",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 10,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Hjo",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 08, 2021",
  },
  {
    Områdes_ID: "1497-V-003",
    Projektnamn: "Skalleberg och Bossgården",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hjo",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1497-V-009",
    Projektnamn: "Åsen",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hjo",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "1497-V-016",
    Projektnamn: "Prästbolet",
    Verksamhetsutövare: "Boda Nät ek. förening",
    Organisationsnummer: "769618-5771",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 10.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Hjo",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1497-V-028",
    Projektnamn: "Fridene",
    Verksamhetsutövare: "Carlssons Täckdikning AB",
    Organisationsnummer: "556619-0830",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 3,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Hjo",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1497-V-037",
    Projektnamn: "Övre Rinkabäcken",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hjo",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1497-V-048",
    Projektnamn: "Skalleberg",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hjo",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "May 06, 2019",
  },
  {
    Områdes_ID: "1498-V-006",
    Projektnamn: "Krogstorp",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tidaholm",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1499-V-008",
    Projektnamn: "Gudhem",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Falköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "May 06, 2019",
  },
  {
    Områdes_ID: "1499-V-034",
    Projektnamn: "Skånum R1",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Falköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 05, 2019",
  },
  {
    Områdes_ID: "1862-V-001",
    Projektnamn: "Vindkraftanläggning Norrboda",
    Verksamhetsutövare: "Slättens Vind ek för",
    Organisationsnummer: "769606-9082",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 13.52,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Degerfors",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "Jan 07, 2019",
  },
  {
    Områdes_ID: "1862-V-002",
    Projektnamn: "Vindkraftanläggning Grannäs Eka",
    Verksamhetsutövare: "Greenextreme AB",
    Organisationsnummer: "556722-4596",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Degerfors",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "Aug 27, 2019",
  },
  {
    Områdes_ID: "1883-V-001",
    Projektnamn: "Granåsen",
    Verksamhetsutövare: "Gelleråsen Invest AB",
    Organisationsnummer: "556736-9508",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Karlskoga",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 18, 2020",
  },
  {
    Områdes_ID: "0484-V-004",
    Projektnamn: "",
    Verksamhetsutövare: "Kafjärdens Vindkraft AB",
    Organisationsnummer: "556706-1931",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Eskilstuna",
    Län: "Södermanlands län",
    Elområde: "Stockholm",
    Senast_sparad: "May 04, 2021",
  },
  {
    Områdes_ID: "0760-V-014",
    Projektnamn: "Blötskog",
    Verksamhetsutövare: "Billyvind AB",
    Organisationsnummer: "556705-6931",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Uppvidinge",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 10, 2021",
  },
  {
    Områdes_ID: "0840-V-001",
    Projektnamn: "Albrunna norra",
    Verksamhetsutövare: "OX2 AB",
    Organisationsnummer: "556675-7497",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mörbylånga",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "0840-V-002",
    Projektnamn: "Albrunna norra",
    Verksamhetsutövare: "Kalmar Energi Värme AB",
    Organisationsnummer: "556636-7792",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mörbylånga",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "0840-V-006",
    Projektnamn: "Brostorp",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mörbylånga",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "0840-V-007",
    Projektnamn: "Bröttorp",
    Verksamhetsutövare: "Vingkraft AB",
    Organisationsnummer: "556528-1945",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mörbylånga",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0840-V-009",
    Projektnamn: "Degerhamn piren I",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mörbylånga",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0840-V-010",
    Projektnamn: "Dörby",
    Verksamhetsutövare: "Svenska Vindbolaget AB",
    Organisationsnummer: "556759-9013",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mörbylånga",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "0840-V-014",
    Projektnamn: "Grönhögen",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 7,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.58,
    Uppmätt_årsproduktion_GWh: 0.51,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mörbylånga",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Dec 12, 2019",
  },
  {
    Områdes_ID: "0840-V-025",
    Projektnamn: "Mellstaby",
    Verksamhetsutövare: "Firma Ramström Vind AB",
    Organisationsnummer: "556688-2683",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mörbylånga",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "0840-V-041",
    Projektnamn: "Ventlinge",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 5,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 2.9,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mörbylånga",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "May 28, 2020",
  },
  {
    Områdes_ID: "0882-V-003",
    Projektnamn: "Ävrö",
    Verksamhetsutövare: "RWE Renewables Sweden AB",
    Organisationsnummer: "556938-6864",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Oskarshamn",
    Län: "Kalmar län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 22, 2021",
  },
  {
    Områdes_ID: "0885-V-003",
    Projektnamn: "Mellböda",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 5,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 6,
    Uppmätt_årsproduktion_GWh: 5.29,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Borgholm",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "May 28, 2020",
  },
  {
    Områdes_ID: "1082-V-001",
    Projektnamn: "Gunnön",
    Verksamhetsutövare: "Karlshamn Energi Elförsäljning AB",
    Organisationsnummer: "556527-9345",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 4.8,
    Uppmätt_årsproduktion_GWh: 3.02,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Karlshamn",
    Län: "Blekinge län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1315-V-013",
    Projektnamn: "Vindkraft Stjärnarp",
    Verksamhetsutövare: "Arise Windpower AB",
    Organisationsnummer: "556274-6726",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 16.29,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Halmstad",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1315-V-015",
    Projektnamn: "Skintaby",
    Verksamhetsutövare: "GiroVind Energi AB",
    Organisationsnummer: "556680-8167",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Halmstad",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 15, 2021",
  },
  {
    Områdes_ID: "2101-V-001",
    Projektnamn: "Mårtensklack",
    Verksamhetsutövare: "Wallenstam Vindkraft Vettåsen AB",
    Organisationsnummer: "556791-7710",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 25,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ockelbo",
    Län: "Gävleborgs län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2101-V-003",
    Projektnamn: "Vackerdalberget",
    Verksamhetsutövare: "OWN Power Projects AB",
    Organisationsnummer: "556603-1869",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 12,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ockelbo",
    Län: "Gävleborgs län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2132-V-001",
    Projektnamn: "Vindpark Stocka",
    Verksamhetsutövare: "Property Dynamics AB",
    Organisationsnummer: "556564-9224",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5.2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Nordanstig",
    Län: "Gävleborgs län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2132-V-002",
    Projektnamn: "Vindpark Mörkåsen",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 16.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Nordanstig",
    Län: "Gävleborgs län",
    Elområde: "Sundsvall",
    Senast_sparad: "Jan 09, 2019",
  },
  {
    Områdes_ID: "2183-V-005",
    Projektnamn: "Långberget 2",
    Verksamhetsutövare: "Slitevind AB",
    Organisationsnummer: "556453-2819",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 15.9,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Bollnäs",
    Län: "Gävleborgs län",
    Elområde: "Sundsvall",
    Senast_sparad: "Sep 25, 2020",
  },
  {
    Områdes_ID: "2313-V-006",
    Projektnamn: "Raftsjöhöjden syd",
    Verksamhetsutövare: "Rabbalshede Kraft AB",
    Organisationsnummer: "556681-4652",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 21.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "Feb 22, 2012",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Strömsund",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "May 28, 2020",
  },
  {
    Områdes_ID: "2313-V-008",
    Projektnamn: "Tåsvedberget",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Strömsund",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Sep 05, 2019",
  },
  {
    Områdes_ID: "2326-V-005",
    Projektnamn: "Gammalbodberget 1.",
    Verksamhetsutövare: "Svenska Vindbolaget AB",
    Organisationsnummer: "556759-9013",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Berg",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Aug 27, 2019",
  },
  {
    Områdes_ID: "2480-V-006",
    Projektnamn: "Hörnefors",
    Verksamhetsutövare: "AB Bostaden i Umeå",
    Organisationsnummer: "556500-2408",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 15,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Umeå",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2514-V-002",
    Projektnamn: "Axelsvik 1",
    Verksamhetsutövare: "Kalix Wind AB",
    Organisationsnummer: "559131-0908",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 11.7,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kalix",
    Län: "Norrbottens län",
    Elområde: "Luleå",
    Senast_sparad: "Aug 28, 2020",
  },
  {
    Områdes_ID: "0381-V-001",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Enköping",
    Län: "Uppsala län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0484-V-001",
    Projektnamn: "",
    Verksamhetsutövare: "Kafjärdens Vindkraft AB",
    Organisationsnummer: "556706-1931",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Eskilstuna",
    Län: "Södermanlands län",
    Elområde: "Stockholm",
    Senast_sparad: "May 04, 2021",
  },
  {
    Områdes_ID: "0484-V-002",
    Projektnamn: "",
    Verksamhetsutövare: "Kafjärdens Vindkraft AB",
    Organisationsnummer: "556706-1931",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Eskilstuna",
    Län: "Södermanlands län",
    Elområde: "Stockholm",
    Senast_sparad: "May 04, 2021",
  },
  {
    Områdes_ID: "0484-V-003",
    Projektnamn: "",
    Verksamhetsutövare: "Kafjärdens Vindkraft AB",
    Organisationsnummer: "556706-1931",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Eskilstuna",
    Län: "Södermanlands län",
    Elområde: "Stockholm",
    Senast_sparad: "May 04, 2021",
  },
  {
    Områdes_ID: "0484-V-006",
    Projektnamn: "Näshulta Åstorp",
    Verksamhetsutövare: "Grytet Åstorp AB",
    Organisationsnummer: "556075-2924",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Eskilstuna",
    Län: "Södermanlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 09, 2021",
  },
  {
    Områdes_ID: "0604-V-010",
    Projektnamn: "Brokafall",
    Verksamhetsutövare: "Brokafall Vind AB",
    Organisationsnummer: "556849-5203",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Aneby",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0604-V-012",
    Projektnamn: "Spinkhemmet",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Aneby",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0620-V-001",
    Projektnamn: "Ekekullen Dintestorp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 6.95,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mullsjö",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 26, 2019",
  },
  {
    Områdes_ID: "0834-V-007",
    Projektnamn: "Påboda",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 9.8,
    Uppmätt_årsproduktion_GWh: 10.7,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Torsås",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Nov 18, 2021",
  },
  {
    Områdes_ID: "0840-V-030",
    Projektnamn: "Skarpa Alby I",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mörbylånga",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0840-V-037",
    Projektnamn: "Ullevi I",
    Verksamhetsutövare: "JEA Lantbruks AB",
    Organisationsnummer: "556453-9020",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mörbylånga",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0840-V-038",
    Projektnamn: "Ullevi II",
    Verksamhetsutövare: "JEA Lantbruks AB",
    Organisationsnummer: "556453-9020",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mörbylånga",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0860-V-001",
    Projektnamn: "Ryningsnäs",
    Verksamhetsutövare: "Visby Energi AB",
    Organisationsnummer: "556283-8010",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 13,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Hultsfred",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0860-V-003",
    Projektnamn: "Holm",
    Verksamhetsutövare: "Billyvind AB",
    Organisationsnummer: "556705-6931",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hultsfred",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "0884-V-001",
    Projektnamn: "Vennebjörke",
    Verksamhetsutövare: "Vimmerby Energi & Miljö AB",
    Organisationsnummer: "556189-4352",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vimmerby",
    Län: "Kalmar län",
    Elområde: "Stockholm",
    Senast_sparad: "Mar 12, 2021",
  },
  {
    Områdes_ID: "0885-V-006",
    Projektnamn: "Vannborga",
    Verksamhetsutövare: "Kalmarsund Vind Ek. För.",
    Organisationsnummer: "769613-9661",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 3.1,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Borgholm",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2080-V-001",
    Projektnamn: "Högberget",
    Verksamhetsutövare: "Dala Vind AB",
    Organisationsnummer: "556696-6940",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 10,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falun",
    Län: "Dalarnas län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2085-V-003",
    Projektnamn: "Silkomhöjden",
    Verksamhetsutövare: "Slättens Vind ek för",
    Organisationsnummer: "769606-9082",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 10,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ludvika",
    Län: "Dalarnas län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2085-V-005",
    Projektnamn: "Gussjöberget",
    Verksamhetsutövare: "Kopparleden AB",
    Organisationsnummer: "556611-0093",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ludvika",
    Län: "Dalarnas län",
    Elområde: "Stockholm",
    Senast_sparad: "Dec 01, 2021",
  },
  {
    Områdes_ID: "2085-V-006",
    Projektnamn: "Sausberget",
    Verksamhetsutövare: "Kopparleden AB",
    Organisationsnummer: "556611-0093",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ludvika",
    Län: "Dalarnas län",
    Elområde: "Stockholm",
    Senast_sparad: "Dec 01, 2021",
  },
  {
    Områdes_ID: "2132-V-008",
    Projektnamn: "Vindpark Nyvallsåsen",
    Verksamhetsutövare: "Nyvallsåsen Drift AB",
    Organisationsnummer: "556884-0762",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 11.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Nordanstig",
    Län: "Gävleborgs län",
    Elområde: "Sundsvall",
    Senast_sparad: "Oct 07, 2021",
  },
  {
    Områdes_ID: "2182-V-010",
    Projektnamn: "Skogberget-Åsboberget verk 1",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Söderhamn",
    Län: "Gävleborgs län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2280-V-001",
    Projektnamn: "Vindkraft Vårdkasen",
    Verksamhetsutövare: "Härnösand Energi och Miljö AB",
    Organisationsnummer: "556526-3745",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 13.2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "May 01, 2011",
    Planerat_drifttagande: "Mar 01, 2012",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Härnösand",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2282-V-001",
    Projektnamn: "Vårdkallberget",
    Verksamhetsutövare: "Utavind kooperativa ekonomiska förening",
    Organisationsnummer: "769614-9140",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kramfors",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2305-V-001",
    Projektnamn: "Bodtjärnberget",
    Verksamhetsutövare: "Vindvision Norr AB",
    Organisationsnummer: "556719-9699",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Bräcke",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Aug 27, 2019",
  },
  {
    Områdes_ID: "2313-V-003",
    Projektnamn: "Harrsjön",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 4.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Strömsund",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Jan 09, 2019",
  },
  {
    Områdes_ID: "2313-V-005",
    Projektnamn: "Betåsberget",
    Verksamhetsutövare: "Ekovind AB",
    Organisationsnummer: "556343-8208",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "Oct 01, 2011",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Strömsund",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Jan 09, 2019",
  },
  {
    Områdes_ID: "2326-V-007",
    Projektnamn: "Handsjökrusen 1.",
    Verksamhetsutövare: "Vindvision Norr AB",
    Organisationsnummer: "556719-9699",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Berg",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Aug 27, 2019",
  },
  {
    Områdes_ID: "2480-V-008",
    Projektnamn: "Hörnefors",
    Verksamhetsutövare: "AB Bostaden i Umeå",
    Organisationsnummer: "556500-2408",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 10,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Umeå",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2482-V-003",
    Projektnamn: "Noret",
    Verksamhetsutövare: "GiroVind Energi AB",
    Organisationsnummer: "556680-8167",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Skellefteå",
    Län: "Västerbottens län",
    Elområde: "Luleå",
    Senast_sparad: "Feb 09, 2021",
  },
  {
    Områdes_ID: "2514-V-001",
    Projektnamn: "Storön",
    Verksamhetsutövare: "GE Maskintjänst AB",
    Organisationsnummer: "556435-7878",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 3.35,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kalix",
    Län: "Norrbottens län",
    Elområde: "Luleå",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0760-V-024",
    Projektnamn: "Holma",
    Verksamhetsutövare: "Kraftö AB",
    Organisationsnummer: "556708-7456",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Uppvidinge",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 10, 2021",
  },
  {
    Områdes_ID: "0767-V-006",
    Projektnamn: "Hässlehult",
    Verksamhetsutövare: "Arise Windpower AB",
    Organisationsnummer: "556274-6726",
    Aktuella_verk: 7,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Markaryd",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0767-V-008",
    Projektnamn: "Språxhult",
    Verksamhetsutövare: "Statkraft Södra Vindarrende AB",
    Organisationsnummer: "556091-8772",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Markaryd",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 17, 2019",
  },
  {
    Områdes_ID: "1060-V-001",
    Projektnamn: "Ljungryda",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 15,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Olofström",
    Län: "Blekinge län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1060-V-002",
    Projektnamn: "Ljungryda",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 20,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Olofström",
    Län: "Blekinge län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0760-V-029",
    Projektnamn: "PeBo Naturbruk",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 3,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Uppvidinge",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0780-V-004",
    Projektnamn: "Uråsa",
    Verksamhetsutövare: "Uråsa Vind AB",
    Organisationsnummer: "556721-9828",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 22,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Växjö",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0840-V-028",
    Projektnamn: "Ryd-Rönnerum",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 10,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mörbylånga",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0840-V-052",
    Projektnamn: "Ryd_Rönnerum_Högsrum",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mörbylånga",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2181-V-002",
    Projektnamn: "Åsen",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 12,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sandviken",
    Län: "Gävleborgs län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2182-V-002",
    Projektnamn: "Vindpark Klubbäcken",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Söderhamn",
    Län: "Gävleborgs län",
    Elområde: "Sundsvall",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "0139-V-001",
    Projektnamn: "Nyborg Ryggåsen",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Upplands-Bro",
    Län: "Stockholms län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 15, 2019",
  },
  {
    Områdes_ID: "1291-V-036",
    Projektnamn: "Hannas",
    Verksamhetsutövare: "Firma Ramström Vind AB",
    Organisationsnummer: "556688-2683",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 13.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Simrishamn",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Dec 13, 2019",
  },
  {
    Områdes_ID: "1214-V-016",
    Projektnamn: "Ängalid III & Torsnäs, Torrlösa",
    Verksamhetsutövare: "Mimer vind AB",
    Organisationsnummer: "556608-8760",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 4.4,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Svalöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1264-V-014",
    Projektnamn: "Kadesjö-Gussnava",
    Verksamhetsutövare: "Slitevind AB",
    Organisationsnummer: "556453-2819",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Skurup",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1266-V-042",
    Projektnamn: "Söderto-Mossarp",
    Verksamhetsutövare: "Billyvind AB",
    Organisationsnummer: "556705-6931",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hörby",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Dec 08, 2021",
  },
  {
    Områdes_ID: "1267-V-001",
    Projektnamn: "",
    Verksamhetsutövare: "Farina AB",
    Organisationsnummer: "556060-0743",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Höör",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1277-V-012",
    Projektnamn: "Tranarp Kärreberga",
    Verksamhetsutövare: "Billyvind AB",
    Organisationsnummer: "556705-6931",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Åstorp",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1277-V-021",
    Projektnamn: "Kärreberga",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Åstorp",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1281-V-016",
    Projektnamn: "Västanby gård",
    Verksamhetsutövare: "Västanby AB",
    Organisationsnummer: "556604-8392",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 3.2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Lund",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 08, 2020",
  },
  {
    Områdes_ID: "1285-V-001",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Eslöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 01, 2019",
  },
  {
    Områdes_ID: "1285-V-061",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Eslöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1286-V-006",
    Projektnamn: "Örum vindkraftpark",
    Verksamhetsutövare: "Stefan Widen AB",
    Organisationsnummer: "556651-9715",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1290-V-087",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 08, 2019",
  },
  {
    Områdes_ID: "1261-V-012",
    Projektnamn: "Södervidinge",
    Verksamhetsutövare: "Ekovind AB",
    Organisationsnummer: "556343-8208",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 16.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kävlinge",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 08, 2020",
  },
  {
    Områdes_ID: "1263-V-005",
    Projektnamn: "Snapparp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 5,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Svedala",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1263-V-015",
    Projektnamn: "Vinninge",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 16.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Svedala",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Mar 15, 2019",
  },
  {
    Områdes_ID: "1266-V-016",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hörby",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 11, 2019",
  },
  {
    Områdes_ID: "1270-V-025",
    Projektnamn: "Äsperöd",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 10.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tomelilla",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1281-V-008",
    Projektnamn: "",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Lund",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 09, 2019",
  },
  {
    Områdes_ID: "1283-V-078",
    Projektnamn: "Kvistofta-Katslösa",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 5,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Helsingborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1285-V-021",
    Projektnamn: "Odarslöv",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 14.7,
    Uppmätt_årsproduktion_GWh: 11.48,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Eslöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1285-V-027",
    Projektnamn: "Reslövsgården",
    Verksamhetsutövare: "Nier Maskin AB",
    Organisationsnummer: "556187-0543",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 6.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Eslöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 09, 2020",
  },
  {
    Områdes_ID: "1214-V-015",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Svalöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 05, 2019",
  },
  {
    Områdes_ID: "1214-V-028",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Svalöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1214-V-038",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Svalöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 05, 2019",
  },
  {
    Områdes_ID: "1214-V-043",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Svalöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 05, 2019",
  },
  {
    Områdes_ID: "1214-V-046",
    Projektnamn: "",
    Verksamhetsutövare: "BayWa r.e. Scandinavia AB",
    Organisationsnummer: "556885-6503",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Svalöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1214-V-050",
    Projektnamn: "Fäladen",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Svalöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 05, 2019",
  },
  {
    Områdes_ID: "1214-V-052",
    Projektnamn: "Tirup Hällstorp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 14,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Svalöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Mar 15, 2019",
  },
  {
    Områdes_ID: "1214-V-057",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Svalöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 05, 2019",
  },
  {
    Områdes_ID: "1230-V-007",
    Projektnamn: "Trolleberg",
    Verksamhetsutövare: "Vindproduktion i Flackarp AB",
    Organisationsnummer: "556559-7282",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 11,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Staffanstorp",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1230-V-010",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Staffanstorp",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1261-V-008",
    Projektnamn: "",
    Verksamhetsutövare: "Farina AB",
    Organisationsnummer: "556060-0743",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kävlinge",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1261-V-016",
    Projektnamn: "",
    Verksamhetsutövare: "Förvaltnings AB Nöten",
    Organisationsnummer: "556146-5120",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kävlinge",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "1261-V-019",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kävlinge",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "1262-V-007",
    Projektnamn: "",
    Verksamhetsutövare: "Västanby AB",
    Organisationsnummer: "556604-8392",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Lomma",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "1263-V-013",
    Projektnamn: "Tjustorp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Svedala",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1264-V-001",
    Projektnamn: "Kadesjö",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 2.8,
    Uppmätt_årsproduktion_GWh: 2.25,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Skurup",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1264-V-004",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Skurup",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Oct 09, 2019",
  },
  {
    Områdes_ID: "1265-V-003",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sjöbo",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1265-V-009",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Sjöbo",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "1265-V-012",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Sjöbo",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "1265-V-019",
    Projektnamn: "Klamby",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Sjöbo",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Mar 12, 2021",
  },
  {
    Områdes_ID: "1266-V-019",
    Projektnamn: "Lyby",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 10.4,
    Uppmätt_årsproduktion_GWh: 8.61,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Hörby",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 08, 2020",
  },
  {
    Områdes_ID: "1266-V-029",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hörby",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 29, 2019",
  },
  {
    Områdes_ID: "1266-V-043",
    Projektnamn: "Sallerup",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Hörby",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1267-V-002",
    Projektnamn: "",
    Verksamhetsutövare: "Billyvind AB",
    Organisationsnummer: "556705-6931",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Höör",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 17, 2019",
  },
  {
    Områdes_ID: "1267-V-005",
    Projektnamn: "Orup",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 7,
    Uppmätt_årsproduktion_GWh: 3.1,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Höör",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 08, 2020",
  },
  {
    Områdes_ID: "1270-V-003",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tomelilla",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1270-V-019",
    Projektnamn: "Valterslund",
    Verksamhetsutövare: "HB Valterslunds Vind",
    Organisationsnummer: "969700-8168",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 3.2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tomelilla",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 08, 2020",
  },
  {
    Områdes_ID: "1270-V-021",
    Projektnamn: "Everöd Vindkraftpark",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tomelilla",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Jan 26, 2021",
  },
  {
    Områdes_ID: "1270-V-028",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tomelilla",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "1270-V-043",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tomelilla",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1272-V-004",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Bromölla",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1276-V-005",
    Projektnamn: "",
    Verksamhetsutövare: "Älvsborgsvind AB (publ)",
    Organisationsnummer: "556508-4448",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Klippan",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1276-V-016",
    Projektnamn: "",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Klippan",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1276-V-018",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Klippan",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Oct 09, 2019",
  },
  {
    Områdes_ID: "1277-V-007",
    Projektnamn: "Hyllinge",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Åstorp",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1277-V-008",
    Projektnamn: "Hyllinge",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Åstorp",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1277-V-017",
    Projektnamn: "Sånna",
    Verksamhetsutövare: "Universal Wind AB",
    Organisationsnummer: "556571-8151",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Åstorp",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1277-V-018",
    Projektnamn: "Sånna",
    Verksamhetsutövare: "Skånska Energi AB",
    Organisationsnummer: "556013-6391",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.81,
    Uppmätt_årsproduktion_GWh: 1.31,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Åstorp",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1278-V-002",
    Projektnamn: "Sönnertorp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.78,
    Uppmätt_årsproduktion_GWh: 1.73,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Båstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1280-V-005",
    Projektnamn: "Norra hamnen",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Malmö",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1281-V-014",
    Projektnamn: "Värpinge",
    Verksamhetsutövare: "Kraftringen Produktion AB",
    Organisationsnummer: "556475-7119",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Lund",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1282-V-005",
    Projektnamn: "Annelöv",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 3.7,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Landskrona",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 08, 2020",
  },
  {
    Områdes_ID: "1283-V-062",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Helsingborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1283-V-084",
    Projektnamn: "Hyllstorp",
    Verksamhetsutövare: "GiroVind Energi AB",
    Organisationsnummer: "556680-8167",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Helsingborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1284-V-003",
    Projektnamn: "Ingelsträde",
    Verksamhetsutövare: "Öresundskraft AB",
    Organisationsnummer: "556089-7851",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 9.34,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Höganäs",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 08, 2020",
  },
  {
    Områdes_ID: "1284-V-004",
    Projektnamn: "Stora Görslöv-Täppeshusen",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Höganäs",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1284-V-006",
    Projektnamn: "Kullen",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 2.1,
    Uppmätt_årsproduktion_GWh: 1.95,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Höganäs",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1284-V-018",
    Projektnamn: "Stora Görslöv",
    Verksamhetsutövare: "Söderslätts Vind Investment AB",
    Organisationsnummer: "556485-8032",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Höganäs",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1285-V-011",
    Projektnamn: "Brönneslöv",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 2.7,
    Uppmätt_årsproduktion_GWh: 2.16,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Eslöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1285-V-048",
    Projektnamn: "Arup-Högseröd",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Eslöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1285-V-060",
    Projektnamn: "",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Eslöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 09, 2019",
  },
  {
    Områdes_ID: "1286-V-004",
    Projektnamn: "Bjäresjö",
    Verksamhetsutövare: "Söderslätts Vind Investment AB",
    Organisationsnummer: "556485-8032",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 3.2,
    Uppmätt_årsproduktion_GWh: 1.43,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 09, 2020",
  },
  {
    Områdes_ID: "1286-V-024",
    Projektnamn: "Slitevind Rynge",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 2.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1286-V-027",
    Projektnamn: "Slitevind Eriksfält",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 2.6,
    Uppmätt_årsproduktion_GWh: 2.6,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1286-V-028",
    Projektnamn: "Larsbo",
    Verksamhetsutövare: "Slitevind AB",
    Organisationsnummer: "556453-2819",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 2.6,
    Uppmätt_årsproduktion_GWh: 1.88,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1286-V-037",
    Projektnamn: "Marsvinsholm",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Aug 29, 2019",
  },
  {
    Områdes_ID: "1286-V-040",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 05, 2019",
  },
  {
    Områdes_ID: "1286-V-042",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1286-V-044",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1287-V-006",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Trelleborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 29, 2019",
  },
  {
    Områdes_ID: "1287-V-018",
    Projektnamn: "Gislöv",
    Verksamhetsutövare: "Gislövs Vind AB",
    Organisationsnummer: "556592-3025",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 3.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Trelleborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1287-V-023",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Trelleborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 10, 2019",
  },
  {
    Områdes_ID: "1287-V-028",
    Projektnamn: "Maglarp vindkraftpark",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Trelleborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1290-V-041",
    Projektnamn: "",
    Verksamhetsutövare: "Billyvind AB",
    Organisationsnummer: "556705-6931",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1290-V-050",
    Projektnamn: "Önnestad",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.64,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 15, 2019",
  },
  {
    Områdes_ID: "1290-V-051",
    Projektnamn: "Möllebacken",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 2.5,
    Uppmätt_årsproduktion_GWh: 1.87,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1290-V-053",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1290-V-054",
    Projektnamn: "Hovby",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 2.3,
    Uppmätt_årsproduktion_GWh: 2.19,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1290-V-055",
    Projektnamn: "Fjälkinge",
    Verksamhetsutövare: "Svinaberga Väderspänning AB",
    Organisationsnummer: "556554-6396",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 2.6,
    Uppmätt_årsproduktion_GWh: 2.07,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 09, 2020",
  },
  {
    Områdes_ID: "1290-V-065",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 12, 2019",
  },
  {
    Områdes_ID: "1290-V-080",
    Projektnamn: "Hassla",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1290-V-092",
    Projektnamn: "Rinkaby",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 10.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Oct 06, 2021",
  },
  {
    Områdes_ID: "1290-V-099",
    Projektnamn: "Nöbbelöv",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1290-V-103",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 12, 2019",
  },
  {
    Områdes_ID: "1290-V-106",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1290-V-108",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1290-V-109",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1290-V-111",
    Projektnamn: "Karsholm",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 29, 2019",
  },
  {
    Områdes_ID: "1290-V-112",
    Projektnamn: "Legeved",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 10,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1291-V-005",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Simrishamn",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 03, 2019",
  },
  {
    Områdes_ID: "1291-V-006",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Simrishamn",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 03, 2019",
  },
  {
    Områdes_ID: "1291-V-011",
    Projektnamn: "Simris1",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.35,
    Uppmätt_årsproduktion_GWh: 1.32,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Simrishamn",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1291-V-017",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Simrishamn",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 03, 2019",
  },
  {
    Områdes_ID: "1291-V-030",
    Projektnamn: "Karlaby",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Simrishamn",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1291-V-034",
    Projektnamn: "Rörum",
    Verksamhetsutövare: "Universal Power AB",
    Organisationsnummer: "556726-3859",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Simrishamn",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1291-V-037",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Simrishamn",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1291-V-040",
    Projektnamn: "Olofsfält",
    Verksamhetsutövare: "Olofsfälts Vind AB",
    Organisationsnummer: "559100-3883",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Simrishamn",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 06, 2020",
  },
  {
    Områdes_ID: "1292-V-006",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ängelholm",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Oct 10, 2019",
  },
  {
    Områdes_ID: "1292-V-014",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ängelholm",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Oct 10, 2019",
  },
  {
    Områdes_ID: "1292-V-021",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ängelholm",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Oct 10, 2019",
  },
  {
    Områdes_ID: "1292-V-026",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ängelholm",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Oct 10, 2019",
  },
  {
    Områdes_ID: "1292-V-028",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ängelholm",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1292-V-032",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ängelholm",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Oct 10, 2019",
  },
  {
    Områdes_ID: "1293-V-002",
    Projektnamn: "",
    Verksamhetsutövare: "Billyvind AB",
    Organisationsnummer: "556705-6931",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Hässleholm",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Aug 28, 2019",
  },
  {
    Områdes_ID: "1214-V-018",
    Projektnamn: "Lönnstorp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Svalöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 05, 2019",
  },
  {
    Områdes_ID: "1214-V-026",
    Projektnamn: "Halmstadgården",
    Verksamhetsutövare: "Halmstadgården Vindpark AB",
    Organisationsnummer: "556857-5566",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 16.2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Svalöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 08, 2020",
  },
  {
    Områdes_ID: "1214-V-031",
    Projektnamn: "Lönnstorp",
    Verksamhetsutövare: "BayWa r.e. Scandinavia AB",
    Organisationsnummer: "556885-6503",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Svalöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1230-V-009",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Staffanstorp",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Jan 24, 2019",
  },
  {
    Områdes_ID: "1261-V-007",
    Projektnamn: "Södervidinge",
    Verksamhetsutövare: "Universal Wind AB",
    Organisationsnummer: "556571-8151",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kävlinge",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1261-V-013",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kävlinge",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Mar 27, 2019",
  },
  {
    Områdes_ID: "1265-V-001",
    Projektnamn: "",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Sjöbo",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "1265-V-004",
    Projektnamn: "Alestad-Hårderup",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Sjöbo",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "May 15, 2019",
  },
  {
    Områdes_ID: "1265-V-005",
    Projektnamn: "Klamby",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Sjöbo",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "1265-V-006",
    Projektnamn: "Klamby",
    Verksamhetsutövare: "Söderslätts Vind Investment AB",
    Organisationsnummer: "556485-8032",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Sjöbo",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 29, 2019",
  },
  {
    Områdes_ID: "1265-V-010",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Sjöbo",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "1265-V-016",
    Projektnamn: "Assmåsa",
    Verksamhetsutövare: "Tre Vind AB",
    Organisationsnummer: "556594-2363",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 10.41,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sjöbo",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 08, 2020",
  },
  {
    Områdes_ID: "1265-V-017",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Sjöbo",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "1265-V-018",
    Projektnamn: "Alestad-Hårderup",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Sjöbo",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Mar 12, 2021",
  },
  {
    Områdes_ID: "1266-V-021",
    Projektnamn: "Huggelseke-Bessinge",
    Verksamhetsutövare: "Östra Sallerup Vind AB",
    Organisationsnummer: "556847-2871",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 16.2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Hörby",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1267-V-003",
    Projektnamn: "",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Höör",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 17, 2019",
  },
  {
    Områdes_ID: "1267-V-006",
    Projektnamn: "Brostorp-Snogeröd",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 16.5,
    Uppmätt_årsproduktion_GWh: 15.14,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Höör",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 08, 2020",
  },
  {
    Områdes_ID: "1270-V-009",
    Projektnamn: "Everöd Vindkraftpark",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tomelilla",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "1270-V-022",
    Projektnamn: "Ramsåsa",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 4.85,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tomelilla",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 08, 2020",
  },
  {
    Områdes_ID: "1270-V-023",
    Projektnamn: "Ingelstadgården",
    Verksamhetsutövare: "Ingelsta Gården AB",
    Organisationsnummer: "556404-2165",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 19,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tomelilla",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 08, 2020",
  },
  {
    Områdes_ID: "1270-V-045",
    Projektnamn: "Bollerup",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tomelilla",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1272-V-003",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Bromölla",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1278-V-006",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Båstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1278-V-008",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Båstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1278-V-011",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 6,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Båstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1281-V-009",
    Projektnamn: "Stångby",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Lund",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1281-V-017",
    Projektnamn: "Odarslöv",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 16.2,
    Uppmätt_årsproduktion_GWh: 12.49,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Lund",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1281-V-020",
    Projektnamn: "Stångby",
    Verksamhetsutövare: "Stångby Vind AB",
    Organisationsnummer: "559015-0701",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 16.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Lund",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Jan 04, 2021",
  },
  {
    Områdes_ID: "1282-V-010",
    Projektnamn: "Örja",
    Verksamhetsutövare: "RWE Renewables Sweden AB",
    Organisationsnummer: "556938-6864",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 17.58,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Landskrona",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 22, 2021",
  },
  {
    Områdes_ID: "1283-V-053",
    Projektnamn: "",
    Verksamhetsutövare: "Öresundskraft AB",
    Organisationsnummer: "556089-7851",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Helsingborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1283-V-055",
    Projektnamn: "Kulla Gunnarstorp",
    Verksamhetsutövare: "Öresundskraft AB",
    Organisationsnummer: "556089-7851",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Helsingborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1283-V-065",
    Projektnamn: "Horsahagen",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Helsingborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "May 02, 2019",
  },
  {
    Områdes_ID: "1284-V-002",
    Projektnamn: "Gunnestorp",
    Verksamhetsutövare: "Gunnestorps Miljö Energi AB",
    Organisationsnummer: "556663-8614",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 12,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Höganäs",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Mar 15, 2019",
  },
  {
    Områdes_ID: "1284-V-010",
    Projektnamn: "Glimminge",
    Verksamhetsutövare: "Glimminge Vindsamfällighetsförening",
    Organisationsnummer: "716439-1877",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.4,
    Uppmätt_årsproduktion_GWh: 0.83,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Höganäs",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 08, 2020",
  },
  {
    Områdes_ID: "1285-V-003",
    Projektnamn: "",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Eslöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1285-V-007",
    Projektnamn: "",
    Verksamhetsutövare: "BayWa r.e. Scandinavia AB",
    Organisationsnummer: "556885-6503",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Eslöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1285-V-009",
    Projektnamn: "Värlinge gård",
    Verksamhetsutövare: "Universal Wind AB",
    Organisationsnummer: "556571-8151",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 11,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Eslöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 09, 2020",
  },
  {
    Områdes_ID: "1285-V-010",
    Projektnamn: "Rävatorpet",
    Verksamhetsutövare: "Borlunda Kraft AB",
    Organisationsnummer: "556655-8853",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Eslöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Jan 25, 2019",
  },
  {
    Områdes_ID: "1285-V-022",
    Projektnamn: "Västrabygård",
    Verksamhetsutövare: "Västraby Vindpark AB",
    Organisationsnummer: "556661-8491",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 14.4,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Eslöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 08, 2020",
  },
  {
    Områdes_ID: "1285-V-026",
    Projektnamn: "Slättäng",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 4.5,
    Uppmätt_årsproduktion_GWh: 3.49,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Eslöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 09, 2020",
  },
  {
    Områdes_ID: "1285-V-029",
    Projektnamn: "Äspinge-Klemedstorp",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 22.4,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Eslöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 09, 2020",
  },
  {
    Områdes_ID: "1285-V-049",
    Projektnamn: "Gårdstånga",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Eslöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1286-V-002",
    Projektnamn: "Ruuthsbo-Kärragården",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 6,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1286-V-003",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1286-V-014",
    Projektnamn: "Ystad hamn",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 15, 2021",
  },
  {
    Områdes_ID: "1286-V-019",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Aug 29, 2019",
  },
  {
    Områdes_ID: "1286-V-046",
    Projektnamn: "St Herrestad",
    Verksamhetsutövare: "Von Görtz Vind AB",
    Organisationsnummer: "556812-3169",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 16.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1287-V-001",
    Projektnamn: "Stora Beddinge",
    Verksamhetsutövare: "Söderslätts Vind Investment AB",
    Organisationsnummer: "556485-8032",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Trelleborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 27, 2019",
  },
  {
    Områdes_ID: "1287-V-004",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Trelleborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 10, 2019",
  },
  {
    Områdes_ID: "1287-V-017",
    Projektnamn: "Brönnestad-Hönsinge",
    Verksamhetsutövare: "Vindenergi Syd AB",
    Organisationsnummer: "556604-5901",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5.21,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Trelleborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 09, 2020",
  },
  {
    Områdes_ID: "1287-V-019",
    Projektnamn: "Stora Jordberga",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 18.9,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Trelleborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1287-V-025",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Trelleborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 10, 2019",
  },
  {
    Områdes_ID: "1287-V-035",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Trelleborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Mar 29, 2019",
  },
  {
    Områdes_ID: "1287-V-040",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Trelleborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Mar 29, 2019",
  },
  {
    Områdes_ID: "1290-V-036",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 05, 2019",
  },
  {
    Områdes_ID: "1290-V-045",
    Projektnamn: "Lyngby",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 15, 2021",
  },
  {
    Områdes_ID: "1290-V-056",
    Projektnamn: "Gärds-Köpinge",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 5,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 7.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1290-V-059",
    Projektnamn: "Lyngbygård",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 20,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 09, 2020",
  },
  {
    Områdes_ID: "1290-V-072",
    Projektnamn: "Isgrannatorp",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1290-V-081",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1290-V-102",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1290-V-104",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1290-V-105",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 15, 2021",
  },
  {
    Områdes_ID: "1291-V-007",
    Projektnamn: "Östra Herrestad Vindkraftpark",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Simrishamn",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1291-V-018",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Simrishamn",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 03, 2019",
  },
  {
    Områdes_ID: "1291-V-019",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Simrishamn",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 15, 2021",
  },
  {
    Områdes_ID: "1291-V-021",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Simrishamn",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1291-V-027",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Simrishamn",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 15, 2021",
  },
  {
    Områdes_ID: "1292-V-002",
    Projektnamn: "Ingelstorp Strövelstorp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ängelholm",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1292-V-009",
    Projektnamn: "Össjö vindkraftpark",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ängelholm",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 15, 2021",
  },
  {
    Områdes_ID: "1292-V-024",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ängelholm",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Oct 10, 2019",
  },
  {
    Områdes_ID: "1292-V-029",
    Projektnamn: "Skörpinge",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ängelholm",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1293-V-016",
    Projektnamn: "",
    Verksamhetsutövare: "Statkraft Södra Vindarrende AB",
    Organisationsnummer: "556091-8772",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hässleholm",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 17, 2019",
  },
  {
    Områdes_ID: "1263-V-003",
    Projektnamn: "Klågerup",
    Verksamhetsutövare: "BayWa r.e. Scandinavia AB",
    Organisationsnummer: "556885-6503",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 16.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Svedala",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 08, 2020",
  },
  {
    Områdes_ID: "1281-V-019",
    Projektnamn: "Stångby",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Lund",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1287-V-003",
    Projektnamn: "Jordberga",
    Verksamhetsutövare: "Älvsborgsvind AB (publ)",
    Organisationsnummer: "556508-4448",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Trelleborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1290-V-058",
    Projektnamn: "Kiaby",
    Verksamhetsutövare: "Akka Vind AB",
    Organisationsnummer: "556711-2015",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 19.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 09, 2020",
  },
  {
    Områdes_ID: "0509-V-025",
    Projektnamn: "",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 10,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ödeshög",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0834-V-012",
    Projektnamn: "Påboda",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Torsås",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Nov 18, 2021",
  },
  {
    Områdes_ID: "0834-V-015",
    Projektnamn: "Påboda",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 10,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Torsås",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Jan 11, 2019",
  },
  {
    Områdes_ID: "0834-V-019",
    Projektnamn: "Kvilla 2",
    Verksamhetsutövare: "Firma Ramström Vind AB",
    Organisationsnummer: "556688-2683",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 10,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Torsås",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Jan 11, 2019",
  },
  {
    Områdes_ID: "0834-V-021",
    Projektnamn: "Finlabo",
    Verksamhetsutövare: "Svenska Vindbolaget AB",
    Organisationsnummer: "556759-9013",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Torsås",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 06, 2019",
  },
  {
    Områdes_ID: "0885-V-029",
    Projektnamn: "Hunderum",
    Verksamhetsutövare: "Södra Statkraft Vindkraft Utveckling AB",
    Organisationsnummer: "556785-3865",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Borgholm",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "0885-V-042",
    Projektnamn: "Långlöt",
    Verksamhetsutövare: "Universal Wind AB",
    Organisationsnummer: "556571-8151",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Borgholm",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "1286-V-056",
    Projektnamn: "",
    Verksamhetsutövare: "Slitevind AB",
    Organisationsnummer: "556453-2819",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1286-V-064",
    Projektnamn: "",
    Verksamhetsutövare: "Kraftö AB",
    Organisationsnummer: "556708-7456",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1286-V-079",
    Projektnamn: "",
    Verksamhetsutövare: "Slitevind AB",
    Organisationsnummer: "556453-2819",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1286-V-081",
    Projektnamn: "",
    Verksamhetsutövare: "Siral System & Co AB",
    Organisationsnummer: "556171-8296",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1286-V-088",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1286-V-091",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1286-V-102",
    Projektnamn: "",
    Verksamhetsutövare: "Slitevind AB",
    Organisationsnummer: "556453-2819",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1286-V-106",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Aug 27, 2019",
  },
  {
    Områdes_ID: "1286-V-107",
    Projektnamn: "",
    Verksamhetsutövare: "Agrivind AB",
    Organisationsnummer: "556242-1387",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1286-V-110",
    Projektnamn: "",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0834-V-017",
    Projektnamn: "Strömby/Karlshult",
    Verksamhetsutövare: "Firma Ramström Vind AB",
    Organisationsnummer: "556688-2683",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Torsås",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "0885-V-017",
    Projektnamn: "Långöre",
    Verksamhetsutövare: "Firma Ramström Vind AB",
    Organisationsnummer: "556688-2683",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 7.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Borgholm",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0885-V-020",
    Projektnamn: "Rönnerum",
    Verksamhetsutövare: "Ryd-Rönnerm Drift AB",
    Organisationsnummer: "556844-5612",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 15,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Borgholm",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0885-V-027",
    Projektnamn: "Egby 2",
    Verksamhetsutövare: "Universal Power AB",
    Organisationsnummer: "556726-3859",
    Aktuella_verk: 5,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 24.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Borgholm",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0885-V-032",
    Projektnamn: "Långöre",
    Verksamhetsutövare: "Firma Ramström Vind AB",
    Organisationsnummer: "556688-2683",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Borgholm",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0885-V-034",
    Projektnamn: "Melösa",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Borgholm",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Aug 27, 2019",
  },
  {
    Områdes_ID: "0885-V-040",
    Projektnamn: "Mellböda",
    Verksamhetsutövare: "RWE Renewables Sweden AB",
    Organisationsnummer: "556938-6864",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Borgholm",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 22, 2021",
  },
  {
    Områdes_ID: "0885-V-044",
    Projektnamn: "Egby",
    Verksamhetsutövare: "Egby Vindkraftverk AB",
    Organisationsnummer: "556760-5919",
    Aktuella_verk: 5,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 26,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Borgholm",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1286-V-076",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Aug 29, 2019",
  },
  {
    Områdes_ID: "1286-V-077",
    Projektnamn: "",
    Verksamhetsutövare: "Slitevind AB",
    Organisationsnummer: "556453-2819",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1286-V-111",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 15, 2021",
  },
  {
    Områdes_ID: "1382-V-044",
    Projektnamn: "Vertical Wind",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falkenberg",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "Aug 20, 2020",
  },
  {
    Områdes_ID: "1382-V-045",
    Projektnamn: "Ekängen",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Falkenberg",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "Aug 28, 2019",
  },
  {
    Områdes_ID: "2283-V-004",
    Projektnamn: "Nallkullen",
    Verksamhetsutövare: "Väktaren Vind AB",
    Organisationsnummer: "556628-7578",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Sollefteå",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "Dec 07, 2021",
  },
  {
    Områdes_ID: "2326-V-009",
    Projektnamn: "Björnskallen",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 24.99,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Berg",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2380-V-001",
    Projektnamn: "Bjärme",
    Verksamhetsutövare: "Näsbygdens vind AB",
    Organisationsnummer: "556779-6064",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 25,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Östersund",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2321-V-012",
    Projektnamn: "Ytterocke",
    Verksamhetsutövare: "Ytterocke Energi AB",
    Organisationsnummer: "556802-8681",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Åre",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Sep 10, 2020",
  },
  {
    Områdes_ID: "2321-V-008",
    Projektnamn: "Kamsåsen",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 2,
    Beräknad_årsproduktion_GWh: 21.99,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Åre",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Nov 26, 2021",
  },
  {
    Områdes_ID: "0428-V-002",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vingåker",
    Län: "Södermanlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Jan 15, 2019",
  },
  {
    Områdes_ID: "0480-V-001",
    Projektnamn: "Runtuna",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Nyköping",
    Län: "Södermanlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0484-V-009",
    Projektnamn: "Alvesta",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Eskilstuna",
    Län: "Södermanlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0483-V-007",
    Projektnamn: "Haneberg",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Katrineholm",
    Län: "Södermanlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Jan 15, 2019",
  },
  {
    Områdes_ID: "0428-V-004",
    Projektnamn: "Stavhälla",
    Verksamhetsutövare: "Prästlönetillgångar i Strängnäs Stift",
    Organisationsnummer: "817605-7688",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 10.4,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vingåker",
    Län: "Södermanlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0486-V-001",
    Projektnamn: "Näs/Knutsberg",
    Verksamhetsutövare: "Prästlönetillgångar i Strängnäs Stift",
    Organisationsnummer: "817605-7688",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Strängnäs",
    Län: "Södermanlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 09, 2021",
  },
  {
    Områdes_ID: "0488-V-001",
    Projektnamn: "Överåda",
    Verksamhetsutövare: "Cloudberry Offshore Wind AB",
    Organisationsnummer: "556710-7403",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Trosa",
    Län: "Södermanlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Apr 08, 2019",
  },
  {
    Områdes_ID: "0643-V-003",
    Projektnamn: "Gölhult",
    Verksamhetsutövare: "Greenextreme AB",
    Organisationsnummer: "556722-4596",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Habo",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "Aug 26, 2019",
  },
  {
    Områdes_ID: "0643-V-011",
    Projektnamn: "Äskhult",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Habo",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "Mar 23, 2021",
  },
  {
    Områdes_ID: "0662-V-002",
    Projektnamn: "",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 10,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Gislaved",
    Län: "Jönköpings län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0682-V-004",
    Projektnamn: "Topperyd 3 och 4",
    Verksamhetsutövare: "Bixia ProWin AB",
    Organisationsnummer: "556884-4848",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 10.36,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Nässjö",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0682-V-005",
    Projektnamn: "Topperyd 1 och 2",
    Verksamhetsutövare: "Bixia Gryningsvind AB",
    Organisationsnummer: "556779-5348",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 10.36,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Nässjö",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0683-V-003",
    Projektnamn: "Vallerstad Vind",
    Verksamhetsutövare: "Vallerstad Vind AB",
    Organisationsnummer: "556765-7522",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 13,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Värnamo",
    Län: "Jönköpings län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0685-V-006",
    Projektnamn: "Lemhults Torp",
    Verksamhetsutövare: "Billyvind AB",
    Organisationsnummer: "556705-6931",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vetlanda",
    Län: "Jönköpings län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0687-V-003",
    Projektnamn: "Oberga",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 10,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "Jan 01, 2012",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tranås",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 17, 2019",
  },
  {
    Områdes_ID: "0480-V-002",
    Projektnamn: "Ytterbostugan",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Nyköping",
    Län: "Södermanlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0486-V-002",
    Projektnamn: "Selaön",
    Verksamhetsutövare: "Kraftö AB",
    Organisationsnummer: "556708-7456",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Strängnäs",
    Län: "Södermanlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0642-V-002",
    Projektnamn: "Pirkafjäll",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mullsjö",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 10, 2021",
  },
  {
    Områdes_ID: "0642-V-009",
    Projektnamn: "Pirkafjäll/Kyrkarp",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mullsjö",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "Apr 16, 2019",
  },
  {
    Områdes_ID: "0642-V-012",
    Projektnamn: "Smedstorp",
    Verksamhetsutövare: "Triventus AB",
    Organisationsnummer: "556627-3016",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mullsjö",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0643-V-005",
    Projektnamn: "Tumbäck",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Habo",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0643-V-009",
    Projektnamn: "Skogshemmet(säcken)",
    Verksamhetsutövare: "Triventus AB",
    Organisationsnummer: "556627-3016",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Habo",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0643-V-010",
    Projektnamn: "Tumbäck",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Habo",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0662-V-003",
    Projektnamn: "Karaby/Kållerstad vindkraftsprojekt",
    Verksamhetsutövare: "Gothia Vind 10 AB",
    Organisationsnummer: "556804-2443",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 29,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Gislaved",
    Län: "Jönköpings län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0662-V-004",
    Projektnamn: "Trollabergens vindkraftsprojekt",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Gislaved",
    Län: "Jönköpings län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 10, 2021",
  },
  {
    Områdes_ID: "0662-V-005",
    Projektnamn: "Norra Bohults vindkraftsprojekt",
    Verksamhetsutövare: "ENO- Energy Sweden AB",
    Organisationsnummer: "556877-9598",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 18,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Gislaved",
    Län: "Jönköpings län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0662-V-009",
    Projektnamn: "Käshults vindkraftsprojekt",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 33,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Gislaved",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0680-V-003",
    Projektnamn: "Gunillaberg",
    Verksamhetsutövare: "Vindpark Gunillaberg AB",
    Organisationsnummer: "559077-9079",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 28.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Jönköping",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "Mar 13, 2020",
  },
  {
    Områdes_ID: "0680-V-006",
    Projektnamn: "Brahehus",
    Verksamhetsutövare: "Brahehus Vind AB",
    Organisationsnummer: "556720-7443",
    Aktuella_verk: 9,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 55.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Jönköping",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "Mar 13, 2020",
  },
  {
    Områdes_ID: "0680-V-008",
    Projektnamn: "Sötterfällan",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 10,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 128,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "Aug 01, 2019",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Jönköping",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "Nov 07, 2019",
  },
  {
    Områdes_ID: "0680-V-010",
    Projektnamn: "Lyckås",
    Verksamhetsutövare: "Kraftö AB",
    Organisationsnummer: "556708-7456",
    Aktuella_verk: 14,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Jönköping",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "Nov 26, 2021",
  },
  {
    Områdes_ID: "0680-V-012",
    Projektnamn: "Tuggarpsgruppen",
    Verksamhetsutövare: "Jönköping Energi AB",
    Organisationsnummer: "556015-3354",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 23,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Jönköping",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "Jan 09, 2019",
  },
  {
    Områdes_ID: "0682-V-006",
    Projektnamn: "Hägganäs",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Nässjö",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0682-V-008",
    Projektnamn: "Hallhult",
    Verksamhetsutövare: "Arise Windpower AB",
    Organisationsnummer: "556274-6726",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Nässjö",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0682-V-009",
    Projektnamn: "Packebo",
    Verksamhetsutövare: "Arise Windpower AB",
    Organisationsnummer: "556274-6726",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Nässjö",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "Mar 20, 2019",
  },
  {
    Områdes_ID: "0682-V-010",
    Projektnamn: "Hylte",
    Verksamhetsutövare: "Bixia Byggvind AB",
    Organisationsnummer: "556785-1208",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Nässjö",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0682-V-011",
    Projektnamn: "Vindpark Höglandet",
    Verksamhetsutövare: "Statkraft Södra Vindkraft AB",
    Organisationsnummer: "556785-3873",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Nässjö",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0682-V-012",
    Projektnamn: "Fredriksdal",
    Verksamhetsutövare: "Bixia ProWin AB",
    Organisationsnummer: "556884-4848",
    Aktuella_verk: 15,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 85.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Nässjö",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 10, 2021",
  },
  {
    Områdes_ID: "0683-V-001",
    Projektnamn: "Gällaryd-Gripenberg",
    Verksamhetsutövare: "H2 Vind AB",
    Organisationsnummer: "556776-2603",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 22.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Värnamo",
    Län: "Jönköpings län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0683-V-004",
    Projektnamn: "Hindsen",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Värnamo",
    Län: "Jönköpings län",
    Elområde: "Malmö",
    Senast_sparad: "Oct 02, 2019",
  },
  {
    Områdes_ID: "0685-V-003",
    Projektnamn: "Äsprilla, Fröderyd",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 15,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vetlanda",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0685-V-007",
    Projektnamn: "Stensåsa",
    Verksamhetsutövare: "Stenåsa Vindkraft AB",
    Organisationsnummer: "556911-5370",
    Aktuella_verk: 7,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 38.99,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vetlanda",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0685-V-009",
    Projektnamn: "Lilla Götestorp",
    Verksamhetsutövare: "Billyvind AB",
    Organisationsnummer: "556705-6931",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vetlanda",
    Län: "Jönköpings län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 10, 2021",
  },
  {
    Områdes_ID: "0685-V-010",
    Projektnamn: "Skäftesfall",
    Verksamhetsutövare: "Statkraft Södra Vindkraft AB",
    Organisationsnummer: "556785-3873",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vetlanda",
    Län: "Jönköpings län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0687-V-002",
    Projektnamn: "Adelöv",
    Verksamhetsutövare: "Adelöv Vind AB",
    Organisationsnummer: "556809-1986",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tranås",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 06, 2019",
  },
  {
    Områdes_ID: "0662-V-006",
    Projektnamn: "",
    Verksamhetsutövare: "Kraftö AB",
    Organisationsnummer: "556708-7456",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Gislaved",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0685-V-004",
    Projektnamn: "Äskås-Harshult (10 verk varav 2 i Vetlanda. Ansöka",
    Verksamhetsutövare: "Statkraft Södra Vindkraft AB",
    Organisationsnummer: "556785-3873",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vetlanda",
    Län: "Jönköpings län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0484-V-013",
    Projektnamn: "Haneberg",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Eskilstuna",
    Län: "Södermanlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0642-V-004",
    Projektnamn: "Ryfors",
    Verksamhetsutövare: "BayWa r.e. Scandinavia AB",
    Organisationsnummer: "556885-6503",
    Aktuella_verk: 9,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mullsjö",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "Nov 12, 2021",
  },
  {
    Områdes_ID: "0680-V-007",
    Projektnamn: "Örserum",
    Verksamhetsutövare: "RWE Renewables Sweden AB",
    Organisationsnummer: "556938-6864",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Jönköping",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 22, 2021",
  },
  {
    Områdes_ID: "0683-V-002",
    Projektnamn: "Solslätt",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Värnamo",
    Län: "Jönköpings län",
    Elområde: "Malmö",
    Senast_sparad: "Mar 12, 2021",
  },
  {
    Områdes_ID: "0685-V-011",
    Projektnamn: "Lemnhult",
    Verksamhetsutövare: "Lemnhult Energi AB",
    Organisationsnummer: "556711-9564",
    Aktuella_verk: 32,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 269.76,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vetlanda",
    Län: "Jönköpings län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0687-V-001",
    Projektnamn: "Skogsvind",
    Verksamhetsutövare: "Luveryd Vindkraft AB",
    Organisationsnummer: "556756-5766",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 26,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tranås",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 17, 2019",
  },
  {
    Områdes_ID: "0687-V-005",
    Projektnamn: "Hålaveden(Lyngsbergen)",
    Verksamhetsutövare: "Triventus AB",
    Organisationsnummer: "556627-3016",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tranås",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0980-V-005",
    Projektnamn: "Olsvenne 2",
    Verksamhetsutövare: "Vattenfall Vindkraft AB",
    Organisationsnummer: "556581-4273",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0980-V-019",
    Projektnamn: "Näs Sigsarve Sladdkvenni",
    Verksamhetsutövare: "Gotlandsvind AB",
    Organisationsnummer: "556823-4651",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.43,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0980-V-020",
    Projektnamn: "Grötlingbo Kauparve Mårten 1",
    Verksamhetsutövare: "Grötlingbo Vind Samfällighetsförening",
    Organisationsnummer: "716404-8527",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.46,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0980-V-021",
    Projektnamn: "Östergarn Bengts",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0980-V-022",
    Projektnamn: "Barlingbo Stave Stafva",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.31,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0980-V-024",
    Projektnamn: "Fole Stora Ryftes - Ryftes",
    Verksamhetsutövare: "Ryftes Energi och Förvaltning AB",
    Organisationsnummer: "556272-4236",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.15,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0980-V-025",
    Projektnamn: "Lummelunda Tjauls",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.32,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0980-V-026",
    Projektnamn: "Grötlingbo Sigsarve Vindudd IV",
    Verksamhetsutövare: "Vindudd AB",
    Organisationsnummer: "556529-5325",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.1,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0980-V-027",
    Projektnamn: "Grötlingbo Skradsarve Vindudd V",
    Verksamhetsutövare: "Vindudd AB",
    Organisationsnummer: "556529-5325",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.1,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0980-V-028",
    Projektnamn: "Lärbro Hall Hägvards I Hägvards Hall",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.41,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0980-V-030",
    Projektnamn: "Lanthamnen Klinte",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1,
    Uppmätt_årsproduktion_GWh: 0.75,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0980-V-032",
    Projektnamn: "När Siglajvs 1 & 2",
    Verksamhetsutövare: "Balingsta Vind HB",
    Organisationsnummer: "969623-2165",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 2.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Jan 18, 2021",
  },
  {
    Områdes_ID: "0980-V-033",
    Projektnamn: "Grötlingbo Roes Grötlingbo 1 Agri",
    Verksamhetsutövare: "Agrivind AB",
    Organisationsnummer: "556242-1387",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.58,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0980-V-034",
    Projektnamn: "Havdhem Bols Ryftes Hulda [Kulle 2]",
    Verksamhetsutövare: "Siral System & Co AB",
    Organisationsnummer: "556171-8296",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.3,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Jan 10, 2019",
  },
  {
    Områdes_ID: "0980-V-035",
    Projektnamn: "Hablingbo Stora Burge",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.72,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0980-V-036",
    Projektnamn: "Klinte Strands Isak",
    Verksamhetsutövare: "Stefan Widen AB",
    Organisationsnummer: "556651-9715",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.3,
    Uppmätt_årsproduktion_GWh: 0.96,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0980-V-038",
    Projektnamn: "Hangvar Kyrkebys 2",
    Verksamhetsutövare: "Dahlin Energy AB",
    Organisationsnummer: "556526-0626",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.2,
    Uppmätt_årsproduktion_GWh: 0.9,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0980-V-039",
    Projektnamn: "Fole Stora Sojdeby - Ryftes Kristina",
    Verksamhetsutövare: "Ryftes Energi och Förvaltning AB",
    Organisationsnummer: "556272-4236",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.2,
    Uppmätt_årsproduktion_GWh: 0.81,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0980-V-045",
    Projektnamn: "Näs Levide Selma",
    Verksamhetsutövare: "Sjölunda Vindpark AB",
    Organisationsnummer: "556561-9276",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 2.7,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0980-V-046",
    Projektnamn: "Hangvar Kyrkebys 1",
    Verksamhetsutövare: "Dahlin Energy AB",
    Organisationsnummer: "556526-0626",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.2,
    Uppmätt_årsproduktion_GWh: 0.9,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0980-V-052",
    Projektnamn: "Hablingbo Stjups",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.72,
    Uppmätt_årsproduktion_GWh: 1.76,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "May 03, 2019",
  },
  {
    Områdes_ID: "0980-V-058",
    Projektnamn: "Hellvi Stengrinde",
    Verksamhetsutövare: "Hellvi Vind AB",
    Organisationsnummer: "556866-0590",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 06, 2021",
  },
  {
    Områdes_ID: "0980-V-002",
    Projektnamn: "Smöjen vindpark 1 [Slitevind XX]",
    Verksamhetsutövare: "Slitevind AB",
    Organisationsnummer: "556453-2819",
    Aktuella_verk: 10,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 27,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Jan 09, 2019",
  },
  {
    Områdes_ID: "0980-V-004",
    Projektnamn: "Kulle vindpark",
    Verksamhetsutövare: "Siral System & Co AB",
    Organisationsnummer: "556171-8296",
    Aktuella_verk: 12,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 19.59,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Jan 10, 2019",
  },
  {
    Områdes_ID: "0980-V-008",
    Projektnamn: "Gansparken 1",
    Verksamhetsutövare: "Gansnäs driftbolag AB",
    Organisationsnummer: "556748-9876",
    Aktuella_verk: 6,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 32.6,
    Uppmätt_årsproduktion_GWh: 26.66,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0980-V-009",
    Projektnamn: "Näsudden Väst N1",
    Verksamhetsutövare: "Näsudden Väst Administration",
    Organisationsnummer: "556655-4803",
    Aktuella_verk: 12,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 90,
    Uppmätt_årsproduktion_GWh: 100.64,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0980-V-010",
    Projektnamn: "Stugylparken S1",
    Verksamhetsutövare: "Stugyl AB",
    Organisationsnummer: "556756-4652",
    Aktuella_verk: 9,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 72,
    Uppmätt_årsproduktion_GWh: 79.75,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0980-V-011",
    Projektnamn: "Mästermyr Vindkraftpark",
    Verksamhetsutövare: "Vattenfall Vindkraft AB",
    Organisationsnummer: "556581-4273",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "0980-V-012",
    Projektnamn: "Vindpark Boge",
    Verksamhetsutövare: "Boge Vindbruk AB",
    Organisationsnummer: "556812-8796",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "0980-V-013",
    Projektnamn: "Anga Vinpark",
    Verksamhetsutövare: "Gothia Vind 10 AB",
    Organisationsnummer: "556804-2443",
    Aktuella_verk: 8,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0980-V-014",
    Projektnamn: "Näsudden Väst Vindkraftpark",
    Verksamhetsutövare: "Vattenfall Vindkraft AB",
    Organisationsnummer: "556581-4273",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0980-V-016",
    Projektnamn: "Forsviden Norra",
    Verksamhetsutövare: "wpd Stormossen AB",
    Organisationsnummer: "556800-8691",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0980-V-023",
    Projektnamn: "När Öndarve Närvind Johan",
    Verksamhetsutövare: "Närvind Samfällighetsförening",
    Organisationsnummer: "716404-8618",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.29,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Jan 18, 2021",
  },
  {
    Områdes_ID: "0980-V-049",
    Projektnamn: "Klinte Vindpark",
    Verksamhetsutövare: "Siral System & Co AB",
    Organisationsnummer: "556171-8296",
    Aktuella_verk: 5,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 26,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Jan 11, 2019",
  },
  {
    Områdes_ID: "0980-V-018",
    Projektnamn: "Näs Amfunds Snaigsto",
    Verksamhetsutövare: "Gotlandsvind AB",
    Organisationsnummer: "556823-4651",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.9,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0980-V-029",
    Projektnamn: "Lummelunda Tjauls Agro 1",
    Verksamhetsutövare: "Balingsta Vind HB",
    Organisationsnummer: "969623-2165",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.96,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0980-V-031",
    Projektnamn: "Hablingbo Stjups Hablingbovind",
    Verksamhetsutövare: "Hablingbovind ek för",
    Organisationsnummer: "769600-7298",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 2.44,
    Uppmätt_årsproduktion_GWh: 1.74,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0980-V-047",
    Projektnamn: "Hangvar Kyrkebys, Kyrkebys 3 & 4",
    Verksamhetsutövare: "Vindkraft i Hangvar socken AB",
    Organisationsnummer: "556575-6342",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 2.8,
    Uppmätt_årsproduktion_GWh: 2.01,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2505-V-001",
    Projektnamn: "Granliden vindkraftpark",
    Verksamhetsutövare: "Granliden Vindkraft AB c/o NV Nordisk Vindkraft AB",
    Organisationsnummer: "556805-7623",
    Aktuella_verk: 11,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 80.3,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Arvidsjaur",
    Län: "Norrbottens län",
    Elområde: "Luleå",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2283-V-021",
    Projektnamn: "Flakaberget",
    Verksamhetsutövare: "Mittvind i Sverige AB",
    Organisationsnummer: "556862-4927",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Sollefteå",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "Dec 07, 2021",
  },
  {
    Områdes_ID: "2518-V-009",
    Projektnamn: "Taka Apua vindkraftpark",
    Verksamhetsutövare: "OX2 AB",
    Organisationsnummer: "556675-7497",
    Aktuella_verk: 19,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 169.1,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Övertorneå",
    Län: "Norrbottens län",
    Elområde: "Luleå",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0764-V-004",
    Projektnamn: "Sjöatorp",
    Verksamhetsutövare: "SR Energy AB",
    Organisationsnummer: "556711-9549",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Alvesta",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "Jan 18, 2022",
  },
  {
    Områdes_ID: "0781-V-030",
    Projektnamn: "Hamneda",
    Verksamhetsutövare: "Gothia Vind AB",
    Organisationsnummer: "556714-2095",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ljungby",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "2080-V-008",
    Projektnamn: "Trollberget",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falun",
    Län: "Dalarnas län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0584-V-017",
    Projektnamn: "Furåsa",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vadstena",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 10, 2021",
  },
  {
    Områdes_ID: "2283-V-022",
    Projektnamn: "Rödstahöjden",
    Verksamhetsutövare: "OX2 AB",
    Organisationsnummer: "556675-7497",
    Aktuella_verk: 6,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 57,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sollefteå",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2580-V-003",
    Projektnamn: "Playa Plannja",
    Verksamhetsutövare: "Luleå Energi AB",
    Organisationsnummer: "556139-8255",
    Aktuella_verk: 7,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 25.2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Luleå",
    Län: "Norrbottens län",
    Elområde: "Luleå",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2506-V-001",
    Projektnamn: "Uljabuoda vindkraftspark",
    Verksamhetsutövare: "Skellefteå Kraft AB",
    Organisationsnummer: "556016-2561",
    Aktuella_verk: 10,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 80,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Arjeplog",
    Län: "Norrbottens län",
    Elområde: "Luleå",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2505-V-002",
    Projektnamn: "Hornliden",
    Verksamhetsutövare: "OX2 AB",
    Organisationsnummer: "556675-7497",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 37,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Arvidsjaur",
    Län: "Norrbottens län",
    Elområde: "Luleå",
    Senast_sparad: "Feb 09, 2021",
  },
  {
    Områdes_ID: "2510-V-003",
    Projektnamn: "Suorva Vindkraftpark",
    Verksamhetsutövare: "Vattenfall Vindkraft AB",
    Organisationsnummer: "556581-4273",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Jokkmokk",
    Län: "Norrbottens län",
    Elområde: "Luleå",
    Senast_sparad: "Feb 27, 2020",
  },
  {
    Områdes_ID: "0760-V-034",
    Projektnamn: "Horshaga",
    Verksamhetsutövare: "SR Energy AB",
    Organisationsnummer: "556711-9549",
    Aktuella_verk: 11,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Jan 01, 2022",
    Planerat_drifttagande: "Nov 01, 2023",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Uppvidinge",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "Jan 18, 2022",
  },
  {
    Områdes_ID: "2080-V-009",
    Projektnamn: "Råberg",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Falun",
    Län: "Dalarnas län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "0781-V-031",
    Projektnamn: "Vråskogen",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ljungby",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0980-V-060",
    Projektnamn: "Rute Furillen Slitevind XI & XII",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 2.8,
    Uppmätt_årsproduktion_GWh: 2.11,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0980-V-061",
    Projektnamn: "Hablingbo Bertels",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1482-V-006",
    Projektnamn: "Lycke",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kungälv",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 19, 2019",
  },
  {
    Områdes_ID: "0781-V-033",
    Projektnamn: "Skäckarp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ljungby",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0760-V-036",
    Projektnamn: "Sjömåla",
    Verksamhetsutövare: "UppVind Ekonomisk förening",
    Organisationsnummer: "769621-3649",
    Aktuella_verk: 6,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Uppvidinge",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "Mar 23, 2021",
  },
  {
    Områdes_ID: "1482-V-004",
    Projektnamn: "Vindrosen Skårby",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 7.4,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kungälv",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0780-V-008",
    Projektnamn: "Äskås-Harshult",
    Verksamhetsutövare: "Statkraft Södra Vindkraft AB",
    Organisationsnummer: "556785-3873",
    Aktuella_verk: 8,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Växjö",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0980-V-059",
    Projektnamn: "Stenkyrka Stora Bjärs 1 & 2",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.55,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1482-V-005",
    Projektnamn: "Kareby-Torp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kungälv",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0760-V-037",
    Projektnamn: "Gödeshult",
    Verksamhetsutövare: "Arise Windpower AB",
    Organisationsnummer: "556274-6726",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Uppvidinge",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2161-V-008",
    Projektnamn: "Vindpark Kölvallen",
    Verksamhetsutövare: "Arise Windpower AB",
    Organisationsnummer: "556274-6726",
    Aktuella_verk: 43,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 305.3,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ljusdal",
    Län: "Gävleborgs län",
    Elområde: "Sundsvall",
    Senast_sparad: "Oct 14, 2021",
  },
  {
    Områdes_ID: "0683-V-005",
    Projektnamn: "Klintarna",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Värnamo",
    Län: "Jönköpings län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 17, 2019",
  },
  {
    Områdes_ID: "0686-V-012",
    Projektnamn: "Lockarp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Eksjö",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1492-V-015",
    Projektnamn: "Edsleskogs-Hult",
    Verksamhetsutövare: "Veddige Vindkraft AB",
    Organisationsnummer: "556753-6072",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 18,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Åmål",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1492-V-016",
    Projektnamn: "Fröskogs-Hult",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Åmål",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0604-V-014",
    Projektnamn: "Frinnaryd",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Aneby",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 19, 2020",
  },
  {
    Områdes_ID: "0662-V-011",
    Projektnamn: "Lida",
    Verksamhetsutövare: "Hansa Vind AB",
    Organisationsnummer: "556840-8941",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Gislaved",
    Län: "Jönköpings län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 10, 2021",
  },
  {
    Områdes_ID: "0665-V-009",
    Projektnamn: "Hagstad",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vaggeryd",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 04, 2020",
  },
  {
    Områdes_ID: "0665-V-010",
    Projektnamn: "Åsthult",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vaggeryd",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 03, 2019",
  },
  {
    Områdes_ID: "0685-V-019",
    Projektnamn: "Stora Moshult",
    Verksamhetsutövare: "Åkesson & Bjerknaes AB",
    Organisationsnummer: "556798-1310",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vetlanda",
    Län: "Jönköpings län",
    Elområde: "Malmö",
    Senast_sparad: "Oct 15, 2021",
  },
  {
    Områdes_ID: "0980-V-048",
    Projektnamn: "Grötlingbo Kattlunds",
    Verksamhetsutövare: "Grötlingbo Möbelfabrik AB",
    Organisationsnummer: "556509-6954",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0980-V-054",
    Projektnamn: "Lärbro Nors Norrvange Nr 1 & 2",
    Verksamhetsutövare: "Röcklingers Handelsträdgård AB",
    Organisationsnummer: "556595-6249",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 11.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Jan 09, 2019",
  },
  {
    Områdes_ID: "0980-V-062",
    Projektnamn: "Hejnum Boters (Fole Stora Sojdeby)",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5.8,
    Uppmätt_årsproduktion_GWh: 5.76,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0980-V-057",
    Projektnamn: "Storugns 10",
    Verksamhetsutövare: "Storugns III AB",
    Organisationsnummer: "556868-2370",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 6.1,
    Uppmätt_årsproduktion_GWh: 6.27,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0584-V-026",
    Projektnamn: "Klosterstad",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vadstena",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0586-V-045",
    Projektnamn: "Vävinge / Lottstad",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 9.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mjölby",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0586-V-014",
    Projektnamn: "Lagmansberga 21",
    Verksamhetsutövare: "Lagmansberga Tvåan Vind kooperativ Ek. För",
    Organisationsnummer: "769616-9684",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5.7,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mjölby",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1498-V-047",
    Projektnamn: "Vindpark Härja",
    Verksamhetsutövare: "Cloudberry Offshore Wind AB",
    Organisationsnummer: "556710-7403",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tidaholm",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 19, 2020",
  },
  {
    Områdes_ID: "0583-V-030",
    Projektnamn: "Hanorp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Motala",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0586-V-047",
    Projektnamn: "Vallerstad",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mjölby",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "May 02, 2019",
  },
  {
    Områdes_ID: "0586-V-048",
    Projektnamn: "Ramstad",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mjölby",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "May 02, 2019",
  },
  {
    Områdes_ID: "0586-V-049",
    Projektnamn: "Hageby Vistena Ullekalv",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mjölby",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "May 02, 2019",
  },
  {
    Områdes_ID: "0586-V-054",
    Projektnamn: "Finketorp Hogstad",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mjölby",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "May 02, 2019",
  },
  {
    Områdes_ID: "0586-V-057",
    Projektnamn: "Nederlösa",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mjölby",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 10, 2021",
  },
  {
    Områdes_ID: "0586-V-061",
    Projektnamn: "Strömmestad Haddestad",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mjölby",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 10, 2021",
  },
  {
    Områdes_ID: "0586-V-067",
    Projektnamn: "Hyppinge Gårdsverk",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.02,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mjölby",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0586-V-068",
    Projektnamn: "Habblarp Gårdsverk",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mjölby",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0583-V-034",
    Projektnamn: "Fornåsa",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Motala",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 10, 2021",
  },
  {
    Områdes_ID: "0583-V-042",
    Projektnamn: "Prästtorp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Motala",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 10, 2021",
  },
  {
    Områdes_ID: "0586-V-074",
    Projektnamn: "Hassla",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mjölby",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 10, 2021",
  },
  {
    Områdes_ID: "0586-V-076",
    Projektnamn: "Orås",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mjölby",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1496-V-017",
    Projektnamn: "Östen Skövde",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Skövde",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1430-V-035",
    Projektnamn: "Hällevadsholm 2 och 3",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 11.8,
    Uppmätt_årsproduktion_GWh: 11.41,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Munkedal",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0781-V-035",
    Projektnamn: "Kånna",
    Verksamhetsutövare: "Scanergy South AB",
    Organisationsnummer: "556758-6614",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 7,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ljungby",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "0781-V-036",
    Projektnamn: "Hamneda Horn",
    Verksamhetsutövare: "Arise Windpower AB",
    Organisationsnummer: "556274-6726",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ljungby",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "0781-V-038",
    Projektnamn: "Åby",
    Verksamhetsutövare: "Scanergy South AB",
    Organisationsnummer: "556758-6614",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ljungby",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "Mar 27, 2019",
  },
  {
    Områdes_ID: "1493-V-013",
    Projektnamn: "Gravens grund",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mariestad",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Apr 03, 2019",
  },
  {
    Områdes_ID: "1498-V-050",
    Projektnamn: "Ottravad",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tidaholm",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "0665-V-011",
    Projektnamn: "Gunnilstorp/Tranhult",
    Verksamhetsutövare: "Kraftö AB",
    Organisationsnummer: "556708-7456",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vaggeryd",
    Län: "Jönköpings län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1466-V-003",
    Projektnamn: "Fänneslunda  - Od del 2",
    Verksamhetsutövare: "Göteborg Energi AB",
    Organisationsnummer: "556362-6794",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Herrljunga",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 28, 2019",
  },
  {
    Områdes_ID: "1465-V-003",
    Projektnamn: "Mårdaklev",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Svenljunga",
    Län: "Västra Götalands län",
    Elområde: "Malmö",
    Senast_sparad: "Dec 09, 2019",
  },
  {
    Områdes_ID: "1441-V-006",
    Projektnamn: "Vindkraftpark Fyrskog",
    Verksamhetsutövare: "Gothia Vind AB",
    Organisationsnummer: "556714-2095",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Lerum",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 15, 2021",
  },
  {
    Områdes_ID: "1441-V-008",
    Projektnamn: "Ytterstad",
    Verksamhetsutövare: "Gothia Vind AB",
    Organisationsnummer: "556714-2095",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Lerum",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1490-V-004",
    Projektnamn: "Lövaskog",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Borås",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Nov 09, 2021",
  },
  {
    Områdes_ID: "1465-V-007",
    Projektnamn: "Vindåsen",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Svenljunga",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "0683-V-007",
    Projektnamn: "Rubblarp/Lökaryd/Västanhaga",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Värnamo",
    Län: "Jönköpings län",
    Elområde: "Malmö",
    Senast_sparad: "May 02, 2019",
  },
  {
    Områdes_ID: "0665-V-001",
    Projektnamn: "Åkers-Grytås",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vaggeryd",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 04, 2020",
  },
  {
    Områdes_ID: "1490-V-006",
    Projektnamn: "Hallabron (Ulricehamn)",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Borås",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Apr 08, 2019",
  },
  {
    Områdes_ID: "1490-V-007",
    Projektnamn: "Våssberg",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Borås",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Jan 28, 2020",
  },
  {
    Områdes_ID: "0481-V-001",
    Projektnamn: "Gustav Dahlen:1",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Oxelösund",
    Län: "Södermanlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Apr 05, 2019",
  },
  {
    Områdes_ID: "1293-V-027",
    Projektnamn: "Sörby-Åraslöv",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hässleholm",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1267-V-020",
    Projektnamn: "Lillasäte",
    Verksamhetsutövare: "Billyvind AB",
    Organisationsnummer: "556705-6931",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Höör",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 17, 2019",
  },
  {
    Områdes_ID: "1293-V-028",
    Projektnamn: "Äsphult-Bjärnhult",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hässleholm",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0760-V-039",
    Projektnamn: "Nottebäck",
    Verksamhetsutövare: "WSP Sverige AB",
    Organisationsnummer: "556057-4880",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 4.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Uppvidinge",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0481-V-002",
    Projektnamn: "Gustav Dahlén 2",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Oxelösund",
    Län: "Södermanlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Apr 05, 2019",
  },
  {
    Områdes_ID: "1293-V-030",
    Projektnamn: "Nävlinge",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hässleholm",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0680-V-011",
    Projektnamn: "Norra Kärr",
    Verksamhetsutövare: "Romans Fastighets Kommanditbolag",
    Organisationsnummer: "916522-9296",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 15,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Jönköping",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1494-V-024",
    Projektnamn: "Hasslösa",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 54,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Lidköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1495-V-019",
    Projektnamn: "TVETA / 1",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Skara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1495-V-036",
    Projektnamn: "TVETA / 3",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Skara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2181-V-003",
    Projektnamn: "Vindpark Stormossen",
    Verksamhetsutövare: "WPD Scandinavia AB",
    Organisationsnummer: "556666-3422",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 112,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Sandviken",
    Län: "Gävleborgs län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1444-V-013",
    Projektnamn: "Wästgöta Wind",
    Verksamhetsutövare: "WSP Sverige AB",
    Organisationsnummer: "556057-4880",
    Aktuella_verk: 60,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 414,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Grästorp",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1415-V-001",
    Projektnamn: "Hällesås Ucklums-Berg",
    Verksamhetsutövare: "Rabbalshede Kraft AB",
    Organisationsnummer: "556681-4652",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 28,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Stenungsund",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1415-V-009",
    Projektnamn: "Ljungkile Svenshögen",
    Verksamhetsutövare: "Rabbalshede Kraft AB",
    Organisationsnummer: "556681-4652",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Stenungsund",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1430-V-016",
    Projektnamn: "Ås",
    Verksamhetsutövare: "Rabbalshede Kraft AB",
    Organisationsnummer: "556681-4652",
    Aktuella_verk: 6,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 30,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Munkedal",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "May 29, 2020",
  },
  {
    Områdes_ID: "1495-V-027",
    Projektnamn: "Tagelberg 2",
    Verksamhetsutövare: "Bixia Gryningsvind AB",
    Organisationsnummer: "556779-5348",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5.4,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Skara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 05, 2019",
  },
  {
    Områdes_ID: "1315-V-006",
    Projektnamn: "Ramnared",
    Verksamhetsutövare: "Gothia Vind 10 AB",
    Organisationsnummer: "556804-2443",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hylte",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 29, 2019",
  },
  {
    Områdes_ID: "1472-V-006",
    Projektnamn: "Lunnekullen",
    Verksamhetsutövare: "Lunnekullen Vindkraft AB",
    Organisationsnummer: "556705-3045",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 292.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tibro",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1382-V-014",
    Projektnamn: "Sotared",
    Verksamhetsutövare: "Sotared Vindkraftpark AB",
    Organisationsnummer: "556741-0096",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 30,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falkenberg",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1493-V-007",
    Projektnamn: "Hällsås, Stenstorp, Norrkvarn, Äskekärr, Slätteval",
    Verksamhetsutövare: "Norrhällvind",
    Organisationsnummer: "662010-3645",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mariestad",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "May 15, 2019",
  },
  {
    Områdes_ID: "1460-V-001",
    Projektnamn: "Vindpark Edsleskog",
    Verksamhetsutövare: "Veddige Vindkraft AB",
    Organisationsnummer: "556753-6072",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Bengtsfors",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 15, 2021",
  },
  {
    Områdes_ID: "1485-V-024",
    Projektnamn: "Vindpark Anneröd",
    Verksamhetsutövare: "ANNERÖDS VINDKRAFT AB",
    Organisationsnummer: "556753-6056",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 36.4,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Uddevalla",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1315-V-001",
    Projektnamn: "Almeshult",
    Verksamhetsutövare: "Benber AB",
    Organisationsnummer: "556417-5098",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hylte",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 15, 2021",
  },
  {
    Områdes_ID: "1462-V-020",
    Projektnamn: "Holmen",
    Verksamhetsutövare: "Firma Ramström Vind AB",
    Organisationsnummer: "556688-2683",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 40,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "LillaEdet",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2403-V-009",
    Projektnamn: "Stensvattsmarken",
    Verksamhetsutövare: "Vattenfall Vindkraft AB",
    Organisationsnummer: "556581-4273",
    Aktuella_verk: 24,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "Aug 31, 2026",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Bjurholm",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "Nov 17, 2021",
  },
  {
    Områdes_ID: "2418-V-004",
    Projektnamn: "Ytterberg",
    Verksamhetsutövare: "Vindkraft i Ytterberg AB",
    Organisationsnummer: "556781-9890",
    Aktuella_verk: 22,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 128.92,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Malå",
    Län: "Västerbottens län",
    Elområde: "Luleå",
    Senast_sparad: "Oct 05, 2021",
  },
  {
    Områdes_ID: "2418-V-005",
    Projektnamn: "Åmliden",
    Verksamhetsutövare: "Åmliden Vindkraft AB",
    Organisationsnummer: "556785-3337",
    Aktuella_verk: 23,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 122.13,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Malå",
    Län: "Västerbottens län",
    Elområde: "Luleå",
    Senast_sparad: "Oct 05, 2021",
  },
  {
    Områdes_ID: "2418-V-006",
    Projektnamn: "Åmliden 2",
    Verksamhetsutövare: "Åmliden Vindkraft AB",
    Organisationsnummer: "556785-3337",
    Aktuella_verk: 6,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 31.86,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Malå",
    Län: "Västerbottens län",
    Elområde: "Luleå",
    Senast_sparad: "Oct 05, 2021",
  },
  {
    Områdes_ID: "2482-V-014",
    Projektnamn: "Fjälbyn",
    Verksamhetsutövare: "RWE Renewables Sweden AB",
    Organisationsnummer: "556938-6864",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Skellefteå",
    Län: "Västerbottens län",
    Elområde: "Luleå",
    Senast_sparad: "Feb 22, 2021",
  },
  {
    Områdes_ID: "2417-V-003",
    Projektnamn: "Bodberget - västra",
    Verksamhetsutövare: "Gothia Vind AB",
    Organisationsnummer: "556714-2095",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Norsjö",
    Län: "Västerbottens län",
    Elområde: "Luleå",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1082-V-002",
    Projektnamn: "Humlemåla",
    Verksamhetsutövare: "Billyvind AB",
    Organisationsnummer: "556705-6931",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Karlshamn",
    Län: "Blekinge län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 05, 2019",
  },
  {
    Områdes_ID: "1082-V-003",
    Projektnamn: "Ire",
    Verksamhetsutövare: "Billyvind AB",
    Organisationsnummer: "556705-6931",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Karlshamn",
    Län: "Blekinge län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 17, 2019",
  },
  {
    Områdes_ID: "1082-V-004",
    Projektnamn: "Loberget",
    Verksamhetsutövare: "Billyvind AB",
    Organisationsnummer: "556705-6931",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Karlshamn",
    Län: "Blekinge län",
    Elområde: "Malmö",
    Senast_sparad: "Mar 18, 2021",
  },
  {
    Områdes_ID: "0760-V-032",
    Projektnamn: "Rosendal",
    Verksamhetsutövare: "Billyvind AB",
    Organisationsnummer: "556705-6931",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Uppvidinge",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "Oct 02, 2019",
  },
  {
    Områdes_ID: "2418-V-008",
    Projektnamn: "Storliden",
    Verksamhetsutövare: "Skellefteå Kraft AB",
    Organisationsnummer: "556016-2561",
    Aktuella_verk: 8,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 56.96,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Malå",
    Län: "Västerbottens län",
    Elområde: "Luleå",
    Senast_sparad: "May 18, 2020",
  },
  {
    Områdes_ID: "2480-V-010",
    Projektnamn: "Holmön",
    Verksamhetsutövare: "Holmöns vindkraftverk AB",
    Organisationsnummer: "556760-9101",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Umeå",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2480-V-011",
    Projektnamn: "Holmön - västra",
    Verksamhetsutövare: "Slitevind AB",
    Organisationsnummer: "556453-2819",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Umeå",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1499-V-050",
    Projektnamn: "Gudhema Vallar",
    Verksamhetsutövare: "Wästgöta Wind AB",
    Organisationsnummer: "556944-6478",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 15,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Falköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1499-V-051",
    Projektnamn: "Uleberg",
    Verksamhetsutövare: "Wästgöta Wind AB",
    Organisationsnummer: "556944-6478",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 15,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Falköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1083-V-014",
    Projektnamn: "Blekinge Offshore",
    Verksamhetsutövare: "Blekinge Offshore AB",
    Organisationsnummer: "556761-1727",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 699,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Sölvesborg",
    Län: "Blekinge län",
    Elområde: "Malmö",
    Senast_sparad: "Aug 23, 2021",
  },
  {
    Områdes_ID: "2361-V-040",
    Projektnamn: "Brickan (Svegström)",
    Verksamhetsutövare: "O2 El ekonomiska förening",
    Organisationsnummer: "769611-9515",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Härjedalen",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0980-V-069",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2026-V-001",
    Projektnamn: "Gagnef Rosberget",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Gagnef",
    Län: "Dalarnas län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 02, 2019",
  },
  {
    Områdes_ID: "1493-V-014",
    Projektnamn: "Säby",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mariestad",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Apr 01, 2019",
  },
  {
    Områdes_ID: "1440-V-001",
    Projektnamn: "Vindkraftpark Gräfsnäs och Livered m.fl.",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ale",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0331-V-001",
    Projektnamn: "Röcklinge",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Heby",
    Län: "Uppsala län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2083-V-001",
    Projektnamn: "Finnhyttan",
    Verksamhetsutövare: "Dala Vind AB",
    Organisationsnummer: "556696-6940",
    Aktuella_verk: 7,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 37.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "May 14, 2012",
    Planerat_drifttagande: "Jul 01, 2012",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Hedemora",
    Län: "Dalarnas län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1214-V-058",
    Projektnamn: "Knutstorp-Pålstorp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Svalöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "1486-V-002",
    Projektnamn: "Vettebergets Vindpark",
    Verksamhetsutövare: "Rabbalshede Kraft AB",
    Organisationsnummer: "556681-4652",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 13.4,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Strömstad",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 06, 2020",
  },
  {
    Områdes_ID: "0513-V-004",
    Projektnamn: "Hycklinge 5.8",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kinda",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 19, 2020",
  },
  {
    Områdes_ID: "2280-V-020",
    Projektnamn: "Skedom",
    Verksamhetsutövare: "NordanVind vindkraft AB",
    Organisationsnummer: "556574-0403",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Härnösand",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "0834-V-030",
    Projektnamn: "Norra Gullabo",
    Verksamhetsutövare: "Greenextreme AB",
    Organisationsnummer: "556722-4596",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Torsås",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 24, 2021",
  },
  {
    Områdes_ID: "0980-V-051",
    Projektnamn: "Grötlingbo Uddvide Domerarve",
    Verksamhetsutövare: "Uddvide gård",
    Organisationsnummer: "556339-4211",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1383-V-019",
    Projektnamn: "Ulvatorp",
    Verksamhetsutövare: "Vasa Vind AB",
    Organisationsnummer: "556702-6835",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Varberg",
    Län: "Hallands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1266-V-044",
    Projektnamn: "Ulatofta",
    Verksamhetsutövare: "Hörby",
    Organisationsnummer: "212000-1108",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Hörby",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1266-V-045",
    Projektnamn: "Råby",
    Verksamhetsutövare: "Hörby",
    Organisationsnummer: "212000-1108",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hörby",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 14, 2020",
  },
  {
    Områdes_ID: "1266-V-046",
    Projektnamn: "Ormastorp",
    Verksamhetsutövare: "Hörby",
    Organisationsnummer: "212000-1108",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hörby",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "1266-V-047",
    Projektnamn: "Elestorp/Tormastrorp",
    Verksamhetsutövare: "Västanby AB",
    Organisationsnummer: "556604-8392",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hörby",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "2581-V-001",
    Projektnamn: "Haraholmen",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 2.9,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Piteå",
    Län: "Norrbottens län",
    Elområde: "Luleå",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2580-V-004",
    Projektnamn: "Marakallen",
    Verksamhetsutövare: "Ventovarium AB",
    Organisationsnummer: "556612-0068",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Luleå",
    Län: "Norrbottens län",
    Elområde: "Luleå",
    Senast_sparad: "Feb 12, 2020",
  },
  {
    Områdes_ID: "2581-V-002",
    Projektnamn: "Bondön",
    Verksamhetsutövare: "Bondön Wind ApS, Filial Danmark",
    Organisationsnummer: "516405-2598",
    Aktuella_verk: 14,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 68.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Piteå",
    Län: "Norrbottens län",
    Elområde: "Luleå",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2581-V-003",
    Projektnamn: "Dragaliden",
    Verksamhetsutövare: "Dragaliden Vind AB",
    Organisationsnummer: "556758-0492",
    Aktuella_verk: 12,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 69.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Piteå",
    Län: "Norrbottens län",
    Elområde: "Luleå",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2581-V-004",
    Projektnamn: "Stor-Blåliden",
    Verksamhetsutövare: "Storblåliden Vind AB",
    Organisationsnummer: "556814-3894",
    Aktuella_verk: 8,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 43.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Piteå",
    Län: "Norrbottens län",
    Elområde: "Luleå",
    Senast_sparad: "Oct 06, 2021",
  },
  {
    Områdes_ID: "2581-V-007",
    Projektnamn: "Bondön vindkraftpark II",
    Verksamhetsutövare: "Bondön Administration AB",
    Organisationsnummer: "556882-2729",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Piteå",
    Län: "Norrbottens län",
    Elområde: "Luleå",
    Senast_sparad: "Sep 13, 2019",
  },
  {
    Områdes_ID: "2581-V-008",
    Projektnamn: "Klocktärnan vindkraftpark",
    Verksamhetsutövare: "NordanVind vindkraft AB",
    Organisationsnummer: "556574-0403",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Piteå",
    Län: "Norrbottens län",
    Elområde: "Luleå",
    Senast_sparad: "Sep 18, 2019",
  },
  {
    Områdes_ID: "2581-V-010",
    Projektnamn: "Trundön vindkraftpark",
    Verksamhetsutövare: "ÖSTRÖMS VIND AB",
    Organisationsnummer: "556604-4052",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Piteå",
    Län: "Norrbottens län",
    Elområde: "Luleå",
    Senast_sparad: "Sep 13, 2019",
  },
  {
    Områdes_ID: "2582-V-001",
    Projektnamn: "Haradskölen",
    Verksamhetsutövare: "Suorvavind AB",
    Organisationsnummer: "556696-4564",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Boden",
    Län: "Norrbottens län",
    Elområde: "Luleå",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2583-V-001",
    Projektnamn: "Seskarö",
    Verksamhetsutövare: "Kalix Wind AB",
    Organisationsnummer: "559131-0908",
    Aktuella_verk: 5,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 22,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Haparanda",
    Län: "Norrbottens län",
    Elområde: "Luleå",
    Senast_sparad: "Mar 13, 2020",
  },
  {
    Områdes_ID: "2583-V-002",
    Projektnamn: "Vindkraftpark Stopparen",
    Verksamhetsutövare: "Ventovarium AB",
    Organisationsnummer: "556612-0068",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Haparanda",
    Län: "Norrbottens län",
    Elområde: "Luleå",
    Senast_sparad: "Feb 12, 2020",
  },
  {
    Områdes_ID: "2583-V-003",
    Projektnamn: "Vuono vindkraftpark",
    Verksamhetsutövare: "Kraftö AB",
    Organisationsnummer: "556708-7456",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Haparanda",
    Län: "Norrbottens län",
    Elområde: "Luleå",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2584-V-001",
    Projektnamn: "Vindkraftverk Luossavaara",
    Verksamhetsutövare: "Kiruna Showroom AB",
    Organisationsnummer: "556632-4009",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kiruna",
    Län: "Norrbottens län",
    Elområde: "Luleå",
    Senast_sparad: "Feb 09, 2021",
  },
  {
    Områdes_ID: "2584-V-002",
    Projektnamn: "Viscaria 3",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.89,
    Uppmätt_årsproduktion_GWh: 1.36,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kiruna",
    Län: "Norrbottens län",
    Elområde: "Luleå",
    Senast_sparad: "Jun 10, 2020",
  },
  {
    Områdes_ID: "2584-V-003",
    Projektnamn: "Viscaria 2",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.78,
    Uppmätt_årsproduktion_GWh: 1.1,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kiruna",
    Län: "Norrbottens län",
    Elområde: "Luleå",
    Senast_sparad: "Jun 10, 2020",
  },
  {
    Områdes_ID: "2584-V-004",
    Projektnamn: "Viscaria 1",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.84,
    Uppmätt_årsproduktion_GWh: 1.2,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kiruna",
    Län: "Norrbottens län",
    Elområde: "Luleå",
    Senast_sparad: "Jun 10, 2020",
  },
  {
    Områdes_ID: "2584-V-005",
    Projektnamn: "Viscaria 4",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.83,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kiruna",
    Län: "Norrbottens län",
    Elområde: "Luleå",
    Senast_sparad: "Jun 10, 2020",
  },
  {
    Områdes_ID: "2584-V-006",
    Projektnamn: "Viscaria 6",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.7,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kiruna",
    Län: "Norrbottens län",
    Elområde: "Luleå",
    Senast_sparad: "Jun 10, 2020",
  },
  {
    Områdes_ID: "2584-V-007",
    Projektnamn: "Viscaria 5",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.65,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kiruna",
    Län: "Norrbottens län",
    Elområde: "Luleå",
    Senast_sparad: "Jun 10, 2020",
  },
  {
    Områdes_ID: "0617-V-002",
    Projektnamn: "Kulltorp",
    Verksamhetsutövare: "Gnosjö Energi AB",
    Organisationsnummer: "556742-7132",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 23,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gnosjö",
    Län: "Jönköpings län",
    Elområde: "Malmö",
    Senast_sparad: "May 27, 2020",
  },
  {
    Områdes_ID: "1447-V-001",
    Projektnamn: "Bråtaparken",
    Verksamhetsutövare: "CRC Vindkraft AB",
    Organisationsnummer: "556700-3545",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Gullspång",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 15, 2021",
  },
  {
    Områdes_ID: "2080-V-010",
    Projektnamn: "Enviksberget",
    Verksamhetsutövare: "Enviksbergets Vindpark AB",
    Organisationsnummer: "559139-7186",
    Aktuella_verk: 9,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 67.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "Dec 01, 2019",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falun",
    Län: "Dalarnas län",
    Elområde: "Stockholm",
    Senast_sparad: "Mar 19, 2021",
  },
  {
    Områdes_ID: "1435-V-064",
    Projektnamn: "Torseröds Vindkraftpark 5",
    Verksamhetsutövare: "HB Hökensås Kraft",
    Organisationsnummer: "916570-0676",
    Aktuella_verk: 5,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 7.75,
    Uppmätt_årsproduktion_GWh: 6.66,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tanum",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "May 12, 2020",
  },
  {
    Områdes_ID: "0662-V-012",
    Projektnamn: "Frostnäs",
    Verksamhetsutövare: "ENO- Energy Sweden AB",
    Organisationsnummer: "556877-9598",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 53,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gislaved",
    Län: "Jönköpings län",
    Elområde: "Malmö",
    Senast_sparad: "Dec 01, 2021",
  },
  {
    Områdes_ID: "1292-V-005",
    Projektnamn: "Ingelstorp",
    Verksamhetsutövare: "Solid Vind AB",
    Organisationsnummer: "556780-7572",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ängelholm",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "0188-V-005",
    Projektnamn: "Svenska Björn",
    Verksamhetsutövare: "Solid Vind AB",
    Organisationsnummer: "556780-7572",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Norrtälje",
    Län: "Stockholms län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 18, 2019",
  },
  {
    Områdes_ID: "0662-V-015",
    Projektnamn: "Vimmelstorp",
    Verksamhetsutövare: "ENO- Energy Sweden AB",
    Organisationsnummer: "556877-9598",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Gislaved",
    Län: "Jönköpings län",
    Elområde: "Malmö",
    Senast_sparad: "Oct 29, 2020",
  },
  {
    Områdes_ID: "0115-V-001",
    Projektnamn: "Vindkraftverk Hakunge",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vallentuna",
    Län: "Stockholms län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 09, 2021",
  },
  {
    Områdes_ID: "2305-V-006",
    Projektnamn: "Bleckberget",
    Verksamhetsutövare: "Jämtvind AB",
    Organisationsnummer: "556784-8808",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Bräcke",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "2305-V-007",
    Projektnamn: "Storflohöjden",
    Verksamhetsutövare: "Jämtvind AB",
    Organisationsnummer: "556784-8808",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Bräcke",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Dec 22, 2020",
  },
  {
    Områdes_ID: "2305-V-005",
    Projektnamn: "Ismundsundet",
    Verksamhetsutövare: "Jämtvind AB",
    Organisationsnummer: "556784-8808",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Bräcke",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2305-V-013",
    Projektnamn: "",
    Verksamhetsutövare: "Jämtvind AB",
    Organisationsnummer: "556784-8808",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Bräcke",
    Län: "Jämtlands län",
    Elområde: "",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2305-V-020",
    Projektnamn: "Gastsjö",
    Verksamhetsutövare: "Jämtvind AB",
    Organisationsnummer: "556784-8808",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Bräcke",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Apr 07, 2020",
  },
  {
    Områdes_ID: "2303-V-020",
    Projektnamn: "Fisksjölandet",
    Verksamhetsutövare: "Jämtvind AB",
    Organisationsnummer: "556784-8808",
    Aktuella_verk: 10,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ragunda",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2303-V-021",
    Projektnamn: "Fjällmarkhöjden",
    Verksamhetsutövare: "Jämtvind AB",
    Organisationsnummer: "556784-8808",
    Aktuella_verk: 6,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ragunda",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Mar 24, 2021",
  },
  {
    Områdes_ID: "2303-V-022",
    Projektnamn: "Hög-Hanåsen",
    Verksamhetsutövare: "Jämtvind AB",
    Organisationsnummer: "556784-8808",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ragunda",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Apr 23, 2019",
  },
  {
    Områdes_ID: "1290-V-114",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2039-V-002",
    Projektnamn: "Granberg",
    Verksamhetsutövare: "Granberget Vind AB",
    Organisationsnummer: "556775-1549",
    Aktuella_verk: 5,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 26,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Älvdalen",
    Län: "Dalarnas län",
    Elområde: "Stockholm",
    Senast_sparad: "Mar 13, 2020",
  },
  {
    Områdes_ID: "1382-V-046",
    Projektnamn: "Hällarp",
    Verksamhetsutövare: "POG International AB",
    Organisationsnummer: "556667-5467",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Falkenberg",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "Aug 28, 2019",
  },
  {
    Områdes_ID: "1482-V-008",
    Projektnamn: "Dalen",
    Verksamhetsutövare: "Kungälv Närenergi AB",
    Organisationsnummer: "556805-6641",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kungälv",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 06, 2021",
  },
  {
    Områdes_ID: "0840-V-049",
    Projektnamn: "Kastlösa_15_4",
    Verksamhetsutövare: "OX2 AB",
    Organisationsnummer: "556675-7497",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mörbylånga",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2326-V-015",
    Projektnamn: "Mullbergs Vindpark",
    Verksamhetsutövare: "Mullbergs Vindpark AB",
    Organisationsnummer: "556892-3311",
    Aktuella_verk: 26,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 246.74,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Sep 07, 2012",
    Planerat_drifttagande: "Dec 04, 2013",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Berg",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0880-V-021",
    Projektnamn: "Vindpark Ljungbyholm",
    Verksamhetsutövare: "OX2 AB",
    Organisationsnummer: "556675-7497",
    Aktuella_verk: 8,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 100,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Apr 01, 2020",
    Planerat_drifttagande: "Jun 01, 2021",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kalmar",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 29, 2021",
  },
  {
    Områdes_ID: "1293-V-025",
    Projektnamn: "Ignaberga-Attarp",
    Verksamhetsutövare: "Billyvind AB",
    Organisationsnummer: "556705-6931",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hässleholm",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Jan 10, 2022",
  },
  {
    Områdes_ID: "2326-V-018",
    Projektnamn: "Bingsta",
    Verksamhetsutövare: "Greenextreme AB",
    Organisationsnummer: "556722-4596",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Berg",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Aug 27, 2019",
  },
  {
    Områdes_ID: "2313-V-025",
    Projektnamn: "Gisselås",
    Verksamhetsutövare: "Greenextreme AB",
    Organisationsnummer: "556722-4596",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Strömsund",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "1498-V-051",
    Projektnamn: "Gimmene",
    Verksamhetsutövare: "Greenextreme AB",
    Organisationsnummer: "556722-4596",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tidaholm",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "1430-V-039",
    Projektnamn: "Gunboröd",
    Verksamhetsutövare: "Greenextreme AB",
    Organisationsnummer: "556722-4596",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Munkedal",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Mar 25, 2021",
  },
  {
    Områdes_ID: "0880-V-022",
    Projektnamn: "Gunnarsbo",
    Verksamhetsutövare: "Greenextreme AB",
    Organisationsnummer: "556722-4596",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kalmar",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 17, 2019",
  },
  {
    Områdes_ID: "1082-V-005",
    Projektnamn: "Gustavstorp",
    Verksamhetsutövare: "Greenextreme AB",
    Organisationsnummer: "556722-4596",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Karlshamn",
    Län: "Blekinge län",
    Elområde: "Malmö",
    Senast_sparad: "Nov 30, 2021",
  },
  {
    Områdes_ID: "1443-V-002",
    Projektnamn: "Henå",
    Verksamhetsutövare: "Greenextreme AB",
    Organisationsnummer: "556722-4596",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Bollebygd",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 09, 2019",
  },
  {
    Områdes_ID: "0840-V-100",
    Projektnamn: "Kastlösa",
    Verksamhetsutövare: "Kastlösavind Ek För",
    Organisationsnummer: "769600-8932",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.1,
    Uppmätt_årsproduktion_GWh: 0.92,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mörbylånga",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1880-V-020",
    Projektnamn: "Wind-elsson",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Örebro",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0428-V-009",
    Projektnamn: "Ättersta",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vingåker",
    Län: "Södermanlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 08, 2021",
  },
  {
    Områdes_ID: "0980-V-065",
    Projektnamn: "Norrvange",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.05,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0980-V-066",
    Projektnamn: "Tängelgårde",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.1,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 15, 2019",
  },
  {
    Områdes_ID: "0980-V-067",
    Projektnamn: "",
    Verksamhetsutövare: "Ryftes Energi och Förvaltning AB",
    Organisationsnummer: "556272-4236",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0980-V-068",
    Projektnamn: "Näs",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 14, 2019",
  },
  {
    Områdes_ID: "0513-V-001",
    Projektnamn: "Gölingstorp",
    Verksamhetsutövare: "Väktaren Vind AB",
    Organisationsnummer: "556628-7578",
    Aktuella_verk: 8,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kinda",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1493-V-015",
    Projektnamn: "Trilleholm",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mariestad",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 05, 2019",
  },
  {
    Områdes_ID: "1493-V-016",
    Projektnamn: "Rudet",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mariestad",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Mar 28, 2019",
  },
  {
    Områdes_ID: "1493-V-017",
    Projektnamn: "katrineberg",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mariestad",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Mar 19, 2021",
  },
  {
    Områdes_ID: "1214-V-059",
    Projektnamn: "Ask",
    Verksamhetsutövare: "Firma Ramström Vind AB",
    Organisationsnummer: "556688-2683",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Svalöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "1214-V-060",
    Projektnamn: "Lönnstorp",
    Verksamhetsutövare: "Vento Energi AB",
    Organisationsnummer: "556702-7338",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Svalöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Nov 30, 2021",
  },
  {
    Områdes_ID: "0834-V-031",
    Projektnamn: "Strömby",
    Verksamhetsutövare: "Hemvind AB",
    Organisationsnummer: "556893-1595",
    Aktuella_verk: 5,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 125,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Torsås",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2104-V-002",
    Projektnamn: "Söderåsen",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Hofors",
    Län: "Gävleborgs län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2523-V-004",
    Projektnamn: "Sjisjka Vindkraftspark",
    Verksamhetsutövare: "Sjisjka Vind AB",
    Organisationsnummer: "556773-3422",
    Aktuella_verk: 30,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 205.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "Oct 01, 2012",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gällivare",
    Län: "Norrbottens län",
    Elområde: "Luleå",
    Senast_sparad: "Oct 05, 2021",
  },
  {
    Områdes_ID: "0980-V-056",
    Projektnamn: "Kräklingbo Vidfälle",
    Verksamhetsutövare: "Kräklingbo Vind AB",
    Organisationsnummer: "556821-9918",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 12.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1447-V-005",
    Projektnamn: "Prästkila",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Gullspång",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 19, 2020",
  },
  {
    Områdes_ID: "1447-V-003",
    Projektnamn: "Projekt Gullspångsparken",
    Verksamhetsutövare: "CRC Vindkraft AB",
    Organisationsnummer: "556700-3545",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Gullspång",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 15, 2021",
  },
  {
    Områdes_ID: "0642-V-003",
    Projektnamn: "Smedstorp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Falköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 09, 2019",
  },
  {
    Områdes_ID: "1861-V-001",
    Projektnamn: "Frotorp",
    Verksamhetsutövare: "Frotorp Vind AB",
    Organisationsnummer: "556883-6232",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 15,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Hallsberg",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0861-V-052",
    Projektnamn: "Skarvesjö",
    Verksamhetsutövare: "Solid Vind AB",
    Organisationsnummer: "556780-7572",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mönsterås",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "0861-V-053",
    Projektnamn: "Kronobäck",
    Verksamhetsutövare: "Solid Vind AB",
    Organisationsnummer: "556780-7572",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mönsterås",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Mar 20, 2019",
  },
  {
    Områdes_ID: "0860-V-005",
    Projektnamn: "Hyggelsebo",
    Verksamhetsutövare: "Solid Vind AB",
    Organisationsnummer: "556780-7572",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hultsfred",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 29, 2019",
  },
  {
    Områdes_ID: "0683-V-009",
    Projektnamn: "Hånger",
    Verksamhetsutövare: "ENO- Energy Sweden AB",
    Organisationsnummer: "556877-9598",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Värnamo",
    Län: "Jönköpings län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 10, 2021",
  },
  {
    Områdes_ID: "2305-V-021",
    Projektnamn: "Mjösjö",
    Verksamhetsutövare: "Jämtvind AB",
    Organisationsnummer: "556784-8808",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Bräcke",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Dec 02, 2021",
  },
  {
    Områdes_ID: "2182-V-011",
    Projektnamn: "Storgrundet",
    Verksamhetsutövare: "Storgrundet Offshore AB",
    Organisationsnummer: "556709-7554",
    Aktuella_verk: 70,
    Antal_ej_koordinatsatta_verk: 69,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Söderhamn",
    Län: "Gävleborgs län",
    Elområde: "Sundsvall",
    Senast_sparad: "Nov 29, 2021",
  },
  {
    Områdes_ID: "1382-V-048",
    Projektnamn: "Kattegatt Offshore",
    Verksamhetsutövare: "Agrivind AB",
    Organisationsnummer: "556242-1387",
    Aktuella_verk: 50,
    Antal_ej_koordinatsatta_verk: 49,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falkenberg",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "Nov 30, 2021",
  },
  {
    Områdes_ID: "1080-V-016",
    Projektnamn: "Brunsmo Vindkraftpark",
    Verksamhetsutövare: "Arise Windpower AB",
    Organisationsnummer: "556274-6726",
    Aktuella_verk: 5,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 24.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Karlskrona",
    Län: "Blekinge län",
    Elområde: "Malmö",
    Senast_sparad: "May 27, 2020",
  },
  {
    Områdes_ID: "0861-V-054",
    Projektnamn: "Skäppentorp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 8.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mönsterås",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 25, 2019",
  },
  {
    Områdes_ID: "0861-V-051",
    Projektnamn: "Em",
    Verksamhetsutövare: "Södra Vind AB",
    Organisationsnummer: "556765-5716",
    Aktuella_verk: 8,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 42,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mönsterås",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "May 28, 2020",
  },
  {
    Områdes_ID: "0861-V-056",
    Projektnamn: "Mönsterås Bruk",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 10.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mönsterås",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "May 28, 2020",
  },
  {
    Områdes_ID: "0683-V-010",
    Projektnamn: "Hånger",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.95,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Värnamo",
    Län: "Jönköpings län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2062-V-004",
    Projektnamn: "Säliträdberget",
    Verksamhetsutövare: "Säliträdberget Vind AB",
    Organisationsnummer: "556717-6721",
    Aktuella_verk: 8,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 44,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mora",
    Län: "Dalarnas län",
    Elområde: "Stockholm",
    Senast_sparad: "Mar 09, 2020",
  },
  {
    Områdes_ID: "1415-V-022",
    Projektnamn: "Dalen 2",
    Verksamhetsutövare: "Kungälv Närenergi AB",
    Organisationsnummer: "556805-6641",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Stenungsund",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 30, 2019",
  },
  {
    Områdes_ID: "0484-V-012",
    Projektnamn: "Uvberget",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 8,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Eskilstuna",
    Län: "Södermanlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0883-V-010",
    Projektnamn: "Baldersrum",
    Verksamhetsutövare: "Greenextreme AB",
    Organisationsnummer: "556722-4596",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Västervik",
    Län: "Kalmar län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1473-V-012",
    Projektnamn: "Slätte",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Töreboda",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 08, 2019",
  },
  {
    Områdes_ID: "1293-V-031",
    Projektnamn: "Åbuen 2",
    Verksamhetsutövare: "Billyvind AB",
    Organisationsnummer: "556705-6931",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hässleholm",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 17, 2019",
  },
  {
    Områdes_ID: "1473-V-018",
    Projektnamn: "Lyrestad II",
    Verksamhetsutövare: "Lyrestad Vind AB",
    Organisationsnummer: "556872-2846",
    Aktuella_verk: 8,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 85.04,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Töreboda",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Jan 23, 2020",
  },
  {
    Områdes_ID: "1465-V-006",
    Projektnamn: "Håcksvik del 1",
    Verksamhetsutövare: "Gamesa Energy Sweden AB",
    Organisationsnummer: "556814-4181",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Svenljunga",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "0582-V-004",
    Projektnamn: "",
    Verksamhetsutövare: "Vindvision Norr AB",
    Organisationsnummer: "556719-9699",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Söderköping",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0582-V-005",
    Projektnamn: "",
    Verksamhetsutövare: "Vindvision Norr AB",
    Organisationsnummer: "556719-9699",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Söderköping",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0584-V-001",
    Projektnamn: "Bårstad",
    Verksamhetsutövare: "Bårstad Lantbruks AB",
    Organisationsnummer: "556439-2073",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vadstena",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0765-V-004",
    Projektnamn: "Bölsnäs",
    Verksamhetsutövare: "Solid Vind AB",
    Organisationsnummer: "556780-7572",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Älmhult",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 29, 2019",
  },
  {
    Områdes_ID: "0860-V-006",
    Projektnamn: "Rakenäs",
    Verksamhetsutövare: "Solid Vind AB",
    Organisationsnummer: "556780-7572",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hultsfred",
    Län: "Kalmar län",
    Elområde: "Stockholm",
    Senast_sparad: "Apr 29, 2019",
  },
  {
    Områdes_ID: "1460-V-003",
    Projektnamn: "Bäcken",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Bengtsfors",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Mar 17, 2021",
  },
  {
    Områdes_ID: "2305-V-022",
    Projektnamn: "Kusberget",
    Verksamhetsutövare: "Jämtvind AB",
    Organisationsnummer: "556784-8808",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Bräcke",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Feb 07, 2019",
  },
  {
    Områdes_ID: "1442-V-007",
    Projektnamn: "Brevikshult Västra",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vårgårda",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 15, 2021",
  },
  {
    Områdes_ID: "2422-V-005",
    Projektnamn: "Verboberget - Reutoberget",
    Verksamhetsutövare: "RES Renewable Norden AB",
    Organisationsnummer: "556616-0684",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Sorsele",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2280-V-019",
    Projektnamn: "Åberget",
    Verksamhetsutövare: "NordanVind vindkraft AB",
    Organisationsnummer: "556574-0403",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Härnösand",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "1499-V-052",
    Projektnamn: "Vårkumla-Brandstorp",
    Verksamhetsutövare: "Greenextreme AB",
    Organisationsnummer: "556722-4596",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Falköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 09, 2019",
  },
  {
    Områdes_ID: "1470-V-049",
    Projektnamn: "Jung-Åsa",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 6,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 34.68,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Jan 01, 2013",
    Planerat_drifttagande: "Mar 30, 2014",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 24, 2020",
  },
  {
    Områdes_ID: "2260-V-009",
    Projektnamn: "Länsterhöjden",
    Verksamhetsutövare: "SCA Energy AB",
    Organisationsnummer: "556129-8885",
    Aktuella_verk: 20,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 278,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Jan 01, 2018",
    Planerat_drifttagande: "Jun 01, 2020",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ånge",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "Jun 10, 2020",
  },
  {
    Områdes_ID: "1780-V-002",
    Projektnamn: "Galtryggen",
    Verksamhetsutövare: "Greenextreme AB",
    Organisationsnummer: "556722-4596",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 24,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Karlstad",
    Län: "Värmlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 26, 2021",
  },
  {
    Områdes_ID: "1765-V-007",
    Projektnamn: "Årjäng Byn",
    Verksamhetsutövare: "Gothia Vind AB",
    Organisationsnummer: "556714-2095",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Årjäng",
    Län: "Värmlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "1766-V-004",
    Projektnamn: "Frykdalshöjden",
    Verksamhetsutövare: "Bixia Byggvind AB",
    Organisationsnummer: "556785-1208",
    Aktuella_verk: 9,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Sep 01, 2021",
    Planerat_drifttagande: "Dec 01, 2021",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sunne",
    Län: "Värmlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Nov 30, 2021",
  },
  {
    Områdes_ID: "1780-V-007",
    Projektnamn: "Väse",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Karlstad",
    Län: "Värmlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 17, 2019",
  },
  {
    Områdes_ID: "2023-V-007",
    Projektnamn: "Fageråsen",
    Verksamhetsutövare: "Dala Vind AB",
    Organisationsnummer: "556696-6940",
    Aktuella_verk: 34,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 510,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Malung-Sälen",
    Län: "Dalarnas län",
    Elområde: "Stockholm",
    Senast_sparad: "Aug 09, 2021",
  },
  {
    Områdes_ID: "2313-V-019",
    Projektnamn: "Skyttmon",
    Verksamhetsutövare: "JP Vind AB",
    Organisationsnummer: "556754-9174",
    Aktuella_verk: 16,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 80,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Strömsund",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2161-V-013",
    Projektnamn: "Degerkölen",
    Verksamhetsutövare: "RES Renewable Norden AB",
    Organisationsnummer: "556616-0684",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ljusdal",
    Län: "Gävleborgs län",
    Elområde: "Sundsvall",
    Senast_sparad: "Feb 27, 2020",
  },
  {
    Områdes_ID: "2326-V-019",
    Projektnamn: "Galberget",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 14,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Berg",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2260-V-013",
    Projektnamn: "Storflötten",
    Verksamhetsutövare: "Turinge Energi AB",
    Organisationsnummer: "556857-3744",
    Aktuella_verk: 38,
    Antal_ej_koordinatsatta_verk: 2,
    Beräknad_årsproduktion_GWh: 500.4,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Jan 01, 2018",
    Planerat_drifttagande: "Jun 01, 2020",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ånge",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "Sep 14, 2020",
  },
  {
    Områdes_ID: "1384-V-003",
    Projektnamn: "Ulvås",
    Verksamhetsutövare: "Lygnern Vind AB",
    Organisationsnummer: "556792-4039",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kungsbacka",
    Län: "Hallands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1442-V-008",
    Projektnamn: "Brevikshult Östra",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vårgårda",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 15, 2021",
  },
  {
    Områdes_ID: "0563-V-002",
    Projektnamn: "Skönero",
    Verksamhetsutövare: "Vindvision Norr AB",
    Organisationsnummer: "556719-9699",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Valdemarsvik",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 08, 2021",
  },
  {
    Områdes_ID: "0834-V-001",
    Projektnamn: "Fastlycke_Övraby",
    Verksamhetsutövare: "Bjäre Kraft Energi AB",
    Organisationsnummer: "556527-5020",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Torsås",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Mar 23, 2020",
  },
  {
    Områdes_ID: "2422-V-002",
    Projektnamn: "Juktan Vindkraftpark",
    Verksamhetsutövare: "Vattenfall Juktan Vind AB",
    Organisationsnummer: "556987-0958",
    Aktuella_verk: 9,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 81.99,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sorsele",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "Oct 05, 2021",
  },
  {
    Områdes_ID: "2481-V-005",
    Projektnamn: "Norrbäck Vindkraftpark",
    Verksamhetsutövare: "Vattenfall Vindkraft AB",
    Organisationsnummer: "556581-4273",
    Aktuella_verk: 55,
    Antal_ej_koordinatsatta_verk: 18,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Lycksele",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "Jan 12, 2021",
  },
  {
    Områdes_ID: "2422-V-003",
    Projektnamn: "Bredträsk",
    Verksamhetsutövare: "Björnberget Vindkraft AB",
    Organisationsnummer: "556898-1871",
    Aktuella_verk: 10,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Sorsele",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1490-V-005",
    Projektnamn: "Brämhult",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Borås",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1442-V-006",
    Projektnamn: "Ornungaskogen del 1",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vårgårda",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1490-V-008",
    Projektnamn: "Ornungaskogen del 2",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Borås",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1470-V-050",
    Projektnamn: "Stenkulla",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 10,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Jan 01, 2013",
    Planerat_drifttagande: "Mar 30, 2014",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1765-V-005",
    Projektnamn: "Årjäng NV etapp 2",
    Verksamhetsutövare: "Rabbalshede Kraft AB",
    Organisationsnummer: "556681-4652",
    Aktuella_verk: 8,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 111.2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Feb 01, 2021",
    Planerat_drifttagande: "Dec 01, 2022",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Årjäng",
    Län: "Värmlands län",
    Elområde: "Stockholm",
    Senast_sparad: "May 07, 2021",
  },
  {
    Områdes_ID: "1765-V-006",
    Projektnamn: "Häljebyn",
    Verksamhetsutövare: "Rabbalshede Kraft AB",
    Organisationsnummer: "556681-4652",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Årjäng",
    Län: "Värmlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Apr 23, 2019",
  },
  {
    Områdes_ID: "1782-V-002",
    Projektnamn: "Grävlingkullarna",
    Verksamhetsutövare: "SR Energy AB",
    Organisationsnummer: "556711-9549",
    Aktuella_verk: 18,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Filipstad",
    Län: "Värmlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1782-V-003",
    Projektnamn: "Fjällrämmen",
    Verksamhetsutövare: "Bergvik Skog AB",
    Organisationsnummer: "556610-2959",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Filipstad",
    Län: "Värmlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0780-V-012",
    Projektnamn: "Tävelsås",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Växjö",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 25, 2019",
  },
  {
    Områdes_ID: "0781-V-040",
    Projektnamn: "Sommarsäte",
    Verksamhetsutövare: "Billyvind AB",
    Organisationsnummer: "556705-6931",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ljungby",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 26, 2019",
  },
  {
    Områdes_ID: "1491-V-025",
    Projektnamn: "Svarvarehemmet",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ulricehamn",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1491-V-025",
    Projektnamn: "Svarvarehemmet",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ulricehamn",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2260-V-010",
    Projektnamn: "Björnberget",
    Verksamhetsutövare: "RES Renewable Norden AB",
    Organisationsnummer: "556616-0684",
    Aktuella_verk: 60,
    Antal_ej_koordinatsatta_verk: 37,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Jan 01, 2021",
    Planerat_drifttagande: "Aug 01, 2022",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ånge",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "Nov 02, 2021",
  },
  {
    Områdes_ID: "2260-V-012",
    Projektnamn: "Gubbaberget",
    Verksamhetsutövare: "RES Renewable Norden AB",
    Organisationsnummer: "556616-0684",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ånge",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2161-V-012",
    Projektnamn: "Gubbaberget Söder",
    Verksamhetsutövare: "RES Renewable Norden AB",
    Organisationsnummer: "556616-0684",
    Aktuella_verk: 13,
    Antal_ej_koordinatsatta_verk: 1,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Sep 01, 2021",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ljusdal",
    Län: "Gävleborgs län",
    Elområde: "Sundsvall",
    Senast_sparad: "Nov 10, 2021",
  },
  {
    Områdes_ID: "2283-V-027",
    Projektnamn: "Hästkullen",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Sollefteå",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2282-V-009",
    Projektnamn: "Spelåsen",
    Verksamhetsutövare: "Kabeko Kraft AB",
    Organisationsnummer: "556763-7151",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kramfors",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2280-V-013",
    Projektnamn: "Spjutåsberget",
    Verksamhetsutövare: "Härnösand Energi och Miljö AB",
    Organisationsnummer: "556526-3745",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 24,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Aug 15, 2018",
    Planerat_drifttagande: "Dec 01, 2020",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Härnösand",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "Apr 20, 2020",
  },
  {
    Områdes_ID: "1060-V-006",
    Projektnamn: "Bengtsboda",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Olofström",
    Län: "Blekinge län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 27, 2019",
  },
  {
    Områdes_ID: "1082-V-006",
    Projektnamn: "Letesmåla",
    Verksamhetsutövare: "Arise Windpower AB",
    Organisationsnummer: "556274-6726",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Karlshamn",
    Län: "Blekinge län",
    Elområde: "Malmö",
    Senast_sparad: "Mar 28, 2019",
  },
  {
    Områdes_ID: "0763-V-007",
    Projektnamn: "Letesmåla 2",
    Verksamhetsutövare: "Arise Windpower AB",
    Organisationsnummer: "556274-6726",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tingsryd",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1083-V-015",
    Projektnamn: "Öbackarna- Lörbyrondellen",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Sölvesborg",
    Län: "Blekinge län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 27, 2019",
  },
  {
    Områdes_ID: "1060-V-005",
    Projektnamn: "Skälmershult",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Olofström",
    Län: "Blekinge län",
    Elområde: "Malmö",
    Senast_sparad: "May 10, 2019",
  },
  {
    Områdes_ID: "2584-V-009",
    Projektnamn: "Longastunturi",
    Verksamhetsutövare: "OX2 AB",
    Organisationsnummer: "556675-7497",
    Aktuella_verk: 40,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kiruna",
    Län: "Norrbottens län",
    Elområde: "Luleå",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2584-V-008",
    Projektnamn: "Kuusivaara",
    Verksamhetsutövare: "KSV Kraft AB",
    Organisationsnummer: "556845-8532",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kiruna",
    Län: "Norrbottens län",
    Elområde: "Luleå",
    Senast_sparad: "Apr 20, 2020",
  },
  {
    Områdes_ID: "2523-V-002",
    Projektnamn: "Kuusivaara",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Gällivare",
    Län: "Norrbottens län",
    Elområde: "Luleå",
    Senast_sparad: "Apr 20, 2020",
  },
  {
    Områdes_ID: "2514-V-006",
    Projektnamn: "Bergön",
    Verksamhetsutövare: "WPD Scandinavia AB",
    Organisationsnummer: "556666-3422",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kalix",
    Län: "Norrbottens län",
    Elområde: "Luleå",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0880-V-024",
    Projektnamn: "Haraldsmåla",
    Verksamhetsutövare: "Svenska Vindbolaget AB",
    Organisationsnummer: "556759-9013",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kalmar",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0861-V-057",
    Projektnamn: "Haraldsmåla",
    Verksamhetsutövare: "Svenska Vindbolaget AB",
    Organisationsnummer: "556759-9013",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mönsterås",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "May 02, 2019",
  },
  {
    Områdes_ID: "0884-V-002",
    Projektnamn: "Hoppeskogen",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vimmerby",
    Län: "Kalmar län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1471-V-021",
    Projektnamn: "Pilaholm",
    Verksamhetsutövare: "Erikstorp Utveckling AB",
    Organisationsnummer: "556748-1667",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Götene",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Apr 17, 2019",
  },
  {
    Områdes_ID: "0861-V-058",
    Projektnamn: "Åby-Alebo",
    Verksamhetsutövare: "Åby Alebo Energi AB",
    Organisationsnummer: "559208-1706",
    Aktuella_verk: 36,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 499.68,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mönsterås",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Jan 18, 2022",
  },
  {
    Områdes_ID: "0861-V-059",
    Projektnamn: "Kärnebo",
    Verksamhetsutövare: "Statkraft Södra Vindkraft AB",
    Organisationsnummer: "556785-3873",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mönsterås",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0840-V-101",
    Projektnamn: "Ryd-Rönnerum",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mörbylånga",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0883-V-011",
    Projektnamn: "Tyllinge",
    Verksamhetsutövare: "Arise Windpower AB",
    Organisationsnummer: "556274-6726",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Västervik",
    Län: "Kalmar län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1461-V-035",
    Projektnamn: "Tegen",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mellerud",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 15, 2021",
  },
  {
    Områdes_ID: "1480-V-003",
    Projektnamn: "Vindplats Göteborg",
    Verksamhetsutövare: "Göteborg Energi AB",
    Organisationsnummer: "556362-6794",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Göteborg",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0382-V-003",
    Projektnamn: "Forsmark Vindkraftpark",
    Verksamhetsutövare: "Vattenfall Vindkraft AB",
    Organisationsnummer: "556581-4273",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 15,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Östhammar",
    Län: "Uppsala län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 08, 2021",
  },
  {
    Områdes_ID: "0687-V-004",
    Projektnamn: "Äpplaryd",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tranås",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 20, 2020",
  },
  {
    Områdes_ID: "0687-V-008",
    Projektnamn: "Prästatorpet",
    Verksamhetsutövare: "Linköpings stifts prästlönefond",
    Organisationsnummer: "262000-1558",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.02,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tranås",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2313-V-027",
    Projektnamn: "Sikåskälen",
    Verksamhetsutövare: "SWS Vind AB",
    Organisationsnummer: "556911-8085",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Strömsund",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Sep 27, 2019",
  },
  {
    Områdes_ID: "2305-V-025",
    Projektnamn: "Mörttjärnberget 2",
    Verksamhetsutövare: "Statkraft SCA Vind AB",
    Organisationsnummer: "556706-3507",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Bräcke",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "2284-V-024",
    Projektnamn: "Åliden - Lidenprojekten",
    Verksamhetsutövare: "Åliden Vind AB",
    Organisationsnummer: "556856-7589",
    Aktuella_verk: 12,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 159.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "Jun 01, 2020",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Örnsköldsvik",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "Jun 29, 2020",
  },
  {
    Områdes_ID: "0513-V-002",
    Projektnamn: "Gröninge",
    Verksamhetsutövare: "RWE Renewables Sweden AB",
    Organisationsnummer: "556938-6864",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kinda",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 22, 2021",
  },
  {
    Områdes_ID: "0665-V-008",
    Projektnamn: "Palsbo",
    Verksamhetsutövare: "RWE Renewables Sweden AB",
    Organisationsnummer: "556938-6864",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vaggeryd",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 22, 2021",
  },
  {
    Områdes_ID: "0881-V-004",
    Projektnamn: "Villköl",
    Verksamhetsutövare: "RWE Renewables Sweden AB",
    Organisationsnummer: "556938-6864",
    Aktuella_verk: 7,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 54.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "May 01, 2012",
    Planerat_drifttagande: "Dec 01, 2012",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Nybro",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 22, 2021",
  },
  {
    Områdes_ID: "1780-V-006",
    Projektnamn: "Sundstorp",
    Verksamhetsutövare: "Greenextreme AB",
    Organisationsnummer: "556722-4596",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Karlstad",
    Län: "Värmlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 15, 2020",
  },
  {
    Områdes_ID: "0136-V-002",
    Projektnamn: "Lännåker",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Haninge",
    Län: "Stockholms län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 07, 2020",
  },
  {
    Områdes_ID: "1383-V-020",
    Projektnamn: "Grimmared",
    Verksamhetsutövare: "Triventus AB",
    Organisationsnummer: "556627-3016",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 68,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Varberg",
    Län: "Hallands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0617-V-003",
    Projektnamn: "Knuts kulle",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 18.3,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gnosjö",
    Län: "Jönköpings län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 25, 2020",
  },
  {
    Områdes_ID: "0617-V-001",
    Projektnamn: "Gunnilstorp/Tranhult",
    Verksamhetsutövare: "Kraftö AB",
    Organisationsnummer: "556708-7456",
    Aktuella_verk: 19,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Gnosjö",
    Län: "Jönköpings län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2480-V-018",
    Projektnamn: "Botsmark",
    Verksamhetsutövare: "European Energy",
    Organisationsnummer: "556762-0041",
    Aktuella_verk: 16,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Umeå",
    Län: "Västerbottens län",
    Elområde: "Luleå",
    Senast_sparad: "Dec 08, 2021",
  },
  {
    Områdes_ID: "1278-V-017",
    Projektnamn: "Öllöv",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 1,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Båstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "0617-V-004",
    Projektnamn: "Gamlebo",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Gnosjö",
    Län: "Jönköpings län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 10, 2021",
  },
  {
    Områdes_ID: "0461-V-002",
    Projektnamn: "Lebro",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.06,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gnesta",
    Län: "Södermanlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Jan 07, 2019",
  },
  {
    Områdes_ID: "1293-V-032",
    Projektnamn: "Stora Tockarp",
    Verksamhetsutövare: "WindEn sweden AB",
    Organisationsnummer: "556766-8040",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hässleholm",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 17, 2019",
  },
  {
    Områdes_ID: "1293-V-033",
    Projektnamn: "Galthult",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hässleholm",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1283-V-089",
    Projektnamn: "Fjärestad",
    Verksamhetsutövare: "Mimer vind AB",
    Organisationsnummer: "556608-8760",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Helsingborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Mar 12, 2021",
  },
  {
    Områdes_ID: "1284-V-022",
    Projektnamn: "Stora Gorslöv",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 14.76,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Höganäs",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1461-V-036",
    Projektnamn: "Bäcken",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mellerud",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 05, 2019",
  },
  {
    Områdes_ID: "1460-V-005",
    Projektnamn: "Alltorp",
    Verksamhetsutövare: "Scanergy South AB",
    Organisationsnummer: "556758-6614",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Bengtsfors",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 15, 2021",
  },
  {
    Områdes_ID: "2161-V-011",
    Projektnamn: "Vindkraftpark Hemberget",
    Verksamhetsutövare: "Nordex Sverige AB",
    Organisationsnummer: "556756-9792",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ljusdal",
    Län: "Gävleborgs län",
    Elområde: "Sundsvall",
    Senast_sparad: "Nov 11, 2021",
  },
  {
    Områdes_ID: "2361-V-034",
    Projektnamn: "Åndberget",
    Verksamhetsutövare: "OX2 AB",
    Organisationsnummer: "556675-7497",
    Aktuella_verk: 53,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 837.4,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Jun 01, 2019",
    Planerat_drifttagande: "Dec 01, 2021",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Härjedalen",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Nov 12, 2021",
  },
  {
    Områdes_ID: "2183-V-008",
    Projektnamn: "Kraftberget",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Bollnäs",
    Län: "Gävleborgs län",
    Elområde: "Sundsvall",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "1380-V-020",
    Projektnamn: "Ågård",
    Verksamhetsutövare: "Eldsberga Vind AB",
    Organisationsnummer: "556965-8866",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 6.3,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Halmstad",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "Oct 05, 2021",
  },
  {
    Områdes_ID: "1860-V-004",
    Projektnamn: "Torpaskoga",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Laxå",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "Mar 19, 2021",
  },
  {
    Områdes_ID: "1882-V-007",
    Projektnamn: "Öna",
    Verksamhetsutövare: "AskersundsBostäder AB",
    Organisationsnummer: "556432-8119",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Askersund",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "Aug 27, 2019",
  },
  {
    Områdes_ID: "0188-V-006",
    Projektnamn: "Skederids-Boda",
    Verksamhetsutövare: "Greenextreme AB",
    Organisationsnummer: "556722-4596",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Norrtälje",
    Län: "Stockholms län",
    Elområde: "Stockholm",
    Senast_sparad: "Mar 11, 2021",
  },
  {
    Områdes_ID: "0188-V-009",
    Projektnamn: "Svartnö",
    Verksamhetsutövare: "Firma Ramström Vind AB",
    Organisationsnummer: "556688-2683",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Norrtälje",
    Län: "Stockholms län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 09, 2021",
  },
  {
    Områdes_ID: "2184-V-008",
    Projektnamn: "Vallåsen",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hudiksvall",
    Län: "Gävleborgs län",
    Elområde: "Sundsvall",
    Senast_sparad: "Feb 19, 2020",
  },
  {
    Områdes_ID: "0780-V-011",
    Projektnamn: "Härensås",
    Verksamhetsutövare: "Billyvind AB",
    Organisationsnummer: "556705-6931",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Växjö",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 30, 2019",
  },
  {
    Områdes_ID: "0764-V-005",
    Projektnamn: "",
    Verksamhetsutövare: "Kraftö AB",
    Organisationsnummer: "556708-7456",
    Aktuella_verk: 6,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Alvesta",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0480-V-003",
    Projektnamn: "Smultronet 4",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Nyköping",
    Län: "Södermanlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Apr 05, 2019",
  },
  {
    Områdes_ID: "1780-V-009",
    Projektnamn: "Hultsberg",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Karlstad",
    Län: "Värmlands län",
    Elområde: "Stockholm",
    Senast_sparad: "May 02, 2019",
  },
  {
    Områdes_ID: "1780-V-013",
    Projektnamn: "Mången",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 10.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Karlstad",
    Län: "Värmlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Mar 11, 2020",
  },
  {
    Områdes_ID: "0586-V-055",
    Projektnamn: "Karleby",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mjölby",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 10, 2021",
  },
  {
    Områdes_ID: "2080-V-011",
    Projektnamn: "Tavelberget, etapp 2",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 6.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falun",
    Län: "Dalarnas län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0586-V-056",
    Projektnamn: "Normlösa-Östanbäck",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mjölby",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 10, 2021",
  },
  {
    Områdes_ID: "0821-V-001",
    Projektnamn: "Ekenäs",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Högsby",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "May 03, 2019",
  },
  {
    Områdes_ID: "0880-V-025",
    Projektnamn: "Mortorp",
    Verksamhetsutövare: "Firma Ramström Vind AB",
    Organisationsnummer: "556688-2683",
    Aktuella_verk: 6,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 35.4,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kalmar",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Jan 07, 2019",
  },
  {
    Områdes_ID: "1785-V-011",
    Projektnamn: "Stubberud",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 4.4,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Säffle",
    Län: "Värmlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1880-V-021",
    Projektnamn: "Koltorp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Örebro",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 18, 2020",
  },
  {
    Områdes_ID: "1880-V-022",
    Projektnamn: "Mosjö-Törsjö",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Örebro",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 06, 2019",
  },
  {
    Områdes_ID: "1281-V-022",
    Projektnamn: "Assartorp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 3,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Lund",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1281-V-023",
    Projektnamn: "Stångby",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 1,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Lund",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1280-V-007",
    Projektnamn: "Petersborg",
    Verksamhetsutövare: "RWE Renewables Sweden AB",
    Organisationsnummer: "556938-6864",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Malmö",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 22, 2021",
  },
  {
    Områdes_ID: "1471-V-039",
    Projektnamn: "Stora Lund",
    Verksamhetsutövare: "Greenextreme AB",
    Organisationsnummer: "556722-4596",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Götene",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1471-V-040",
    Projektnamn: "Vättlösa",
    Verksamhetsutövare: "Erikstorp Utveckling AB",
    Organisationsnummer: "556748-1667",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Götene",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1256-V-008",
    Projektnamn: "Hjärsåslilla",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Östra Göinge",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 06, 2019",
  },
  {
    Områdes_ID: "2062-V-005",
    Projektnamn: "Rossberget",
    Verksamhetsutövare: "Dala Vind AB",
    Organisationsnummer: "556696-6940",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 9,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mora",
    Län: "Dalarnas län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "2061-V-003",
    Projektnamn: "Långholmsberget",
    Verksamhetsutövare: "RWE Renewables Sweden AB",
    Organisationsnummer: "556938-6864",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Smedjebacken",
    Län: "Dalarnas län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 22, 2021",
  },
  {
    Områdes_ID: "0428-V-005",
    Projektnamn: "Blomsterhult",
    Verksamhetsutövare: "Triventus AB",
    Organisationsnummer: "556627-3016",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vingåker",
    Län: "Södermanlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Jan 15, 2019",
  },
  {
    Områdes_ID: "0581-V-013",
    Projektnamn: "Morkullberget",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Norrköping",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Aug 23, 2019",
  },
  {
    Områdes_ID: "0509-V-029",
    Projektnamn: "Ryket",
    Verksamhetsutövare: "Greenextreme AB",
    Organisationsnummer: "556722-4596",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ödeshög",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Dec 08, 2021",
  },
  {
    Områdes_ID: "0509-V-030",
    Projektnamn: "Lysings Härads",
    Verksamhetsutövare: "Himna Energi AB",
    Organisationsnummer: "556635-8924",
    Aktuella_verk: 6,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ödeshög",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0980-V-017",
    Projektnamn: "Forsviden Södra vindkraftspark",
    Verksamhetsutövare: "Forsviden Vind AB",
    Organisationsnummer: "556848-3498",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2480-V-021",
    Projektnamn: "Holmsund",
    Verksamhetsutövare: "Umeå Energi Sol Vind och Vatten AB",
    Organisationsnummer: "556649-3127",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Umeå",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0683-V-011",
    Projektnamn: "Pinnarekulla",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Värnamo",
    Län: "Jönköpings län",
    Elområde: "Malmö",
    Senast_sparad: "May 02, 2019",
  },
  {
    Områdes_ID: "2584-V-010",
    Projektnamn: "Riphacochkka vindkraftpark",
    Verksamhetsutövare: "OX2 AB",
    Organisationsnummer: "556675-7497",
    Aktuella_verk: 7,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 25.2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kiruna",
    Län: "Norrbottens län",
    Elområde: "Luleå",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1264-V-002",
    Projektnamn: "Rydsgård",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 2.8,
    Uppmätt_årsproduktion_GWh: 2.25,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Skurup",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Dec 23, 2020",
  },
  {
    Områdes_ID: "1264-V-025",
    Projektnamn: "Trunnerup",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Skurup",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Aug 26, 2019",
  },
  {
    Områdes_ID: "2462-V-008",
    Projektnamn: "Övre Dikasjö",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vilhelmina",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "Aug 20, 2019",
  },
  {
    Områdes_ID: "1884-V-001",
    Projektnamn: "Vassland Eolus",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 85,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Nora",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1214-V-061",
    Projektnamn: "Pålstorp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Svalöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "1272-V-007",
    Projektnamn: "Ryssberget",
    Verksamhetsutövare: "Arise Windpower AB",
    Organisationsnummer: "556274-6726",
    Aktuella_verk: 21,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Bromölla",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Jan 10, 2022",
  },
  {
    Områdes_ID: "2121-V-002",
    Projektnamn: "Hälsingeskogen",
    Verksamhetsutövare: "OX2 AB",
    Organisationsnummer: "556675-7497",
    Aktuella_verk: 40,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ovanåker",
    Län: "Gävleborgs län",
    Elområde: "Stockholm",
    Senast_sparad: "Dec 08, 2021",
  },
  {
    Områdes_ID: "0682-V-016",
    Projektnamn: "Djurseryd",
    Verksamhetsutövare: "Greenextreme AB",
    Organisationsnummer: "556722-4596",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Nässjö",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 05, 2019",
  },
  {
    Områdes_ID: "0682-V-020",
    Projektnamn: "Fagerberg",
    Verksamhetsutövare: "Firma Ramström Vind AB",
    Organisationsnummer: "556688-2683",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Nässjö",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "Mar 11, 2021",
  },
  {
    Områdes_ID: "2021-V-026",
    Projektnamn: "Kajsberget 6",
    Verksamhetsutövare: "Bergvik Skog AB",
    Organisationsnummer: "556610-2959",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vansbro",
    Län: "Dalarnas län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2085-V-018",
    Projektnamn: "Fjällberget",
    Verksamhetsutövare: "Fjällberget Energi AB",
    Organisationsnummer: "556571-5470",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 39.9,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "Oct 01, 2019",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ludvika",
    Län: "Dalarnas län",
    Elområde: "Stockholm",
    Senast_sparad: "Dec 12, 2019",
  },
  {
    Områdes_ID: "2021-V-027",
    Projektnamn: "Kajsberget 7",
    Verksamhetsutövare: "Bergvik Skog AB",
    Organisationsnummer: "556610-2959",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vansbro",
    Län: "Dalarnas län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1882-V-009",
    Projektnamn: "Södra Kärra",
    Verksamhetsutövare: "Arise Windpower AB",
    Organisationsnummer: "556274-6726",
    Aktuella_verk: 6,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 37.38,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Askersund",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 25, 2020",
  },
  {
    Områdes_ID: "1882-V-010",
    Projektnamn: "Gärdshyttan",
    Verksamhetsutövare: "Rabbalshede Kraft AB",
    Organisationsnummer: "556681-4652",
    Aktuella_verk: 5,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 54.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Apr 01, 2019",
    Planerat_drifttagande: "Apr 01, 2020",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Askersund",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "Jun 11, 2020",
  },
  {
    Områdes_ID: "1882-V-014",
    Projektnamn: "Nyckelhult 2",
    Verksamhetsutövare: "Gamesa Energy Sweden AB",
    Organisationsnummer: "556814-4181",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Askersund",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "1290-V-115",
    Projektnamn: "Träne Örmatofta",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1880-V-026",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Örebro",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "1282-V-015",
    Projektnamn: "Övra Glumslöv",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Landskrona",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1499-V-025",
    Projektnamn: "Orreholmen",
    Verksamhetsutövare: "Erikstorp Utveckling AB",
    Organisationsnummer: "556748-1667",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 22.52,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 08, 2021",
  },
  {
    Områdes_ID: "1495-V-047",
    Projektnamn: "Amundtorp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.08,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Skara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 14, 2019",
  },
  {
    Områdes_ID: "1384-V-006",
    Projektnamn: "Torpa",
    Verksamhetsutövare: "CRC Vindkraft AB",
    Organisationsnummer: "556700-3545",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kungsbacka",
    Län: "Hallands län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 14, 2019",
  },
  {
    Områdes_ID: "1384-V-008",
    Projektnamn: "Lerberg",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kungsbacka",
    Län: "Hallands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2132-V-004",
    Projektnamn: "Vindpark Ulvberget",
    Verksamhetsutövare: "Rabbalshede Kraft AB",
    Organisationsnummer: "556681-4652",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Nordanstig",
    Län: "Gävleborgs län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1491-V-008",
    Projektnamn: "Lundby Trädet",
    Verksamhetsutövare: "Sverige Vindkraftkooperativ Ek för",
    Organisationsnummer: "769603-7089",
    Aktuella_verk: 5,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 33,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ulricehamn",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 18, 2021",
  },
  {
    Områdes_ID: "1438-V-007",
    Projektnamn: "Buråsen",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Dals-Ed",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "1439-V-012",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Färgelanda",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Dec 13, 2021",
  },
  {
    Områdes_ID: "1438-V-008",
    Projektnamn: "Holmevattnet",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Dals-Ed",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "2418-V-009",
    Projektnamn: "Vindkraftpark Kokträskliden",
    Verksamhetsutövare: "Nordex Sverige AB",
    Organisationsnummer: "556756-9792",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Malå",
    Län: "Västerbottens län",
    Elområde: "Luleå",
    Senast_sparad: "Sep 04, 2019",
  },
  {
    Områdes_ID: "0685-V-008",
    Projektnamn: "Åmjölkesbo",
    Verksamhetsutövare: "Billyvind AB",
    Organisationsnummer: "556705-6931",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vetlanda",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "Dec 08, 2021",
  },
  {
    Områdes_ID: "1080-V-017",
    Projektnamn: "Öljersjö",
    Verksamhetsutövare: "Karlskrona",
    Organisationsnummer: "212000-0829",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 6.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Karlskrona",
    Län: "Blekinge län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0834-V-032",
    Projektnamn: "Applerum",
    Verksamhetsutövare: "Solid Vind AB",
    Organisationsnummer: "556780-7572",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Torsås",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Nov 18, 2021",
  },
  {
    Områdes_ID: "0834-V-032",
    Projektnamn: "Applerum",
    Verksamhetsutövare: "Solid Vind AB",
    Organisationsnummer: "556780-7572",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Torsås",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Nov 18, 2021",
  },
  {
    Områdes_ID: "1080-V-019",
    Projektnamn: "Vinberga",
    Verksamhetsutövare: "Kraftö AB",
    Organisationsnummer: "556708-7456",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Karlskrona",
    Län: "Blekinge län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1080-V-020",
    Projektnamn: "Persmåla",
    Verksamhetsutövare: "Kraftö AB",
    Organisationsnummer: "556708-7456",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Karlskrona",
    Län: "Blekinge län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1080-V-021",
    Projektnamn: "Ramdala",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 11.4,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Karlskrona",
    Län: "Blekinge län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1080-V-022",
    Projektnamn: "Säby",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 6.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Karlskrona",
    Län: "Blekinge län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1461-V-038",
    Projektnamn: "Båsane",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mellerud",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Apr 22, 2021",
  },
  {
    Områdes_ID: "1461-V-040",
    Projektnamn: "Liane",
    Verksamhetsutövare: "Scanergy South AB",
    Organisationsnummer: "556758-6614",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mellerud",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 15, 2021",
  },
  {
    Områdes_ID: "1499-V-054",
    Projektnamn: "Vartofta Gård",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Falköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Apr 22, 2021",
  },
  {
    Områdes_ID: "0780-V-013",
    Projektnamn: "Målajord",
    Verksamhetsutövare: "BayWa r.e. Scandinavia AB",
    Organisationsnummer: "556885-6503",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 50.1,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Dec 15, 2019",
    Planerat_drifttagande: "Sep 01, 2021",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Växjö",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 08, 2021",
  },
  {
    Områdes_ID: "0980-V-001",
    Projektnamn: "Bockstigen 1",
    Verksamhetsutövare: "Bockstigen AB",
    Organisationsnummer: "556921-8257",
    Aktuella_verk: 5,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 14, 2021",
  },
  {
    Områdes_ID: "1462-V-018",
    Projektnamn: "Vindkraftpark Högen",
    Verksamhetsutövare: "Rabbalshede Kraft AB",
    Organisationsnummer: "556681-4652",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 37.98,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "May 01, 2021",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "LillaEdet",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Aug 30, 2021",
  },
  {
    Områdes_ID: "1080-V-018",
    Projektnamn: "Lösen-Älmtamåla",
    Verksamhetsutövare: "Karlskrona",
    Organisationsnummer: "212000-0829",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Karlskrona",
    Län: "Blekinge län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1861-V-008",
    Projektnamn: "Odensvi",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hallsberg",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1814-V-003",
    Projektnamn: "Stickninge",
    Verksamhetsutövare: "Greenextreme AB",
    Organisationsnummer: "556722-4596",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Lekeberg",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "1499-V-053",
    Projektnamn: "Mönarp",
    Verksamhetsutövare: "Greenextreme AB",
    Organisationsnummer: "556722-4596",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Falköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Aug 26, 2019",
  },
  {
    Områdes_ID: "0883-V-012",
    Projektnamn: "Lebo",
    Verksamhetsutövare: "Kraftö AB",
    Organisationsnummer: "556708-7456",
    Aktuella_verk: 6,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Västervik",
    Län: "Kalmar län",
    Elområde: "Stockholm",
    Senast_sparad: "Dec 01, 2021",
  },
  {
    Områdes_ID: "1443-V-003",
    Projektnamn: "Hedared Bollebygd",
    Verksamhetsutövare: "Gamesa Energy Sweden AB",
    Organisationsnummer: "556814-4181",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Bollebygd",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1497-V-051",
    Projektnamn: "Lilla Solberga",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hjo",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "1461-V-037",
    Projektnamn: "Kuserud",
    Verksamhetsutövare: "WindEn sweden AB",
    Organisationsnummer: "556766-8040",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mellerud",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 15, 2021",
  },
  {
    Områdes_ID: "1438-V-009",
    Projektnamn: "Töftedals-Bön",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Dals-Ed",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 15, 2021",
  },
  {
    Områdes_ID: "1460-V-006",
    Projektnamn: "Värnebo",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Bengtsfors",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1460-V-006",
    Projektnamn: "Värnebo",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Bengtsfors",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1439-V-006",
    Projektnamn: "Åttingsåker",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Färgelanda",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 15, 2021",
  },
  {
    Områdes_ID: "1439-V-007",
    Projektnamn: "Stuveryr",
    Verksamhetsutövare: "Wästgöta Wind AB",
    Organisationsnummer: "556944-6478",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Färgelanda",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 02, 2021",
  },
  {
    Områdes_ID: "1439-V-008",
    Projektnamn: "Bandene 1",
    Verksamhetsutövare: "WindEn sweden AB",
    Organisationsnummer: "556766-8040",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Färgelanda",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Aug 28, 2019",
  },
  {
    Områdes_ID: "1439-V-009",
    Projektnamn: "Bandene 2",
    Verksamhetsutövare: "WindEn sweden AB",
    Organisationsnummer: "556766-8040",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Färgelanda",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1439-V-010",
    Projektnamn: "Horntveten",
    Verksamhetsutövare: "WindEn sweden AB",
    Organisationsnummer: "556766-8040",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Färgelanda",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1439-V-011",
    Projektnamn: "Runnsäter",
    Verksamhetsutövare: "WindEn sweden AB",
    Organisationsnummer: "556766-8040",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Färgelanda",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1460-V-007",
    Projektnamn: "Gunbjörbyn",
    Verksamhetsutövare: "Scanergy South AB",
    Organisationsnummer: "556758-6614",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Bengtsfors",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 15, 2021",
  },
  {
    Områdes_ID: "1882-V-008",
    Projektnamn: "Lunna",
    Verksamhetsutövare: "Vindpark Lunna AB",
    Organisationsnummer: "559077-9095",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 21,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Askersund",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "Jan 07, 2019",
  },
  {
    Områdes_ID: "1470-V-051",
    Projektnamn: "Rangeltorp",
    Verksamhetsutövare: "Agrivind AB",
    Organisationsnummer: "556242-1387",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 10,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 29, 2021",
  },
  {
    Områdes_ID: "1470-V-052",
    Projektnamn: "Kroken",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 14, 2019",
  },
  {
    Områdes_ID: "0980-V-070",
    Projektnamn: "Lau Liffride",
    Verksamhetsutövare: "Röcklingers Handelsträdgård AB",
    Organisationsnummer: "556595-6249",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Apr 22, 2021",
  },
  {
    Områdes_ID: "0980-V-072",
    Projektnamn: "Stjupsparken",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 12, 2019",
  },
  {
    Områdes_ID: "1277-V-032",
    Projektnamn: "Kärrarp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Åstorp",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "0188-V-007",
    Projektnamn: "Varsvik",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 17,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 164.9,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Norrtälje",
    Län: "Stockholms län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1780-V-010",
    Projektnamn: "Nordviken",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 4,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Karlstad",
    Län: "Värmlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "0882-V-005",
    Projektnamn: "Vindpark Oskarshamn",
    Verksamhetsutövare: "Cloudberry Offshore Wind AB",
    Organisationsnummer: "556710-7403",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Oskarshamn",
    Län: "Kalmar län",
    Elområde: "Stockholm",
    Senast_sparad: "Aug 16, 2021",
  },
  {
    Områdes_ID: "1780-V-016",
    Projektnamn: "Bjurerud",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Karlstad",
    Län: "Värmlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Apr 30, 2019",
  },
  {
    Områdes_ID: "1780-V-008",
    Projektnamn: "Långenäs",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Karlstad",
    Län: "Värmlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 26, 2021",
  },
  {
    Områdes_ID: "1780-V-014",
    Projektnamn: "Nyeds-Hulteby",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Karlstad",
    Län: "Värmlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "2161-V-014",
    Projektnamn: "Mjöberget",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 6,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ljusdal",
    Län: "Gävleborgs län",
    Elområde: "Sundsvall",
    Senast_sparad: "Oct 14, 2021",
  },
  {
    Områdes_ID: "0884-V-003",
    Projektnamn: "Gnöst",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vimmerby",
    Län: "Kalmar län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "0880-V-026",
    Projektnamn: "Österhultsmåla",
    Verksamhetsutövare: "OX2 AB",
    Organisationsnummer: "556675-7497",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 50,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Apr 01, 2020",
    Planerat_drifttagande: "Apr 01, 2021",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kalmar",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 29, 2021",
  },
  {
    Områdes_ID: "2361-V-041",
    Projektnamn: "Vindkraftpark Florkölen",
    Verksamhetsutövare: "Nordex Sverige AB",
    Organisationsnummer: "556756-9792",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Härjedalen",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "0582-V-006",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Söderköping",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Apr 30, 2019",
  },
  {
    Områdes_ID: "1233-V-007",
    Projektnamn: "Hököpinge",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vellinge",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1781-V-013",
    Projektnamn: "Nolgården Sörgården",
    Verksamhetsutövare: "Firma Ramström Vind AB",
    Organisationsnummer: "556688-2683",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kristinehamn",
    Län: "Värmlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1486-V-010",
    Projektnamn: "Kangeröd",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Strömstad",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "1281-V-024",
    Projektnamn: "Dalby",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 7,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Lund",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Dec 16, 2019",
  },
  {
    Områdes_ID: "1281-V-025",
    Projektnamn: "Hällestad",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Lund",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1281-V-026",
    Projektnamn: "Lyngby",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 2,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Lund",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "2425-V-003",
    Projektnamn: "Svanabyn",
    Verksamhetsutövare: "OX2 AB",
    Organisationsnummer: "556675-7497",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Dorotea",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "Sep 27, 2021",
  },
  {
    Områdes_ID: "1442-V-009",
    Projektnamn: "Siene",
    Verksamhetsutövare: "Greenextreme AB",
    Organisationsnummer: "556722-4596",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vårgårda",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 15, 2021",
  },
  {
    Områdes_ID: "1442-V-010",
    Projektnamn: "Skakeltorp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vårgårda",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Dec 08, 2021",
  },
  {
    Områdes_ID: "1442-V-011",
    Projektnamn: "Uggletorp",
    Verksamhetsutövare: "WindEn sweden AB",
    Organisationsnummer: "556766-8040",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vårgårda",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1442-V-012",
    Projektnamn: "Ornungaskogen",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vårgårda",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Nov 01, 2019",
  },
  {
    Områdes_ID: "1465-V-008",
    Projektnamn: "Brunnsbo",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Svenljunga",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 20, 2020",
  },
  {
    Områdes_ID: "0483-V-008",
    Projektnamn: "Gersnäs",
    Verksamhetsutövare: "Elproduktion AB",
    Organisationsnummer: "556595-7171",
    Aktuella_verk: 11,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.32,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Katrineholm",
    Län: "Södermanlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Nov 10, 2021",
  },
  {
    Områdes_ID: "1280-V-008",
    Projektnamn: "Tygelsjö",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Malmö",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1256-V-007",
    Projektnamn: "Svenstorp",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Östra Göinge",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Mar 18, 2021",
  },
  {
    Områdes_ID: "1484-V-013",
    Projektnamn: "HUMLEKÄRR",
    Verksamhetsutövare: "OWN Power Projects AB",
    Organisationsnummer: "556603-1869",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Lysekil",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 05, 2019",
  },
  {
    Områdes_ID: "0117-V-003",
    Projektnamn: "Norrö",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Österåker",
    Län: "Stockholms län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0428-V-010",
    Projektnamn: "Rosendal-Mjälnäs",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Vingåker",
    Län: "Södermanlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0581-V-012",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Norrköping",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 12, 2021",
  },
  {
    Områdes_ID: "0509-V-031",
    Projektnamn: "Sättra",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ödeshög",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1230-V-012",
    Projektnamn: "Gullåkra",
    Verksamhetsutövare: "WindEn sweden AB",
    Organisationsnummer: "556766-8040",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Staffanstorp",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "1230-V-011",
    Projektnamn: "Flackarp",
    Verksamhetsutövare: "WindEn sweden AB",
    Organisationsnummer: "556766-8040",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.01,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Staffanstorp",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0509-V-032",
    Projektnamn: "Ruskelsby",
    Verksamhetsutövare: "Firma Ramström Vind AB",
    Organisationsnummer: "556688-2683",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ödeshög",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1488-V-012",
    Projektnamn: "Kortered",
    Verksamhetsutövare: "WindEn sweden AB",
    Organisationsnummer: "556766-8040",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Trollhättan",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "0488-V-003",
    Projektnamn: "Trosaskogen",
    Verksamhetsutövare: "AB Hvalfisken",
    Organisationsnummer: "556430-4193",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.43,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Trosa",
    Län: "Södermanlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1264-V-024",
    Projektnamn: "Kalvabacken",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Skurup",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0767-V-010",
    Projektnamn: "Sånna",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.01,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Markaryd",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0682-V-017",
    Projektnamn: "Fredriksdal",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Nässjö",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0682-V-018",
    Projektnamn: "Hallhult",
    Verksamhetsutövare: "Arise Windpower AB",
    Organisationsnummer: "556274-6726",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Nässjö",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0682-V-019",
    Projektnamn: "Mållebo",
    Verksamhetsutövare: "OX2 AB",
    Organisationsnummer: "556675-7497",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Nässjö",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0682-V-015",
    Projektnamn: "Södra-Vibäck",
    Verksamhetsutövare: "Greenextreme AB",
    Organisationsnummer: "556722-4596",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Nässjö",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "Mar 11, 2021",
  },
  {
    Områdes_ID: "1882-V-012",
    Projektnamn: "Norra Hunna",
    Verksamhetsutövare: "Gamesa Energy Sweden AB",
    Organisationsnummer: "556814-4181",
    Aktuella_verk: 5,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 51.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Jul 01, 2020",
    Planerat_drifttagande: "Apr 01, 2021",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Askersund",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "May 20, 2021",
  },
  {
    Områdes_ID: "1882-V-013",
    Projektnamn: "Nyckelhult",
    Verksamhetsutövare: "Gamesa Energy Sweden AB",
    Organisationsnummer: "556814-4181",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Askersund",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 15, 2021",
  },
  {
    Områdes_ID: "1861-V-004",
    Projektnamn: "Nyckelhult 1",
    Verksamhetsutövare: "Gamesa Energy Sweden AB",
    Organisationsnummer: "556814-4181",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hallsberg",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "1861-V-005",
    Projektnamn: "Nyckelhult 2",
    Verksamhetsutövare: "Gamesa Energy Sweden AB",
    Organisationsnummer: "556814-4181",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hallsberg",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "1861-V-007",
    Projektnamn: "Hallsberg",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hallsberg",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "May 08, 2019",
  },
  {
    Områdes_ID: "1282-V-016",
    Projektnamn: "RÖNNEBERGA",
    Verksamhetsutövare: "WindEn sweden AB",
    Organisationsnummer: "556766-8040",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Landskrona",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 16, 2021",
  },
  {
    Områdes_ID: "1384-V-007",
    Projektnamn: "Ölmevalla-Bolg",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kungsbacka",
    Län: "Hallands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1384-V-009",
    Projektnamn: "Brandshult",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kungsbacka",
    Län: "Hallands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1443-V-004",
    Projektnamn: "Älmåsa",
    Verksamhetsutövare: "Gothia Vind AB",
    Organisationsnummer: "556714-2095",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Bollebygd",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Apr 22, 2021",
  },
  {
    Områdes_ID: "1486-V-011",
    Projektnamn: "Lytorp",
    Verksamhetsutövare: "Greenextreme AB",
    Organisationsnummer: "556722-4596",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Strömstad",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Apr 22, 2021",
  },
  {
    Områdes_ID: "0880-V-027",
    Projektnamn: "Mortorp",
    Verksamhetsutövare: "Billyvind AB",
    Organisationsnummer: "556705-6931",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kalmar",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Oct 30, 2019",
  },
  {
    Områdes_ID: "1266-V-048",
    Projektnamn: "Gunnarp",
    Verksamhetsutövare: "Universal Wind AB",
    Organisationsnummer: "556571-8151",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hörby",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "1266-V-049",
    Projektnamn: "Henset",
    Verksamhetsutövare: "Firma Ramström Vind AB",
    Organisationsnummer: "556688-2683",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hörby",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1266-V-050",
    Projektnamn: "Köinge",
    Verksamhetsutövare: "Hörby",
    Organisationsnummer: "212000-1108",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 2.3,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Hörby",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0662-V-008",
    Projektnamn: "Palsbo",
    Verksamhetsutövare: "RWE Renewables Sweden AB",
    Organisationsnummer: "556938-6864",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Gislaved",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 22, 2021",
  },
  {
    Områdes_ID: "0760-V-041",
    Projektnamn: "Berg",
    Verksamhetsutövare: "SR Energy AB",
    Organisationsnummer: "556711-9549",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Uppvidinge",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "Dec 13, 2021",
  },
  {
    Områdes_ID: "0760-V-042",
    Projektnamn: "Brännan",
    Verksamhetsutövare: "SR Energy AB",
    Organisationsnummer: "556711-9549",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Uppvidinge",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "Oct 30, 2019",
  },
  {
    Områdes_ID: "1814-V-001",
    Projektnamn: "Kronoberget",
    Verksamhetsutövare: "SR Energy AB",
    Organisationsnummer: "556711-9549",
    Aktuella_verk: 16,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 200,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Mar 01, 2018",
    Planerat_drifttagande: "Oct 01, 2019",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Lekeberg",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "Jan 18, 2022",
  },
  {
    Områdes_ID: "1782-V-001",
    Projektnamn: "Kungbergen",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 180,
    Uppmätt_årsproduktion_GWh: 180,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Filipstad",
    Län: "Värmlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1496-V-018",
    Projektnamn: "Billingen",
    Verksamhetsutövare: "Wästgöta Wind AB",
    Organisationsnummer: "556944-6478",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "Jan 01, 2022",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Skövde",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Nov 15, 2021",
  },
  {
    Områdes_ID: "1499-V-055",
    Projektnamn: "Stora Bjurum Ö",
    Verksamhetsutövare: "Wästgöta Wind AB",
    Organisationsnummer: "556944-6478",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "",
    Kommun: "Falköping",
    Län: "Västra Götalands län",
    Elområde: "",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1495-V-048",
    Projektnamn: "Högetomt Skara LO",
    Verksamhetsutövare: "Wästgöta Wind AB",
    Organisationsnummer: "556944-6478",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "",
    Kommun: "Skara",
    Län: "Västra Götalands län",
    Elområde: "",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1461-V-041",
    Projektnamn: "Teåker",
    Verksamhetsutövare: "Wästgöta Wind AB",
    Organisationsnummer: "556944-6478",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mellerud",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Nov 22, 2021",
  },
  {
    Områdes_ID: "1496-V-020",
    Projektnamn: "Skövde LO2",
    Verksamhetsutövare: "Wästgöta Wind AB",
    Organisationsnummer: "556944-6478",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Skövde",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Jan 11, 2021",
  },
  {
    Områdes_ID: "2303-V-025",
    Projektnamn: "Vindpark Björnsjöbodarna - Bodriset",
    Verksamhetsutövare: "SCA Energy AB",
    Organisationsnummer: "556129-8885",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ragunda",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Nov 24, 2021",
  },
  {
    Områdes_ID: "2305-V-026",
    Projektnamn: "Vindpark Björnsjöbodarna - Solberget Väst",
    Verksamhetsutövare: "SCA Energy AB",
    Organisationsnummer: "556129-8885",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Bräcke",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Oct 30, 2019",
  },
  {
    Områdes_ID: "1491-V-028",
    Projektnamn: "Stängsered",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ulricehamn",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Apr 22, 2021",
  },
  {
    Områdes_ID: "1491-V-029",
    Projektnamn: "Blidsberg",
    Verksamhetsutövare: "Västra Götalands län",
    Organisationsnummer: "202100-2361",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ulricehamn",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 26, 2019",
  },
  {
    Områdes_ID: "2282-V-017",
    Projektnamn: "Storhöjden",
    Verksamhetsutövare: "Kabeko Kraft AB",
    Organisationsnummer: "556763-7151",
    Aktuella_verk: 34,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Jan 01, 2022",
    Planerat_drifttagande: "Oct 01, 2024",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kramfors",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "Sep 29, 2021",
  },
  {
    Områdes_ID: "2282-V-018",
    Projektnamn: "Storhöjden",
    Verksamhetsutövare: "Kabeko Kraft AB",
    Organisationsnummer: "556763-7151",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 3,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kramfors",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "Feb 01, 2021",
  },
  {
    Områdes_ID: "2282-V-026",
    Projektnamn: "Vitberget V3",
    Verksamhetsutövare: "Kabeko Kraft AB",
    Organisationsnummer: "556763-7151",
    Aktuella_verk: 8,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Jan 01, 2022",
    Planerat_drifttagande: "Dec 31, 2024",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kramfors",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "Dec 02, 2021",
  },
  {
    Områdes_ID: "2282-V-028",
    Projektnamn: "Vitberget V1",
    Verksamhetsutövare: "Kabeko Kraft AB",
    Organisationsnummer: "556763-7151",
    Aktuella_verk: 23,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Jan 01, 2022",
    Planerat_drifttagande: "Dec 31, 2025",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kramfors",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "Oct 27, 2021",
  },
  {
    Områdes_ID: "2282-V-029",
    Projektnamn: "Vitberget V2",
    Verksamhetsutövare: "Kabeko Kraft AB",
    Organisationsnummer: "556763-7151",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Jan 01, 2022",
    Planerat_drifttagande: "Dec 31, 2025",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kramfors",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "Oct 27, 2021",
  },
  {
    Områdes_ID: "2085-V-004",
    Projektnamn: "Saxberget",
    Verksamhetsutövare: "SR Energy AB",
    Organisationsnummer: "556711-9549",
    Aktuella_verk: 17,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 99.96,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ludvika",
    Län: "Dalarnas län",
    Elområde: "Stockholm",
    Senast_sparad: "Jan 18, 2022",
  },
  {
    Områdes_ID: "2061-V-005",
    Projektnamn: "Älgkullen",
    Verksamhetsutövare: "SR Energy AB",
    Organisationsnummer: "556711-9549",
    Aktuella_verk: 15,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Apr 01, 2022",
    Planerat_drifttagande: "Apr 01, 2024",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Smedjebacken",
    Län: "Dalarnas län",
    Elområde: "Stockholm",
    Senast_sparad: "Dec 01, 2021",
  },
  {
    Områdes_ID: "1214-V-062",
    Projektnamn: "Tirup",
    Verksamhetsutövare: "Mimer vind AB",
    Organisationsnummer: "556608-8760",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Svalöv",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 12, 2019",
  },
  {
    Områdes_ID: "2080-V-012",
    Projektnamn: "Ånglarna",
    Verksamhetsutövare: "Ånglan Vind AB",
    Organisationsnummer: "559091-2043",
    Aktuella_verk: 20,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falun",
    Län: "Dalarnas län",
    Elområde: "Stockholm",
    Senast_sparad: "Dec 03, 2021",
  },
  {
    Områdes_ID: "2482-V-015",
    Projektnamn: "Aldermyrberget",
    Verksamhetsutövare: "WPD Onshore Aldermyrberget AB",
    Organisationsnummer: "556823-2853",
    Aktuella_verk: 17,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 268.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "Dec 01, 2020",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Skellefteå",
    Län: "Västerbottens län",
    Elområde: "Luleå",
    Senast_sparad: "Oct 15, 2020",
  },
  {
    Områdes_ID: "1465-V-004",
    Projektnamn: "Mjöbäck Vindkraftpark",
    Verksamhetsutövare: "Göteborg Energi AB",
    Organisationsnummer: "556362-6794",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Svenljunga",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1497-V-046",
    Projektnamn: "Hjo Fågelås Vindkraftpark",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hjo",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Jan 14, 2021",
  },
  {
    Områdes_ID: "1470-V-053",
    Projektnamn: "Vara Badene",
    Verksamhetsutövare: "Erikstorp Utveckling AB",
    Organisationsnummer: "556748-1667",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 20,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 08, 2021",
  },
  {
    Områdes_ID: "2305-V-027",
    Projektnamn: "Vindpark Björnsjöbodarna - Valpåsmyrberget",
    Verksamhetsutövare: "SCA Energy AB",
    Organisationsnummer: "556129-8885",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Bräcke",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Mar 18, 2019",
  },
  {
    Områdes_ID: "2305-V-028",
    Projektnamn: "Vindpark Björnsjöbodarna - Kilbodhöjden",
    Verksamhetsutövare: "SCA Energy AB",
    Organisationsnummer: "556129-8885",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Bräcke",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Nov 24, 2021",
  },
  {
    Områdes_ID: "1785-V-017",
    Projektnamn: "Knöstad",
    Verksamhetsutövare: "Eurowind Energy",
    Organisationsnummer: "556753-6049",
    Aktuella_verk: 12,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 135,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Oct 31, 2020",
    Planerat_drifttagande: "Dec 01, 2021",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Säffle",
    Län: "Värmlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 28, 2021",
  },
  {
    Områdes_ID: "1766-V-005",
    Projektnamn: "Norra Länsmansberget",
    Verksamhetsutövare: "Bixia Byggvind AB",
    Organisationsnummer: "556785-1208",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Sep 01, 2021",
    Planerat_drifttagande: "Oct 01, 2022",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sunne",
    Län: "Värmlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Nov 30, 2021",
  },
  {
    Områdes_ID: "1766-V-006",
    Projektnamn: "Backmossen",
    Verksamhetsutövare: "Bixia Byggvind AB",
    Organisationsnummer: "556785-1208",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Sunne",
    Län: "Värmlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0604-V-013",
    Projektnamn: "Norra vedbo (Jönköping/Aneby)",
    Verksamhetsutövare: "Vindin AB",
    Organisationsnummer: "556713-5172",
    Aktuella_verk: 29,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "Jan 01, 2022",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Aneby",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 24, 2021",
  },
  {
    Områdes_ID: "0685-V-017",
    Projektnamn: "Lockarp",
    Verksamhetsutövare: "Svenska Vindbolaget AB",
    Organisationsnummer: "556759-9013",
    Aktuella_verk: 9,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vetlanda",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 24, 2021",
  },
  {
    Områdes_ID: "0980-V-073",
    Projektnamn: "Forsviden Södra Vindkraftpark",
    Verksamhetsutövare: "Forsviden Vind AB",
    Organisationsnummer: "556848-3498",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "2305-V-029",
    Projektnamn: "Fasikan",
    Verksamhetsutövare: "Kraftö AB",
    Organisationsnummer: "556708-7456",
    Aktuella_verk: 15,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 180,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Bräcke",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Mar 24, 2021",
  },
  {
    Områdes_ID: "1443-V-001",
    Projektnamn: "Töllsjö-Slätthult HEDARED",
    Verksamhetsutövare: "Bollebygd-Töllsjö Vindkrafts AB",
    Organisationsnummer: "556752-3989",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 32,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Bollebygd",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2505-V-003",
    Projektnamn: "Brattberget",
    Verksamhetsutövare: "Kraftö AB",
    Organisationsnummer: "556708-7456",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Arvidsjaur",
    Län: "Norrbottens län",
    Elområde: "Luleå",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0760-V-040",
    Projektnamn: "Ekholma",
    Verksamhetsutövare: "Billyvind AB",
    Organisationsnummer: "556705-6931",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Uppvidinge",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1435-V-011",
    Projektnamn: "Skaveröd/Gurseröd",
    Verksamhetsutövare: "Skaveröd Gurseröd Vind AB",
    Organisationsnummer: "556809-3453",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 19.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tanum",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1435-V-052",
    Projektnamn: "Skaveröd/Gurseröd",
    Verksamhetsutövare: "Skaveröd Gurseröd Vind AB",
    Organisationsnummer: "556809-3453",
    Aktuella_verk: 11,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 93.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tanum",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Jun 11, 2020",
  },
  {
    Områdes_ID: "2380-V-002",
    Projektnamn: "Munkflohögen",
    Verksamhetsutövare: "Munkflohögen Vindkraft AB",
    Organisationsnummer: "556783-1986",
    Aktuella_verk: 23,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 189.98,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Östersund",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Oct 07, 2021",
  },
  {
    Områdes_ID: "2313-V-012",
    Projektnamn: "Gåxsjö-Raftsjöhöjden",
    Verksamhetsutövare: "Munkflohögen Vindkraft AB",
    Organisationsnummer: "556783-1986",
    Aktuella_verk: 7,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 126,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Sep 02, 2020",
    Planerat_drifttagande: "Dec 01, 2021",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Strömsund",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Dec 23, 2021",
  },
  {
    Områdes_ID: "2280-V-023",
    Projektnamn: "Vindpark Lillsela",
    Verksamhetsutövare: "SCA Energy AB",
    Organisationsnummer: "556129-8885",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Härnösand",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "Jun 09, 2021",
  },
  {
    Områdes_ID: "2313-V-013",
    Projektnamn: "Gåxsjö-Raftsjöhöjden 2",
    Verksamhetsutövare: "Munkflohögen Vindkraft AB",
    Organisationsnummer: "556783-1986",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Strömsund",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Oct 12, 2021",
  },
  {
    Områdes_ID: "1493-V-011",
    Projektnamn: "Västra Kinneskogen",
    Verksamhetsutövare: "Vasa Vind AB",
    Organisationsnummer: "556702-6835",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mariestad",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 22, 2021",
  },
  {
    Områdes_ID: "2313-V-017",
    Projektnamn: "Raftsjöhöjden",
    Verksamhetsutövare: "Munkflohögen Vindkraft AB",
    Organisationsnummer: "556783-1986",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 18,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Sep 02, 2020",
    Planerat_drifttagande: "Dec 01, 2021",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Strömsund",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Dec 10, 2021",
  },
  {
    Områdes_ID: "2313-V-022",
    Projektnamn: "Åskälen",
    Verksamhetsutövare: "Åskälen Vindkraft AB",
    Organisationsnummer: "556765-6128",
    Aktuella_verk: 61,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 305,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Oct 01, 2018",
    Planerat_drifttagande: "Mar 01, 2020",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Strömsund",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Oct 07, 2021",
  },
  {
    Områdes_ID: "2380-V-007",
    Projektnamn: "Österåsen",
    Verksamhetsutövare: "Åskälen Vindkraft AB",
    Organisationsnummer: "556765-6128",
    Aktuella_verk: 19,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 95,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "Mar 01, 2020",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Östersund",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Nov 27, 2019",
  },
  {
    Områdes_ID: "2513-V-001",
    Projektnamn: "Hällberget",
    Verksamhetsutövare: "Vasa Vind AB",
    Organisationsnummer: "556702-6835",
    Aktuella_verk: 150,
    Antal_ej_koordinatsatta_verk: 149,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Överkalix",
    Län: "Norrbottens län",
    Elområde: "Luleå",
    Senast_sparad: "Aug 06, 2021",
  },
  {
    Områdes_ID: "0780-V-014",
    Projektnamn: "Åshult",
    Verksamhetsutövare: "BayWa r.e. Scandinavia AB",
    Organisationsnummer: "556885-6503",
    Aktuella_verk: 9,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Växjö",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "Oct 15, 2021",
  },
  {
    Områdes_ID: "0780-V-002",
    Projektnamn: "Furuby",
    Verksamhetsutövare: "BayWa r.e. Scandinavia AB",
    Organisationsnummer: "556885-6503",
    Aktuella_verk: 11,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 210,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "May 31, 2021",
    Planerat_drifttagande: "Dec 01, 2022",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Växjö",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 24, 2021",
  },
  {
    Områdes_ID: "1452-V-010",
    Projektnamn: "Grimsås",
    Verksamhetsutövare: "Grimsås Vindkraft AB",
    Organisationsnummer: "556783-7496",
    Aktuella_verk: 13,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 117,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tranemo",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 08, 2021",
  },
  {
    Områdes_ID: "1465-V-001",
    Projektnamn: "Vindkraftpark Östra Frölunda",
    Verksamhetsutövare: "Gothia Vind 10 AB",
    Organisationsnummer: "556804-2443",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Svenljunga",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Jun 25, 2019",
  },
  {
    Områdes_ID: "1499-V-048",
    Projektnamn: "Rosenskog",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 54.9,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "Nov 01, 2023",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 30, 2021",
  },
  {
    Områdes_ID: "1493-V-012",
    Projektnamn: "Lyrestad",
    Verksamhetsutövare: "Lyrestad Vind AB",
    Organisationsnummer: "556872-2846",
    Aktuella_verk: 14,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 148.82,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mariestad",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Jan 23, 2020",
  },
  {
    Områdes_ID: "0885-V-001",
    Projektnamn: "Kårehamn",
    Verksamhetsutövare: "RWE Renewables Sweden AB",
    Organisationsnummer: "556938-6864",
    Aktuella_verk: 16,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 180,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Jun 01, 2012",
    Planerat_drifttagande: "Sep 01, 2013",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Borgholm",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 22, 2021",
  },
  {
    Områdes_ID: "0881-V-002",
    Projektnamn: "Stengårdsholma",
    Verksamhetsutövare: "RWE Renewables Sweden AB",
    Organisationsnummer: "556938-6864",
    Aktuella_verk: 10,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 103,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Mar 01, 2011",
    Planerat_drifttagande: "Sep 01, 2011",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Nybro",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 22, 2021",
  },
  {
    Områdes_ID: "1737-V-005",
    Projektnamn: "Granberget",
    Verksamhetsutövare: "Gothia Vind AB",
    Organisationsnummer: "556714-2095",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Torsby",
    Län: "Värmlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0883-V-004",
    Projektnamn: "Lervik",
    Verksamhetsutövare: "Eurowind Energy",
    Organisationsnummer: "556753-6049",
    Aktuella_verk: 9,
    Antal_ej_koordinatsatta_verk: 1,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Västervik",
    Län: "Kalmar län",
    Elområde: "Stockholm",
    Senast_sparad: "Dec 01, 2021",
  },
  {
    Områdes_ID: "2283-V-037",
    Projektnamn: "Fängsjön",
    Verksamhetsutövare: "FORSCA AB",
    Organisationsnummer: "556839-1915",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Sollefteå",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "Dec 13, 2021",
  },
  {
    Områdes_ID: "2031-V-004",
    Projektnamn: "Lannaberget",
    Verksamhetsutövare: "WPD Onshore Lannaberget AB",
    Organisationsnummer: "556853-1007",
    Aktuella_verk: 35,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 455,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Rättvik",
    Län: "Dalarnas län",
    Elområde: "Sundsvall",
    Senast_sparad: "Dec 07, 2021",
  },
  {
    Områdes_ID: "2031-V-005",
    Projektnamn: "Broboberget",
    Verksamhetsutövare: "WPD Onshore Broboberget AB",
    Organisationsnummer: "556853-1015",
    Aktuella_verk: 80,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1040,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Rättvik",
    Län: "Dalarnas län",
    Elområde: "Sundsvall",
    Senast_sparad: "Dec 07, 2021",
  },
  {
    Områdes_ID: "2283-V-038",
    Projektnamn: "Vaberget",
    Verksamhetsutövare: "WPD Onshore Vaberget AB",
    Organisationsnummer: "556843-5191",
    Aktuella_verk: 22,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 220,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sollefteå",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "Sep 30, 2021",
  },
  {
    Områdes_ID: "2481-V-011",
    Projektnamn: "Norrliden Björksele",
    Verksamhetsutövare: "Vindvision Norr AB",
    Organisationsnummer: "556719-9699",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Apr 01, 2020",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Lycksele",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "Dec 02, 2021",
  },
  {
    Områdes_ID: "0583-V-016",
    Projektnamn: "Hultema",
    Verksamhetsutövare: "VKS Vindkraft Sverige AB",
    Organisationsnummer: "556807-1889",
    Aktuella_verk: 23,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Oct 01, 2021",
    Planerat_drifttagande: "Mar 01, 2023",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Motala",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 24, 2021",
  },
  {
    Områdes_ID: "1283-V-090",
    Projektnamn: "Rosendal/Lydinge/Benarp",
    Verksamhetsutövare: "Öresundskraft Kraft & Värme AB",
    Organisationsnummer: "556501-1003",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Helsingborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Dec 13, 2021",
  },
  {
    Områdes_ID: "2481-V-004",
    Projektnamn: "Vargträsk 1",
    Verksamhetsutövare: "Vattenfall Vindkraft AB",
    Organisationsnummer: "556581-4273",
    Aktuella_verk: 21,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 214.83,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Lycksele",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "Dec 13, 2021",
  },
  {
    Områdes_ID: "2463-V-012",
    Projektnamn: "Fäbodberget Vindkraftpark",
    Verksamhetsutövare: "Vattenfall Vindkraft AB",
    Organisationsnummer: "556581-4273",
    Aktuella_verk: 34,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 442,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Mar 01, 2020",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Åsele",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "Jan 18, 2022",
  },
  {
    Områdes_ID: "1766-V-001",
    Projektnamn: "Häjsberget och södra Länsmansberget",
    Verksamhetsutövare: "Tekniska verken i Linköping Vind",
    Organisationsnummer: "556853-7038",
    Aktuella_verk: 18,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 179.4,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "Jun 19, 2020",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sunne",
    Län: "Värmlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Nov 16, 2020",
  },
  {
    Områdes_ID: "1083-V-016",
    Projektnamn: "Mjällby Ellen",
    Verksamhetsutövare: "Sverige Vindkraftkooperativ Ek för",
    Organisationsnummer: "769603-7089",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5.9,
    Uppmätt_årsproduktion_GWh: 5.91,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sölvesborg",
    Län: "Blekinge län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0881-V-005",
    Projektnamn: "Gränö",
    Verksamhetsutövare: "Kumbro Vind AB",
    Organisationsnummer: "556914-8249",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Nybro",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Dec 13, 2021",
  },
  {
    Områdes_ID: "1737-V-006",
    Projektnamn: "Skrallarberget",
    Verksamhetsutövare: "Gothia Vind AB",
    Organisationsnummer: "556714-2095",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Torsby",
    Län: "Värmlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Jun 18, 2019",
  },
  {
    Områdes_ID: "1383-V-021",
    Projektnamn: "Tvååker",
    Verksamhetsutövare: "WindEn sweden AB",
    Organisationsnummer: "556766-8040",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.1,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Varberg",
    Län: "Hallands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2280-V-021",
    Projektnamn: "Björnlandhöjden",
    Verksamhetsutövare: "Nysäter Wind AB",
    Organisationsnummer: "559079-0209",
    Aktuella_verk: 50,
    Antal_ej_koordinatsatta_verk: 9,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "Nov 01, 2021",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Härnösand",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "Jun 09, 2021",
  },
  {
    Områdes_ID: "0581-V-016",
    Projektnamn: "Vindpark Marviken",
    Verksamhetsutövare: "Wind4shore AB",
    Organisationsnummer: "556929-5776",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Norrköping",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Dec 13, 2021",
  },
  {
    Områdes_ID: "0581-V-017",
    Projektnamn: "Vikboland Vind",
    Verksamhetsutövare: "Wind4shore AB",
    Organisationsnummer: "556929-5776",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Norrköping",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 09, 2021",
  },
  {
    Områdes_ID: "2361-V-026",
    Projektnamn: "Glötesvålen",
    Verksamhetsutövare: "Glötesvålen Vind AB",
    Organisationsnummer: "556773-3869",
    Aktuella_verk: 30,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 219.9,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Härjedalen",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Oct 07, 2021",
  },
  {
    Områdes_ID: "2518-V-001",
    Projektnamn: "Aapua Vindpark",
    Verksamhetsutövare: "Siral System & Co AB",
    Organisationsnummer: "556171-8296",
    Aktuella_verk: 7,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 31.22,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Övertorneå",
    Län: "Norrbottens län",
    Elområde: "Luleå",
    Senast_sparad: "Sep 14, 2020",
  },
  {
    Områdes_ID: "2409-V-006",
    Projektnamn: "Lantvallen",
    Verksamhetsutövare: "European Energy",
    Organisationsnummer: "556762-0041",
    Aktuella_verk: 7,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Robertsfors",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "Dec 08, 2021",
  },
  {
    Områdes_ID: "1861-V-009",
    Projektnamn: "Odensvi",
    Verksamhetsutövare: "Mpiro Windpower AB",
    Organisationsnummer: "556709-5095",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hallsberg",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "Aug 26, 2019",
  },
  {
    Områdes_ID: "0604-V-003",
    Projektnamn: "Norra Sunhult",
    Verksamhetsutövare: "Luveryd Vindkraft AB",
    Organisationsnummer: "556756-5766",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Aneby",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2421-V-012",
    Projektnamn: "Gråtanliden",
    Verksamhetsutövare: "GHG Wind AB",
    Organisationsnummer: "556841-0186",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 200,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Storuman",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "1482-V-009",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kungälv",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Mar 07, 2019",
  },
  {
    Områdes_ID: "0484-V-014",
    Projektnamn: "Duvhällen Vindpark",
    Verksamhetsutövare: "Duvhällen Vindpark AB",
    Organisationsnummer: "559045-9367",
    Aktuella_verk: 10,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Mar 01, 2022",
    Planerat_drifttagande: "Dec 01, 2023",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Eskilstuna",
    Län: "Södermanlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Mar 11, 2021",
  },
  {
    Områdes_ID: "1765-V-008",
    Projektnamn: "Hån Vindpark",
    Verksamhetsutövare: "Hån Vindpark AB",
    Organisationsnummer: "556957-5797",
    Aktuella_verk: 7,
    Antal_ej_koordinatsatta_verk: 6,
    Beräknad_årsproduktion_GWh: 95,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Sep 01, 2021",
    Planerat_drifttagande: "Dec 31, 2022",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Årjäng",
    Län: "Värmlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 19, 2021",
  },
  {
    Områdes_ID: "1264-V-026",
    Projektnamn: "Sydkustens Vind",
    Verksamhetsutövare: "Kustvind AB",
    Organisationsnummer: "556832-4924",
    Aktuella_verk: 33,
    Antal_ej_koordinatsatta_verk: 32,
    Beräknad_årsproduktion_GWh: 1999.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Skurup",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "May 06, 2021",
  },
  {
    Områdes_ID: "0360-V-001",
    Projektnamn: "Larsbo-Valparbo",
    Verksamhetsutövare: "Greenextreme AB",
    Organisationsnummer: "556722-4596",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tierp",
    Län: "Uppsala län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 25, 2019",
  },
  {
    Områdes_ID: "1382-V-008",
    Projektnamn: "Högabjär",
    Verksamhetsutövare: "Vattenfall Vindkraft AB",
    Organisationsnummer: "556581-4273",
    Aktuella_verk: 9,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 73.44,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falkenberg",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1382-V-009",
    Projektnamn: "Kärsås",
    Verksamhetsutövare: "Vattenfall Vindkraft AB",
    Organisationsnummer: "556581-4273",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 24.48,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falkenberg",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "Jan 09, 2019",
  },
  {
    Områdes_ID: "1286-V-012",
    Projektnamn: "Hedeskoga Vindkraftpark",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 11.2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 16, 2020",
  },
  {
    Områdes_ID: "0665-V-016",
    Projektnamn: "Vaggeryds Vindbrukspark",
    Verksamhetsutövare: "BayWa r.e. Scandinavia AB",
    Organisationsnummer: "556885-6503",
    Aktuella_verk: 27,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vaggeryd",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 24, 2021",
  },
  {
    Områdes_ID: "0488-V-002",
    Projektnamn: "Ävlingeby gård",
    Verksamhetsutövare: "WindEn sweden AB",
    Organisationsnummer: "556766-8040",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.06,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Trosa",
    Län: "Södermanlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Jan 07, 2019",
  },
  {
    Områdes_ID: "1383-V-022",
    Projektnamn: "Västergården",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Varberg",
    Län: "Hallands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1435-V-068",
    Projektnamn: "Naverstads-Tyft Edsäm",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 9.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tanum",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Aug 29, 2019",
  },
  {
    Områdes_ID: "2101-V-007",
    Projektnamn: "Bergvind Lingbo",
    Verksamhetsutövare: "Åmot-Lingbo Vindkraft AB",
    Organisationsnummer: "559070-7898",
    Aktuella_verk: 58,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 748.2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Jan 01, 2018",
    Planerat_drifttagande: "Jun 01, 2020",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ockelbo",
    Län: "Gävleborgs län",
    Elområde: "Sundsvall",
    Senast_sparad: "Oct 07, 2021",
  },
  {
    Områdes_ID: "2283-V-001",
    Projektnamn: "Björkhöjden",
    Verksamhetsutövare: "Statkraft SCA Vind AB",
    Organisationsnummer: "556706-3507",
    Aktuella_verk: 90,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 776.7,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sollefteå",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2303-V-016",
    Projektnamn: "Bodhögarna",
    Verksamhetsutövare: "Statkraft SCA Vind AB",
    Organisationsnummer: "556706-3507",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ragunda",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Dec 02, 2021",
  },
  {
    Områdes_ID: "2260-V-011",
    Projektnamn: "Bäråsen",
    Verksamhetsutövare: "RES Renewable Norden AB",
    Organisationsnummer: "556616-0684",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ånge",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0881-V-003",
    Projektnamn: "Ebbegärde",
    Verksamhetsutövare: "Arise Windpower AB",
    Organisationsnummer: "556274-6726",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Nybro",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2280-V-015",
    Projektnamn: "Hästkullen",
    Verksamhetsutövare: "Nysäter Wind AB",
    Organisationsnummer: "559079-0209",
    Aktuella_verk: 73,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1051.2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "Oct 01, 2021",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Härnösand",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "Nov 23, 2021",
  },
  {
    Områdes_ID: "2409-V-003",
    Projektnamn: "Sävar",
    Verksamhetsutövare: "Sävar Vindkraft AB",
    Organisationsnummer: "559102-0101",
    Aktuella_verk: 40,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Robertsfors",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "Dec 02, 2021",
  },
  {
    Områdes_ID: "1860-V-005",
    Projektnamn: "Laxåskogen",
    Verksamhetsutövare: "VKS Vindkraft Sverige AB",
    Organisationsnummer: "556807-1889",
    Aktuella_verk: 8,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 74.9,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Laxå",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 20, 2021",
  },
  {
    Områdes_ID: "2262-V-001",
    Projektnamn: "Stor-Skälsjön",
    Verksamhetsutövare: "Enercon GmbH",
    Organisationsnummer: "516407-1630",
    Aktuella_verk: 65,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Jan 01, 2020",
    Planerat_drifttagande: "Jun 01, 2022",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Timrå",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "Oct 19, 2021",
  },
  {
    Områdes_ID: "2518-V-008",
    Projektnamn: "Maevaara vindkraftpark",
    Verksamhetsutövare: "Maevaara Vind AB",
    Organisationsnummer: "556909-4732",
    Aktuella_verk: 34,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 234.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Övertorneå",
    Län: "Norrbottens län",
    Elområde: "Luleå",
    Senast_sparad: "Oct 05, 2021",
  },
  {
    Områdes_ID: "1962-V-001",
    Projektnamn: "Målarberget",
    Verksamhetsutövare: "Vindpark Målarberget i Norberg AB",
    Organisationsnummer: "556853-2690",
    Aktuella_verk: 30,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 299.7,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Jun 01, 2019",
    Planerat_drifttagande: "May 01, 2021",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Norberg",
    Län: "Västmanlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 07, 2021",
  },
  {
    Områdes_ID: "0765-V-001",
    Projektnamn: "Hyltåkra med omnejd i Älmhult",
    Verksamhetsutövare: "Billyvind AB",
    Organisationsnummer: "556705-6931",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Älmhult",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "Oct 02, 2019",
  },
  {
    Områdes_ID: "2462-V-007",
    Projektnamn: "Pauträsk vindpark",
    Verksamhetsutövare: "Vattenfall Vindkraft AB",
    Organisationsnummer: "556581-4273",
    Aktuella_verk: 117,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Storuman",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "Jan 20, 2021",
  },
  {
    Områdes_ID: "1489-V-007",
    Projektnamn: "Rödene",
    Verksamhetsutövare: "Rödene Vindkraft AB",
    Organisationsnummer: "556847-4976",
    Aktuella_verk: 16,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Aug 10, 2020",
    Planerat_drifttagande: "Jul 01, 2022",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Alingsås",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Nov 11, 2021",
  },
  {
    Områdes_ID: "1430-V-027",
    Projektnamn: "Skottfjället",
    Verksamhetsutövare: "Arise Windpower AB",
    Organisationsnummer: "556274-6726",
    Aktuella_verk: 13,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 64.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Munkedal",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 15, 2021",
  },
  {
    Områdes_ID: "2283-V-031",
    Projektnamn: "Stamåsen",
    Verksamhetsutövare: "Statkraft SCA Vind AB",
    Organisationsnummer: "556706-3507",
    Aktuella_verk: 50,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 192.4,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sollefteå",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "Jan 20, 2021",
  },
  {
    Områdes_ID: "2281-V-010",
    Projektnamn: "Stockåsbodarna",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 8,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 64,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sundsvall",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "Dec 09, 2021",
  },
  {
    Områdes_ID: "2421-V-004",
    Projektnamn: "Blaiken",
    Verksamhetsutövare: "BlaikenVind Aktiebolag",
    Organisationsnummer: "556043-1354",
    Aktuella_verk: 99,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 699.93,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Jun 01, 2012",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Storuman",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "Oct 05, 2021",
  },
  {
    Områdes_ID: "2283-V-006",
    Projektnamn: "Storbrännkullen",
    Verksamhetsutövare: "Väktaren Vind AB",
    Organisationsnummer: "556628-7578",
    Aktuella_verk: 12,
    Antal_ej_koordinatsatta_verk: 12,
    Beräknad_årsproduktion_GWh: 36,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "Jun 01, 2022",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ragunda",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Nov 24, 2021",
  },
  {
    Områdes_ID: "2104-V-003",
    Projektnamn: "Tjärnäs",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 6,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Hofors",
    Län: "Gävleborgs län",
    Elområde: "Stockholm",
    Senast_sparad: "Dec 01, 2021",
  },
  {
    Områdes_ID: "1315-V-016",
    Projektnamn: "Treriksröset",
    Verksamhetsutövare: "Arise Windpower AB",
    Organisationsnummer: "556274-6726",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ljungby",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 04, 2020",
  },
  {
    Områdes_ID: "2510-V-002",
    Projektnamn: "VindIn vattenregleringsmagasin",
    Verksamhetsutövare: "Vindin AB",
    Organisationsnummer: "556713-5172",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Jokkmokk",
    Län: "Norrbottens län",
    Elområde: "Luleå",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0488-V-004",
    Projektnamn: "Långgrund 1",
    Verksamhetsutövare: "Svea Vind Offshore AB",
    Organisationsnummer: "559025-6136",
    Aktuella_verk: 93,
    Antal_ej_koordinatsatta_verk: 92,
    Beräknad_årsproduktion_GWh: 7099.62,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "May 30, 2026",
    Planerat_drifttagande: "Aug 30, 2028",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Trosa",
    Län: "Södermanlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 29, 2021",
  },
  {
    Områdes_ID: "0192-V-001",
    Projektnamn: "Söder Landsort",
    Verksamhetsutövare: "Svea Vind Offshore AB",
    Organisationsnummer: "559025-6136",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Nynäshamn",
    Län: "Stockholms län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 12, 2020",
  },
  {
    Områdes_ID: "0120-V-002",
    Projektnamn: "Almagrundet",
    Verksamhetsutövare: "Svea Vind Offshore AB",
    Organisationsnummer: "559025-6136",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Värmdö",
    Län: "Stockholms län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 12, 2020",
  },
  {
    Områdes_ID: "0360-V-002",
    Projektnamn: "Campsgrund",
    Verksamhetsutövare: "Svea Vind Offshore AB",
    Organisationsnummer: "559025-6136",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Tierp",
    Län: "Uppsala län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 12, 2021",
  },
  {
    Områdes_ID: "2180-V-006",
    Projektnamn: "Utposten",
    Verksamhetsutövare: "Svea Vind Offshore AB",
    Organisationsnummer: "559025-6136",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Gävle",
    Län: "Gävleborgs län",
    Elområde: "Sundsvall",
    Senast_sparad: "Feb 05, 2021",
  },
  {
    Områdes_ID: "2183-V-001",
    Projektnamn: "Vindpark Tönsen",
    Verksamhetsutövare: "Åmot-Lingbo Vindkraft AB",
    Organisationsnummer: "559070-7898",
    Aktuella_verk: 27,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 348.3,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Jan 01, 2018",
    Planerat_drifttagande: "Mar 01, 2020",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Bollnäs",
    Län: "Gävleborgs län",
    Elområde: "Sundsvall",
    Senast_sparad: "Oct 07, 2021",
  },
  {
    Områdes_ID: "2303-V-013",
    Projektnamn: "Ögonfägnaden",
    Verksamhetsutövare: "Statkraft SCA Vind AB",
    Organisationsnummer: "556706-3507",
    Aktuella_verk: 33,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 309.87,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ragunda",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "May 10, 2019",
  },
  {
    Områdes_ID: "1380-V-009",
    Projektnamn: "Örken",
    Verksamhetsutövare: "RWE Renewables Sweden AB",
    Organisationsnummer: "556938-6864",
    Aktuella_verk: 6,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 49.98,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Halmstad",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 22, 2021",
  },
  {
    Områdes_ID: "1315-V-010",
    Projektnamn: "Örken Nord",
    Verksamhetsutövare: "SR Energy AB",
    Organisationsnummer: "556711-9549",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hylte",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "Jan 18, 2022",
  },
  {
    Områdes_ID: "2023-V-008",
    Projektnamn: "Byråsen",
    Verksamhetsutövare: "Dala Vind AB",
    Organisationsnummer: "556696-6940",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Malung-Sälen",
    Län: "Dalarnas län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2062-V-006",
    Projektnamn: "Bösjövarden",
    Verksamhetsutövare: "OX2 AB",
    Organisationsnummer: "556675-7497",
    Aktuella_verk: 9,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 74.97,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mora",
    Län: "Dalarnas län",
    Elområde: "Stockholm",
    Senast_sparad: "Mar 09, 2020",
  },
  {
    Områdes_ID: "1495-V-007",
    Projektnamn: "Basebo",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 20,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Skara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 27, 2019",
  },
  {
    Områdes_ID: "2023-V-003",
    Projektnamn: "Rämsberget",
    Verksamhetsutövare: "Glötesvålen Vind AB",
    Organisationsnummer: "556773-3869",
    Aktuella_verk: 7,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 71.96,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Malung-Sälen",
    Län: "Dalarnas län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 07, 2021",
  },
  {
    Områdes_ID: "2023-V-004",
    Projektnamn: "Korpfjället",
    Verksamhetsutövare: "Glötesvålen Vind AB",
    Organisationsnummer: "556773-3869",
    Aktuella_verk: 9,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 67.95,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Malung-Sälen",
    Län: "Dalarnas län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 07, 2021",
  },
  {
    Områdes_ID: "2083-V-003",
    Projektnamn: "Riskebo",
    Verksamhetsutövare: "Dala Vind AB",
    Organisationsnummer: "556696-6940",
    Aktuella_verk: 7,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 130.97,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Mar 01, 2020",
    Planerat_drifttagande: "Jun 01, 2022",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Hedemora",
    Län: "Dalarnas län",
    Elområde: "Stockholm",
    Senast_sparad: "Jun 17, 2021",
  },
  {
    Områdes_ID: "2181-V-001",
    Projektnamn: "Vettåsen/Finnberget",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 8,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 50,
    Uppmätt_årsproduktion_GWh: 32.85,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sandviken",
    Län: "Gävleborgs län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2283-V-039",
    Projektnamn: "Knäsjöberget",
    Verksamhetsutövare: "Kabeko Kraft AB",
    Organisationsnummer: "556763-7151",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Sollefteå",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "Feb 01, 2021",
  },
  {
    Områdes_ID: "2283-V-040",
    Projektnamn: "Knäsjöberget",
    Verksamhetsutövare: "Kabeko Kraft AB",
    Organisationsnummer: "556763-7151",
    Aktuella_verk: 14,
    Antal_ej_koordinatsatta_verk: 11,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Jan 01, 2022",
    Planerat_drifttagande: "Dec 31, 2024",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sollefteå",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "Oct 27, 2021",
  },
  {
    Områdes_ID: "1862-V-004",
    Projektnamn: "Krontorp",
    Verksamhetsutövare: "Bäckhammar Vind AB",
    Organisationsnummer: "556956-6176",
    Aktuella_verk: 9,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 127.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Degerfors",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 17, 2020",
  },
  {
    Områdes_ID: "2303-V-019",
    Projektnamn: "Björkvattnet",
    Verksamhetsutövare: "Treblade Björkvattnet AB",
    Organisationsnummer: "556912-5429",
    Aktuella_verk: 33,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 574.2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Jul 01, 2019",
    Planerat_drifttagande: "Oct 01, 2021",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ragunda",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Oct 11, 2021",
  },
  {
    Områdes_ID: "2080-V-005",
    Projektnamn: "Svartnäs",
    Verksamhetsutövare: "Svartnäs Vindkraft AB",
    Organisationsnummer: "556775-9039",
    Aktuella_verk: 32,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 382.4,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Jul 01, 2017",
    Planerat_drifttagande: "Jan 28, 2019",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falun",
    Län: "Dalarnas län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 07, 2021",
  },
  {
    Områdes_ID: "0512-V-006",
    Projektnamn: "Hedningsmåla",
    Verksamhetsutövare: "Tranås Energi Elförsörjning AB",
    Organisationsnummer: "556197-0178",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 31.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ydre",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2101-V-008",
    Projektnamn: "Vindpark Jädraås",
    Verksamhetsutövare: "Jädraås Vindkraft AB",
    Organisationsnummer: "556733-6481",
    Aktuella_verk: 66,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 719.58,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ockelbo",
    Län: "Gävleborgs län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1882-V-001",
    Projektnamn: "Zinkgruvan",
    Verksamhetsutövare: "European Energy",
    Organisationsnummer: "556762-0041",
    Aktuella_verk: 14,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 177.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Askersund",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 13, 2019",
  },
  {
    Områdes_ID: "2284-V-008",
    Projektnamn: "Fanbyn",
    Verksamhetsutövare: "Norrvinden Första Förvaltning AB",
    Organisationsnummer: "556829-1123",
    Aktuella_verk: 8,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 12.24,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Örnsköldsvik",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "Jun 11, 2020",
  },
  {
    Områdes_ID: "2182-V-012",
    Projektnamn: "Ödmården",
    Verksamhetsutövare: "Bergvik Skog AB",
    Organisationsnummer: "556610-2959",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 98,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Söderhamn",
    Län: "Gävleborgs län",
    Elområde: "Sundsvall",
    Senast_sparad: "Dec 13, 2021",
  },
  {
    Områdes_ID: "0563-V-001",
    Projektnamn: "Söderköping/Valdemarsvik",
    Verksamhetsutövare: "Söderköping Vind AB",
    Organisationsnummer: "556859-7875",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Valdemarsvik",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Dec 13, 2021",
  },
  {
    Områdes_ID: "0481-V-004",
    Projektnamn: "Fällbådan",
    Verksamhetsutövare: "Cloudberry Offshore Wind AB",
    Organisationsnummer: "556710-7403",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Jan 01, 2022",
    Planerat_drifttagande: "Aug 01, 2023",
    Området_ej_aktuellt_i_sin_helhet: "",
    Kommun: "Oxelösund",
    Län: "Södermanlands län",
    Elområde: "",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0481-V-005",
    Projektnamn: "Norra Klasgrunden",
    Verksamhetsutövare: "Cloudberry Offshore Wind AB",
    Organisationsnummer: "556710-7403",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Oxelösund",
    Län: "Södermanlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 12, 2021",
  },
  {
    Områdes_ID: "0481-V-006",
    Projektnamn: "Södra Klasgrunden",
    Verksamhetsutövare: "Cloudberry Offshore Wind AB",
    Organisationsnummer: "556710-7403",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Oxelösund",
    Län: "Södermanlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 12, 2021",
  },
  {
    Områdes_ID: "0480-V-004",
    Projektnamn: "Penningskär",
    Verksamhetsutövare: "Cloudberry Offshore Wind AB",
    Organisationsnummer: "556710-7403",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Nyköping",
    Län: "Södermanlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Mar 26, 2019",
  },
  {
    Områdes_ID: "0382-V-004",
    Projektnamn: "Grepen",
    Verksamhetsutövare: "Cloudberry Offshore Wind AB",
    Organisationsnummer: "556710-7403",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Östhammar",
    Län: "Uppsala län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 12, 2021",
  },
  {
    Områdes_ID: "2480-V-022",
    Projektnamn: "Södra Kvarken",
    Verksamhetsutövare: "Cloudberry Offshore Wind AB",
    Organisationsnummer: "556710-7403",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Umeå",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "Oct 12, 2021",
  },
  {
    Områdes_ID: "2480-V-023",
    Projektnamn: "Väktaren",
    Verksamhetsutövare: "Cloudberry Offshore Wind AB",
    Organisationsnummer: "556710-7403",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Umeå",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "Oct 12, 2021",
  },
  {
    Områdes_ID: "2409-V-007",
    Projektnamn: "Rata Storgrund etapp 1",
    Verksamhetsutövare: "Cloudberry Offshore Wind AB",
    Organisationsnummer: "556710-7403",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Robertsfors",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "Sep 18, 2019",
  },
  {
    Områdes_ID: "2409-V-008",
    Projektnamn: "Rata Storgrund etapp 2",
    Verksamhetsutövare: "Cloudberry Offshore Wind AB",
    Organisationsnummer: "556710-7403",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Robertsfors",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "Sep 18, 2019",
  },
  {
    Områdes_ID: "2583-V-004",
    Projektnamn: "Haru",
    Verksamhetsutövare: "Cloudberry Offshore Wind AB",
    Organisationsnummer: "556710-7403",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Haparanda",
    Län: "Norrbottens län",
    Elområde: "Luleå",
    Senast_sparad: "Mar 26, 2019",
  },
  {
    Områdes_ID: "0861-V-060",
    Projektnamn: "Brotorp",
    Verksamhetsutövare: "Arise Windpower AB",
    Organisationsnummer: "556274-6726",
    Aktuella_verk: 14,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 140,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mönsterås",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2581-V-009",
    Projektnamn: "Markbygden vindkraftpark, etapp 2",
    Verksamhetsutövare: "Markbygden Vind AB",
    Organisationsnummer: "556710-9292",
    Aktuella_verk: 201,
    Antal_ej_koordinatsatta_verk: 48,
    Beräknad_årsproduktion_GWh: 1400.9,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Oct 01, 2020",
    Planerat_drifttagande: "Oct 01, 2021",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Piteå",
    Län: "Norrbottens län",
    Elområde: "Luleå",
    Senast_sparad: "Oct 27, 2021",
  },
  {
    Områdes_ID: "0760-V-043",
    Projektnamn: "Rosenholm Vindpark",
    Verksamhetsutövare: "SR Energy AB",
    Organisationsnummer: "556711-9549",
    Aktuella_verk: 16,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Uppvidinge",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "Jan 18, 2022",
  },
  {
    Områdes_ID: "0760-V-035",
    Projektnamn: "Karskruv",
    Verksamhetsutövare: "OX2 AB",
    Organisationsnummer: "556675-7497",
    Aktuella_verk: 26,
    Antal_ej_koordinatsatta_verk: 3,
    Beräknad_årsproduktion_GWh: 290,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "Jan 01, 2023",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Uppvidinge",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 21, 2021",
  },
  {
    Områdes_ID: "2180-V-007",
    Projektnamn: "Utknallen",
    Verksamhetsutövare: "Svea Vind Offshore AB",
    Organisationsnummer: "559025-6136",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Gävle",
    Län: "Gävleborgs län",
    Elområde: "Sundsvall",
    Senast_sparad: "Feb 05, 2021",
  },
  {
    Områdes_ID: "1491-V-019",
    Projektnamn: "Tåhult",
    Verksamhetsutövare: "OX2 AB",
    Organisationsnummer: "556675-7497",
    Aktuella_verk: 6,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ulricehamn",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0760-V-044",
    Projektnamn: "Marhult Vindpark",
    Verksamhetsutövare: "OX2 AB",
    Organisationsnummer: "556675-7497",
    Aktuella_verk: 11,
    Antal_ej_koordinatsatta_verk: 11,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Uppvidinge",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 14, 2021",
  },
  {
    Områdes_ID: "0860-V-007",
    Projektnamn: "Skräplinge",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.1,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Hultsfred",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0980-V-074",
    Projektnamn: "Hablinge Vindpark",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Jun 25, 2019",
  },
  {
    Områdes_ID: "0643-V-008",
    Projektnamn: "Hornamossen",
    Verksamhetsutövare: "Lake Wind AB",
    Organisationsnummer: "559169-5928",
    Aktuella_verk: 19,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 124,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Jun 01, 2019",
    Planerat_drifttagande: "Apr 01, 2021",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Habo",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "Nov 10, 2021",
  },
  {
    Områdes_ID: "0360-V-003",
    Projektnamn: "Stönnansbo",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tierp",
    Län: "Uppsala län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 27, 2019",
  },
  {
    Områdes_ID: "0305-V-002",
    Projektnamn: "Rölunda",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.25,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Håbo",
    Län: "Uppsala län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2361-V-023",
    Projektnamn: "Stentjärnåsen",
    Verksamhetsutövare: "Stentjärnåsen Vindkraft AB",
    Organisationsnummer: "556731-8968",
    Aktuella_verk: 5,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 28.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Härjedalen",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "May 13, 2020",
  },
  {
    Områdes_ID: "2260-V-015",
    Projektnamn: "Klevberget",
    Verksamhetsutövare: "OX2 AB",
    Organisationsnummer: "556675-7497",
    Aktuella_verk: 35,
    Antal_ej_koordinatsatta_verk: 11,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ånge",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "Nov 03, 2021",
  },
  {
    Områdes_ID: "0604-V-016",
    Projektnamn: "Bordsjö Vindbrukspark",
    Verksamhetsutövare: "BayWa r.e. Scandinavia AB",
    Organisationsnummer: "556885-6503",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Aneby",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "Jun 04, 2021",
  },
  {
    Områdes_ID: "2418-V-001",
    Projektnamn: "Hornberget",
    Verksamhetsutövare: "Jämtkraft AB",
    Organisationsnummer: "556001-6064",
    Aktuella_verk: 5,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 30,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Malå",
    Län: "Västerbottens län",
    Elområde: "Luleå",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0662-V-010",
    Projektnamn: "Klämman",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 23,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gislaved",
    Län: "Jönköpings län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2463-V-013",
    Projektnamn: "Fjällberg Vindkraftspark",
    Verksamhetsutövare: "Vindkraft i Fjällberg AB",
    Organisationsnummer: "556893-6164",
    Aktuella_verk: 48,
    Antal_ej_koordinatsatta_verk: 48,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Åsele",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "Dec 02, 2021",
  },
  {
    Områdes_ID: "0880-V-016",
    Projektnamn: "Rockneby",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kalmar",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0880-V-028",
    Projektnamn: "Rockneby",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 5,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 26,
    Uppmätt_årsproduktion_GWh: 22.77,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kalmar",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0880-V-030",
    Projektnamn: "Vassmolösa",
    Verksamhetsutövare: "Vassmolösa Vind AB",
    Organisationsnummer: "556887-5495",
    Aktuella_verk: 5,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 25.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kalmar",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 15, 2020",
  },
  {
    Områdes_ID: "2080-V-013",
    Projektnamn: "Trollberget 2",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 7.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falun",
    Län: "Dalarnas län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 03, 2020",
  },
  {
    Områdes_ID: "1465-V-013",
    Projektnamn: "Änglarp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Svenljunga",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0461-V-001",
    Projektnamn: "Ånhammar",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Gnesta",
    Län: "Södermanlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Dec 13, 2021",
  },
  {
    Områdes_ID: "1499-V-056",
    Projektnamn: "Västtorp",
    Verksamhetsutövare: "Västtorp Vind AB",
    Organisationsnummer: "556823-0618",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 21, 2019",
  },
  {
    Områdes_ID: "1499-V-057",
    Projektnamn: "Åsle",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falköping",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1488-V-007",
    Projektnamn: "Velanda gård",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Trollhättan",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2463-V-003",
    Projektnamn: "Stor-Rotliden Vindkraftpark",
    Verksamhetsutövare: "Vattenfall Vindkraft AB",
    Organisationsnummer: "556581-4273",
    Aktuella_verk: 40,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 240,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Åsele",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1276-V-021",
    Projektnamn: "Lyckås gård",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Klippan",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1471-V-007",
    Projektnamn: "Erikstorp I",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.1,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "Feb 16, 1989",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Götene",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0980-V-075",
    Projektnamn: "",
    Verksamhetsutövare: "Gotland",
    Organisationsnummer: "212000-0803",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Apr 30, 2019",
  },
  {
    Områdes_ID: "1487-V-034",
    Projektnamn: "Gårdsverk Asmundstorp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vänersborg",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0583-V-025",
    Projektnamn: "Gislorp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 4.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Motala",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0682-V-014",
    Projektnamn: "Tapplarp",
    Verksamhetsutövare: "Smålandsvind AB",
    Organisationsnummer: "556716-1731",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 11,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Nässjö",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0682-V-003",
    Projektnamn: "Hallhult",
    Verksamhetsutövare: "Medvind Vindkraft AB",
    Organisationsnummer: "556741-9600",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 7.9,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Nässjö",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "Dec 18, 2018",
  },
  {
    Områdes_ID: "0682-V-007",
    Projektnamn: "Hallhult/Fredriksdal",
    Verksamhetsutövare: "Smålandsvind AB",
    Organisationsnummer: "556716-1731",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Nässjö",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1290-V-117",
    Projektnamn: "Bösarp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kristianstad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1440-V-004",
    Projektnamn: "Grolanda",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ale",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1286-V-009",
    Projektnamn: "Ruuthsbo Vindkraftpark",
    Verksamhetsutövare: "Vattenfall Vindkraft AB",
    Organisationsnummer: "556581-4273",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1293-V-034",
    Projektnamn: "Brönnestad",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.25,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Hässleholm",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Jan 20, 2021",
  },
  {
    Områdes_ID: "1266-V-051",
    Projektnamn: "Brunnslöv",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Hörby",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2161-V-009",
    Projektnamn: "Sörbyparken",
    Verksamhetsutövare: "Järvsö Sörby Vindkraft AB",
    Organisationsnummer: "556781-2200",
    Aktuella_verk: 37,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 379.99,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ljusdal",
    Län: "Gävleborgs län",
    Elområde: "Sundsvall",
    Senast_sparad: "Oct 07, 2021",
  },
  {
    Områdes_ID: "1267-V-021",
    Projektnamn: "Äspinge",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Höör",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1780-V-005",
    Projektnamn: "Vindpark Vänern",
    Verksamhetsutövare: "Karlstads Energi AB",
    Organisationsnummer: "556071-6085",
    Aktuella_verk: 10,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 66.4,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Karlstad",
    Län: "Värmlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Mar 10, 2020",
  },
  {
    Områdes_ID: "1880-V-027",
    Projektnamn: "Mosås",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 11,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Örebro",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "Jan 15, 2019",
  },
  {
    Områdes_ID: "1880-V-028",
    Projektnamn: "Seltorp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Örebro",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0188-V-010",
    Projektnamn: "Erken",
    Verksamhetsutövare: "Erken Drift AB",
    Organisationsnummer: "556868-5126",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 12.8,
    Uppmätt_årsproduktion_GWh: 10.25,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Norrtälje",
    Län: "Stockholms län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1081-V-004",
    Projektnamn: "Väbynäs",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 7,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ronneby",
    Län: "Blekinge län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0685-V-027",
    Projektnamn: "Höreryd",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5.7,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vetlanda",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0685-V-025",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vetlanda",
    Län: "Jönköpings län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0685-V-021",
    Projektnamn: "Mossgård",
    Verksamhetsutövare: "Smålands Miljöenergi AB",
    Organisationsnummer: "556784-5168",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5.7,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vetlanda",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1060-V-007",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Olofström",
    Län: "Blekinge län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1083-V-017",
    Projektnamn: "Lörby-Ysane",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 15,
    Uppmätt_årsproduktion_GWh: 14.56,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sölvesborg",
    Län: "Blekinge län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 15, 2020",
  },
  {
    Områdes_ID: "1083-V-018",
    Projektnamn: "Sölve",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 9.9,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sölvesborg",
    Län: "Blekinge län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1083-V-019",
    Projektnamn: "Björkevik",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.13,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sölvesborg",
    Län: "Blekinge län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0117-V-004",
    Projektnamn: "Östanå",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.1,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Österåker",
    Län: "Stockholms län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1882-V-005",
    Projektnamn: "Mariedamm",
    Verksamhetsutövare: "Göteborg Energi AB",
    Organisationsnummer: "556362-6794",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 10,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "Jan 01, 2012",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Askersund",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2482-V-019",
    Projektnamn: "Råliden",
    Verksamhetsutövare: "WPD Onshore Råliden AB",
    Organisationsnummer: "556853-0991",
    Aktuella_verk: 54,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1099.98,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Skellefteå",
    Län: "Västerbottens län",
    Elområde: "Luleå",
    Senast_sparad: "Dec 02, 2021",
  },
  {
    Områdes_ID: "2482-V-020",
    Projektnamn: "Klöverberget",
    Verksamhetsutövare: "WPD Onshore Klöverberget AB",
    Organisationsnummer: "556853-1023",
    Aktuella_verk: 19,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 315.4,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Skellefteå",
    Län: "Västerbottens län",
    Elområde: "Luleå",
    Senast_sparad: "Dec 07, 2021",
  },
  {
    Områdes_ID: "1473-V-024",
    Projektnamn: "Smeby",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Töreboda",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1493-V-023",
    Projektnamn: "Börstorp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.18,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mariestad",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1280-V-009",
    Projektnamn: "Östra Hamnen",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Malmö",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 15, 2019",
  },
  {
    Områdes_ID: "0381-V-015",
    Projektnamn: "Långtora Vindkraft",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Enköping",
    Län: "Uppsala län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2418-V-012",
    Projektnamn: "Åmliden",
    Verksamhetsutövare: "Renvind AB",
    Organisationsnummer: "556759-3578",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 2.2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Malå",
    Län: "Västerbottens län",
    Elområde: "Luleå",
    Senast_sparad: "Oct 05, 2021",
  },
  {
    Områdes_ID: "2321-V-013",
    Projektnamn: "Kläppe",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Åre",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1283-V-091",
    Projektnamn: "Hyllinge",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Helsingborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1283-V-092",
    Projektnamn: "Ottarp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Helsingborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0680-V-001",
    Projektnamn: "",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Jönköping",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0980-V-071",
    Projektnamn: "Lärbro Liffride",
    Verksamhetsutövare: "Siral System & Co AB",
    Organisationsnummer: "556171-8296",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Nov 30, 2021",
  },
  {
    Områdes_ID: "2463-V-011",
    Projektnamn: "Blakliden Vindkraftpark",
    Verksamhetsutövare: "Vattenfall Vindkraft AB",
    Organisationsnummer: "556581-4273",
    Aktuella_verk: 50,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 650,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Nov 01, 2019",
    Planerat_drifttagande: "Mar 01, 2022",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Åsele",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "Jan 18, 2022",
  },
  {
    Områdes_ID: "2581-V-015",
    Projektnamn: "Skogberget",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 36,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 249.84,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Piteå",
    Län: "Norrbottens län",
    Elområde: "Luleå",
    Senast_sparad: "Apr 15, 2020",
  },
  {
    Områdes_ID: "1286-V-029",
    Projektnamn: "Örum",
    Verksamhetsutövare: "Stefan Widen AB",
    Organisationsnummer: "556651-9715",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ystad",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1291-V-069",
    Projektnamn: "Gärsnäs",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.01,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Simrishamn",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1270-V-049",
    Projektnamn: "Tjustorp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tomelilla",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 21, 2019",
  },
  {
    Områdes_ID: "1270-V-050",
    Projektnamn: "Ö Ingelstad",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.02,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tomelilla",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0586-V-077",
    Projektnamn: "STRÖMMESTAD",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 12,
    Uppmätt_årsproduktion_GWh: 10.76,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mjölby",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Apr 15, 2020",
  },
  {
    Områdes_ID: "0586-V-071",
    Projektnamn: "Bränninge",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.7,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mjölby",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0586-V-058",
    Projektnamn: "Salvetorp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mjölby",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0586-V-078",
    Projektnamn: "Hogstad",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 4.9,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mjölby",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0586-V-063",
    Projektnamn: "Appuna",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 11.2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mjölby",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0586-V-060",
    Projektnamn: "Vistena 18-2",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mjölby",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0586-V-069",
    Projektnamn: "Högby Gårdsverk",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mjölby",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2482-V-021",
    Projektnamn: "Brännlidens vindkraftspark",
    Verksamhetsutövare: "Brännliden Vind AB",
    Organisationsnummer: "559157-3422",
    Aktuella_verk: 10,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 160,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "May 01, 2018",
    Planerat_drifttagande: "Jan 01, 2020",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Skellefteå",
    Län: "Västerbottens län",
    Elområde: "Luleå",
    Senast_sparad: "Oct 05, 2021",
  },
  {
    Områdes_ID: "1885-V-007",
    Projektnamn: "Siggebohyttan",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 15,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Lindesberg",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "Dec 08, 2021",
  },
  {
    Områdes_ID: "2309-V-010",
    Projektnamn: "Nordbyn",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Krokom",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Feb 27, 2020",
  },
  {
    Områdes_ID: "2309-V-011",
    Projektnamn: "Vallsta",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "",
    Kommun: "Krokom",
    Län: "Jämtlands län",
    Elområde: "",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0604-V-017",
    Projektnamn: "Vindparken Lönhult",
    Verksamhetsutövare: "Lönhult Vind AB",
    Organisationsnummer: "556860-6759",
    Aktuella_verk: 5,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Aneby",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 15, 2021",
  },
  {
    Områdes_ID: "2521-V-004",
    Projektnamn: "Selkävaara Vindkraftpark",
    Verksamhetsutövare: "Vattenfall Vindkraft AB",
    Organisationsnummer: "556581-4273",
    Aktuella_verk: 38,
    Antal_ej_koordinatsatta_verk: 38,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Pajala",
    Län: "Norrbottens län",
    Elområde: "Luleå",
    Senast_sparad: "Dec 09, 2021",
  },
  {
    Områdes_ID: "2521-V-001",
    Projektnamn: "Lehtirova vindkraftpark",
    Verksamhetsutövare: "Lehtirova Wind AB",
    Organisationsnummer: "559000-6549",
    Aktuella_verk: 41,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 489.95,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Pajala",
    Län: "Norrbottens län",
    Elområde: "Luleå",
    Senast_sparad: "Oct 05, 2021",
  },
  {
    Områdes_ID: "0481-V-007",
    Projektnamn: "Långgrund 2",
    Verksamhetsutövare: "Svea Vind Offshore AB",
    Organisationsnummer: "559025-6136",
    Aktuella_verk: 105,
    Antal_ej_koordinatsatta_verk: 104,
    Beräknad_årsproduktion_GWh: 7799.4,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "May 30, 2026",
    Planerat_drifttagande: "Aug 30, 2028",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Oxelösund",
    Län: "Södermanlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Aug 11, 2021",
  },
  {
    Områdes_ID: "2180-V-008",
    Projektnamn: "Utposten 2",
    Verksamhetsutövare: "Svea Vind Offshore AB",
    Organisationsnummer: "559025-6136",
    Aktuella_verk: 50,
    Antal_ej_koordinatsatta_verk: 49,
    Beräknad_årsproduktion_GWh: 1800,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "May 30, 2025",
    Planerat_drifttagande: "Aug 30, 2027",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gävle",
    Län: "Gävleborgs län",
    Elområde: "Sundsvall",
    Senast_sparad: "Oct 19, 2021",
  },
  {
    Områdes_ID: "2184-V-009",
    Projektnamn: "Gretas Klackar 1",
    Verksamhetsutövare: "Svea Vind Offshore AB",
    Organisationsnummer: "559025-6136",
    Aktuella_verk: 108,
    Antal_ej_koordinatsatta_verk: 107,
    Beräknad_årsproduktion_GWh: 7899.12,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "May 30, 2026",
    Planerat_drifttagande: "Aug 30, 2028",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Hudiksvall",
    Län: "Gävleborgs län",
    Elområde: "Sundsvall",
    Senast_sparad: "Nov 15, 2021",
  },
  {
    Områdes_ID: "0861-V-055",
    Projektnamn: "Idhult",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 8,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 36,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mönsterås",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Mar 10, 2020",
  },
  {
    Områdes_ID: "0840-V-018",
    Projektnamn: "Hässleby",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mörbylånga",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2326-V-021",
    Projektnamn: "Nordkölen",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Berg",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "2326-V-003",
    Projektnamn: "Gärdesfloberget",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Berg",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Nov 25, 2021",
  },
  {
    Områdes_ID: "2326-V-022",
    Projektnamn: "Gärdesfloberget",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Berg",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "1470-V-054",
    Projektnamn: "Bystorp Vind",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2260-V-016",
    Projektnamn: "Storåsen",
    Verksamhetsutövare: "RES Renewable Norden AB",
    Organisationsnummer: "556616-0684",
    Aktuella_verk: 31,
    Antal_ej_koordinatsatta_verk: 30,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ånge",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "Sep 29, 2021",
  },
  {
    Områdes_ID: "2422-V-009",
    Projektnamn: "Sandselehöjderna Vindkraftpark",
    Verksamhetsutövare: "Vattenfall Vindkraft AB",
    Organisationsnummer: "556581-4273",
    Aktuella_verk: 100,
    Antal_ej_koordinatsatta_verk: 100,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sorsele",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "Dec 07, 2021",
  },
  {
    Områdes_ID: "2581-V-016",
    Projektnamn: "Markbygden ETT",
    Verksamhetsutövare: "Markbygden Vind AB",
    Organisationsnummer: "556710-9292",
    Aktuella_verk: 179,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 2076.4,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Nov 01, 2017",
    Planerat_drifttagande: "Dec 31, 2019",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Piteå",
    Län: "Norrbottens län",
    Elområde: "Luleå",
    Senast_sparad: "Sep 08, 2021",
  },
  {
    Områdes_ID: "1083-V-020",
    Projektnamn: "Lönneborg",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 10,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sölvesborg",
    Län: "Blekinge län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1470-V-055",
    Projektnamn: "Längjum",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 15,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1470-V-056",
    Projektnamn: "Rockagården",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1461-V-014",
    Projektnamn: "Erikstads-Björnebol",
    Verksamhetsutövare: "Dalboslättens Vind AB",
    Organisationsnummer: "556802-3385",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 10.7,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mellerud",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0665-V-019",
    Projektnamn: "Hagstad",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vaggeryd",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0584-V-027",
    Projektnamn: "Borghamn",
    Verksamhetsutövare: "Bårstad Häckenäs Vind AB",
    Organisationsnummer: "556882-1838",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 5.3,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vadstena",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Dec 13, 2021",
  },
  {
    Områdes_ID: "1265-V-024",
    Projektnamn: "Tolånga",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.01,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sjöbo",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1265-V-025",
    Projektnamn: "Fränninge",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.01,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sjöbo",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "2282-V-030",
    Projektnamn: "V1 Sörlidberget",
    Verksamhetsutövare: "Kabeko Kraft AB",
    Organisationsnummer: "556763-7151",
    Aktuella_verk: 22,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Jan 01, 2022",
    Planerat_drifttagande: "Dec 31, 2024",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kramfors",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "Oct 27, 2021",
  },
  {
    Områdes_ID: "0880-V-031",
    Projektnamn: "Söderåkra",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kalmar",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0682-V-022",
    Projektnamn: "Rävsnäs",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.05,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Nässjö",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1491-V-031",
    Projektnamn: "Humla",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.08,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ulricehamn",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1291-V-070",
    Projektnamn: "Rörum",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0.04,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Simrishamn",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1442-V-013",
    Projektnamn: "Horla",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vårgårda",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1470-V-057",
    Projektnamn: "Sparlösa",
    Verksamhetsutövare: "Sydax AB",
    Organisationsnummer: "556304-0202",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 1.7,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "1737-V-007",
    Projektnamn: "Mangslidberget",
    Verksamhetsutövare: "WPD Mangslid AB",
    Organisationsnummer: "556898-0899",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Torsby",
    Län: "Värmlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Apr 08, 2019",
  },
  {
    Områdes_ID: "0584-V-028",
    Projektnamn: "Vilseberga",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 14.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vadstena",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Dec 12, 2019",
  },
  {
    Områdes_ID: "1737-V-001",
    Projektnamn: "Stöllsäterberget",
    Verksamhetsutövare: "WPD Onshore Stöllsäterberget AB",
    Organisationsnummer: "556898-0956",
    Aktuella_verk: 15,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 177,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Jun 01, 2021",
    Planerat_drifttagande: "Jun 01, 2022",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Torsby",
    Län: "Värmlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Dec 14, 2021",
  },
  {
    Områdes_ID: "2421-V-014",
    Projektnamn: "Verkanliden",
    Verksamhetsutövare: "Fred Olsen Renewables AB",
    Organisationsnummer: "556591-2077",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Storuman",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "Dec 02, 2021",
  },
  {
    Områdes_ID: "2182-V-015",
    Projektnamn: "Gretas Klackar 2",
    Verksamhetsutövare: "Svea Vind Offshore AB",
    Organisationsnummer: "559025-6136",
    Aktuella_verk: 62,
    Antal_ej_koordinatsatta_verk: 61,
    Beräknad_årsproduktion_GWh: 2199.76,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "May 30, 2025",
    Planerat_drifttagande: "Aug 30, 2027",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Söderhamn",
    Län: "Gävleborgs län",
    Elområde: "Sundsvall",
    Senast_sparad: "Dec 03, 2021",
  },
  {
    Områdes_ID: "2283-V-036",
    Projektnamn: "Finnåberget",
    Verksamhetsutövare: "Arise Wind HoldCo 10 AB",
    Organisationsnummer: "559075-2746",
    Aktuella_verk: 26,
    Antal_ej_koordinatsatta_verk: 1,
    Beräknad_årsproduktion_GWh: 85.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sollefteå",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "Oct 20, 2021",
  },
  {
    Områdes_ID: "2404-V-003",
    Projektnamn: "Fäbodliden II",
    Verksamhetsutövare: "Fred Olsen Renewables AB",
    Organisationsnummer: "556591-2077",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vindeln",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "Sep 29, 2021",
  },
  {
    Områdes_ID: "2283-V-042",
    Projektnamn: "Storhöjden etapp 2",
    Verksamhetsutövare: "Kabeko Kraft AB",
    Organisationsnummer: "556763-7151",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 1,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Sollefteå",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "Jan 29, 2021",
  },
  {
    Områdes_ID: "2284-V-016",
    Projektnamn: "Bräntet, Solberg",
    Verksamhetsutövare: "Solberg Vindkraft AB",
    Organisationsnummer: "556769-7676",
    Aktuella_verk: 22,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 253,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Örnsköldsvik",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "May 10, 2019",
  },
  {
    Områdes_ID: "0665-V-017",
    Projektnamn: "Boarp",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 13,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 72,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "Nov 01, 2023",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vaggeryd",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 20, 2021",
  },
  {
    Områdes_ID: "1490-V-009",
    Projektnamn: "Hedared",
    Verksamhetsutövare: "BayWa r.e. Scandinavia AB",
    Organisationsnummer: "556885-6503",
    Aktuella_verk: 5,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Borås",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Dec 13, 2021",
  },
  {
    Områdes_ID: "0686-V-015",
    Projektnamn: "Bruzaholm Vindkraftpark",
    Verksamhetsutövare: "Vattenfall Vindkraft AB",
    Organisationsnummer: "556581-4273",
    Aktuella_verk: 23,
    Antal_ej_koordinatsatta_verk: 24,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Eksjö",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "Aug 02, 2021",
  },
  {
    Områdes_ID: "1491-V-030",
    Projektnamn: "Boarp och Stigared Vindkraftpark",
    Verksamhetsutövare: "Vattenfall Vindkraft AB",
    Organisationsnummer: "556581-4273",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 25,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ulricehamn",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 12, 2021",
  },
  {
    Områdes_ID: "2284-V-025",
    Projektnamn: "Stormyrberget Vindkraftpark",
    Verksamhetsutövare: "Vattenfall Vindkraft AB",
    Organisationsnummer: "556581-4273",
    Aktuella_verk: 60,
    Antal_ej_koordinatsatta_verk: 60,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Örnsköldsvik",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "Oct 19, 2021",
  },
  {
    Områdes_ID: "1452-V-009",
    Projektnamn: "Grönhult Vindkraftpark",
    Verksamhetsutövare: "Grönhult Wind AB",
    Organisationsnummer: "559275-4849",
    Aktuella_verk: 12,
    Antal_ej_koordinatsatta_verk: 12,
    Beräknad_årsproduktion_GWh: 210,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "Oct 01, 2022",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tranemo",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Dec 09, 2021",
  },
  {
    Områdes_ID: "0980-V-076",
    Projektnamn: "Näsudden Öst Vindkraftpark",
    Verksamhetsutövare: "Vattenfall Vindkraft AB",
    Organisationsnummer: "556581-4273",
    Aktuella_verk: 8,
    Antal_ej_koordinatsatta_verk: 8,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Dec 03, 2021",
  },
  {
    Områdes_ID: "1498-V-012",
    Projektnamn: "Velinga Vindkraftpark",
    Verksamhetsutövare: "Vattenfall Vindkraft AB",
    Organisationsnummer: "556581-4273",
    Aktuella_verk: 12,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tidaholm",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Dec 21, 2021",
  },
  {
    Områdes_ID: "0980-V-003",
    Projektnamn: "Näsudden Vindkraftpark",
    Verksamhetsutövare: "Vattenfall Vindkraft AB",
    Organisationsnummer: "556581-4273",
    Aktuella_verk: 8,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 27.65,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 06, 2021",
  },
  {
    Områdes_ID: "0980-V-007",
    Projektnamn: "Skåls vindpark",
    Verksamhetsutövare: "Siral System & Co AB",
    Organisationsnummer: "556171-8296",
    Aktuella_verk: 10,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 20.72,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Jan 09, 2019",
  },
  {
    Områdes_ID: "0880-V-032",
    Projektnamn: "Gräsgärde",
    Verksamhetsutövare: "Eurowind Energy",
    Organisationsnummer: "556753-6049",
    Aktuella_verk: 12,
    Antal_ej_koordinatsatta_verk: 12,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kalmar",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Sep 29, 2021",
  },
  {
    Områdes_ID: "0881-V-007",
    Projektnamn: "Högehult",
    Verksamhetsutövare: "Eurowind Energy",
    Organisationsnummer: "556753-6049",
    Aktuella_verk: 9,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Nybro",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Dec 03, 2021",
  },
  {
    Områdes_ID: "1438-V-010",
    Projektnamn: "Ed SV / Buråsen",
    Verksamhetsutövare: "Rabbalshede Kraft AB",
    Organisationsnummer: "556681-4652",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Dals-Ed",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 15, 2021",
  },
  {
    Områdes_ID: "0509-V-027",
    Projektnamn: "Ödeshög",
    Verksamhetsutövare: "Hästholmens Vind Samf. för.",
    Organisationsnummer: "716426-0940",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ödeshög",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "",
  },
  {
    Områdes_ID: "0980-V-077",
    Projektnamn: "Skålsparken Väst",
    Verksamhetsutövare: "Skålsparken AB",
    Organisationsnummer: "556882-7488",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 27,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "May 14, 2020",
  },
  {
    Områdes_ID: "0360-V-004",
    Projektnamn: "Flottskär",
    Verksamhetsutövare: "Vindströmmen AB",
    Organisationsnummer: "556761-9357",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 9.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tierp",
    Län: "Uppsala län",
    Elområde: "Stockholm",
    Senast_sparad: "Dec 08, 2021",
  },
  {
    Områdes_ID: "2523-V-006",
    Projektnamn: "Storlandet Vindkraftpark",
    Verksamhetsutövare: "Vattenfall Vindkraft AB",
    Organisationsnummer: "556581-4273",
    Aktuella_verk: 120,
    Antal_ej_koordinatsatta_verk: 120,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gällivare",
    Län: "Norrbottens län",
    Elområde: "Luleå",
    Senast_sparad: "Dec 07, 2021",
  },
  {
    Områdes_ID: "0883-V-013",
    Projektnamn: "Tribbhult",
    Verksamhetsutövare: "Statkraft Södra Vindkraft AB",
    Organisationsnummer: "556785-3873",
    Aktuella_verk: 26,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Västervik",
    Län: "Kalmar län",
    Elområde: "Stockholm",
    Senast_sparad: "Nov 23, 2021",
  },
  {
    Områdes_ID: "2581-V-012",
    Projektnamn: "Markbygden Etapp 3",
    Verksamhetsutövare: "Markbygden Vind AB",
    Organisationsnummer: "556710-9292",
    Aktuella_verk: 442,
    Antal_ej_koordinatsatta_verk: 442,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Piteå",
    Län: "Norrbottens län",
    Elområde: "Luleå",
    Senast_sparad: "Jan 20, 2021",
  },
  {
    Områdes_ID: "2581-V-017",
    Projektnamn: "Ersträsk",
    Verksamhetsutövare: "Enercon GmbH",
    Organisationsnummer: "516407-1630",
    Aktuella_verk: 68,
    Antal_ej_koordinatsatta_verk: 20,
    Beräknad_årsproduktion_GWh: 322.6,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Jan 01, 2017",
    Planerat_drifttagande: "Nov 01, 2021",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Piteå",
    Län: "Norrbottens län",
    Elområde: "Luleå",
    Senast_sparad: "Aug 30, 2021",
  },
  {
    Områdes_ID: "2084-V-004",
    Projektnamn: "Skansen",
    Verksamhetsutövare: "Tekniska verken i Linköping Vind",
    Organisationsnummer: "556853-7038",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Avesta",
    Län: "Dalarnas län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 03, 2021",
  },
  {
    Områdes_ID: "1315-V-017",
    Projektnamn: "Örken-Munkabol",
    Verksamhetsutövare: "RWE Renewables Sweden AB",
    Organisationsnummer: "556938-6864",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Halmstad",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "Oct 05, 2021",
  },
  {
    Områdes_ID: "1784-V-001",
    Projektnamn: "Stömne",
    Verksamhetsutövare: "SR Energy AB",
    Organisationsnummer: "556711-9549",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Arvika",
    Län: "Värmlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Jan 18, 2022",
  },
  {
    Områdes_ID: "2409-V-009",
    Projektnamn: "Röjmyrberget",
    Verksamhetsutövare: "Fred Olsen Renewables AB",
    Organisationsnummer: "556591-2077",
    Aktuella_verk: 5,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Robertsfors",
    Län: "Västerbottens län",
    Elområde: "Luleå",
    Senast_sparad: "Dec 07, 2021",
  },
  {
    Områdes_ID: "0581-V-018",
    Projektnamn: "Morkullberget",
    Verksamhetsutövare: "Firma Ramström Vind AB",
    Organisationsnummer: "556688-2683",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Norrköping",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Nov 10, 2021",
  },
  {
    Områdes_ID: "1489-V-011",
    Projektnamn: "Upplo",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Alingsås",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 20, 2020",
  },
  {
    Områdes_ID: "2482-V-022",
    Projektnamn: "Källbomark",
    Verksamhetsutövare: "OX2 AB",
    Organisationsnummer: "556675-7497",
    Aktuella_verk: 9,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Skellefteå",
    Län: "Västerbottens län",
    Elområde: "Luleå",
    Senast_sparad: "Dec 07, 2021",
  },
  {
    Områdes_ID: "2482-V-024",
    Projektnamn: "Smygheden",
    Verksamhetsutövare: "OX2 AB",
    Organisationsnummer: "556675-7497",
    Aktuella_verk: 10,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Skellefteå",
    Län: "Västerbottens län",
    Elområde: "Luleå",
    Senast_sparad: "Dec 07, 2021",
  },
  {
    Områdes_ID: "0513-V-005",
    Projektnamn: "Hycklinge",
    Verksamhetsutövare: "Fred Olsen Renewables AB",
    Organisationsnummer: "556591-2077",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Kinda",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Aug 11, 2021",
  },
  {
    Områdes_ID: "2521-V-005",
    Projektnamn: "Käymävaara Vindkraftspark",
    Verksamhetsutövare: "Vattenfall Vindkraft AB",
    Organisationsnummer: "556581-4273",
    Aktuella_verk: 30,
    Antal_ej_koordinatsatta_verk: 30,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Pajala",
    Län: "Norrbottens län",
    Elområde: "Luleå",
    Senast_sparad: "Dec 09, 2021",
  },
  {
    Områdes_ID: "1264-V-027",
    Projektnamn: "Rydsgård",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Skurup",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Nov 09, 2020",
  },
  {
    Områdes_ID: "1383-V-023",
    Projektnamn: "Bäckagård",
    Verksamhetsutövare: "Varberg Energi AB",
    Organisationsnummer: "556524-3010",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Varberg",
    Län: "Hallands län",
    Elområde: "Stockholm",
    Senast_sparad: "Nov 30, 2021",
  },
  {
    Områdes_ID: "1737-V-008",
    Projektnamn: "Ripfjället",
    Verksamhetsutövare: "Wpd Ripfjället",
    Organisationsnummer: "556898-0881",
    Aktuella_verk: 30,
    Antal_ej_koordinatsatta_verk: 29,
    Beräknad_årsproduktion_GWh: 670.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Malung-Sälen",
    Län: "Dalarnas län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 14, 2021",
  },
  {
    Områdes_ID: "2132-V-011",
    Projektnamn: "Nyvallsåsen",
    Verksamhetsutövare: "Firma Ramström Vind AB",
    Organisationsnummer: "556688-2683",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Nordanstig",
    Län: "Gävleborgs län",
    Elområde: "Sundsvall",
    Senast_sparad: "Dec 03, 2021",
  },
  {
    Områdes_ID: "1885-V-008",
    Projektnamn: "Munkhyttan",
    Verksamhetsutövare: "Munkhyttan Vindkraft AB",
    Organisationsnummer: "559082-8025",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Lindesberg",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "Nov 25, 2021",
  },
  {
    Områdes_ID: "0781-V-041",
    Projektnamn: "Skäckarp",
    Verksamhetsutövare: "SR Energy AB",
    Organisationsnummer: "556711-9549",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 19,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ljungby",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "Aug 04, 2021",
  },
  {
    Områdes_ID: "2161-V-016",
    Projektnamn: "Skarpen",
    Verksamhetsutövare: "OX2 AB",
    Organisationsnummer: "556675-7497",
    Aktuella_verk: 23,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ljusdal",
    Län: "Gävleborgs län",
    Elområde: "Sundsvall",
    Senast_sparad: "Sep 23, 2021",
  },
  {
    Områdes_ID: "2581-V-018",
    Projektnamn: "Blåsmark",
    Verksamhetsutövare: "Fu-Gen Blåsmark Vind AB",
    Organisationsnummer: "559191-1283",
    Aktuella_verk: 15,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 294.9,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Piteå",
    Län: "Norrbottens län",
    Elområde: "Luleå",
    Senast_sparad: "Dec 07, 2021",
  },
  {
    Områdes_ID: "0860-V-008",
    Projektnamn: "Mösjöberg",
    Verksamhetsutövare: "Dackevind AB",
    Organisationsnummer: "559073-2698",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hultsfred",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Jan 08, 2021",
  },
  {
    Områdes_ID: "3000-V-003",
    Projektnamn: "WFZ1",
    Verksamhetsutövare: "Cloudberry Offshore Wind AB",
    Organisationsnummer: "556710-7403",
    Aktuella_verk: 104,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Jun 01, 2025",
    Planerat_drifttagande: "Jun 01, 2027",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sveriges ekonomiska zon",
    Län: "Sveriges ekonomiska zon",
    Elområde: "Stockholm",
    Senast_sparad: "Nov 26, 2021",
  },
  {
    Områdes_ID: "3000-V-004",
    Projektnamn: "WFZ2",
    Verksamhetsutövare: "Cloudberry Offshore Wind AB",
    Organisationsnummer: "556710-7403",
    Aktuella_verk: 74,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sveriges ekonomiska zon",
    Län: "Sveriges ekonomiska zon",
    Elområde: "Stockholm",
    Senast_sparad: "Nov 26, 2021",
  },
  {
    Områdes_ID: "3000-V-005",
    Projektnamn: "WFZ3",
    Verksamhetsutövare: "Cloudberry Offshore Wind AB",
    Organisationsnummer: "556710-7403",
    Aktuella_verk: 141,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Jun 01, 2027",
    Planerat_drifttagande: "Jun 01, 2029",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sveriges ekonomiska zon",
    Län: "Sveriges ekonomiska zon",
    Elområde: "Stockholm",
    Senast_sparad: "Nov 26, 2021",
  },
  {
    Områdes_ID: "3000-V-006",
    Projektnamn: "WFZ4",
    Verksamhetsutövare: "Cloudberry Offshore Wind AB",
    Organisationsnummer: "556710-7403",
    Aktuella_verk: 128,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Jun 01, 2028",
    Planerat_drifttagande: "Jun 01, 2030",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sveriges ekonomiska zon",
    Län: "Sveriges ekonomiska zon",
    Elområde: "Stockholm",
    Senast_sparad: "Nov 26, 2021",
  },
  {
    Områdes_ID: "3000-V-007",
    Projektnamn: "WFZ5",
    Verksamhetsutövare: "Cloudberry Offshore Wind AB",
    Organisationsnummer: "556710-7403",
    Aktuella_verk: 124,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Jun 01, 2029",
    Planerat_drifttagande: "Jun 01, 2031",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sveriges ekonomiska zon",
    Län: "Sveriges ekonomiska zon",
    Elområde: "Stockholm",
    Senast_sparad: "Nov 26, 2021",
  },
  {
    Områdes_ID: "1495-V-049",
    Projektnamn: "Svarvarebacken",
    Verksamhetsutövare: "Firma Ramström Vind AB",
    Organisationsnummer: "556688-2683",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 1,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Skara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 06, 2020",
  },
  {
    Områdes_ID: "2482-V-025",
    Projektnamn: "Blisterliden",
    Verksamhetsutövare: "Holmen energi AB",
    Organisationsnummer: "556524-8456",
    Aktuella_verk: 33,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "Dec 01, 2024",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Skellefteå",
    Län: "Västerbottens län",
    Elområde: "Luleå",
    Senast_sparad: "Dec 07, 2021",
  },
  {
    Områdes_ID: "2361-V-042",
    Projektnamn: "Grubban",
    Verksamhetsutövare: "OX2 AB",
    Organisationsnummer: "556675-7497",
    Aktuella_verk: 36,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Härjedalen",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Nov 02, 2021",
  },
  {
    Områdes_ID: "2404-V-004",
    Projektnamn: "Rambo",
    Verksamhetsutövare: "OX2 AB",
    Organisationsnummer: "556675-7497",
    Aktuella_verk: 17,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 416.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vindeln",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "Sep 10, 2021",
  },
  {
    Områdes_ID: "0680-V-013",
    Projektnamn: "Lyckås",
    Verksamhetsutövare: "Kraftö AB",
    Organisationsnummer: "556708-7456",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Jönköping",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "Nov 18, 2021",
  },
  {
    Områdes_ID: "3000-V-009",
    Projektnamn: "Kriegers Flak",
    Verksamhetsutövare: "Vattenfall Vindkraft AB",
    Organisationsnummer: "556581-4273",
    Aktuella_verk: 45,
    Antal_ej_koordinatsatta_verk: 45,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Dec 31, 2023",
    Planerat_drifttagande: "Dec 31, 2027",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sveriges ekonomiska zon",
    Län: "Sveriges ekonomiska zon",
    Elområde: "Malmö",
    Senast_sparad: "Dec 09, 2021",
  },
  {
    Områdes_ID: "3000-V-010",
    Projektnamn: "Stora Middelgrund",
    Verksamhetsutövare: "Vattenfall Vindkraft AB",
    Organisationsnummer: "556581-4273",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Sveriges ekonomiska zon",
    Län: "Sveriges ekonomiska zon",
    Elområde: "Malmö",
    Senast_sparad: "Jan 19, 2021",
  },
  {
    Områdes_ID: "2313-V-028",
    Projektnamn: "Bleka",
    Verksamhetsutövare: "WPD Scandinavia AB",
    Organisationsnummer: "556666-3422",
    Aktuella_verk: 15,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 270,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Strömsund",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Dec 07, 2021",
  },
  {
    Områdes_ID: "0763-V-008",
    Projektnamn: "Karsamåla",
    Verksamhetsutövare: "Billyvind AB",
    Organisationsnummer: "556705-6931",
    Aktuella_verk: 9,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tingsryd",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "Oct 15, 2021",
  },
  {
    Områdes_ID: "1497-V-052",
    Projektnamn: "Dalshult",
    Verksamhetsutövare: "Stakahemmets Vind AB",
    Organisationsnummer: "556710-7882",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Hjo",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 26, 2021",
  },
  {
    Områdes_ID: "0980-V-078",
    Projektnamn: "Näs Sigsarve",
    Verksamhetsutövare: "Gotlandsvind AB",
    Organisationsnummer: "556823-4651",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Aug 04, 2021",
  },
  {
    Områdes_ID: "2404-V-005",
    Projektnamn: "Olofsberg",
    Verksamhetsutövare: "Fred Olsen Renewables AB",
    Organisationsnummer: "556591-2077",
    Aktuella_verk: 80,
    Antal_ej_koordinatsatta_verk: 79,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Vindeln",
    Län: "Västerbottens län",
    Elområde: "Luleå",
    Senast_sparad: "Aug 31, 2021",
  },
  {
    Områdes_ID: "3000-V-012",
    Projektnamn: "Kattegatt Syd",
    Verksamhetsutövare: "Vattenfall Vindkraft AB",
    Organisationsnummer: "556581-4273",
    Aktuella_verk: 80,
    Antal_ej_koordinatsatta_verk: 79,
    Beräknad_årsproduktion_GWh: 4700,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sveriges ekonomiska zon",
    Län: "Sveriges ekonomiska zon",
    Elområde: "Malmö",
    Senast_sparad: "Dec 07, 2021",
  },
  {
    Områdes_ID: "3000-V-013",
    Projektnamn: "Fyrskeppet",
    Verksamhetsutövare: "Finngrunden Offshore AB",
    Organisationsnummer: "556701-0854",
    Aktuella_verk: 100,
    Antal_ej_koordinatsatta_verk: 99,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Jan 01, 2027",
    Planerat_drifttagande: "Jan 01, 2029",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sveriges ekonomiska zon",
    Län: "Sveriges ekonomiska zon",
    Elområde: "Sundsvall",
    Senast_sparad: "Oct 20, 2021",
  },
  {
    Områdes_ID: "1497-V-053",
    Projektnamn: "Vindkraftpark Fågelås",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 10,
    Antal_ej_koordinatsatta_verk: 10,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Hjo",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 30, 2021",
  },
  {
    Områdes_ID: "1262-V-009",
    Projektnamn: "Alnarp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Lomma",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "1407-V-003",
    Projektnamn: "Björkö",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Apr 01, 2020",
    Planerat_drifttagande: "Jul 31, 2020",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Öckerö",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Feb 19, 2021",
  },
  {
    Områdes_ID: "1292-V-034",
    Projektnamn: "Össjö",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Ängelholm",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Dec 08, 2021",
  },
  {
    Områdes_ID: "0760-V-045",
    Projektnamn: "Hökanäs-Hovgård",
    Verksamhetsutövare: "SR Energy AB",
    Organisationsnummer: "556711-9549",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Uppvidinge",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "Dec 03, 2021",
  },
  {
    Områdes_ID: "1280-V-010",
    Projektnamn: "Yttre ringvägen",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Malmö",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Dec 03, 2021",
  },
  {
    Områdes_ID: "3000-V-014",
    Projektnamn: "Galatea-Galene",
    Verksamhetsutövare: "OX2 AB",
    Organisationsnummer: "556675-7497",
    Aktuella_verk: 101,
    Antal_ej_koordinatsatta_verk: 100,
    Beräknad_årsproduktion_GWh: 5999.4,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sveriges ekonomiska zon",
    Län: "Sveriges ekonomiska zon",
    Elområde: "Stockholm",
    Senast_sparad: "Dec 07, 2021",
  },
  {
    Områdes_ID: "0861-V-061",
    Projektnamn: "Torp",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mönsterås",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Dec 03, 2021",
  },
  {
    Områdes_ID: "1861-V-010",
    Projektnamn: "Odensvi",
    Verksamhetsutövare: "SR Energy AB",
    Organisationsnummer: "556711-9549",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Hallsberg",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 20, 2021",
  },
  {
    Områdes_ID: "1882-V-015",
    Projektnamn: "Lilla Kettstaka",
    Verksamhetsutövare: "Kettstaka Vind AB",
    Organisationsnummer: "556804-7293",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Askersund",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "Mar 19, 2021",
  },
  {
    Områdes_ID: "2161-V-017",
    Projektnamn: "Källmyrberget",
    Verksamhetsutövare: "RWE Renewables Sweden AB",
    Organisationsnummer: "556938-6864",
    Aktuella_verk: 30,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ljusdal",
    Län: "Gävleborgs län",
    Elområde: "Sundsvall",
    Senast_sparad: "Sep 20, 2021",
  },
  {
    Områdes_ID: "1486-V-012",
    Projektnamn: "Vindpark Femstenaberg",
    Verksamhetsutövare: "Rabbalshede Kraft AB",
    Organisationsnummer: "556681-4652",
    Aktuella_verk: 7,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 123.2,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Mar 01, 2021",
    Planerat_drifttagande: "Dec 01, 2022",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Strömstad",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 21, 2021",
  },
  {
    Områdes_ID: "0484-V-015",
    Projektnamn: "Stora Uvberget",
    Verksamhetsutövare: "RES Renewable Norden AB",
    Organisationsnummer: "556616-0684",
    Aktuella_verk: 6,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Eskilstuna",
    Län: "Södermanlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Aug 11, 2021",
  },
  {
    Områdes_ID: "0562-V-003",
    Projektnamn: "Högsjön",
    Verksamhetsutövare: "Holmen energi AB",
    Organisationsnummer: "556524-8456",
    Aktuella_verk: 14,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 350,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Finspång",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 26, 2021",
  },
  {
    Områdes_ID: "0562-V-004",
    Projektnamn: "Klintaberg",
    Verksamhetsutövare: "Holmen energi AB",
    Organisationsnummer: "556524-8456",
    Aktuella_verk: 18,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 450,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Finspång",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 26, 2021",
  },
  {
    Områdes_ID: "0581-V-019",
    Projektnamn: "Skybygget",
    Verksamhetsutövare: "Holmen energi AB",
    Organisationsnummer: "556524-8456",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Norrköping",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 26, 2021",
  },
  {
    Områdes_ID: "1473-V-025",
    Projektnamn: "Fägremo",
    Verksamhetsutövare: "Rabbalshede Kraft AB",
    Organisationsnummer: "556681-4652",
    Aktuella_verk: 10,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Töreboda",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Dec 02, 2021",
  },
  {
    Områdes_ID: "2183-V-009",
    Projektnamn: "Lillås",
    Verksamhetsutövare: "OX2 AB",
    Organisationsnummer: "556675-7497",
    Aktuella_verk: 29,
    Antal_ej_koordinatsatta_verk: 28,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Bollnäs",
    Län: "Gävleborgs län",
    Elområde: "Sundsvall",
    Senast_sparad: "Aug 02, 2021",
  },
  {
    Områdes_ID: "2183-V-010",
    Projektnamn: "Norrberget",
    Verksamhetsutövare: "OX2 AB",
    Organisationsnummer: "556675-7497",
    Aktuella_verk: 12,
    Antal_ej_koordinatsatta_verk: 11,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Bollnäs",
    Län: "Gävleborgs län",
    Elområde: "Sundsvall",
    Senast_sparad: "Aug 02, 2021",
  },
  {
    Områdes_ID: "2181-V-006",
    Projektnamn: "Galmsjömyran",
    Verksamhetsutövare: "Njordr AB",
    Organisationsnummer: "559214-5923",
    Aktuella_verk: 40,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sandviken",
    Län: "Gävleborgs län",
    Elområde: "Stockholm",
    Senast_sparad: "Nov 03, 2021",
  },
  {
    Områdes_ID: "1380-V-021",
    Projektnamn: "Gräsås",
    Verksamhetsutövare: "RWE Renewables Sweden AB",
    Organisationsnummer: "556938-6864",
    Aktuella_verk: 7,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Halmstad",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "Dec 03, 2021",
  },
  {
    Områdes_ID: "0763-V-009",
    Projektnamn: "Uråsa Vindbrukspark",
    Verksamhetsutövare: "BayWa r.e. Scandinavia AB",
    Organisationsnummer: "556885-6503",
    Aktuella_verk: 5,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tingsryd",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "Oct 15, 2021",
  },
  {
    Områdes_ID: "2422-V-010",
    Projektnamn: "Jiltjaur",
    Verksamhetsutövare: "Fred Olsen Renewables AB",
    Organisationsnummer: "556591-2077",
    Aktuella_verk: 72,
    Antal_ej_koordinatsatta_verk: 71,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sorsele",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "Dec 07, 2021",
  },
  {
    Områdes_ID: "2463-V-014",
    Projektnamn: "Storberget 2",
    Verksamhetsutövare: "Åsele Vindkraft AB",
    Organisationsnummer: "556721-0082",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Åsele",
    Län: "Västerbottens län",
    Elområde: "Sundsvall",
    Senast_sparad: "Dec 02, 2021",
  },
  {
    Områdes_ID: "2305-V-031",
    Projektnamn: "Kusberget",
    Verksamhetsutövare: "Kusberget Vindkraft AB",
    Organisationsnummer: "556872-2978",
    Aktuella_verk: 17,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 383.01,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Bräcke",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Aug 02, 2021",
  },
  {
    Områdes_ID: "0980-V-079",
    Projektnamn: "Kapelludden",
    Verksamhetsutövare: "Vindbolaget i När AB",
    Organisationsnummer: "556947-3373",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 8.45,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Gotland",
    Län: "Gotlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 08, 2021",
  },
  {
    Områdes_ID: "3000-V-015",
    Projektnamn: "Stora Middelgrund",
    Verksamhetsutövare: "Vattenfall Vindkraft AB",
    Organisationsnummer: "556581-4273",
    Aktuella_verk: 50,
    Antal_ej_koordinatsatta_verk: 49,
    Beräknad_årsproduktion_GWh: 3600,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sveriges ekonomiska zon",
    Län: "Sveriges ekonomiska zon",
    Elområde: "Malmö",
    Senast_sparad: "Dec 07, 2021",
  },
  {
    Områdes_ID: "3000-V-002",
    Projektnamn: "Sylen",
    Verksamhetsutövare: "Svea Vind Offshore AB",
    Organisationsnummer: "559025-6136",
    Aktuella_verk: 261,
    Antal_ej_koordinatsatta_verk: 260,
    Beräknad_årsproduktion_GWh: 19000.8,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "May 30, 2027",
    Planerat_drifttagande: "Aug 30, 2028",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "",
    Län: "",
    Elområde: "Sundsvall",
    Senast_sparad: "Aug 11, 2021",
  },
  {
    Områdes_ID: "0885-V-046",
    Projektnamn: "Nedra Sandby",
    Verksamhetsutövare: "Firma Ramström Vind AB",
    Organisationsnummer: "556688-2683",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Borgholm",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Feb 11, 2021",
  },
  {
    Områdes_ID: "2260-V-017",
    Projektnamn: "Marktjärn",
    Verksamhetsutövare: "OX2 AB",
    Organisationsnummer: "556675-7497",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ånge",
    Län: "Västernorrland län",
    Elområde: "Sundsvall",
    Senast_sparad: "Sep 21, 2021",
  },
  {
    Områdes_ID: "3000-V-016",
    Projektnamn: "Poseidon Syd",
    Verksamhetsutövare: "Zephyr Vind AB",
    Organisationsnummer: "559276-9987",
    Aktuella_verk: 19,
    Antal_ej_koordinatsatta_verk: 18,
    Beräknad_årsproduktion_GWh: 1111.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Jan 01, 2030",
    Planerat_drifttagande: "Jan 01, 2032",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sveriges ekonomiska zon",
    Län: "Sveriges ekonomiska zon",
    Elområde: "Stockholm",
    Senast_sparad: "Jun 16, 2021",
  },
  {
    Områdes_ID: "3000-V-017",
    Projektnamn: "Poseidon Nord",
    Verksamhetsutövare: "Zephyr Vind AB",
    Organisationsnummer: "559276-9987",
    Aktuella_verk: 75,
    Antal_ej_koordinatsatta_verk: 74,
    Beräknad_årsproduktion_GWh: 4387.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Jan 01, 2030",
    Planerat_drifttagande: "Jan 01, 2032",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sveriges ekonomiska zon",
    Län: "Sveriges ekonomiska zon",
    Elområde: "Stockholm",
    Senast_sparad: "Jun 16, 2021",
  },
  {
    Områdes_ID: "3000-V-018",
    Projektnamn: "Eystrasalt",
    Verksamhetsutövare: "wpd Offshore Stockholm AB",
    Organisationsnummer: "556764-3894",
    Aktuella_verk: 286,
    Antal_ej_koordinatsatta_verk: 285,
    Beräknad_årsproduktion_GWh: 13799.5,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sveriges ekonomiska zon",
    Län: "Sveriges ekonomiska zon",
    Elområde: "Sundsvall",
    Senast_sparad: "Nov 19, 2021",
  },
  {
    Områdes_ID: "1495-V-050",
    Projektnamn: "Sävedalen",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Skara",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Mar 10, 2021",
  },
  {
    Områdes_ID: "0840-V-102",
    Projektnamn: "Kastlösa",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Mörbylånga",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Apr 01, 2021",
  },
  {
    Områdes_ID: "2303-V-026",
    Projektnamn: "Granåsen",
    Verksamhetsutövare: "Jämtvind AB",
    Organisationsnummer: "556784-8808",
    Aktuella_verk: 6,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 153,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ragunda",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Aug 02, 2021",
  },
  {
    Områdes_ID: "0860-V-009",
    Projektnamn: "Aspeland",
    Verksamhetsutövare: "Njordr AB",
    Organisationsnummer: "559214-5923",
    Aktuella_verk: 17,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Hultsfred",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Dec 03, 2021",
  },
  {
    Områdes_ID: "3000-V-019",
    Projektnamn: "Skåne havsvindpark",
    Verksamhetsutövare: "Skåne Offshore Windfarm AB",
    Organisationsnummer: "559258-2059",
    Aktuella_verk: 125,
    Antal_ej_koordinatsatta_verk: 124,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sveriges ekonomiska zon",
    Län: "Sveriges ekonomiska zon",
    Elområde: "Malmö",
    Senast_sparad: "May 06, 2021",
  },
  {
    Områdes_ID: "1484-V-014",
    Projektnamn: "Humlekärr",
    Verksamhetsutövare: "Projektör ej registrerad",
    Organisationsnummer: "999999-9999",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Lysekil",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Dec 02, 2021",
  },
  {
    Områdes_ID: "1270-V-051",
    Projektnamn: "Bondrum",
    Verksamhetsutövare: "Tekniska verken i Linköping Vind",
    Organisationsnummer: "556853-7038",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Tomelilla",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Oct 07, 2021",
  },
  {
    Områdes_ID: "1415-V-023",
    Projektnamn: "Rämma",
    Verksamhetsutövare: "Tekniska verken i Linköping Vind",
    Organisationsnummer: "556853-7038",
    Aktuella_verk: 2,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Stenungsund",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Dec 03, 2021",
  },
  {
    Områdes_ID: "0581-V-020",
    Projektnamn: "Jättebergen",
    Verksamhetsutövare: "Tekniska verken i Linköping Vind",
    Organisationsnummer: "556853-7038",
    Aktuella_verk: 15,
    Antal_ej_koordinatsatta_verk: 14,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Norrköping",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Dec 03, 2021",
  },
  {
    Områdes_ID: "2184-V-010",
    Projektnamn: "Silja",
    Verksamhetsutövare: "Arise Windpower AB",
    Organisationsnummer: "556274-6726",
    Aktuella_verk: 6,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Hudiksvall",
    Län: "Gävleborgs län",
    Elområde: "Sundsvall",
    Senast_sparad: "Dec 03, 2021",
  },
  {
    Områdes_ID: "1737-V-009",
    Projektnamn: "Röknölen",
    Verksamhetsutövare: "Fred Olsen Renewables AB",
    Organisationsnummer: "556591-2077",
    Aktuella_verk: 57,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Torsby",
    Län: "Värmlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Dec 03, 2021",
  },
  {
    Områdes_ID: "2182-V-016",
    Projektnamn: "Storgrundet",
    Verksamhetsutövare: "Storgrundet Offshore AB",
    Organisationsnummer: "556709-7554",
    Aktuella_verk: 51,
    Antal_ej_koordinatsatta_verk: 50,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Söderhamn",
    Län: "Gävleborgs län",
    Elområde: "Sundsvall",
    Senast_sparad: "Dec 03, 2021",
  },
  {
    Områdes_ID: "2181-V-007",
    Projektnamn: "Stormossen",
    Verksamhetsutövare: "wpd Stormossen AB",
    Organisationsnummer: "556800-8691",
    Aktuella_verk: 32,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 574.4,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sandviken",
    Län: "Gävleborgs län",
    Elområde: "Stockholm",
    Senast_sparad: "Dec 03, 2021",
  },
  {
    Områdes_ID: "3000-V-020",
    Projektnamn: "Mareld",
    Verksamhetsutövare: "Hexicon AB",
    Organisationsnummer: "556795-9894",
    Aktuella_verk: 230,
    Antal_ej_koordinatsatta_verk: 229,
    Beräknad_årsproduktion_GWh: 11999.1,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sveriges ekonomiska zon",
    Län: "Sveriges ekonomiska zon",
    Elområde: "Stockholm",
    Senast_sparad: "Jun 01, 2021",
  },
  {
    Områdes_ID: "3000-V-021",
    Projektnamn: "Dyning",
    Verksamhetsutövare: "Hexicon AB",
    Organisationsnummer: "556795-9894",
    Aktuella_verk: 200,
    Antal_ej_koordinatsatta_verk: 199,
    Beräknad_årsproduktion_GWh: 10000,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sveriges ekonomiska zon",
    Län: "Sveriges ekonomiska zon",
    Elområde: "Stockholm",
    Senast_sparad: "Jun 02, 2021",
  },
  {
    Områdes_ID: "2034-V-004",
    Projektnamn: "Orsa Norr",
    Verksamhetsutövare: "Dala Vind AB",
    Organisationsnummer: "556696-6940",
    Aktuella_verk: 96,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 2400,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Orsa",
    Län: "Dalarnas län",
    Elområde: "Sundsvall",
    Senast_sparad: "Dec 03, 2021",
  },
  {
    Områdes_ID: "3000-V-022",
    Projektnamn: "Triton",
    Verksamhetsutövare: "OX2 AB",
    Organisationsnummer: "556675-7497",
    Aktuella_verk: 129,
    Antal_ej_koordinatsatta_verk: 128,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sveriges ekonomiska zon",
    Län: "Sveriges ekonomiska zon",
    Elområde: "Malmö",
    Senast_sparad: "Jun 07, 2021",
  },
  {
    Områdes_ID: "1983-V-002",
    Projektnamn: "Tretjärnsberget",
    Verksamhetsutövare: "OX2 AB",
    Organisationsnummer: "556675-7497",
    Aktuella_verk: 25,
    Antal_ej_koordinatsatta_verk: 24,
    Beräknad_årsproduktion_GWh: 850,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Köping",
    Län: "Västmanlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Nov 26, 2021",
  },
  {
    Områdes_ID: "1883-V-002",
    Projektnamn: "Kedjeåsen",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Karlskoga",
    Län: "Örebro län",
    Elområde: "Stockholm",
    Senast_sparad: "Dec 16, 2021",
  },
  {
    Områdes_ID: "0781-V-042",
    Projektnamn: "Sällebråten",
    Verksamhetsutövare: "Arise Windpower AB",
    Organisationsnummer: "556274-6726",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Ljungby",
    Län: "Kronobergs län",
    Elområde: "Malmö",
    Senast_sparad: "Dec 03, 2021",
  },
  {
    Områdes_ID: "1287-V-046",
    Projektnamn: "Jordberga",
    Verksamhetsutövare: "Arnold Towers Holding AB",
    Organisationsnummer: "556789-7243",
    Aktuella_verk: 3,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Trelleborg",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Dec 07, 2021",
  },
  {
    Områdes_ID: "0860-V-010",
    Projektnamn: "Tönshult",
    Verksamhetsutövare: "SR Energy AB",
    Organisationsnummer: "556711-9549",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Hultsfred",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Oct 15, 2021",
  },
  {
    Områdes_ID: "1280-V-011",
    Projektnamn: "Sjollen",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 20,
    Antal_ej_koordinatsatta_verk: 19,
    Beräknad_årsproduktion_GWh: 1040,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Malmö",
    Län: "Skåne län",
    Elområde: "Malmö",
    Senast_sparad: "Aug 05, 2021",
  },
  {
    Områdes_ID: "1382-V-050",
    Projektnamn: "Kila",
    Verksamhetsutövare: "EnBW Sverige Vind AB",
    Organisationsnummer: "559132-8884",
    Aktuella_verk: 9,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Falkenberg",
    Län: "Hallands län",
    Elområde: "Malmö",
    Senast_sparad: "Aug 02, 2021",
  },
  {
    Områdes_ID: "3000-V-023",
    Projektnamn: "Kultje",
    Verksamhetsutövare: "Hexicon AB",
    Organisationsnummer: "556795-9894",
    Aktuella_verk: 220,
    Antal_ej_koordinatsatta_verk: 219,
    Beräknad_årsproduktion_GWh: 11000,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sveriges ekonomiska zon",
    Län: "Sveriges ekonomiska zon",
    Elområde: "Stockholm",
    Senast_sparad: "Aug 04, 2021",
  },
  {
    Områdes_ID: "1490-V-010",
    Projektnamn: "Våssberg",
    Verksamhetsutövare: "Zephyr Vind AB",
    Organisationsnummer: "559276-9987",
    Aktuella_verk: 1,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Borås",
    Län: "Västra Götalands län",
    Elområde: "Stockholm",
    Senast_sparad: "Dec 02, 2021",
  },
  {
    Områdes_ID: "0682-V-023",
    Projektnamn: "Hjärtsöla",
    Verksamhetsutövare: "Billyvind AB",
    Organisationsnummer: "556705-6931",
    Aktuella_verk: 0,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Ja",
    Kommun: "Nässjö",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "Sep 09, 2021",
  },
  {
    Områdes_ID: "0562-V-005",
    Projektnamn: "Mörtsjö",
    Verksamhetsutövare: "SR Energy AB",
    Organisationsnummer: "556711-9549",
    Aktuella_verk: 34,
    Antal_ej_koordinatsatta_verk: 33,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Finspång",
    Län: "Östergötlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Jan 18, 2022",
  },
  {
    Områdes_ID: "0880-V-033",
    Projektnamn: "Ebbegärde",
    Verksamhetsutövare: "SR Energy AB",
    Organisationsnummer: "556711-9549",
    Aktuella_verk: 25,
    Antal_ej_koordinatsatta_verk: 24,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Kalmar",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Jan 18, 2022",
  },
  {
    Områdes_ID: "0861-V-062",
    Projektnamn: "Kärnebo",
    Verksamhetsutövare: "SR Energy AB",
    Organisationsnummer: "556711-9549",
    Aktuella_verk: 7,
    Antal_ej_koordinatsatta_verk: 6,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Mönsterås",
    Län: "Kalmar län",
    Elområde: "Malmö",
    Senast_sparad: "Jan 18, 2022",
  },
  {
    Områdes_ID: "3000-V-024",
    Projektnamn: "Aurora",
    Verksamhetsutövare: "OX2 AB",
    Organisationsnummer: "556675-7497",
    Aktuella_verk: 370,
    Antal_ej_koordinatsatta_verk: 369,
    Beräknad_årsproduktion_GWh: 23999.97,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sveriges ekonomiska zon",
    Län: "Sveriges ekonomiska zon",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 14, 2021",
  },
  {
    Områdes_ID: "0680-V-014",
    Projektnamn: "Mällby",
    Verksamhetsutövare: "Firma Ramström Vind AB",
    Organisationsnummer: "556688-2683",
    Aktuella_verk: 4,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Jönköping",
    Län: "Jönköpings län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 15, 2021",
  },
  {
    Områdes_ID: "2326-V-023",
    Projektnamn: "Nordkölen",
    Verksamhetsutövare: "Eolus Vind AB",
    Organisationsnummer: "556389-3956",
    Aktuella_verk: 34,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 680,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Berg",
    Län: "Jämtlands län",
    Elområde: "Sundsvall",
    Senast_sparad: "Oct 20, 2021",
  },
  {
    Områdes_ID: "1730-V-002",
    Projektnamn: "Björnetjärnsberget",
    Verksamhetsutövare: "Cloudberry Wind AB",
    Organisationsnummer: "556795-5074",
    Aktuella_verk: 18,
    Antal_ej_koordinatsatta_verk: 0,
    Beräknad_årsproduktion_GWh: 0,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "",
    Planerat_drifttagande: "",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Eda",
    Län: "Värmlands län",
    Elområde: "Stockholm",
    Senast_sparad: "Oct 25, 2021",
  },
  {
    Områdes_ID: "3000-V-025",
    Projektnamn: "Vidar",
    Verksamhetsutövare: "Zephyr Vind AB",
    Organisationsnummer: "559276-9987",
    Aktuella_verk: 91,
    Antal_ej_koordinatsatta_verk: 90,
    Beräknad_årsproduktion_GWh: 5500.04,
    Uppmätt_årsproduktion_GWh: 0,
    Planerad_byggstart: "Apr 01, 2027",
    Planerat_drifttagande: "Oct 01, 2029",
    Området_ej_aktuellt_i_sin_helhet: "Nej",
    Kommun: "Sveriges ekonomiska zon",
    Län: "Sveriges ekonomiska zon",
    Elområde: "Stockholm",
    Senast_sparad: "Nov 10, 2021",
  }
];
